import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@mui/material/TableSortLabel';
import ReactLoading from 'react-loading';
import ApplicationConcessionRequestService from '@services/ApplicationConcessionRequestService';
import { toast } from 'react-toastify';
import Moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: '14px', // Set font size for the body cells only
  },
}));

const useStyles2 = {
  minWidth: 500,
};

const CustomPaginationActionsTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const appContext = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);
  const [activeFieldForSorting, setActiveFieldForSorting] = useState();
  const mountedRef = useRef();

  useEffect(() => {
    if (!mountedRef.current) return;
    update();
  }, [orderBy, orderDir, rowsPerPage]);

  useEffect(() => {
    if (!mountedRef.current) return;
    update();
  }, [page]);

  useEffect(() => {
    mountedRef.current = true;
    setPage(1);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const update = () => {
    setIsLoading(true);

    ApplicationConcessionRequestService.search(
      { applicationNo: applicationId },
      orderBy,
      orderDir,
      page,
      null,
      rowsPerPage,
    )
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteItem = () => {
    if (!(appContext?.userPermissions?.indexOf('Delete Concession Requests') > -1)) {
      toggle();
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    ApplicationConcessionRequestService.delete(givenRows[rowIndex].publicId)
      .then((_) => {
        let items = [...givenRows];
        items.splice(rowIndex, 1);
        setRows(items);
        toggle();
        toast('Concession request has been successfully deleted.', { type: 'success' });
      })
      .catch((_) => {
        toggle();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const parseDollarString = (val) => {
    return val ? moneyFormatter.format(parseFloat(val).toFixed(2)) : '-';
    //return val? '$' + parseFloat(val).toFixed(2) : "-";
  };

  return (
    <div>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
          <ModalBody>Are you sure you want to delete this Concession Request?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deleteItem}>
              Delete
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <TableContainer component={Paper}>
        <Table className={useStyles2} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Id</StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('requestDate');
                  setActiveFieldForSorting('requestDate');
                }}
              >
                REQUEST DATE
                <TableSortLabel
                  active={activeFieldForSorting === 'requestDate' ? true : false}
                  direction={
                    activeFieldForSorting === 'requestDate' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('requestDate')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('concessionPayoffAmount');
                  setActiveFieldForSorting('concessionPayoffAmount');
                }}
              >
                CONCESSION PAYOFF AMOUNT
                <TableSortLabel
                  active={activeFieldForSorting === 'concessionPayoffAmount' ? true : false}
                  direction={
                    activeFieldForSorting === 'concessionPayoffAmount'
                      ? orderDir.toLowerCase()
                      : 'asc'
                  }
                  onClick={() => arrange('concessionPayoffAmount')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('concessionAmount');
                  setActiveFieldForSorting('concessionAmount');
                }}
              >
                CONCESSION AMOUNT
                <TableSortLabel
                  active={activeFieldForSorting === 'concessionAmount' ? true : false}
                  direction={
                    activeFieldForSorting === 'concessionAmount' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('concessionAmount')}
                ></TableSortLabel>
              </StyledTableCell>
              {/* <StyledTableCell
                onClick={() => {
                  arrange('notes');
                  setActiveFieldForSorting('notes');
                }}
              >
                NOTES
                <TableSortLabel
                  active={activeFieldForSorting === 'notes' ? true : false}
                  direction={activeFieldForSorting === 'notes' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('notes')}
                ></TableSortLabel>
              </StyledTableCell> */}

              <StyledTableCell
                onClick={() => {
                  arrange('isSentforApproval');
                  setActiveFieldForSorting('isSentforApproval');
                }}
              >
                SENT FOR APPR.
                <TableSortLabel
                  active={activeFieldForSorting === 'isSentforApproval' ? true : false}
                  direction={
                    activeFieldForSorting === 'isSentforApproval' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('isSentforApproval')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('isApproved');
                  setActiveFieldForSorting('isApproved');
                }}
              >
                APPROVED
                <TableSortLabel
                  active={activeFieldForSorting === 'isApproved' ? true : false}
                  direction={
                    activeFieldForSorting === 'isApproved' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('isApproved')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('approvalReviewDate');
                  setActiveFieldForSorting('approvalReviewDate');
                }}
              >
                REVIEW DATE
                <TableSortLabel
                  active={activeFieldForSorting === 'approvalReviewDate' ? true : false}
                  direction={
                    activeFieldForSorting === 'approvalReviewDate' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('approvalReviewDate')}
                ></TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                onClick={() => {
                  arrange('approvalReviewedByName');
                  setActiveFieldForSorting('approvalReviewedByName');
                }}
              >
                REVIEW BY
                <TableSortLabel
                  active={activeFieldForSorting === 'approvalReviewedByName' ? true : false}
                  direction={
                    activeFieldForSorting === 'approvalReviewedByName'
                      ? orderDir.toLowerCase()
                      : 'asc'
                  }
                  onClick={() => arrange('approvalReviewedByName')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right">ACTIONS</StyledTableCell>
            </TableRow>
          </TableHead>
          {isLoading === false && (
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>
                      {row?.requestDate &&
                        Moment(row?.requestDate?.split('T')[0]).format('MM-DD-YYYY')}{' '}
                      {Moment(row?.requestDate).format('h:mm:ss A')}
                    </TableCell>
                    <TableCell>
                      {row.concessionPayoffAmount !== null
                        ? parseDollarString(row.concessionPayoffAmount)
                        : null}
                    </TableCell>
                    <TableCell>
                      {row.concessionAmount !== null
                        ? parseDollarString(row.concessionAmount)
                        : null}
                    </TableCell>
                    {/* <TableCell>{row.notes}</TableCell> */}
                    <TableCell>{row.isSentforApproval == true ? 'YES' : 'NO'}</TableCell>
                    <TableCell>{row.isApproved == true ? 'YES' : 'NO'}</TableCell>
                    <TableCell>
                      {row?.approvalReviewDate &&
                        Moment(row?.approvalReviewDate?.split('T')[0]).format('MM-DD-YYYY')}
                    </TableCell>
                    <TableCell>{row.approvalReviewedByName}</TableCell>
                    <TableCell className="p-2" align="right">
                      <a
                        className="btn mr-1 btn-sm"
                        onClick={() =>
                          navigate(
                            `/underwriting/applications/${applicationId}/payoffs/concession-request/${row.publicId}`,
                          )
                        }
                      >
                        <EditIcon />
                      </a>

                      {/* {row.isSentforApproval == true || row.isApproved == true ? undefined : ( */}
                      <a
                        className="btn btn-sm"
                        onClick={() => {
                          setRowIndex(index);
                          setGivenRows(rows);
                          toggle();
                        }}
                      >
                        <DeleteIcon style={{ color: 'var(--red)' }} />
                      </a>
                      {/* )} */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {isLoading && (
            <TableBody>
              <TableRow>
                <td></td>
                <td>
                  <ReactLoading type="bars" color="#7E8299" />
                </td>
                <td></td>
              </TableRow>
            </TableBody>
          )}

          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      <div className="float-left mt-5">
        <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
          {[5, 10, 15, 25, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="float-right mt-5">
        <Pagination
          count={pages}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default CustomPaginationActionsTable;
