import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '@contexts/AppContext';

const ApplicationsNewMenu = (props) => {
  const appContext = useContext(AppContext);

  const applicationNo = props.applicationId;

  let applicationId = appContext.currentApplication
    ? appContext.currentApplication.applicationNo
    : 'new-portfolio';

  if (props.applicationId) applicationId = props.applicationId;

  useEffect(() => {
    const makeInnerMenuItemActive = () => {
      const { menuItemIndex } = props;
      const aElements = document.getElementById('applications-new-menu').querySelectorAll('li a');
      if (aElements[menuItemIndex]) {
        aElements[menuItemIndex].classList.add('active');
      }
    };

    // const makeOuterMenusActive = () => {
    //   document.querySelector('#menuitem-underwriting').classList.add('active');
    //   document.querySelector('#kt_header_tab_2 li').classList.add('menu-item-active');
    // };

    makeInnerMenuItemActive();
    // makeOuterMenusActive();
  });

  return (
    <ul id="applications-new-menu" className="nav nav-tabs nav-tabs-line mb-10">
      <li className="nav-item">
        <Link to={'/underwriting/applications/' + applicationId + '/attorney'} className="nav-link">
          Attorney
        </Link>
      </li>
      {applicationId !== 'new-application' && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/plaintiff'}
            className="nav-link"
          >
            Plaintiff
          </Link>
        </li>
      )}
      {applicationId !== 'new-application' && appContext.newAppllicationStep > 1 && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/accident'}
            className="nav-link"
          >
            Accident
          </Link>
        </li>
      )}
      {applicationId !== 'new-application' && appContext.newAppllicationStep > 2 && (
        <li className="nav-item">
          <Link to={'/underwriting/applications/' + applicationId + '/injury'} className="nav-link">
            Injury
          </Link>
        </li>
      )}
      {applicationId !== 'new-application' && appContext.newAppllicationStep > 3 && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/coverage'}
            className="nav-link"
          >
            Coverage
          </Link>
        </li>
      )}
      {applicationId !== 'new-application' && appContext.newAppllicationStep > 4 && (
        <li className="nav-item">
          <Link to={'/underwriting/applications/' + applicationId + '/misc'} className="nav-link">
            Misc
          </Link>
        </li>
      )}
      {applicationId !== 'new-application' && appContext.newAppllicationStep > 5 && (
        <li className="nav-item">
          <Link to={'/underwriting/applications/' + applicationId + '/liens'} className="nav-link">
            Liens
          </Link>
        </li>
      )}
      {applicationId !== 'new-application' && appContext.newAppllicationStep > 0 && (
        <li className="nav-item">
          <Link to={'/underwriting/applications/' + applicationId + '/notes'} className="nav-link">
            Notes
          </Link>
        </li>
      )}
      {applicationId !== 'new-application' && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/financial-terms'}
            className="nav-link financial-terms"
          >
            Financial Terms
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/disbursement'}
            className="nav-link disbs"
          >
            Disbs.
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/ims'}
            className="nav-link ims"
          >
            IMS
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/activity'}
            className="nav-link activity"
          >
            Activity
          </Link>
        </li>
      )}
    </ul>
  );
};

export default ApplicationsNewMenu;
