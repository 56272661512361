import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Col } from 'reactstrap';
import Grid from './Grid';

import AppContext from '@contexts/AppContext';

const PortfoliosList = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-portfolios').click();
    document
      .querySelector('#kt_header_tab_3 > div > div > ul > li:nth-child(4)')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  });

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Portfolios</h1>
              </div>
              <Link
                to="/portfolios/new-portfolio"
                className="btn btn-primary "
                style={{
                  backgroundColor: 'var(--green)',
                  borderColor: 'var(--green)',
                }}
              >
                New Portfolio
              </Link>
            </div>
            <Row>
              <Col md="12">
                <Grid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfoliosList;
