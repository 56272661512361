import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  Label,
  Alert,
  Button,
  Form,
  FormGroup,
  Container,
  Table,
} from 'reactstrap';
import ProcessingFeeRatesService from '@services/ProcessingFeeRatesService';
import ApplicationAdvanceService from '@services/ApplicationAdvanceService';
import ApplicationProcessingFeeRates from '@services/ApplicationProcessingFeeRates';
import ApplicationsService from '@services/ApplicationsService';
import AppContext from '@contexts/AppContext';

const ProcessingFeesTable = () => {
  const { applicationId, advanceId } = useParams();
  const navigate = useNavigate();
  const [processingFeeRatesList, setProcessingFeeRatesList] = useState([]);
  const [applicationPublicId, setApplicationPublicId] = useState(null);
  const appContext = useContext(AppContext);

  const handleAddProcessingFeeRatesList = () => {
    let updatedProcessingFeeRatesList = [...processingFeeRatesList];
    updatedProcessingFeeRatesList.push({});
    setProcessingFeeRatesList(updatedProcessingFeeRatesList);
  };

  const handleDeleteProcessingFee = (index) => {
    let updatedProcessingFeeRatesList = [...processingFeeRatesList];
    updatedProcessingFeeRatesList.splice(index, 1);
    setProcessingFeeRatesList(updatedProcessingFeeRatesList);
  };

  useEffect(() => {
    // get application publicId from applicationNo

    ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 10).then(
      (response) => {
        setApplicationPublicId(response.data.data[0].publicId);
      },
    );

    // business rule here - if first advance of application, prepopulate with default values
    ApplicationAdvanceService.search(
      { applicationNo: applicationId },
      null,
      null,
      1,
      null,
      100000,
    ).then((response) => {
      if (response.data.length === 1) {
        ProcessingFeeRatesService.search2(1).then((response) => {
          setProcessingFeeRatesList(response.data.data);
        });
      } else {
        ApplicationProcessingFeeRates.search({}, null, null, 1, null, 100000).then((response) => {
          setProcessingFeeRatesList(response.data.data);
        });
      }
    });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    processingFeeRatesList.map((item) => {
      item.applicationPublicId = applicationPublicId;
    });

    var data = {};
    data.applicationProcessingFeeRates = processingFeeRatesList;

    ApplicationProcessingFeeRates.create(data).then((response) => {
      appContext.toastMessage.message = 'Data saved successfully';
      navigate('/underwriting/applications/' + applicationId + '/advances/');
    });
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...processingFeeRatesList];
    list[index][name] = parseFloat(value);
    setProcessingFeeRatesList(list);
  };

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                <form onSubmit={handleFormSubmit}>
                  <Row className="mb-10">
                    <Col md="3" style={{ display: 'flex', alignItems: 'center' }}>
                      <h4 style={{ margin: 'unset' }}>Processing Fee Rates Table</h4>
                    </Col>
                    <Col md="2" style={{ display: 'flex', alignItems: 'center', padding: '0px' }}>
                      <Button
                        color="primary"
                        onClick={handleAddProcessingFeeRatesList}
                        style={{
                          backgroundColor: 'var(--green)',
                          borderColor: 'var(--green)',
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                  {processingFeeRatesList.map((x, i) => {
                    return (
                      <div className="form-group mb-10 form-inline" key={i}>
                        <>
                          <Input
                            defaultValue={x.fromAmount}
                            name="fromAmount"
                            onChange={(e) => handleInputChange(e, i)}
                            style={{ width: '70px' }}
                          />
                          <span className="form-control" style={{ border: '0px', width: '5px' }}>
                            -
                          </span>
                          <Input
                            defaultValue={x.toAmount}
                            name="toAmount"
                            onChange={(e) => handleInputChange(e, i)}
                            style={{ width: '70px' }}
                          />
                          <span className="form-control" style={{ border: '0px', width: '5px' }}>
                            =
                          </span>
                          <Input
                            defaultValue={x.feeAmount}
                            name="feeAmount"
                            onChange={(e) => handleInputChange(e, i)}
                            style={{ width: '70px' }}
                          />
                          <span className="form-control" style={{ border: '0px', width: '5px' }}>
                            &nbsp;
                          </span>
                          <i
                            className="flaticon-circle text-secondary"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteProcessingFee(i)}
                          />
                        </>
                      </div>
                    );
                  })}
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() =>
                      navigate(
                        '/underwriting/applications/' +
                          applicationId +
                          '/advances/' +
                          advanceId +
                          '/product',
                      )
                    }
                  >
                    Cancel
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessingFeesTable;
