import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Label, Alert, FormGroup, Container } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import AppContext from '../../../../../contexts/AppContext';
import ClientService from '../../../../../services/ClientService';
import UsZipCodeService from '../../../../../services/UsZipCodeService';
import StateSelect from '../../../../../components/StateSelect';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';

const UnderwritingClient = () => {
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const appContext = useContext(AppContext);
  const [formVisible, setFormVisible] = useState(true);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, } = useForm({ mode: 'onBlur' });

  const [client, setClient] = useState({
    publicId: '',
    title: '',
    firstName: '',
    lastName: '',
    homePhone: '',
    dob: '',
    mobilePhoneNo: '',
    emailAddress: '',
    sslast4Digits: '',
    streetAddress: '',
    city: '',
    stateCode: '',
    zipCode: '',
    hasBankruptcy: undefined,
    hasFamilyObligations: undefined,
    notes: '',
    county: '',
  });

  useEffect(() => {
    const makeMenuItemActive = () => {
      document.querySelector('#menuitem-underwriting').click();
    };

    makeMenuItemActive();
  }, []);

  useEffect(() => {
    const LoadClientDetailsIfIdPresent = () => {
      if (id) {
        setIsLoading(true);

        ClientService.read(id)
          .then((response) => {
            if (response.data) {
              setClient(response.data);
              setIsLoading(false);
            }
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });
      }

      setIsLoading(false);
    };

    LoadClientDetailsIfIdPresent();
  }, []);

  const handleFormSubmitted = (data) => {
    data.hasBankruptcy = data.hasBankruptcy === 'true' ? true : false;
    data.hasFamilyObligations = data.hasFamilyObligations === 'true' ? true : false;
    data.isActive = true;
    data.dob = new Date(data.dob);

    if (!id) {
      if (!(appContext?.userPermissions?.indexOf('Add Clients') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      setIsLoading(true);

      ClientService.create(data)
        .then((response) => {
          appContext.toastMessage.message = 'Client has been successfully saved.';
          navigate('/underwriting/clients');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (!(appContext?.userPermissions?.indexOf('Edit Clients') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      setIsLoading(true);

      data.publicId = client.publicId;

      ClientService.update(data)
        .then((response) => {
          appContext.toastMessage.message = 'Client saved successfully saved.';

          const urlParams = new URLSearchParams(window.location.search);
          const fromNewApplication = urlParams.get('fromNewApplication');

          if (fromNewApplication) {
            navigate(
              '/underwriting/applications/' +
                fromNewApplication +
                '/plaintiff?fromEditClient=' +
                client.publicId,
            );
            return;
          }
          navigate('/underwriting/clients');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCancelButtonClicked = () => {
    navigate('/underwriting/clients');
  };

  const hideForm = () => {
    form.current.style.display = 'none';
  };

  const getStateFromZipCode = (event) => {
    if (event.target.value.length > 4) {
      var filters = {};
      filters.zipCode = event.target.value;

      UsZipCodeService.search(filters).then((response) => {
        let newClient = { ...client };
        setClient(newClient);
        if (response?.data?.data[0]?.countyName != undefined) {
          document.querySelector("input[name='county']").value = response.data?.data[0]?.countyName;
        }
      });
    }
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container className="mt-10 mb-10">
      <Row>
        <Col md="12" className="mx-auto bg-white box-shadow-border">
          <div className="p-10 mt-5 mb-10">
            <div className="mb-10">
              <h1>{ id === 'undefined' || "" ? 'New Client' : 'Client Details'}</h1>
            </div>

            <Row>
              <Col md="8">
                {successMessage && <Alert color="success">{successMessage}</Alert>}
                {successMessage && hideForm()}

                {errorMessage && (
                  <Alert color="danger" className="mt-3">
                    {errorMessage}
                  </Alert>
                )}
              </Col>
            </Row>
            {formVisible && (
              <form onSubmit={handleSubmit(handleFormSubmitted)} noValidate>
              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="2">
                        <FormGroup>
                          <Label>Mr / Mrs</Label>
                          <select
                            name="title"
                            required
                            defaultValue={client?.title || ''}
                            onChange={(selectedValue) => {
                              if (selectedValue) {
                                setClient({
                                  ...client,
                                  title: selectedValue.value,
                                });
                              }
                            }}
                            className="form-control"
                            {...register("title")}
                          >
                            <option value="">Please select a title</option>
                            <option key="mr" value="mr">
                              Mr
                            </option>
                            <option key="mrs" value="mrs">
                              Mrs
                            </option>
                            <option key="ms" value="ms">
                              Ms
                            </option>
                            <option key="estateof" value="estateof">
                              Estate Of
                            </option>
                          </select>
                          {errors.title && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="title" />
                            </Alert>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <div className="form-group">
                          <label htmlFor="firstName">First Name</label>
                          <input
                            type="text"
                            name="firstName"
                            maxLength="20"
                            className="form-control"
                            {...register("firstName",{
                              required: 'First Name is required.',
                              maxLength: { value: 20, message: 'Max length is 20 characters.' },
                            })}
                            defaultValue={client?.firstName || ''}
                          />
                          {errors.firstName && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="firstName" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="form-group">
                          <label htmlFor="lastName">Last Name</label>
                          <input
                            type="text"
                            name="lastName"
                            maxLength="30"
                            className="form-control"
                           {...register("lastName",{
                              required: 'Last Name is required.',
                              maxLength: { value: 30, message: 'Max length is 30 characters.' },
                            })}
                            defaultValue={client?.lastName || ''}
                          />
                          {errors.lastName && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="lastName" />
                            </Alert>
                          )}
                        </div>
                      </Col>

                      <Col>
                        <div className="form-group">
                          <label htmlFor="isActive">Active</label> <br></br>
                          <input
                            type="checkbox"
                            name="isActive"
                            className="mr-2"
                            defaultChecked={client?.isActive || ''}
                            {...register("isActive")}
                          />
                          {errors.isActive && (
                            <Alert color="danger" className="mt-3">
                              <ErrorMessage errors={errors} name="isActive" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <label htmlFor="homePhone">Home Phone Number</label>
                          <input
                            type="tel"
                            name="homePhone"
                            className="form-control"
                            maxLength="20"
                            {...register("homePhone",{
                              maxLength: { value: 20, message: 'Max length is 20 characters.' },
                            })}
                            defaultValue={client?.homePhone || ''}
                            style={{ width: '250px' }}
                          />
                          {errors.homePhone && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="homePhone" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <label htmlFor="mobilePhoneNo">Mobile Phone Number</label>
                          <input
                            type="tel"
                            name="mobilePhoneNo"
                            className="form-control"
                            maxLength="20"
                            {...register("mobilePhoneNo",{
                              maxLength: { value: 20, message: 'Max length is 20 characters.' },
                            })}
                            defaultValue={client?.mobilePhoneNo || ''}
                            style={{ width: '250px' }}
                          />
                          {errors.mobilePhoneNo && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="mobilePhoneNo" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <label htmlFor="dob">Date of birth</label>
                          <input
                            type="date"
                            name="dob"
                            id="dob"
                            className="form-control"
                            required="required"
                           {...register("dob")}
                            defaultValue={client.dob === null ? null : client.dob.split('T')[0]}
                          />
                          {errors.dob && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="dob" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <label htmlFor="ssLast4Digits">Last 4 digits of SS</label>
                          <input
                            type="text"
                            minLength="4"
                            maxLength="4"
                            inputMode="numeric"
                            name="ssLast4Digits"
                            className="form-control"
                            {...register("ssLast4Digits",{
                              minLength: { value: 4, message: 'Four digits must be entered.' },
                              maxLength: { value: 4, message: 'Max length is 4 characters.' },
                              pattern: {
                                value: /^[0-9]*$/,
                                message: 'Only numeric values allowed.',
                              },
                            })}
                            defaultValue={client?.sslast4Digits || ''}
                          />
                          {errors.ssLast4Digits && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="ssLast4Digits" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <div className="form-group">
                          <label htmlFor="emailAddress">Email address</label>
                          <input
                            type="email"
                            name="emailAddress"
                            maxLength="50"
                            className="form-control"
                            {...register("emailAddress",{
                              maxLength: { value: 50, message: 'Max length is 50 characters.' },
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address provided.',
                              },
                            })}
                            defaultValue={client?.emailAddress || ''}
                          />
                          {errors.emailAddress && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="emailAddress" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <div className="form-group">
                          <label htmlFor="streetAddress">Address</label>
                          <input
                            type="text"
                            name="streetAddress"
                            maxLength="30"
                            className="form-control"
                            {...register("streetAddress",{
                              maxLength: { value: 30, message: 'Max length is 30 characters.' },
                            })}
                            defaultValue={client?.streetAddress || ''}
                          />
                          {errors.streetAddress && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="streetAddress" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <label htmlFor="city">City</label>
                          <input
                            type="text"
                            name="city"
                            maxLength="30"
                            className="form-control"
                            {...register("city",{
                              maxLength: { value: 30, message: 'Max length is 30 characters.' },
                            })}
                            defaultValue={client?.city || ''}
                          />
                          {errors.city && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="city" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                      <Col md="4">
                        <StateSelect
                          name="stateCode"
                          label="State"
                          {...register("stateCode")}
                          defaultValue={client?.stateCode || ''}
                          setObject={setClient}
                          givenObject={client}
                          errors={errors}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <label htmlFor="zipCode">Zip Code</label>
                          <input
                            type="text"
                            inputMode="numeric"
                            maxLength="10"
                            name="zipCode"
                            className="form-control"
                            {...register("zipCode",{
                              maxLength: { value: 10, message: 'Max length is 10 characters.' },
                              pattern: {
                                 value: /^\d{5}(?:[-\s]\d{4})?$/,
                                message: 'The US Zip code provided is wrong.',
                              },
                            })}
                            onChange={getStateFromZipCode}
                            defaultValue={client?.zipCode || ''}
                          />
                          {errors.zipCode && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="zipCode" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <label htmlFor="county">County</label>
                          <input
                            type="text"
                            maxLength="30"
                            name="county"
                            className="form-control"
                            {...register("county",{
                              maxLength: { value: 30, message: 'Max length is 30 characters.' },
                            })}
                            defaultValue={client?.county || ''}
                          />
                          {errors.county && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="county" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <div className="form-group">
                          <label>
                            Has PLAINTIFF have any family maintenance obligations (e.g. child or{' '}
                            <br /> spousal support) or obligations to the goverment (e.g. social
                            services)?
                          </label>
                          <br />
                          <input
                            type="radio"
                            name="hasFamilyObligations"
                            value={true}
                            required="required"
                            {...register("hasFamilyObligations")}
                            defaultChecked={client.hasFamilyObligations ? 'checked' : undefined}
                          />
                          <label htmlFor="obligationsYes" className="ml-2 mr-2">
                            Yes
                          </label>
                          <input
                            type="radio"
                            name="hasFamilyObligations"
                            value={false}
                            required="required"
                            {...register("hasFamilyObligations")}
                            defaultChecked={
                              !client.hasFamilyObligations &&
                              client.hasFamilyObligations != undefined
                                ? 'checked'
                                : undefined
                            }
                          />
                          <label htmlFor="obligationsNo" className="ml-2 mr-2">
                            No
                          </label>

                          {errors.hasFamilyObligations && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="hasFamilyObligations" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <div className="form-group">
                          <label>
                            Has PLAINTIFF declared bankruptcy or in the process of filling for{' '}
                            <br /> bankruptcy ?
                          </label>
                          <br />
                          <input
                            type="radio"
                            name="hasBankruptcy"
                            value={true}
                             {...register("hasBankruptcy")}
                            required="required"
                            defaultChecked={client.hasBankruptcy ? 'checked' : undefined}
                          />
                          <label htmlFor="bankruptcyYes" className="ml-2 mr-2">
                            Yes
                          </label>
                          <input
                            type="radio"
                            name="hasBankruptcy"
                            value={false}
                             {...register("hasBankruptcy")}
                            required="required"
                            defaultChecked={
                              !client.hasBankruptcy && client.hasBankruptcy != undefined
                                ? 'checked'
                                : undefined
                            }
                          />
                          <label htmlFor="bankruptcyYes" className="ml-2 mr-2">
                            No
                          </label>

                          {errors.hasBankruptcy && (
                            <Alert color="danger" className="mt-5">
                              <ErrorMessage errors={errors} name="hasBankruptcy" />
                            </Alert>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <Label htmlFor="notes">Notes</Label>
                          <textarea
                            name="notes"
                            rows="6"
                             {...register("notes")}
                             defaultValue={client?.notes || ''}
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <button type="submit" className="btn btn-primary mr-5">
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={handleCancelButtonClicked}
                          className="btn btn-secondary"
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </form>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UnderwritingClient;