import React, { useState, useEffect, useContext, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { Box } from '@mui/material';

import { useParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@mui/material/TableSortLabel';
import ReactLoading from 'react-loading';
import ApplicationPayoffsGridService from '@services/ApplicationPayoffsGridService';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import Moment from 'moment';
import DeleteDocumentPopup from '@components/DeleteDocumentPopup';
import SendEmailPopup from '@components/SendEmailPopup';
import GridService from '@services/GridService';
import fileDownload from 'js-file-download';
import SystemRolesService from '@services/SystemRolesService';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: '14px', // Set font size for the body cells only
  },
}));

const useStyles2 = {
  minWidth: 500,
};

const CustomPaginationActionsTable = (props) => {
  let { isPayoffNewEnabled, wrapperSetIsPayoffNewEnabled } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const { applicationId } = useParams();
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();

  //email form
  const [emailModal, setEmailModal] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleEmail = () => setEmailModal(!emailModal);
  const [systemUsers, setSystemUsers] = useState([]);
  const [emails, setEmails] = useState(null);
  const [files, setFiles] = useState([]);
  const [client, setClient] = useState(false);
  const [attorney, setAttorney] = useState(false);
  const [broker, setBroker] = useState(false);
  const [accountManager, setAccountManager] = useState(false);
  const [subject, setSubject] = useState(null);
  const [body, setBody] = useState(null);
  const [selectedItemPublicId, setSelectedItemPublicId] = useState(null);
  const [applicationPublicId, setApplicationPublicId] = useState(null);
  const [document, setDocument] = useState({});
  //const [isPayoffNewEnabled, setIsPayoffNewEnabled] = useState(true);
  const appContext = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);
  const [activeFieldForSorting, setActiveFieldForSorting] = useState();
  const mountedRef = useRef();

  useEffect(() => {
    if (!mountedRef.current) return;
    update();
  }, [page]);

  useEffect(() => {
    if (rows.length > 0) {
      wrapperSetIsPayoffNewEnabled(false);
    } else {
      wrapperSetIsPayoffNewEnabled(true);
    }
  }, [rows]);

  useEffect(() => {
    mountedRef.current = true;
    setPage(1);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const update = () => {
    setIsLoading(true);
    ApplicationPayoffsGridService.search(
      { applicationNo: applicationId },
      orderBy,
      orderDir,
      page,
      null,
      rowsPerPage,
    )
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const deleteItem = () => {
    if (!(appContext?.userPermissions?.indexOf('Delete Payoffs') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    ApplicationPayoffsGridService.delete(givenRows[rowIndex].publicId)
      .then((result) => {
        let items = [...givenRows];
        items.splice(rowIndex, 1);
        setRows(items);
        toggle();
        toast('Payoff has been successfully deleted.', { type: 'success' });
      })
      .catch((_) => {});
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handleEmailPopup = (row) => {
    SystemRolesService.search(filters, orderBy, orderDir, 1, null, 20)
      .then((response) => {
        if (!response.data) return;
        setSystemUsers(response.data.data);
        setDocument({
          documentName: `${applicationId}-Lien Satisfaction Letter.pdf`,
        });
        setSelectedItemPublicId(row.publicId);
        setApplicationPublicId(row.applicationPublicId);
        toggleEmail();
      })
      .catch((err) => {
        toast.error('An error has occured. Please contact your system administrator.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDownloadFile = (item) => {
    if (!(appContext?.userPermissions?.indexOf('Download Documents') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    let config = {
      responseType: 'blob',
    };

    let endpoint = `/ApplicationPayoff/getfile/${item.publicId}`;

    GridService.read(endpoint, config)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
      })
      .catch((e) => {});
  };

  const handleFormSubmit = (item) => {
    if (!(appContext?.userPermissions?.indexOf('Email Documents') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    let formData = new FormData(); //formdata object
    let tempSysUsers =
      systemUsers.length > 0
        ? systemUsers.reduce((a, o) => (o.checked && a.push(o.publicId), a), [])
        : [];
    let tempEmails = emails?.split(';');
    let attachments = files;
    for (var i = 0; i < tempSysUsers?.length; i++) {
      formData.append('SystemRoles', tempSysUsers[i]);
    }
    for (var i = 0; i < tempEmails?.length; i++) {
      formData.append('ToAdditionalEmails', tempEmails[i]);
    }
    for (var i = 0; i < attachments?.length; i++) {
      formData.append('Attachments', attachments[i]);
    }

    formData.append('publicGuid', selectedItemPublicId);
    formData.append('ApplicationPublicGuid', applicationPublicId);
    formData.append('ToClient', client);
    formData.append('ToAttorney', attorney);
    formData.append('ToBroker', broker);
    formData.append('ToAccountManager', accountManager);
    formData.append('Subject', subject);
    formData.append('Body', body);
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    let endpoint = '/ApplicationPayoff/sendemail';
    GridService.create(endpoint, formData, config)
      .then((res) => {
        if (res?.data === true) {
          setEmailModal(false);
          toast.success('Email sent successfully', { autoClose: 3000 });
        } else {
          if (res?.response?.data?.errors) {
            toast.error(res.response.data?.errors, { autoClose: 5000 });
          }
        }
      })
      .catch((_) => {});
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const parseDollarString = (val) => {
    return val ? moneyFormatter.format(parseFloat(val).toFixed(2)) : '-';
    //return val? '$' + parseFloat(val).toFixed(2) : "-";
  };

  return (
    <>
      <Box m={5}></Box>
      <Row className="mb-5">
        <div className="col-md-6">
          <h3>Payoffs</h3>
        </div>
        <div className="col-md-6">
          {isPayoffNewEnabled && (
            <a
              className="btn btn-primary mr-1 btn-sm  float-right"
              onClick={() =>
                navigate(
                  `/underwriting/applications/${applicationId}/payoffs/applicationPayoffs/new-payoff`,
                )
              }
            >
              New Payoff
            </a>
          )}
          <a
            className="btn btn-primary mr-1 btn-sm  float-right"
            onClick={() =>
              navigate(
                `/underwriting/applications/${applicationId}/payoffs/applicationPayoffs/simulatepayoff`,
              )
            }
          >
            Payoff Breakdown
          </a>
        </div>
      </Row>
      <Row>
        <Col applicationId={applicationId} md="12">
          <DeleteDocumentPopup modal={modal} toggle={toggle} deleteItem={deleteItem} />

          <SendEmailPopup
            emailModal={emailModal}
            toggleEmail={toggleEmail}
            handleFormSubmit={handleFormSubmit}
            setClient={setClient}
            client={client}
            setAccountManager={setAccountManager}
            accountManager={accountManager}
            setAttorney={setAttorney}
            attorney={attorney}
            setBroker={setBroker}
            broker={broker}
            setBody={setBody}
            setSystemUsers={setSystemUsers}
            systemUsers={systemUsers}
            setEmails={setEmails}
            setSubject={setSubject}
            setFiles={setFiles}
            document={document}
          />

          <TableContainer component={Paper}>
            <Table className={useStyles2} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Id</StyledTableCell>

                  <StyledTableCell
                    onClick={() => {
                      arrange('payoffDate');
                      setActiveFieldForSorting('payoffDate');
                    }}
                  >
                    DATE
                    <TableSortLabel
                      active={activeFieldForSorting === 'payoffDate' ? true : false}
                      direction={
                        activeFieldForSorting === 'payoffDate' ? orderDir.toLowerCase() : 'asc'
                      }
                      onClick={() => arrange('payoffDate')}
                    ></TableSortLabel>
                  </StyledTableCell>

                  <StyledTableCell
                    onClick={() => {
                      arrange('amount');
                      setActiveFieldForSorting('amount');
                    }}
                  >
                    PAYOFF AMOUNT
                    <TableSortLabel
                      active={activeFieldForSorting === 'amount' ? true : false}
                      direction={
                        activeFieldForSorting === 'amount' ? orderDir.toLowerCase() : 'asc'
                      }
                      onClick={() => arrange('amount')}
                    ></TableSortLabel>
                  </StyledTableCell>

                  <StyledTableCell
                    onClick={() => {
                      arrange('concessionAmount');
                      setActiveFieldForSorting('concessionAmount');
                    }}
                  >
                    CONCESSION AMOUNT
                    <TableSortLabel
                      active={activeFieldForSorting === 'concessionAmount' ? true : false}
                      direction={
                        activeFieldForSorting === 'concessionAmount'
                          ? orderDir.toLowerCase()
                          : 'asc'
                      }
                      onClick={() => arrange('concessionAmount')}
                    ></TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      arrange('notes');
                      setActiveFieldForSorting('notes');
                    }}
                  >
                    NOTES
                    <TableSortLabel
                      active={activeFieldForSorting === 'notes' ? true : false}
                      direction={activeFieldForSorting === 'notes' ? orderDir.toLowerCase() : 'asc'}
                      onClick={() => arrange('notes')}
                    ></TableSortLabel>
                  </StyledTableCell>

                  <StyledTableCell align="right">ACTIONS</StyledTableCell>
                </TableRow>
              </TableHead>
              {isLoading === false && (
                <TableBody>
                  {rows &&
                    rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell>
                          {row?.payoffDate &&
                            Moment(row?.payoffDate?.split('T')[0]).format('MM-DD-YYYY')}{' '}
                          {Moment(row?.payoffDate).format('h:mm:ss A')}
                        </TableCell>
                        <TableCell>
                          {row.amount !== null ? parseDollarString(row.amount) : null}
                        </TableCell>
                        <TableCell>
                          {row.concessionAmount !== null
                            ? parseDollarString(row.concessionAmount)
                            : null}
                        </TableCell>
                        <TableCell>{row.notes}</TableCell>
                        <TableCell className="p-2" align="right">
                          <a
                            className="btn mr-1 btn-sm"
                            onClick={() =>
                              navigate(
                                `/underwriting/applications/${applicationId}/payoffs/applicationPayoffs/${row.publicId}`,
                              )
                            }
                          >
                            <EditIcon />
                          </a>
                          <Tooltip title="Email Lien Satisfaction Letter">
                            <button
                              className="btn mr-1 btn-sm"
                              onClick={(row) => handleEmailPopup(row)}
                            >
                              <MailOutlineOutlinedIcon />
                            </button>
                          </Tooltip>
                          <Tooltip title="Download Lien Satisfaction Letter">
                            <button
                              className="btn mr-1 btn-sm"
                              onClick={() => handleDownloadFile(row)}
                            >
                              <CloudDownloadOutlinedIcon />
                            </button>
                          </Tooltip>
                          <a
                            className="btn btn-sm"
                            onClick={() => {
                              setRowIndex(index);
                              setGivenRows(rows);
                              toggle();
                            }}
                          >
                            <DeleteIcon style={{ color: 'var(--red)' }} />
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
              {isLoading && (
                <TableBody>
                  <TableRow>
                    <td></td>
                    <td>
                      <ReactLoading type="bars" color="#7E8299" />
                    </td>
                    <td></td>
                  </TableRow>
                </TableBody>
              )}

              <TableFooter></TableFooter>
            </Table>
          </TableContainer>
        </Col>
      </Row>
    </>
  );
};

export default CustomPaginationActionsTable;
