import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import jwt_decode from 'jwt-decode';
import UnderwritingApplicationsPage from './pages/Underwriting/Applications';
import UnderwritingApplicationsToBeReviewedPage from './pages/Underwriting/ApplicationsToBeReviewed';
import UnderwritingApplicationsNewPage from './pages/Underwriting/Applications/Application';
import PortfoliosPayoffLetterRequests from './pages/Portfolios/PayoffLetterRequests';
import PortfolioPayoffLetterRequestNewPage from './pages/Portfolios/PayoffLetterRequests/PayoffLetterRequest';
import PortfoliosConcessionDiary from './pages/Portfolios/ConcessionDiary';
import PortfoliosCasesRequireReview from './pages/Portfolios/CasesRequireReview';
import PortfoliosList from './pages/Portfolios/PortfoliosList';
import AccountsActiveAdvanceReceivables from './pages/Accounts/ActiveAdvanceReceivables';
import AccountsAccountManagerPipeline from './pages/Accounts/AccountManagerPipeline';
import AccountsAccountFunding from './pages/Accounts/AccountFundings';
import AccountsLawFirmContacts from './pages/Accounts/LawFirmContacts';
import AccountingCasesToBeAdvanced from './pages/Accounting/CasesToBeAdvanced';
import AccountingPayoffs from './pages/Accounting/Payoffs';
import AccountingPayoffRequests from './pages/Accounting/PayoffRequests';
import AccountingActiveAdvanceReceivables from './pages/Accounting/ActiveAdvanceReceivables';
import ReportsAccountManagerDetails from './pages/Reports/AccountManagerDetails';
import ReportsAccountManagerSummary from './pages/Reports/AccountManagerSummary';
import ReportsAttorneyData from './pages/Reports/AttorneyData';
import ReportsCommission from './pages/Reports/Commision';
import ReportsFinancialCenter from './pages/Reports/FinancialCenter';
import ReportsFinancialSummary from './pages/Reports/FinancialSummary';
import ReportsLawFirmDetails from './pages/Reports/LawFirmDetails';
import ReportsLawFirmSummary from './pages/Reports/LawFirmSummary';
import ReportsPayoffDetails from './pages/Reports/PayoffDetails';
import ReportsPayoffStaticPoolAnalysis from './pages/Reports/PayoffStaticPoolAnalysis';
import ReportsTypesOfAccidentsSummary from './pages/Reports/TypesOfAccidentsSummary';
import ReportsPresentValue from './pages/Reports/PresentValue';
import CaseStatusUpdateActivity from './pages/Reports/CaseStatusUpdateActivity';
import ContactsLawFirms from './pages/Contacts/LawFirms/LawFirms';
import ContactsAttorneys from './pages/Contacts/Attorneys';
import ContactsBrokers from './pages/Contacts/Brokers';
import ContactsFinancialStores from './pages/Contacts/FinancialStores';
import SettingsUsers from './pages/Settings/Users';
import SettingsUserRoles from './pages/Settings/UserRoles';
import SettingsMiscSettings from './pages/Settings/MiscSettings';
import PersonalInformation from './pages/Profile/PersonalInformation';
import ChangePassword from './pages/Profile/ChangePassword';
import SettingsUsersUser from './pages/Settings/Users/User';
import UnderwritingApplicationsNewPagePlaintiff from './pages/Underwriting/Applications/Application/Plaintiff';
import UnderwritingApplicationsAttorneyCaseStatus from './pages/Underwriting/Applications/Application/AttorneyCaseStatus';

import UnderwritingNecessityFundingCaseStatusUpdates from './pages/Underwriting/NecessityFundingCaseStatusUpdates';

import UnderwritingApplicationsNewPageAccident from './pages/Underwriting/Applications/Application/Accident';
import UnderwritingApplicationsNewPageInjury from './pages/Underwriting/Applications/Application/Injury';
import UnderwritingApplicationsNewPageCoverage from './pages/Underwriting/Applications/Application/Coverage';
import UnderwritingApplicationsNewPageMisc from './pages/Underwriting/Applications/Application/Misc';
import UnderwritingApplicationsNewPageNotes from './pages/Underwriting/Applications/Application/Notes';
import UnderwritingApplicationsNewPageDocument from './pages/Underwriting/Applications/Application/Documents/NewDocument';
import UnderwritingApplicationDocuments from './pages/Underwriting/Applications/Application/Documents';
import UnderwritingApplicationAdvances from './pages/Underwriting/Applications/Application/Advances';
import UnderwritingApplicationEditAdvance from './pages/Underwriting/Applications/Application/Advances/EditAdvance';
import UnderwritingApplicationFinancialTerms from './pages/Underwriting/Applications/Application/FinancialTerms';

import UnderwritingApplicationPayoffs from './pages/Underwriting/Applications/Application/Payoffs';
import UnderwritingApplicationNewPayoffRequest from './pages/Underwriting/Applications/Application/Payoffs/PayoffRequest/NewPayoffRequest';
import UnderwritingApplicationSimulatePayoff from './pages/Underwriting/Applications/Application/Payoffs/PayoffsGrid/SimulatePayoff';
import UnderwritingApplicationNewConcessionRequest from './pages/Underwriting/Applications/Application/Payoffs/ConcessionRequest/NewConcessionRequest';
import UnderwritingApplicationNewPayoff from './pages/Underwriting/Applications/Application/Payoffs/PayoffsGrid/NewPayoff';

import UnderwritingApplicationNewPartialPayment from './pages/Underwriting/Applications/Application/Payoffs/PartialPayments/NewPartialPayment';

import UnderwritingApplicationActivity from './pages/Underwriting/Applications/Application/Activity';
import UnderwritingApplicationDisb from './pages/Underwriting/Applications/Application/Disbs';
import UnderwritingApplicationIms from './pages/Underwriting/Applications/Application/IMS';
import UnderwritingApplicationNewDisb from './pages/Underwriting/Applications/Application/Disbs/NewDisbursement';
import UnderwritingApplicationAdvancesProduct from './pages/Underwriting/Applications/Application/Advances/Product';
import UnderwritingApplicationAdvancesProductProcessingFeesTable from './pages/Underwriting/Applications/Application/Advances/Product/ProcessingFeesTable';
import ContactsLawFirm from './pages/Contacts/LawFirms/LawFirm';
import ContactsAttorney from './pages/Contacts/Attorneys/Attorney';
import ContactsBroker from './pages/Contacts/Brokers/Broker';
import ContactsFinancialStore from './pages/Contacts/FinancialStores/FinancialStore/FinancialStore';
import UnderwritingApplicationLiens from './pages/Underwriting/Applications/Application/Liens';
import UnderwritingApplicationsNewLiensNewLien from './pages/Underwriting/Applications/Application/Liens/NewLien';
import UnderwritingApplicationsNewNotesEditNote from './pages/Underwriting/Applications/Application/Notes/EditNote';
import SettingsUserRolesEditUserRole from './pages/Settings/UserRoles/EditUserRole';
import EditClient from './pages/Underwriting/Clients/components/EditClient';
import UnderwritingClients from './pages/Underwriting/Clients/Clients';
import Portfolio from './pages/Portfolios/PortfoliosList/Portfolio';
import history from './history';
import UserManagePermissions from './pages/Settings/Users/ManagePermissions/UserManagePermission';
import './App.css';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Menu from './components/Menu';
import UserPermittedRoute from './components/UserPermittedRoute/UserPermittedRoute';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Error404 from './pages/Error404';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordSuccess from './pages/ForgotPasswordSuccess';
import Dashboard from './pages/Dashboard';
import Logout from './pages/Logout';

const App = () => {
  const token = localStorage.getItem('lasuris-jwt');

  if (token) {
    const decoded = jwt_decode(token);
    if (decoded.exp < Date.now() / 1000) {
      history('/logout');
    }
  }

  return (
    <Router>
      <div>
        <ToastContainer />

        <Menu />

        <main>
          <Routes>
            <Route
              path="/"
              element={<PrivateRoute component={Dashboard} />}
            />

            <Route path="/login" element={<Login />} />

            <Route path="/resetpassword/:token" element={<ResetPassword />} />

            <Route path="/forgotpassword" element={<ForgotPassword />} />

            <Route path="/forgotpasswordsuccess" element={<ForgotPasswordSuccess />} />

            <Route
              path="/attorneycasestatus/:attorneyPublicId/:lawfirmPublicId"
              element={<UnderwritingNecessityFundingCaseStatusUpdates />}
            />

            <Route
              path="/dashboard"
              element={<PrivateRoute component={Dashboard} />}
            />

            <Route path="/logout" element={<PrivateRoute component={Logout} />} />

            <Route
              path="/profile/changepasssword"
              element={<PrivateRoute component={ChangePassword} />}
            />

            <Route
              path="/portfolios/new-portfolio"
              element={<PrivateRoute component={Portfolio} />}
            />

            <Route
              path="/underwriting/applications/:applicationId/payoffs/:payoffRequestId"
              element={<PrivateRoute component={UnderwritingApplicationNewPayoffRequest} />}
            />

            <Route
              path="/underwriting/applications/:applicationId/documents/new-document"
              element={<PrivateRoute component={UnderwritingApplicationsNewPageDocument} />}
            />

            <Route
              path="/portfolios/portfolios-list/:id"
              element={<PrivateRoute component={Portfolio} />}
            />

            <Route
              path="/underwriting/applications/:applicationId/attorney"
              element={<PrivateRoute component={UnderwritingApplicationsNewPage} />}
            />

            <Route
              path="/underwriting/applications/:applicationId/liens/new-lien/:lienId"
              element={<PrivateRoute component={UnderwritingApplicationsNewLiensNewLien} />}
            />

            <Route
              path="/underwriting/applications/:applicationId/notes/:noteId"
              element={<PrivateRoute component={UnderwritingApplicationsNewNotesEditNote} />}
            />

            <Route
              path="/portfolios/payoff-letter-requests/:applicationId/attorney"
              element={<PrivateRoute component={PortfolioPayoffLetterRequestNewPage} />}
            />

            <Route
              path="/settings/user-roles/new-user-role"
              element={<PrivateRoute component={SettingsUserRolesEditUserRole} />}
            />

            <Route
              path="/accounting/active-advance-receivables"
              element={
                <UserPermittedRoute
                  component={AccountingActiveAdvanceReceivables}
                  userRole={['Accounting']}
                  task={['View Cases to Be Advanced']}
                />
              }
            />
            <Route
              path="/accounting/cases-to-be-advanced"
              element={
                <UserPermittedRoute
                  component={AccountingActiveAdvanceReceivables}
                  userRole={['Accounting']}
                  task={['View Cases to Be Advanced']}
                />
              }
            />

            <Route
              path="/accounting/payoff-requests"
              element={
                <UserPermittedRoute
                  component={AccountingPayoffRequests}
                  userRole={['Accounting']}
                  task={['View Payoff Requests']}
                />
              }
            />

            <Route
              path="/accounting/payoffs"
              element={
                <UserPermittedRoute
                  component={AccountingPayoffs}
                  userRole={['Accounting']}
                  task={['View Payoffs']}
                />
              }
            />

            <Route
              path="/accounts/account-fundings"
              element={
                <UserPermittedRoute
                  component={AccountsAccountFunding}
                  userRole={['Account Manager']}
                  task={['View Account Fundings']}
                />
              }
            />
            <Route
              path="/accounts/account-manager-pipeline"
              element={
                <UserPermittedRoute
                  component={AccountsAccountManagerPipeline}
                  userRole={['Account Manager']}
                  task={['View Account Manager Pipeline']}
                />
              }
            />
            <Route
              path="/accounts/active-advance-receivables"
              element={
                <UserPermittedRoute
                  component={AccountsActiveAdvanceReceivables}
                  userRole={['Account Manager']}
                  task={['View Active Advance Receivables']}
                />
              }
            />
            <Route
              path="/accounts/active-advance-receivables/:lawFirm/:lawFirmPublicId"
              element={
                <UserPermittedRoute
                  component={AccountsActiveAdvanceReceivables}
                  userRole={['Account Manager']}
                  task={['View Active Advance Receivables']}
                />
              }
            />
            <Route
              path="/accounts/law-firm-contacts"
              element={
                <UserPermittedRoute
                  component={AccountsLawFirmContacts}
                  userRole={['Account Manager']}
                  task={['View Law Firm Contacts']}
                />
              }
            />

            {/* Contacts Pages  */}

            <Route
              path="/contacts/attorneys/new-attorney"
              element={<UserPermittedRoute component={ContactsAttorney} task={['Add Attorneys']} />}
            />
            <Route
              path="/contacts/brokers/new-broker"
              element={<UserPermittedRoute component={ContactsBroker} task={['Add Brokers']} />}
            />
            <Route
              path="/contacts/financial-stores/new-financial-store"
              element={
                <UserPermittedRoute
                  component={ContactsFinancialStore}
                  task={['Add Financial Stores']}
                />
              }
            />
            <Route
              path="/contacts/law-firms/new-law-firm"
              element={<UserPermittedRoute component={ContactsLawFirm} task={['Add Law Firms']} />}
            />

            <Route
              path="/contacts/attorneys"
              element={
                <UserPermittedRoute component={ContactsAttorneys} task={['View Attorneys']} />
              }
            />
            <Route
              path="/contacts/attorneys/:id"
              element={
                <UserPermittedRoute component={ContactsAttorney} task={['View Attorneys']} />
              }
            />
            <Route
              path="/contacts/brokers"
              element={<UserPermittedRoute component={ContactsBrokers} task={['View Brokers']} />}
            />
            <Route
              path="/contacts/brokers/:id"
              element={<UserPermittedRoute component={ContactsBroker} task={['View Brokers']} />}
            />

            <Route
              path="/contacts/financial-stores"
              element={
                <UserPermittedRoute
                  component={ContactsFinancialStores}
                  task={['View Financial Stores']}
                />
              }
            />

            <Route
              path="/contacts/financial-stores/:id"
              element={
                <UserPermittedRoute
                  component={ContactsFinancialStore}
                  task={['View Financial Stores']}
                />
              }
            />

            <Route
              path="/contacts/law-firms"
              element={
                <UserPermittedRoute component={ContactsLawFirms} task={['View Law Firms']} />
              }
            />

            <Route
              path="/accounting/cases-to-be-advanced"
              element={
                <UserPermittedRoute
                  component={AccountingCasesToBeAdvanced}
                  userRole={['Accounting']}
                  task={['View Cases to Be Advanced']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/payoffs/applicationPayoffs/simulatepayoff"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationSimulatePayoff}
                  task={['Add Payoffs']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/payoffs/applicationPayoffs/:payoffId"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationNewPayoff}
                  task={['Add Payoffs']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/payoffs/partialpayments/:payoffId"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationNewPartialPayment}
                  task={['Add Payoffs']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/payoffs/partialpayments/new-partialpayment"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationNewPartialPayment}
                  task={['Add Payoffs']}
                />
              }
            />

            <Route
              path="/contacts/law-firms/:id"
              element={<UserPermittedRoute component={ContactsLawFirm} task={['View Law Firms']} />}
            />

            {/* Misc Settings */}
            <Route
              path="/settings/misc-settings"
              element={
                <UserPermittedRoute component={SettingsMiscSettings} task={['View Misc. Settings']} />
              }
            />

            {/* Portfolios */}
            <Route
              path="/portfolios/payoff-letter-requests/new-portfolio"
              element={
                <UserPermittedRoute
                  component={PortfolioPayoffLetterRequestNewPage}
                  userRole={['Portfolio Manager']}
                  task={['Add Potfoilios']}
                />
              }
            />

            <Route
              path="/portfolios/cases-require-review"
              element={
                <UserPermittedRoute
                  component={PortfoliosCasesRequireReview}
                  userRole={['Portfolio Manager']}
                  task={['View Cases Require Review']}
                />
              }
            />

            <Route
              path="/portfolios/concession-diary"
              element={
                <UserPermittedRoute
                  component={PortfoliosConcessionDiary}
                  userRole={['Portfolio Manager']}
                  task={['View Concession Diary']}
                />
              }
            />

            <Route
              path="/portfolios/payoff-letter-requests"
              element={
                <UserPermittedRoute
                  component={PortfoliosPayoffLetterRequests}
                  task={['View Payoff Letter Requests']}
                />
              }
            />

            <Route
              path="/portfolios/portfolios-list"
              element={
                <UserPermittedRoute
                  component={PortfoliosList}
                  userRole={['Portfolio Manager']}
                  task={['View Potfoilios List']}
                />
              }
            />

            {/* Reports */}

            {/* Reports */}
            <Route
              path="/reports/account-manager-details"
              element={
                <UserPermittedRoute
                  component={ReportsAccountManagerDetails}
                  task={['Account Manager Details']}
                />
              }
            />

            <Route
              path="/reports/account-manager-details/:accountManagerId"
              element={
                <UserPermittedRoute
                  component={ReportsAccountManagerDetails}
                  task={['Account Manager Details']}
                />
              }
            />

            <Route
              path="/reports/account-manager-summary"
              element={
                <UserPermittedRoute
                  component={ReportsAccountManagerSummary}
                  task={['Account Manager Summary']}
                />
              }
            />

            <Route
              path="/reports/commission-report"
              element={
                <UserPermittedRoute component={ReportsCommission} task={['Commission Report']} />
              }
            />

            <Route
              path="/reports/attorney-data"
              element={
                <UserPermittedRoute component={ReportsAttorneyData} task={['Attorney Data']} />
              }
            />

            <Route
              path="/reports/financial-center"
              element={
                <UserPermittedRoute component={ReportsFinancialCenter} task={['Financial Center']} />
              }
            />

            <Route
              path="/reports/present-value"
              element={
                <UserPermittedRoute component={ReportsPresentValue} task={['Present Value']} />
              }
            />

            <Route
              path="/reports/financial-summary"
              element={
                <UserPermittedRoute component={ReportsFinancialSummary} task={['Financial Summary']} />
              }
            />

            <Route
              path="/reports/law-firm-details"
              element={
                <UserPermittedRoute component={ReportsLawFirmDetails} task={['Law Firm Details']} />
              }
            />

            <Route
              path="/reports/law-firm-details/:lawFirmPublicId"
              element={
                <UserPermittedRoute component={ReportsLawFirmDetails} task={['Law Firm Details']} />
              }
            />

            <Route
              path="/reports/law-firm-summary"
              element={
                <UserPermittedRoute component={ReportsLawFirmSummary} task={['Law Firm Summary']} />
              }
            />

            <Route
              path="/reports/payoff-details"
              element={
                <UserPermittedRoute component={ReportsPayoffDetails} task={['Payoff Details']} />
              }
            />

            <Route
              path="/reports/payoff-static-pool-analysis"
              element={
                <UserPermittedRoute
                  component={ReportsPayoffStaticPoolAnalysis}
                  task={['Payoff Static Pool Analysis']}
                />
              }
            />

            <Route
              path="/reports/types-of-accidents-summary"
              element={
                <UserPermittedRoute
                  component={ReportsTypesOfAccidentsSummary}
                  task={['Types of Accidents Summary']}
                />
              }
            />

            <Route
              path="/reports/case-status-update-activity"
              element={
                <UserPermittedRoute
                  component={CaseStatusUpdateActivity}
                  task={['Case Status Update Activity']}
                />
              }
            />

            {/* Underwriting - General */}
            <Route
              path="/underwriting/applications/new-application"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsNewPage}
                  userRole={['Lead Underwriter', 'Underwriter']}
                  task={['Add a new Application']}
                />
              }
            />

            <Route
              path="/underwriting/clients/new-client"
              element={<UserPermittedRoute component={EditClient} task={['Add Clients']} />}
            />

            <Route
              path="/underwriting/clients/:id"
              element={<UserPermittedRoute component={EditClient} task={['Edit Clients']} />}
            />

            <Route
              path="/underwriting/clients"
              element={
                <UserPermittedRoute component={UnderwritingClients} task={['View Clients']} />
              }
            />

            <Route
              path="/underwriting/applications-to-be-reviewed"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsToBeReviewedPage}
                  userRole={['Lead Underwriter', 'Underwriter']}
                  task={['View Applications to be Reviewed']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/advances/:advanceId/disbursement/new-disbursement"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationNewDisb}
                  task={['Add Advance Disbursements']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/advances/new-advance"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationEditAdvance}
                  task={['Add Advances']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/payoffs/concession-request/:concessionRequestId"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationNewConcessionRequest}
                  task={['Add Concession Requests', 'View Concession Requests']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/liens/new-lien"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsNewLiensNewLien}
                  task={['Add Liens']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/notes/new-note"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsNewNotesEditNote}
                  task={['Add Notes']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/payoffs/:payoffRequestId"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationNewPayoffRequest}
                  task={['Add Payoff Requests', 'View Payoff Requests', 'Edit Payoff Requests']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/accident"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsNewPageAccident}
                  task={['View Accident Info']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/activity"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationActivity}
                  task={['View Activity']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/advances/:advanceId/disbursement"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationDisb}
                  task={['View Advance Disbursements']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/advances/:advanceId/disbursement/:disbursementId"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationNewDisb}
                  task={['View Advance Disbursements']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/advances/:advanceId/product"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationAdvancesProduct}
                  task={['View Advance Financial Info']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/advances/:advanceId/product/processing-fees-table"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationAdvancesProductProcessingFeesTable}
                  task={['View Advance Financial Info']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/advances"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationAdvances}
                  task={['View Advances']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/advances/:advanceId"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationEditAdvance}
                  task={['View Advances']}
                />
              }
            />

            <Route
              path="/underwriting/applications/new-application/attorney"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsNewPage}
                  task={['View an Application', 'View Attorney Info']}
                />
              }
            />

            <Route
              path="/underwriting/applications"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsPage}
                  task={['View Applications List']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/coverage"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsNewPageCoverage}
                  task={['View Coverage Info']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/documents"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationDocuments}
                  task={['View Documents']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/financial-terms"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationFinancialTerms}
                  task={['View Financial Terms']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/ims"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationIms}
                  task={['View IMS Info']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/injury"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsNewPageInjury}
                  task={['View Injury Info']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/liens"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationLiens}
                  task={['View Liens']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/misc"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsNewPageMisc}
                  task={['View Misc. Info']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/notes"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsNewPageNotes}
                  task={['View Notes']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/payoffs"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationPayoffs}
                  task={['View Payoffs']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/plaintiff"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsNewPagePlaintiff}
                  task={['View Plaintiff Info']}
                />
              }
            />

            <Route
              path="/underwriting/applications/:applicationId/attorneycasestatus"
              element={
                <UserPermittedRoute
                  component={UnderwritingApplicationsAttorneyCaseStatus}
                  task={['View Plaintiff Info']}
                />
              }
            />

            <Route
              path="/settings/users/new-user"
              element={<UserPermittedRoute component={SettingsUsersUser} task={['Add Users']} />}
            />
            <Route
              path="/settings/user-roles/:id"
              element={
                <UserPermittedRoute
                  component={SettingsUserRolesEditUserRole}
                  task={['Edit User Roles']}
                />
              }
            />

            <Route
              path="/settings/users/:id"
              element={<UserPermittedRoute component={SettingsUsersUser} task={['Edit Users']} />}
            />

            <Route
              path="/profile"
              element={
                <UserPermittedRoute component={PersonalInformation} task={['Edit My Profile']} />
              }
            />

            <Route
              path="/settings/user-manage-permissions/:id"
              element={
                <UserPermittedRoute
                  component={UserManagePermissions}
                  task={['Manage User Permissions']}
                />
              }
            />

            <Route
              path="/settings/user-roles"
              element={
                <UserPermittedRoute component={SettingsUserRoles} task={['View User Roles']} />
              }
            />

            <Route
              path="/settings/users"
              element={<UserPermittedRoute component={SettingsUsers} task={['View Users']} />}
            />

          <Route path="*" element={<Error404 />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
