import React from 'react';
import Autosuggest from 'react-autosuggest';
import BrokerService from '@services/BrokerService';

class BrokerAutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      value: '',
      suggestions: [],
    };
  }

  getSuggestionValue = (suggestion) => {
    this.props.selectedBroker(suggestion.publicId);
    return suggestion.companyName;
  };

  renderSuggestion = (suggestion) => {
    return <span>{suggestion.companyName}</span>;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    BrokerService.autoSuggest(value).then((response) => {
      this.setState({ suggestions: response.data.data });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  componentDidMount = () => {
    this.setState({ suggestions: [], value: this.props.defaultBroker });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search Broker ...',
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        ref={this.props.internalRef}
      />
    );
  }
}

export default BrokerAutoSuggest;
