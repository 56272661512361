import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import ApplicationsNewMenu from '../Menu/ApplicationsNewMenu';
import ApplicationsService from '@services/ApplicationsService';
import GridService from '@services/GridService';
import fileDownload from 'js-file-download';
import Header from '../Header';

import DocumentsGrid from './DocumentsGrid';

const Documents = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  let application = appContext.currentApplication;
  const { applicationId } = useParams();
  const [applicationPublicId, setApplicationPublicId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!applicationId || applicationId === 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      navigate('/underwriting/applications/new-application/');
      return;
    }
    let item = document.querySelector('.documents');
    if (item) {
      item.classList.add('active');
    }
    ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1).then(
      (response) => {
        setApplicationPublicId(response?.data?.data[0].publicId);
      },
    );
  }, []);

  useEffect(() => {
    if (appContext.toastMessage.message != '') toast.success(appContext.toastMessage.message);
    appContext.toastMessage.message = null;
  }, [appContext.toastMessage.message, history]);

  const handleDownloadAllDocuments = () => {
    if (!(appContext?.userPermissions?.indexOf('Download Documents') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    let config = {
      responseType: 'blob',
    };

    let endpoint = `/ApplicationDocument/downloadfiles/${applicationPublicId}`;

    GridService.read(endpoint, config)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        var fileName = 'unknown';
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        fileDownload(response.data, fileName);
      })
      .catch((_) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <Header applicationId={applicationId} />

              <ApplicationsNewMenu applicationId={applicationId} />

              <div className="row">
                <Col md="4" className="row"></Col>
                <Col md="8" className="float-right">
                  <Button
                    onClick={handleDownloadAllDocuments}
                    className="btn btn-primary float-right"
                    style={{
                      backgroundColor: 'var(--green)',
                      borderColor: 'var(--green)',
                      color: '#FFFFFF',
                    }}
                  >
                    Download Documents
                  </Button>
                  <Link
                    to={`/underwriting/applications/${applicationId}/documents/new-document`}
                    className="btn btn-primary float-right"
                    style={{
                      backgroundColor: 'var(--green)',
                      borderColor: 'var(--green)',
                      marginRight: 10,
                    }}
                  >
                    Upload Document
                  </Link>
                </Col>
              </div>
            </div>
            <Row>
              <Col md="12">
                <DocumentsGrid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
