import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => {
  useEffect(() => {
    if (!(localStorage.getItem('isUserAuthenticated') === 'true')) {
      const header = document.querySelector('header');
      if (header != null) header.style.display = 'none';
    }
  });

  return (
    <div className="d-flex flex-column flex-root" style={{ minHeight: '100vh' }}>
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{ alignItems: 'center' }}
      >
        <h1 className="font-weight-boldest text-dark-75 mt-15" style={{ fontSize: '10rem' }}>
          404
        </h1>
        <p className="font-size-h3 text-muted font-weight-normal">OOPS! Something went wrong</p>
        <Link to="/" className="btn btn-primary" style={{ width: '200px' }}>
          Go to homepage
        </Link>
      </div>
    </div>
  );
};

export default Error404;
