import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import MultiplierRatesService from '../../../../services/MultiplierRatesService';
import ReactLoading from 'react-loading';

const MultiplierPercentagesTable = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputList, setInputList] = useState([]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { fromMonthCount: 0, toMonthCount: 0, rate: 0 }]);
  };

  useEffect(() => {
    const fetchProcessingFeeRate = () => {
      setIsLoading(true);

      MultiplierRatesService.search2(1)
        .then((res) => {
          res.data.data.map((item) => {
            setInputList((setInputList) => [...setInputList, item]);
          });
          if (res.data && res.data.data && res.data.data.length < 1) {
            setInputList((setInputList) => [
              ...setInputList,
              { fromMonthCount: 0, toMonthCount: 0, rate: 0 },
            ]);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchProcessingFeeRate();
  }, []);

  const onSaveMultiplierPercentage = (data) => {
    setIsLoading(true);

    let newItems = [];
    let oldItems = [];

    inputList.map((item) => {
      if (item.publicId) {
        let obj = {
          publicId: item.publicId,
          fromMonthCount: parseInt(item.fromMonthCount),
          toMonthCount: parseInt(item.toMonthCount),
          rate: parseFloat(item.rate),
        };
        oldItems.push(obj);
      } else {
        let obj2 = {
          fromMonthCount: parseInt(item.fromMonthCount),
          toMonthCount: parseInt(item.toMonthCount),
          rate: parseFloat(item.rate),
        };
        oldItems.push(obj2);
      }
    });

    if (newItems.length > 0) {
      MultiplierRatesService.create({ multiplierRates: newItems })
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (oldItems.length > 0) {
      let updateReqData = {
        multiplierRates: oldItems,
      };

      MultiplierRatesService.update(updateReqData)
        .then((res) => {
          toast('Multiplier Percentages have been successfully updated.', { type: 'success' });
          // navigate('/settings/misc-settings/');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-md-12 p-0 form-inline">
      <h2 className="mt-3 mb-5">Multiplier Percentages</h2>

      <div className="col-md-12 p-0 mb-3 form-inline">
        <h4>Outstanding Months Ranges</h4>
        <button
          type="button"
          className="ml-5 btn btn-primary"
          style={{ backgroundColor: 'var(--green)', borderColor: 'var(--green)' }}
          onClick={handleAddClick}
        >
          Add
        </button>
      </div>
      {inputList.map((x, i) => {
        return (
          <div className="form-group mb-5 p-0 col-md-12" key={i}>
            <input
              name="fromMonthCount"
              type="number"
              className="form-control"
              style={{ width: '100px' }}
              defaultValue={x.fromMonthCount}
              onChange={(e) => handleInputChange(e, i)}
            />
            <span>&nbsp;-&nbsp;</span>
            <input
              name="toMonthCount"
              type="number"
              className="form-control"
              defaultValue={x.toMonthCount}
              style={{ width: '100px' }}
              onChange={(e) => handleInputChange(e, i)}
            />
            <span>&nbsp;=&nbsp;</span>
            <input
              name="rate"
              type="number"
              className="form-control"
              defaultValue={x.rate}
              style={{ width: '100px' }}
              onChange={(e) => handleInputChange(e, i)}
            />
            <i
              className="flaticon-circle text-secondary ml-2 "
              style={{ cursor: 'pointer' }}
              onClick={() => handleRemoveClick(i)}
            />
          </div>
        );
      })}
      <button className="btn btn-primary mt-2" onClick={onSaveMultiplierPercentage}>
        Save
        {/*loadingSave && <div class="spinner-border spinner-border-sm ml-2" role="status"></div>*/}
      </button>
    </div>
  );
};

export default MultiplierPercentagesTable;
