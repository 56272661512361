import React, { useEffect } from 'react';
import Grid from './Grid';
import { Row, Col } from 'reactstrap';

const AccountManagerPipeline = () => {
  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-accounts').click();
    document
      .querySelector('#kt_header_tab_4 > div > div > ul > li:nth-child(2)')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  return (
    <div className="container mt-10 mb-10" style={{ maxWidth: '95%' }}>
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div style={{ padding: '0.5rem' }}>
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Account Manager Pipeline</h1>
              </div>
            </div>
            <Row>
              <Col md="12">
                <Grid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountManagerPipeline;
