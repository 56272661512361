import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { Row, Col } from 'reactstrap';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import PayoffLetterRequestService from '@services/PayoffLetterRequestService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@mui/material/TableSortLabel';
import ReactLoading from 'react-loading';
import FilteringFields from './FilteringFields';
import Moment from 'moment';
import { toast } from 'react-toastify';
import GlobalFilteringFields from '@components/Filtering/GlobalFilteringFields';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: '14px', // Set font size for the body cells only
  },
}));

const useStyles2 = {
  minWidth: 500,
};

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const DEFAULT_STATUS = [];

export default function CustomPaginationActionsTable() {
  const appContext = useContext(AppContext);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState(appContext.payoffRequestsFilters);
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!filters.dateRange || filters.dateRange == '') {
      let tempFilters = {
        ...filters,
        dateRange: 'This month',
      };

      setFilters(tempFilters);
      appContext.payoffRequestsFilters = tempFilters;
      localStorage.setItem('payoffRequestsFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      loadData(filters);
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const loadData = (filters) => {
    let reqFilters = filters;
    if (reqFilters && reqFilters.client) {
      reqFilters = { ...reqFilters, ClientPublicId: reqFilters.client.publicId };
    }
    if (reqFilters && reqFilters.attorney) {
      reqFilters = { ...reqFilters, AttorneyPublicId: reqFilters.attorney.publicId };
    }
    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    if (reqFilters.applicationNo) reqFilters.ApplicationNo = reqFilters.applicationNo;
    if (reqFilters.caseStatus) reqFilters.ApplicationCaseStatus = reqFilters.caseStatus;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.accidentTypeName) reqFilters.AccidentTypeName = reqFilters.accidentTypeName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;
    if (reqFilters.paymentConfinrmationNo)
      reqFilters.PaymentConfinrmationNo = reqFilters.paymentConfinrmationNo;

    delete reqFilters.applicationNo;
    delete reqFilters.client;
    delete reqFilters.caseStatus;
    delete reqFilters.lawFirm;
    delete reqFilters.attorney;
    delete reqFilters.portfolioName;
    delete reqFilters.accidentTypeName;
    delete reqFilters.applicationStatus;
    delete reqFilters.paymentConfinrmationNo;

    setIsLoading(true);

    PayoffLetterRequestService.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAll = () => {
    let tempFilters = {};
    setFilters(tempFilters);
    appContext.payoffRequestsFilters = tempFilters;
    localStorage.setItem('payoffRequestsFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  };

  const update = (filters) => {
    let reqFilters = filters;
    if (reqFilters && reqFilters.client) {
      reqFilters = { ...reqFilters, ClientPublicId: reqFilters.client.publicId };
    }
    if (reqFilters && reqFilters.attorney) {
      reqFilters = { ...reqFilters, AttorneyPublicId: reqFilters.attorney.publicId };
    }
    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    if (reqFilters.applicationNo) reqFilters.ApplicationNo = reqFilters.applicationNo;
    if (reqFilters.caseStatus) reqFilters.ApplicationCaseStatus = reqFilters.caseStatus;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.accidentTypeName) reqFilters.AccidentTypeName = reqFilters.accidentTypeName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;
    if (reqFilters.paymentConfinrmationNo)
      reqFilters.PaymentConfinrmationNo = reqFilters.paymentConfinrmationNo;

    delete reqFilters.applicationNo;
    delete reqFilters.client;
    delete reqFilters.caseStatus;
    delete reqFilters.lawFirm;
    delete reqFilters.attorney;
    delete reqFilters.portfolioName;
    delete reqFilters.accidentTypeName;
    delete reqFilters.applicationStatus;
    delete reqFilters.paymentConfinrmationNo;

    setIsLoading(true);

    PayoffLetterRequestService.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchWithFilters = (filters) => {
    var newFilters = { ...filters };
    setFilters(newFilters);
    appContext.payoffRequestsFilters = newFilters;
    localStorage.setItem('payoffRequestsFilters', JSON.stringify(newFilters));
    if (page === 1) update(newFilters);
    else setPage(1);
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      <GlobalFilteringFields
        appContextFilter={filters}
        searchWithFilters={searchWithFilters}
        getAll={getAll}
        defaultApplicationStatus={DEFAULT_STATUS}
      ></GlobalFilteringFields>

      <Col md="10" className="float-right">
        <TableContainer component={Paper}>
          <Table className={useStyles2} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell
                  style={{ width: '5%', minWidth: '120px' }}
                  onClick={() => {
                    arrange('client');
                    setActiveFieldForSorting('client');
                  }}
                >
                  CLIENT
                  <TableSortLabel
                    active={activeFieldForSorting === 'client' ? true : false}
                    direction={activeFieldForSorting === 'client' ? orderDir.toLowerCase() : 'asc'}
                    onClick={() => arrange('client')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '5%', minWidth: '100px' }}
                  onClick={() => {
                    arrange('applicationStatus');
                    setActiveFieldForSorting('applicationStatus');
                  }}
                >
                  STATUS
                  <TableSortLabel
                    active={activeFieldForSorting === 'applicationStatus' ? true : false}
                    direction={
                      activeFieldForSorting === 'applicationStatus' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('applicationStatus')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '5%', minWidth: '90px' }}
                  onClick={() => {
                    arrange('applicationNo');
                    setActiveFieldForSorting('applicationNo');
                  }}
                >
                  APP #
                  <TableSortLabel
                    active={activeFieldForSorting === 'applicationNo' ? true : false}
                    direction={
                      activeFieldForSorting === 'applicationNo' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('applicationNo')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '5%', minWidth: '125px' }}
                  onClick={() => {
                    arrange('accidentTypeName');
                    setActiveFieldForSorting('accidentTypeName');
                  }}
                >
                  TYPE OF ACCIDENT
                  <TableSortLabel
                    active={activeFieldForSorting === 'accidentTypeName' ? true : false}
                    direction={
                      activeFieldForSorting === 'accidentTypeName' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('accidentTypeName')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '5%', minWidth: '75px' }}
                  onClick={() => {
                    arrange('applicationDate');
                    setActiveFieldForSorting('applicationDate');
                  }}
                >
                  APP. DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'applicationDate' ? true : false}
                    direction={
                      activeFieldForSorting === 'applicationDate' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('applicationDate')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '5%', minWidth: '80px' }}
                  onClick={() => {
                    arrange('requestDate');
                    setActiveFieldForSorting('requestDate');
                  }}
                >
                  REQ. DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'requestDate' ? true : false}
                    direction={
                      activeFieldForSorting === 'requestDate' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('requestDate')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '5%', minWidth: '120px' }}
                  onClick={() => {
                    arrange('payoffGoodUntilDate');
                    setActiveFieldForSorting('payoffGoodUntilDate');
                  }}
                >
                  GOOD UNTIL DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'payoffGoodUntilDate' ? true : false}
                    direction={
                      activeFieldForSorting === 'payoffGoodUntilDate'
                        ? orderDir.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => arrange('payoffGoodUntilDate')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '5%', minWidth: '80px' }}
                  onClick={() => {
                    arrange('daysDiff');
                    setActiveFieldForSorting('daysDiff');
                  }}
                >
                  DAYS DIFF
                  <TableSortLabel
                    active={activeFieldForSorting === 'daysDiff' ? true : false}
                    direction={
                      activeFieldForSorting === 'daysDiff' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('daysDiff')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '5%', minWidth: '130px' }}
                  onClick={() => {
                    arrange('advancedAmount');
                    setActiveFieldForSorting('advancedAmount');
                  }}
                >
                  ADVANCED AMOUNT
                  <TableSortLabel
                    active={activeFieldForSorting === 'advancedAmount' ? true : false}
                    direction={
                      activeFieldForSorting === 'advancedAmount' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('advancedAmount')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '5%', minWidth: '90px' }}
                  onClick={() => {
                    arrange('attorney');
                    setActiveFieldForSorting('attorney');
                  }}
                >
                  ATTORNEY
                  <TableSortLabel
                    active={activeFieldForSorting === 'attorney' ? true : false}
                    direction={
                      activeFieldForSorting === 'attorney' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('attorney')}
                  ></TableSortLabel>
                </StyledTableCell>

                {/* <StyledTableCell
                  style={{ width: '5%', minWidth: '90px' }}
                  onClick={() => {
                    arrange('lastNote');
                    setActiveFieldForSorting('lastNote');
                  }}
                >
                  LAST NOTE
                  <TableSortLabel
                    active={activeFieldForSorting === 'lastNote' ? true : false}
                    direction={
                      activeFieldForSorting === 'lastNote' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('lastNote')}
                  ></TableSortLabel>
                </StyledTableCell> */}
                <StyledTableCell>ACTIONS</StyledTableCell>
              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell style={{ width: '5%', minWidth: '120px' }}>{row.client}</TableCell>
                      <TableCell style={{ width: '5%', minWidth: '100px' }}>
                        {row.applicationStatus}
                      </TableCell>
                      <TableCell style={{ width: '5%', minWidth: '90px' }}>
                        {row.applicationNo}
                      </TableCell>
                      <TableCell style={{ width: '5%', minWidth: '125px' }}>
                        {row.accidentTypeName}
                      </TableCell>
                      <TableCell style={{ width: '5%', minWidth: '75px' }}>
                        {row?.applicationDate &&
                          Moment(row?.applicationDate.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell style={{ width: '5%', minWidth: '80px' }}>
                        {row?.requestDate &&
                          Moment(row?.requestDate.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell style={{ width: '5%', minWidth: '120px' }}>
                        {row?.payoffGoodUntilDate &&
                          Moment(row?.payoffGoodUntilDate.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell style={{ width: '5%', minWidth: '80px' }}>
                        {row.daysDiff}
                      </TableCell>
                      <TableCell style={{ width: '5%', minWidth: '130px', whiteSpace: 'nowrap' }}>
                        {row?.advancedAmount != null &&
                          moneyFormatter.format(parseFloat(row?.advancedAmount).toFixed(2))}
                      </TableCell>
                      <TableCell style={{ width: '5%', minWidth: '90px' }}>
                        {row.attorney}
                      </TableCell>
                      {/* <TableCell style={{ width: '5%', minWidth: '90px' }}>
                        {row.lastNote}
                      </TableCell> */}
                      <TableCell className="p-2">
                        <a
                          className="btn mr-1 btn-sm"
                          href={
                            '/underwriting/applications/' +
                            row.applicationNo +
                            '/payoffs/' +
                            row.payoffRequestPublicId
                          }
                        >
                          <EditIcon />
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
        <div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
      </Col>
    </>
  );
}
