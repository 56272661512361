import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for React 18
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';

const root = document.getElementById('root');
const history = createBrowserHistory();

if (root) {
  // 1. Set up the browser history with the updated location (minus the # sign)
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  // 2. Use the new React 18 root API to render the app
  const rootElement = ReactDOM.createRoot(root);
  rootElement.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
