import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '@contexts/AppContext';

const ApplicationsNewMenu = (props) => {
  const appContext = useContext(AppContext);

  let applicationId = undefined;
  if (appContext.currentApplication === 'new-application') {
    applicationId = 'new-application';
  } else {
    applicationId = appContext.currentApplication
      ? appContext.currentApplication.applicationNo
      : 'new-application';
  }

  if (props.applicationId) applicationId = props.applicationId;

  useEffect(() => {
    const makeInnerMenuItemActive = () => {
      const { menuItemIndex } = props;
      const aElements = document.getElementById('applications-new-menu').querySelectorAll('li a');
      if (aElements[menuItemIndex]) {
        aElements[menuItemIndex].classList.add('active');
      }
    };

    const makeOuterMenusActive = () => {
      document.querySelector('#menuitem-underwriting').classList.add('active');
      document.querySelector('#kt_header_tab_2 li').classList.add('menu-item-active');
    };

    makeInnerMenuItemActive();
    makeOuterMenusActive();
  });

  return (
    <ul id="applications-new-menu" className="nav nav-tabs nav-tabs-line mb-10">
      <li className="nav-item">
        {applicationId === 'new-application' ? (
          <Link to={'/underwriting/applications/' + applicationId + '/'} className="nav-link">
            Attorney
          </Link>
        ) : (
          <Link
            to={'/underwriting/applications/' + applicationId + '/attorney'}
            className="nav-link"
          >
            Attorney
          </Link>
        )}
      </li>

      {applicationId !== 'new-application' && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/plaintiff'}
            className="nav-link"
          >
            Plaintiff
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && !props.disableTabs && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/accident'}
            className="nav-link"
          >
            Accident
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && props.disableTabs === 2 && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/accident'}
            className="nav-link"
          >
            Accident
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && props.disableTabs === 3 && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/accident'}
            className="nav-link"
          >
            Accident
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && props.disableTabs === 1 && (
        <li className="nav-item">
          <div className="nav-link" style={{ opacity: '50%' }}>
            Accident
          </div>
        </li>
      )}

      {applicationId !== 'new-application' && !props.disableTabs && (
        <li className="nav-item">
          <Link to={'/underwriting/applications/' + applicationId + '/injury'} className="nav-link">
            Injury
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && props.disableTabs === 1 && (
        <li className="nav-item">
          <div className="nav-link" style={{ opacity: '50%' }}>
            Injury
          </div>
        </li>
      )}

      {applicationId !== 'new-application' && props.disableTabs === 2 && (
        <li className="nav-item">
          <Link to={'/underwriting/applications/' + applicationId + '/injury'} className="nav-link">
            Injury
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && props.disableTabs === 3 && (
        <li className="nav-item">
          <Link to={'/underwriting/applications/' + applicationId + '/injury'} className="nav-link">
            Injury
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && !props.disableTabs && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/coverage'}
            className="nav-link"
          >
            Coverage
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && props.disableTabs === 3 && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/coverage'}
            className="nav-link"
          >
            Coverage
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' &&
        (props.disableTabs === 1 || props.disableTabs === 2) && (
          <li className="nav-item">
            <div className="nav-link" style={{ opacity: '50%' }}>
              Coverage
            </div>
          </li>
        )}

      {applicationId !== 'new-application' && !props.disableTabs && (
        <li className="nav-item">
          <Link to={'/underwriting/applications/' + applicationId + '/misc'} className="nav-link">
            Misc
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' &&
        (props.disableTabs === 1 || props.disableTabs === 2 || props.disableTabs === 3) && (
          <li className="nav-item">
            <div className="nav-link" style={{ opacity: '50%' }}>
              Misc
            </div>
          </li>
        )}

      {applicationId !== 'new-application' && !props.disableTabs && (
        <li className="nav-item">
          <Link to={'/underwriting/applications/' + applicationId + '/liens'} className="nav-link">
            Liens
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' &&
        (props.disableTabs === 1 || props.disableTabs === 2 || props.disableTabs === 3) && (
          <li className="nav-item">
            <div className="nav-link" style={{ opacity: '50%' }}>
              Liens
            </div>
          </li>
        )}

      {applicationId !== 'new-application' && (
        <li className="nav-item">
          <Link to={'/underwriting/applications/' + applicationId + '/notes'} className="nav-link">
            Notes
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && !props.disableTabs && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/advances'}
            className="nav-link"
          >
            Advances
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' &&
        (props.disableTabs === 1 || props.disableTabs === 2 || props.disableTabs === 3) && (
          <li className="nav-item">
            <div className="nav-link" style={{ opacity: '50%' }}>
              Advances
            </div>
          </li>
        )}

      {applicationId !== 'new-application' && !props.disableTabs && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/documents'}
            className="nav-link documents"
          >
            Documents
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' &&
        (props.disableTabs === 1 || props.disableTabs === 2 || props.disableTabs === 3) && (
          <li className="nav-item">
            <div className="nav-link" style={{ opacity: '50%' }}>
              Documents
            </div>
          </li>
        )}

      {applicationId !== 'new-application' && !props.disableTabs && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/financial-terms'}
            className="nav-link financial-terms"
          >
            Financial Terms
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' &&
        (props.disableTabs === 1 || props.disableTabs === 2 || props.disableTabs === 3) && (
          <li className="nav-item">
            <div className="nav-link" style={{ opacity: '50%' }}>
              Financial Terms
            </div>
          </li>
        )}

      {applicationId !== 'new-application' && !props.disableTabs && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/payoffs'}
            className="nav-link payoffs"
          >
            Payoffs
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' &&
        (props.disableTabs === 1 || props.disableTabs === 2 || props.disableTabs === 3) && (
          <li className="nav-item">
            <div className="nav-link" style={{ opacity: '50%' }}>
              Payoffs
            </div>
          </li>
        )}

      {applicationId !== 'new-application' && !props.disableTabs && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/ims'}
            className="nav-link ims"
          >
            IMS
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' &&
        (props.disableTabs === 1 || props.disableTabs === 2 || props.disableTabs === 3) && (
          <li className="nav-item">
            <div className="nav-link" style={{ opacity: '50%' }}>
              IMS
            </div>
          </li>
        )}

      {applicationId !== 'new-application' && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/AttorneyCaseStatus'}
            className="nav-link"
          >
            Att. Case Status
          </Link>
        </li>
      )}

      {applicationId !== 'new-application' && (
        <li className="nav-item">
          <Link
            to={'/underwriting/applications/' + applicationId + '/activity'}
            className="nav-link activity"
          >
            Activity
          </Link>
        </li>
      )}
    </ul>
  );
};

export default ApplicationsNewMenu;
