import React, { useEffect, useState, useContext, Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useParams } from 'react-router-dom';
import ApplicationsLienService from '@services/ApplicationsLienService';
import LienTypeSelect from '../components/LienTypeSelect';
import Header from '../../Header';
import ApplicationsNewMenu from '../../Menu';
import AppContext from '@contexts/AppContext';
import ApplicationsService from '@services/ApplicationsService';
import ReactLoading from 'react-loading';

const NewLien = () => {
  const { lienId } = useParams();
  const { applicationId } = useParams();
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const submitButton = useRef();
  const [disableTabs, setDisableTabs] = useState(false);
  const [HasFirstButtonSubmitted, setHasFirstButtonSubmitted] = useState(true);
  const [lienType, setLienType] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lien, setLien] = useState({
    applicationPublicId: '',
    lienTo: '',
    lienTypePublicId: '',
    lienAmount: '',
    createdByPublicId: '',
  });

  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const form = useRef();

  const handleFormSubmitted = (data) => {
    if (!(appContext?.userPermissions?.indexOf('Edit Liens') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    const user = JSON.parse(localStorage.getItem('lasuris-user'));

    const newLien = { ...lien };
    newLien.lienAmount = parseFloat(data.lienAmount);
    newLien.lienTo = data.lienTo;
    newLien.createdByPublicId = user.publicId;

    if (lienId) {
      setIsLoading(true);

      ApplicationsLienService.update(newLien)
        .then((response) => {
          appContext.toastMessage.message = 'Lien has been successfully updated.';
          navigate('/underwriting/applications/' + applicationId + '/liens');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (applicationId) {
        setIsLoading(true);

        ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 10).then(
          (response) => {
            newLien.applicationPublicId = response.data.data[0].publicId;

            ApplicationsLienService.create(newLien)
              .then((response) => {
                appContext.toastMessage.message = 'Lien has been successfully saved.';
                navigate('/underwriting/applications/' + applicationId + '/liens');
              })
              .catch((err) => {})
              .finally(() => {
                setIsLoading(false);
              });
          },
        );
      }
    }
  };

  const handleCancelButtonClicked = () => {
    navigate('/underwriting/applications/' + applicationId + '/liens');
  };

  const hideForm = () => {
    form.current.style.display = 'none';
  };

  useEffect(() => {
    if (HasFirstButtonSubmitted === false) {
      setHasFirstButtonSubmitted(true);
      submitButton.current.click();
    }
  }, [HasFirstButtonSubmitted]);

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  });

  const LoadDetailsIfIdPresent = () => {
    if (lienId) {
      setIsLoading(true);

      ApplicationsLienService.read(lienId)
        .then((response) => {
          if (response.data) {
            setLien(response.data);
            setLienType(response.data.lienTypePublicId);
            setIsLoading(false);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    LoadDetailsIfIdPresent();
  }, []);

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">
              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="container mt-10 mb-10">
      <Row>
        <Col md="12" className="mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              {disableTabs != null && (
                <Header applicationId={applicationId} handleSaveAndExit={() => {}} />
              )}

              {disableTabs != null && (
                <ApplicationsNewMenu
                  disableTabs={disableTabs}
                  applicationId={applicationId}
                  menuItemIndex="6"
                />
              )}

              <form ref={form} onSubmit={handleSubmit(handleFormSubmitted)} noValidate>
                <Container className="p-0">
                  <Row>
                    <Col md="12">
                      <Row>
                        <Col md="6">
                          <Row>
                            <Col md="5">
                              <FormGroup>
                                <LienTypeSelect
                                  name="lienType"
                                  label="Lien Type"
                                  useRef={register({ required: 'Lien Type is required.' })}
                                  setObject={setLien}
                                  givenObject={lien}
                                  defaultValue={lien.lienTypePublicId}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="5">
                              <FormGroup>
                                <Label htmlFor="lienTo">Lien To</Label>
                                <input
                                  type="text"
                                  name="lienTo"
                                  maxLength="50"
                                  className="form-control"
                                  ref={register({
                                    required: 'Lien To is required.',
                                    maxLength: {
                                      value: 50,
                                      message: 'Max length is 50 characters.',
                                    },
                                  })}
                                  defaultValue={lien.lienTo}
                                />
                                {errors.lienTo && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="lienTo" />
                                  </Alert>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="5">
                              <FormGroup>
                                <Label htmlFor="amount">Amount</Label>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  name="lienAmount"
                                  className="form-control"
                                  ref={register({
                                    required: 'Lien Amount is required.',
                                    pattern: {
                                      value: /^\d{1,}(\.\d{0,2})?$/,
                                      message: 'Only numeric values with 2 decimals are allowed.',
                                    },
                                  })}
                                  defaultValue={lien.lienAmount}
                                />
                                {errors.lienAmount && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="lienAmount" />
                                  </Alert>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="5">
                              <button type="submit" className="btn btn-primary mr-8">
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handleCancelButtonClicked}
                              >
                                Cancel
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewLien;
