import React, { useEffect, useContext, useRef, useState } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import GridService from '@services/GridService';
import UserService from '@services/UserService';
import '../Styles.css';
import { useParams } from 'react-router-dom';

const AccountManagerDetails = (props) => {
  let dateFilter = useRef();
  // const { params } = props.match.accountManagerId;
  const [dateRange, setDaterange] = useState(false);
  const [accountManagersOptions, setAccountManagersOptions] = useState([]);
  const [selectedAccountManager, setSelectedAccountManager] = useState({});

  const [dateValue, setDateValue] = useState('This month');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [appStatusOptions, setAppStatusOptions] = useState([]);
  const { accountManagerId } = useParams();
  const [userWithOnlyAccountManagerUserRole, setUserWithOnlyAccountManagerUserRole] =
    useState(false);
  const appContext = useContext(AppContext);
  const accountManagerDetailsReportFilters = appContext.accountManagerDetailsReportFilters;

  function clearFilters() {
    setDateValue('This month');
    dateFilter.current.value = 'This month';
    appContext.accountManagerDetailsReportFilters = {};
    setDaterange(false);
    setFromDate(null);
    setToDate(null);
    setSelectedAccountManager({});
    props.getAll();
  }

  function changeDateFunc() {
    const dateFilter = document.getElementById('dateFilter');
    const selectedDate = dateFilter.options[dateFilter.selectedIndex].value;
    if (selectedDate === 'Custom') {
      setDaterange(true);
      setDateValue(null);
    } else {
      setDaterange(false);
      setDateValue(selectedDate);
      setFromDate(null);
      setToDate(null);
    }
  }

  function changeAccountManagerFunc(event) {
    setSelectedAccountManager(event.target.value);
    event.target.size = 1;
    event.target.blur();
  }

  function onFocusFunc(event) {
    event.target.size = 10;
  }

  function onBlurFunc(event) {
    event.target.size = 1;
  }

  function changeFromDate() {
    const fromDate = document.getElementById('fromDate');
    const selectedFromDate = fromDate.value;
    setFromDate(selectedFromDate);
  }

  function changeToDate() {
    const toDate = document.getElementById('toDate');
    const selectedToDate = toDate.value;
    if (fromDate < selectedToDate) {
      setToDate(selectedToDate);
    } else {
      if (fromDate != null) {
      } else {
        alert('Select from date first');
      }
    }
  }

  function selectDateRange() {
    const tempDateValue = document.getElementById('dateFilter')?.value;
    const tempToDate = document.getElementById('toDate')?.value;
    const tempFromDate = document.getElementById('fromDate')?.value;

    if (tempDateValue !== null && tempDateValue !== 'Custom') return tempDateValue;

    if (tempFromDate != null && tempToDate != null) {
      let dateRange = {
        fromDate: new Date(tempFromDate).toISOString(),
        toDate: new Date(tempToDate).toISOString(),
      };
      return dateRange;
    }

    return '';
  }

  useEffect(() => {
    UserService.searchAccountManagers('').then((response) => {
      if (!response.data || !response.data.data) return;

      if (response.data.data.length > 0) {
        let tempStatusOptions = [];
        response.data.data.map((item) => {
          tempStatusOptions.push({
            label: item.displayName,
            value: item.publicId,
          });
          setAccountManagersOptions(tempStatusOptions);
        });
      }

      // this.setState({ suggestions: response.data.data });
    });

    const isFiltersEmpty = Object.keys(accountManagerDetailsReportFilters).length == 0;
    //Get application status options
    GridService.read('/Application/applicationstatuses').then((resp) => {
      if (!resp.data) return;
      if (resp.data.length > 0) {
        let tempStatusOptions = [];
        resp.data.map((item) => {
          tempStatusOptions.push({
            label: item,
            value: item,
          });
          setAppStatusOptions(tempStatusOptions);
        });
      }
    });

    //Retaining data by checking with appcontext
    if (!isFiltersEmpty) {
      if (accountManagerDetailsReportFilters.dateRange) {
        if (typeof accountManagerDetailsReportFilters.dateRange == 'string') {
          dateFilter.current.value = accountManagerDetailsReportFilters.dateRange;
          setDateValue(accountManagerDetailsReportFilters.dateRange);
          setDaterange(false);
          setFromDate(null);
          setToDate(null);
          appContext.accountManagerSummaryReportFilters.dateRange = dateFilter.current.value;
        } else {
          dateFilter.current.value = 'Custom';
          setDaterange(true);
          // setDateValue('This month');
          appContext.accountManagerSummaryReportFilters.dateRange =
            accountManagerDetailsReportFilters.dateRange;
          setFromDate(accountManagerDetailsReportFilters.dateRange.fromDate.split('T')[0]);
          setToDate(accountManagerDetailsReportFilters.dateRange.toDate.split('T')[0]);
        }
      }
      setAccountManager();
    }
  }, []);

  async function setAccountManager() {
    let tempFilterData = await JSON.parse(
      localStorage.getItem('accountManagerDetailsReportFilters'),
    );
    const userDetails = JSON.parse(localStorage.getItem('lasuris-user'));
    if (
      userDetails?.roles &&
      userDetails.roles.length == 1 &&
      userDetails.roles[0]?.roleName == 'Account Manager'
    ) {
      setUserWithOnlyAccountManagerUserRole(true);
      setSelectedAccountManager(userDetails);
      return;
    } else {
      setUserWithOnlyAccountManagerUserRole(false);
    }

    if (accountManagerId) {
      if (appContext.accountManagerSummaryReportFilters.dateRange) {
        if (typeof appContext.accountManagerSummaryReportFilters.dateRange == 'string') {
          dateFilter.current.value = appContext.accountManagerSummaryReportFilters.dateRange;
          setDateValue(appContext.accountManagerSummaryReportFilters.dateRange);
          setDaterange(false);
          setFromDate(null);
          setToDate(null);
        } else {
          dateFilter.current.value = 'Custom';
          setDaterange(true);
          //setDateValue('This month');
          setFromDate(
            appContext.accountManagerSummaryReportFilters.dateRange.fromDate.split('T')[0],
          );
          setToDate(appContext.accountManagerSummaryReportFilters.dateRange.toDate.split('T')[0]);
        }
      }
      setSelectedAccountManager(accountManagerId);
    } else if (tempFilterData.accountManager) {
      setSelectedAccountManager(tempFilterData.accountManager);
    }
  }

  function search() {
    let filters = {
      dateRange: selectDateRange(),
      accountManager: selectedAccountManager,
    };

    if (filters.dateRange === '') delete filters.dateRange;

    props.searchWithFilters(filters);
  }

  return (
    <>
      <Col md="2" className="float-left acc-man-filter-container">
        <Row className="mt-5">
          <Col md="6">
            <h2>Filters</h2>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="dateRange">Advance Date Range</label>
          </Col>
          <Col md="12">
            <select
              id="dateFilter"
              onChange={changeDateFunc}
              className="form-control"
              ref={dateFilter}
            >
              <option value="This month">This month</option>
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="This week">This week</option>
              <option value="Last week">Last week</option>
              <option value="Last month">Last month</option>
              <option value="YTD">YTD</option>
              <option value="Custom">Custom</option>
            </select>
          </Col>
        </Row>
        {dateRange == true && (
          <>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">From</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeFromDate}
                  type="date"
                  id="fromDate"
                  className="form-control"
                  required="required"
                  defaultValue={fromDate}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">To</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeToDate}
                  type="date"
                  id="toDate"
                  className="form-control"
                  required="required"
                  defaultValue={toDate}
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="accountManager">Account Manager</label>
          </Col>
          <Col md="12">
            <select
              className="form-control"
              id="accidentTypeFilter"
              name="accidentTypeFilter"
              onChange={changeAccountManagerFunc}
              onFocus={onFocusFunc}
              onBlur={onBlurFunc}
              value={selectedAccountManager}
            >
              <option value="">Select Account Manager</option>
              {accountManagersOptions.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col md="6" className="pr-0">
            <span className="a-link">
              <a className="btn clear-filter-btn" onClick={clearFilters}>
                Clear filters
              </a>
            </span>
          </Col>
          <Col md="6">
            <span className="a-link float-right">
              <a className="btn btn-primary search-btn" onClick={search}>
                <i class="fas fa-search"></i>
                Search
              </a>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default AccountManagerDetails;
