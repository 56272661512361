import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthService from '@services/AuthService';
import AppContext from '@contexts/AppContext';
import { useForm } from 'react-hook-form';

import './ResetPassword.css';

import lasurisLogo from '@images/lasuris_logo.png';

const ResetPassword = (props) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { register, handleSubmit, watch, errors } = useForm();
  const token = props.match.params.token;
  const form = useRef();

  useEffect(() => {
    if (appContext.toastMessage.message === 'You have logged out sucessfully.') {
      toast.info(appContext.toastMessage.message);
      appContext.toastMessage.message = null;
    }
  });

  const loginFormSubmit = (e) => {
    if (e.password !== e.passwordRepeat) {
      setErrorMessage('Passwords do not match. Please enter same passwords');
      return;
    }

    AuthService.resetPassword(token, e.password)
      .then((res) => {
        appContext.toastMessage.message = 'You have reset your password successfully.';
        toast.info(appContext.toastMessage.message, { autoClose: 3000 });
        navigate('/login');
      })
      .catch((err) => {
        // appContext.toastMessage.message = err.message;
        // toast.error(appContext.toastMessage.message, { autoClose: 3000 });

        setErrorMessage(err.message);
        // toast.success(appContext.toastMessage.message, { autoClose: 3000 });
        // setError('email', 'Wrong email.');
      });
  };

  const handleCancelButtonClicked = () => {
    navigate('/login');
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="d-flex flex-column flex-root flex-center mt-20">
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white box-shadow-border">
          <div
            className="login-content flex-row-fluid d-flex flex-column 
          justify-content-center position-relative overflow-hidden p-7 mx-auto"
            style={{ width: '500px' }}
          >
            <div className="d-flex flex-column-fluid flex-center" />
            <div className="login-form login-signin">
              <div className="d-flex flex-center mb-5">
                <Link to="/login">
                  <img src={lasurisLogo} alt="logo" />
                </Link>
              </div>

              <form ref={form} onSubmit={handleSubmit(loginFormSubmit)} noValidate>
                {errorMessage && (
                  <div className="alert alert-custom alert-danger mb-10" role="alert">
                    <div className="alert-icon">
                      <i className="flaticon-warning" />
                    </div>
                    <div className="alert-text"> {errorMessage} </div>
                  </div>
                )}

                <div className="pt-lg-0 pt-5">
                  <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg d-flex flex-center">
                    LitFinPro Reset Password
                  </h3>
                </div>

                <p></p>
                <div className="form-group fv-plugins-icon-container">
                  <div className="d-flex justify-content-between mt-n5">
                    <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                      Password
                    </label>
                  </div>
                  <input
                    className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    ref={register({ required: true })}
                    name="password"
                    autoComplete="off"
                  />
                  {errors.password && <span>This field is required</span>}
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <div className="d-flex justify-content-between mt-n5">
                    <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                      Repeat Password
                    </label>
                  </div>
                  <input
                    className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    ref={register({ required: true })}
                    name="passwordRepeat"
                    autoComplete="off"
                  />
                  {errors.passwordRepeat && <span>This field is required</span>}
                </div>

                <div className="form-group d-flex flex-wrap pb-lg-0 d-flex flex-center">
                  <input
                    ref={register}
                    type="submit"
                    id="kt_login_signin_submit"
                    className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                    value="Send"
                  />
                  <input
                    ref={register}
                    type="button"
                    onClick={handleCancelButtonClicked}
                    id="kt_login_signin_submit"
                    className="btn btn-secondary font-size-h6 px-8 py-4 my-3 mr-3"
                    value="Cancel"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
