import { useParams, Link } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { Col } from 'reactstrap';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AppContext from '@contexts/AppContext';
import ReactLoading from 'react-loading';
import './Styles.css';
import LawFirmService from '@services/LawFirmService';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: '14px', // Set font size for the body cells only
  },
}));

const useStyles2 = {
  minWidth: 500,
};

export default function LawFirmsAdvancesTable() {
  const appContext = useContext(AppContext);
  const { id } = useParams();

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(99999);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setIsLoading(true);

    if (id) {
      LawFirmService.readAdvances(id)
        .then((response) => {
          setRows(response.data);
          setIsLoading(false);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  return (
    <>
      <Col md="8" className="float-left lfsr-table-container">
        <br />
        <TableContainer component={Paper}>
          <Table className={useStyles2} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell className="num-cases"></StyledTableCell>
                <StyledTableCell className="law-firm">Advance Status</StyledTableCell>

                <StyledTableCell className="total-advanced-amt">
                  Total Advanced Amount
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell className="num-cases"></TableCell>
                      <TableCell className="law-firm">{row?.status ? row.status : '-'}</TableCell>
                      <TableCell className="total-advanced-amt">
                        {row?.count ? row.count : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      </Col>
    </>
  );
}
