import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';
import { toast } from 'react-toastify';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('lasuris-user');
      localStorage.removeItem('lasuris-jwt');
      localStorage.setItem('isUserAuthenticated', false);
      window.location.reload(false);
    }
    if (error.response.status === 422) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    } else {
      toast.error('An error has occured. Please contact your system administrator.', {
        autoClose: 3000,
      });
    }
    throw new Error(error.response.data.errors ?? error.message);
  },
);

class AttorneyAuthService {
  constructor() {
    if (!AttorneyAuthService.instance) {
      AttorneyAuthService.instance = this;
    }
    return AttorneyAuthService.instance;
  }

  loginAttorney = async (email, password, attorneyPublicId) => {
    const response = await api.post('/users/attorneylogin', { email, password, attorneyPublicId });

    if (response.data) {
      localStorage.setItem('lasuris-user', JSON.stringify(response.data.user));
      localStorage.setItem('lasuris-jwt', response.data.jwtToken);
      return response.data;
    } else {
      return response;
    }
  };
}

const instance = new AttorneyAuthService();
Object.freeze(instance);

export default instance;
