import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import LawFirmService from '@services/LawFirmService';

class LawFirmAutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    const { defaultLawFirm } = props;
    this.state = {
      value: defaultLawFirm?.lawFirmName || '',
      suggestions: [],
    };
  }

  componentDidMount() {
    const { defaultLawFirm } = this.props;
    this.setState({
      value: defaultLawFirm?.lawFirmName || '',
    });
  }

  componentDidUpdate(prevProps) {
    const { defaultLawFirm } = this.props;
    if (prevProps.defaultLawFirm !== defaultLawFirm) {
      this.setState({
        value: defaultLawFirm?.lawFirmName || '',
      });
    }
  }

  getSuggestionValue = (suggestion) => {
    const { selectedLawFirm, selectedLawFirmName } = this.props;
    selectedLawFirm(suggestion);
    selectedLawFirmName(suggestion.lawFirmName);
    return suggestion.lawFirmName;
  };

  static renderSuggestion(suggestion) {
    return <span>{suggestion.lawFirmName}</span>;
  }

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });

    if (!newValue) {
      const { selectedLawFirm, selectedLawFirmName } = this.props;
      selectedLawFirmName('');
      selectedLawFirm({});
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    LawFirmService.autoSuggest(value).then((response) => {
      this.setState({ suggestions: response.data.data });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search Law Firm...',
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={LawFirmAutoSuggest.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

LawFirmAutoSuggest.propTypes = {
  defaultLawFirm: PropTypes.shape({
    lawFirmName: PropTypes.string,
  }),
  selectedLawFirm: PropTypes.func.isRequired,
  selectedLawFirmName: PropTypes.func.isRequired,
};

LawFirmAutoSuggest.defaultProps = {
  defaultLawFirm: null,
};

export default LawFirmAutoSuggest;
