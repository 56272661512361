import React, { useEffect, useContext, useRef, useState } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import '../Styles.css';

const FilteringFields = (props) => {
  let dateFilter = useRef();

  const clearFilters = () => {
    setDateValue('This month');
    dateFilter.current.value = 'This month';
    appContext.lawFirmSummaryReportFilters = {};
    setDaterange(false);
    setFromDate(null);
    setToDate(null);
    props.getAll();
  };
  const [dateRange, setDaterange] = useState(false);
  const [dateValue, setDateValue] = useState('This month');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  function changeFunc() {
    const dateFilter = document.getElementById('dateFilter');
    const selectedDate = dateFilter.options[dateFilter.selectedIndex].value;
    if (selectedDate === 'Custom') {
      setDaterange(true);
      setDateValue(null);
    } else {
      setDaterange(false);
      setDateValue(selectedDate);
      setFromDate(null);
      setToDate(null);
    }
  }
  function changeFromDate() {
    const fromDate = document.getElementById('fromDate');
    const selectedFromDate = fromDate.value;
    setFromDate(selectedFromDate);
  }
  function changeToDate() {
    const toDate = document.getElementById('toDate');
    const selectedToDate = toDate.value;
    if (fromDate < selectedToDate) {
      setToDate(selectedToDate);
    } else {
      if (fromDate != null) {
      } else {
        alert('Select from date first');
      }
    }
  }
  function selectDateRange() {
    if (dateValue != null) {
      return dateValue;
    } else {
      if (fromDate != null && toDate != null) {
        let dateRange = {
          fromDate: new Date(fromDate).toISOString(),
          toDate: new Date(toDate).toISOString(),
        };
        return dateRange;
      } else {
        return '';
      }
    }
  }
  const appContext = useContext(AppContext);
  const lawFirmSummaryReportFilters = appContext.lawFirmSummaryReportFilters;
  useEffect(() => {
    //Retaining data by checking with appcontext
    const isFiltersEmpty = Object.keys(lawFirmSummaryReportFilters).length == 0;
    if (!isFiltersEmpty) {
      if (lawFirmSummaryReportFilters.dateRange) {
        if (typeof lawFirmSummaryReportFilters.dateRange == 'string') {
          dateFilter.current.value = lawFirmSummaryReportFilters.dateRange;
          setDateValue(lawFirmSummaryReportFilters.dateRange);
          setDaterange(false);
          setFromDate(null);
          setToDate(null);
        } else {
          dateFilter.current.value = 'Custom';
          setDaterange(true);
          setDateValue(null);
          setFromDate(lawFirmSummaryReportFilters.dateRange.fromDate.split('T')[0]);
          setToDate(lawFirmSummaryReportFilters.dateRange.toDate.split('T')[0]);
        }
      }
    }
  }, []);

  const search = () => {
    let filters = {
      dateRange: selectDateRange(),
    };

    if (filters.dateRange === '') delete filters.dateRange;

    props.searchWithFilters(filters);
  };
  return (
    <>
      <Col md="3" className="float-left lfsr-filter-container">
        <Row className="mt-5">
          <Col md="6">
            <h2>Filters</h2>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="dateRange">Advance Date Range</label>
          </Col>
          <Col md="12">
            <select id="dateFilter" onChange={changeFunc} className="form-control" ref={dateFilter}>
              <option value="This month">This month</option>
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="This week">This week</option>
              <option value="Last week">Last week</option>
              <option value="Last month">Last month</option>
              <option value="YTD">YTD</option>
              <option value="Custom">Custom</option>
            </select>
          </Col>
        </Row>
        {dateRange == true && (
          <>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">From</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeFromDate}
                  type="date"
                  id="fromDate"
                  className="form-control"
                  required="required"
                  defaultValue={fromDate}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">To</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeToDate}
                  type="date"
                  id="toDate"
                  className="form-control"
                  required="required"
                  defaultValue={toDate}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-5 mb-5">
          <Col md="6" className="pr-0">
            <span className="a-link">
              <a className="btn clear-filter-button" onClick={clearFilters}>
                Clear filters
              </a>
            </span>
          </Col>
          <Col md="6">
            <span className="a-link float-right">
              <a className="btn btn-primary search-btn" onClick={search}>
                <i class="fas fa-search"></i>
                Search
              </a>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default FilteringFields;
