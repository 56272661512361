import React, { useEffect, useState } from 'react';
import ProcessingFeeRatesService from '../../../../services/ProcessingFeeRatesService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

const ProcessingFeeRatesTable = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [inputList, setInputList] = useState([]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { fromAmount: 0, toAmount: 0, feeAmount: 0 }]);
  };

  useEffect(() => {
    const fetchProcessingFeeRate = () => {
      setIsLoading(true);

      ProcessingFeeRatesService.search2(1)
        .then((res) => {
          res.data.data.map((item) => {
            setInputList((setInputList) => [...setInputList, item]);
          });
          if (res.data && res.data.data && res.data.data.length < 1) {
            setInputList((setInputList) => [
              ...setInputList,
              { fromAmount: 0, toAmount: 0, feeAmount: 0 },
            ]);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchProcessingFeeRate();
  }, []);

  const onSaveProcessingFeeRate = (data) => {
    setIsLoading(true);

    let newItems = [];
    let oldItems = [];

    inputList.map((item) => {
      if (item.publicId) {
        let obj = {
          publicId: item.publicId,
          fromAmount: parseFloat(item.fromAmount),
          toAmount: parseFloat(item.toAmount),
          feeAmount: parseFloat(item.feeAmount),
        };
        oldItems.push(obj);
      } else {
        let obj2 = {
          fromAmount: parseFloat(item.fromAmount),
          toAmount: parseFloat(item.toAmount),
          feeAmount: parseFloat(item.feeAmount),
        };
        oldItems.push(obj2);
      }
    });

    if (newItems.length > 0) {
      ProcessingFeeRatesService.create({ processingFeeRates: newItems })
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (oldItems.length > 0) {
      let updateReqData = {
        processingFeeRates: oldItems,
      };

      ProcessingFeeRatesService.update(updateReqData)
        .then((res) => {
          toast.success('Processing Fee Rates have been successfully updated.', {
            autoClose: 3000,
          });
          // navigate('/settings/misc-settings/');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-md-12 p-0 form-inline">
      <div className="col-md-12 p-0 mb-3 form-inline">
        <h2 className="mt-3 mb-5">Processing Fee Rates Table</h2>
        <button
          type="button"
          className="mt-3 mb-5 ml-10 btn btn-primary"
          style={{ backgroundColor: 'var(--green)', borderColor: 'var(--green)' }}
          onClick={handleAddClick}
        >
          Add
        </button>
      </div>

      {inputList.map((x, i) => {
        return (
          <div className="form-group mb-5 p-0 col-md-12" key={i}>
            <input
              name="fromAmount"
              type="number"
              className="form-control"
              value={x.fromAmount}
              style={{ width: '100px' }}
              onChange={(e) => handleInputChange(e, i)}
            />
            <span>&nbsp;-&nbsp;</span>
            <input
              name="toAmount"
              type="number"
              className="form-control"
              value={x.toAmount}
              style={{ width: '100px' }}
              onChange={(e) => handleInputChange(e, i)}
            />
            <span>&nbsp;=&nbsp;</span>
            <input
              name="feeAmount"
              type="number"
              className="form-control"
              value={x.feeAmount}
              style={{ width: '100px' }}
              onChange={(e) => handleInputChange(e, i)}
            />
            <i
              className="flaticon-circle text-secondary ml-2 "
              style={{ cursor: 'pointer' }}
              onClick={() => handleRemoveClick(i)}
            />
          </div>
        );
      })}

      <button className="btn btn-primary mt-2" onClick={onSaveProcessingFeeRate}>
        Save
        {/*loadingSave && <span class="spinner-border spinner-border-sm ml-2" role="status"></span>*/}
      </button>
    </div>
  );
};

export default ProcessingFeeRatesTable;
