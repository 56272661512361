import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import ApplicationsNewMenu from '../Menu/ApplicationsNewMenu';
import Header from '../Header';
import ApplicationFlagService from '@services/ApplicationFlagService';

import NotesGrid from './NotesGrid';

const Notes = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { applicationId } = useParams();
  const [disableTabs, setDisableTabs] = useState(false);

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-underwriting').click();
  };

  useEffect(() => {
    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      navigate('/underwriting/applications/new-application/');
      return;
    }

    makeMenuItemActive();

    setIsLoading(true);

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped' },
      null,
      null,
      1,
      null,
      1,
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped') {
          setDisableTabs(1);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
      null,
      null,
      1,
      null,
      1,
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped2') {
          setDisableTabs(2);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped3' },
      null,
      null,
      1,
      null,
      1,
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped3') {
          setDisableTabs(3);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  });

  const handleNewItem = () => {
    let path = applicationId ? applicationId : 'new-application';
    navigate('/underwriting/applications/' + path + '/notes/new-note');
  };

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              {disableTabs != null && <Header applicationId={applicationId} />}

              {disableTabs != null && (
                <ApplicationsNewMenu
                  disableTabs={disableTabs}
                  applicationId={applicationId}
                  menuItemIndex="7"
                />
              )}

              <Row>
                <Col md="4">
                  <h1 className="mb-10">Notes</h1>
                </Col>
                <Col md="8" className="float-right">
                  <Link
                    onClick={() => handleNewItem()}
                    className="btn btn-primary float-right"
                    style={{
                      backgroundColor: 'var(--green)',
                      borderColor: 'var(--green)',
                    }}
                  >
                    New Note
                  </Link>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <NotesGrid />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notes;
