import axios from 'axios';
import authHeader from './AuthHeader';
import history from '@src/history';
import { toast } from 'react-toastify';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

class SearchService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.api = null;

    this.createApiClient();
  }

  createApiClient = () => {
    this.api = axios.create({ baseURL: this.baseUrl });

    this.api.interceptors.request.use((config) => auth(config));
    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => resError(error),
    );
  };

  search = (filters, p, fields) => {
    return this.api.post('/search', {
      filters: Object.keys(filters).length > 0 ? filters : null,
      page: p,
      fields: fields.length ? fields : null,
    });
  };

  create = (entity) => {
    return this.api.post('/', entity);
  };

  read = (id) => {
    return this.api.get('/' + id);
  };

  update = (entity) => {
    return this.api.put('/', entity);
  };

  delete = (id) => {
    return this.api.delete('/' + id);
  };
}

export default SearchService;
