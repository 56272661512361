import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AppContext from '@contexts/AppContext';
import ReactLoading from 'react-loading';
import { Row, Col } from 'reactstrap';
import GridService from '@services/GridService';
import './Styles.css';
import ApplicationsService from '@services/ApplicationsService';

function truncateNumber(value, precision) {
  var step = Math.pow(10, precision || 0);
  var temp = Math.trunc(step * value);

  return temp / step;
}

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const useStyles2 = {
  minWidth: 300,
  maxWidth: 400,
};

export default function FinancialGrid() {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { applicationId } = useParams();

  const [rows, setRows] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    if (!applicationId) {
      return;
    } else {
      ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1).then(
        (response) => {
          setIsLoading(true);
          let endpoint = `ApplicationFinancialTerm/${response?.data?.data[0].publicId}`;
          GridService.read(endpoint)
            .then((response) => {
              setIsLoading(false);
              if (!response.data) return;
              setRows(response.data);
            })
            .catch((error) => {
              setIsLoading(false);
            });
        },
      );
    }
  };
  useEffect(() => {
    if (!applicationId || applicationId === 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      navigate('/underwriting/applications/new-application/');
      return;
    }

    fetchData();
  }, []);

  return isLoading === false ? (
    <Row>
      <Col md="4">
        <h3 className="table-header">Financial Summary</h3>
        <div>
          <TableContainer
            style={{ border: '1px solid', borderRadius: 0 }}
            className="tableFinancial"
            component={Paper}
          >
            <Table className={useStyles2} aria-label="custom pagination table">
              <TableHead></TableHead>
              <TableBody>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Total Number of Advances</TableCell>
                  <TableCell>
                    {rows.totalNumberofAdvancedFiles ? rows.totalNumberofAdvancedFiles : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Advanced Amount</TableCell>
                  <TableCell>
                    {rows.advancedAmount
                      ? moneyFormatter.format(parseFloat(rows.advancedAmount).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Contractual Cash Outlay</TableCell>
                  <TableCell>
                    {rows.contractualCashOutlay
                      ? moneyFormatter.format(parseFloat(rows.contractualCashOutlay).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Cash to Client</TableCell>
                  <TableCell>
                    {rows.cashtoClient
                      ? moneyFormatter.format(parseFloat(rows.cashtoClient).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Other Disbursements </TableCell>
                  <TableCell>
                    {rows.otherDisbursements
                      ? moneyFormatter.format(parseFloat(rows.otherDisbursements).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">NFP Fees</TableCell>
                  <TableCell>
                    {rows.nfpfees
                      ? moneyFormatter.format(parseFloat(rows.nfpfees).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Account Manager Fees</TableCell>
                  <TableCell>
                    {rows.accountManagerFees
                      ? moneyFormatter.format(parseFloat(rows.accountManagerFees).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Referral Fees</TableCell>
                  <TableCell>
                    {rows.referralFees
                      ? moneyFormatter.format(parseFloat(rows.referralFees).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Store Fees</TableCell>
                  <TableCell>
                    {rows.storeFees
                      ? moneyFormatter.format(parseFloat(rows.storeFees).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Bank Wire Fees</TableCell>
                  <TableCell>
                    {rows.bankWireFees
                      ? moneyFormatter.format(parseFloat(rows.bankWireFees).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Overnight Check Fees</TableCell>
                  <TableCell>
                    {rows.overnightCheckFees
                      ? moneyFormatter.format(parseFloat(rows.overnightCheckFees).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Present value </TableCell>
                  <TableCell>
                    {rows.presentValue
                      ? moneyFormatter.format(parseFloat(rows.presentValue).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Concession (Marketing)</TableCell>
                  <TableCell>
                    {rows.concessionMarketing
                      ? moneyFormatter.format(parseFloat(rows.concessionMarketing).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Case Related Concession</TableCell>
                  <TableCell style={{ borderBottom: '1px solid' }}>
                    {rows.caseRelatedConcession
                      ? moneyFormatter.format(parseFloat(rows.caseRelatedConcession).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Total Concession</TableCell>
                  <TableCell>
                    {rows.totalConcession
                      ? moneyFormatter.format(parseFloat(rows.totalConcession).toFixed(2))
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">As % of Present Value</TableCell>
                  <TableCell>
                    {rows.totalConcession && rows.presentValue
                      ? rows.presentValue <= 0
                        ? '0.00'
                        : parseFloat((rows.totalConcession / rows.presentValue) * 100).toFixed(2) +
                          '%'
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Amount Received</TableCell>
                  <TableCell>
                    {rows.amountReceived ? '$' + parseFloat(rows.amountReceived).toFixed(2) : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Average Days Outstanding</TableCell>
                  <TableCell>
                    {rows.averageDaysOutstanding
                      ? parseFloat(rows.averageDaysOutstanding).toFixed(0)
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell style={{ textDecoration: 'underLine', fontWeight: 'bold' }}>
                    Forecast
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">WA Aggregate APR </TableCell>
                  <TableCell>
                    {rows.forecastWaaggregateApr
                      ? parseFloat(rows.forecastWaaggregateApr * 100).toFixed(2) + '%'
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Individual APR </TableCell>
                  <TableCell>
                    {rows.forecastIndividualApr
                      ? parseFloat(rows.forecastIndividualApr * 100).toFixed(2) + '%'
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell style={{ textDecoration: 'underLine', fontWeight: 'bold' }}>
                    Actual
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Aggregate APR </TableCell>
                  <TableCell>
                    {rows.actualAggregateApr
                      ? parseFloat(rows.actualAggregateApr * 100).toFixed(2) + '%'
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Individual APR </TableCell>
                  <TableCell>
                    {rows.actualIndividualApr
                      ? parseFloat(rows.actualIndividualApr * 100).toFixed(2) + '%'
                      : '-'}
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableFooter></TableFooter>
            </Table>
          </TableContainer>
        </div>
      </Col>
      <Col md="4">
        <h3 className="table-header">Other Metrics</h3>
        <div>
          <TableContainer
            style={{ border: '1px solid', borderRadius: 0 }}
            className="tableFinancial"
            component={Paper}
          >
            <Table className={useStyles2} aria-label="custom pagination table">
              <TableHead></TableHead>
              <TableBody>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Delivery Fees</TableCell>
                  <TableCell>
                    {rows.deliveryFees
                      ? moneyFormatter.format(
                          truncateNumber(parseFloat(rows.deliveryFees), 2).toFixed(2),
                        )
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Gross Revenue</TableCell>
                  <TableCell>
                    {rows.grossRevenue
                      ? moneyFormatter.format(
                          truncateNumber(parseFloat(rows.grossRevenue), 2).toFixed(2),
                        )
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Net Revenue</TableCell>
                  <TableCell>
                    {rows.netRevenue
                      ? moneyFormatter.format(
                          truncateNumber(parseFloat(rows.netRevenue), 2).toFixed(2),
                        )
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Payoff(Actual)APR</TableCell>
                  <TableCell>
                    {rows.payoffActualApr
                      ? truncateNumber(parseFloat(rows.payoffActualApr * 100), 2).toFixed(2) + '%'
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow className="highlight-row">
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell className="highlight-text">Forecast APR</TableCell>
                  <TableCell>
                    {rows.forecastApr
                      ? truncateNumber(parseFloat(rows.forecastApr * 100), 2).toFixed(2) + '%'
                      : '-'}
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableFooter></TableFooter>
            </Table>
          </TableContainer>
        </div>
      </Col>
    </Row>
  ) : (
    <ReactLoading type="bars" color="#7E8299" />
  );
}
