import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import AttorneyService from '@services/AttorneyService';

class AttorneyAutoSuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultAttorney?.displayName || '',
      suggestions: [],
    };
  }

  static renderSuggestion = (suggestion) => <span>{suggestion.displayName}</span>;

  componentDidMount() {
    const { defaultAttorney } = this.props;
    this.setState({
      value: defaultAttorney?.displayName || '',
    });
  }

  componentDidUpdate(prevProps) {
    const { defaultAttorney } = this.props;
    if (prevProps.defaultAttorney !== defaultAttorney) {
      this.setState({
        value: defaultAttorney?.displayName || '',
      });
    }
  }

  getSuggestionValue = (suggestion) => {
    const { selectedAttorney, selectedAttorneyName } = this.props;
    selectedAttorney(suggestion);
    selectedAttorneyName(suggestion.displayName);
    return suggestion.displayName;
  };

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
    if (!newValue) {
      const { selectedAttorney, selectedAttorneyName } = this.props;
      selectedAttorneyName('');
      selectedAttorney({});
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    AttorneyService.autoSuggest(value).then((response) => {
      this.setState({ suggestions: response.data.data });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search Attorney...',
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={AttorneyAutoSuggest.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

AttorneyAutoSuggest.propTypes = {
  selectedAttorney: PropTypes.func.isRequired,
  selectedAttorneyName: PropTypes.func.isRequired,
  defaultAttorney: PropTypes.shape({
    displayName: PropTypes.string,
  }),
};

AttorneyAutoSuggest.defaultProps = {
  defaultAttorney: {},
};

export default AttorneyAutoSuggest;
