/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import GenericInjuryService from '@services/GenericInjuryService';
import ApplicationInjuryInfoService from '@services/ApplicationInjuryInfoService';
import ApplicationsService from '@services/ApplicationsService';
import ApplicationAccidentInfoService from '@services/ApplicationAccidentInfoService';
import ApplicationFlagService from '@services/ApplicationFlagService';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Alert,
  FormGroup,
  Container,
} from 'reactstrap';

import ApplicationsNewMenu from '../Menu';
import AppContext from '@contexts/AppContext';
import Header from '../Header';
import MultipleSelect from './components/MultipleSelect/MultipleSelect';
import ReactLoading from 'react-loading';

const Injury = (props) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const applicationNumber = props.match.params.applicationId;

  const [selectedInjuryList, setSelectedInjuryList] = useState([{}]);
  const [HasFirstButtonSubbmited, setHasFirstButtonSubbmited] = useState(true);
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [modal, setModal] = useState(false);
  const [genericInjuryOptions, setGenericInjuryOptions] = useState();
  const [specificInjuryOptions, setSpecificInjuryOptions] = useState();
  const [disableTabs, setDisableTabs] = useState(false);
  const [injuriesList, setInjuriesList] = useState();
  const [multipleInjuriesError, setMultipleInjuriesError] = useState(undefined);
  const [showNYstateSeriousInjury, setShowNYstateSeriousInjury] = useState(false);
  const { applicationId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [injury, setInjury] = useState({});

  const handleRemoveClick = (index) => {
    const list = [...selectedInjuryList];
    list.splice(index, 1);
    setSelectedInjuryList(list);
  };

  const handleAddClick = () => {
    setSelectedInjuryList([...selectedInjuryList, {}]);
  };

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;

    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      navigate('/underwriting/applications/new-application/');
      return;
    }

    if (applicationNumber) {
      setIsLoading(true);

      ApplicationInjuryInfoService.search({ applicationNo: applicationNumber }, 1, {})
        .then((response) => {
          if (response?.data?.data[0] != undefined) {
            setInjury(response.data.data[0]);
            setSelectedInjuryList(response?.data?.data[0]?.applicationPlantiffInjuries);
            setIsLoading(false);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }

    GenericInjuryService.search({}, 1, {})
      .then((response) => {
        const defaultValue = (
          <option value="" key="100">
            Please select a value
          </option>
        );

        const genericInjuriesOptions = response.data.data.map((item) => (
          <option key={item.publicId} value={item.publicId}>
            {item.genericInjuryName}
          </option>
        ));

        setInjuriesList(response.data.data);

        setGenericInjuryOptions([defaultValue, genericInjuriesOptions]);
        setSpecificInjuryOptions([defaultValue]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);

    // business rule here - show input box only if specific conditions are met
    ApplicationAccidentInfoService.search({ applicationNo: applicationNumber }, 1, {})
      .then((response) => {
        if (response.data.data.length === 0) {
          appContext.toastMessage.message =
            'There are missing required data. You cannot continue to this tab.';
          appContext.toastMessage.type = 'error';
          setIsLoading(false);
          navigate('/underwriting/applications/' + applicationNumber + '/accident');
          return;
        }

        if (
          (response?.data?.data[0]?.accidentStateCode === 'NY' ||
            response?.data?.data[0]?.accidentStateCode === 'NJ') &&
          response?.data?.data[0]?.accidentTypePublicId === '2469b696-9388-3435-5399-68b8d3008546'
        ) {
          setIsLoading(false);
          setShowNYstateSeriousInjury(true);
        } else {
          let updatedInjury = { ...injury };
          updatedInjury.injuriesSatisfyNystateThreashold = 'N/A';
          setIsLoading(false);
          setInjury(updatedInjury);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped' },
      null,
      null,
      1,
      null,
      1,
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped') {
          setIsLoading(false);
          setDisableTabs(1);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
      null,
      null,
      1,
      null,
      1,
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped2') {
          setDisableTabs(2);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped3' },
      null,
      null,
      1,
      null,
      1,
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped3') {
          setIsLoading(false);
          setDisableTabs(3);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleFormSubmitted = (data) => {
    setIsLoading(true);

    if (!(appContext?.userPermissions?.indexOf('Edit Injury Info') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      setIsLoading(false);
      return;
    }

    let applicationPublicId = undefined;

    ApplicationsService.search({ applicationNo: applicationNumber }, null, null, 1, {}, 10)
      .then((response) => {
        applicationPublicId = response.data.data[0].publicId;
        data.applicationPublicId = applicationPublicId;

        var list = [...selectedInjuryList];

        let shouldExit = false;

        list.forEach((item) => {
          if (!item.hasOwnProperty('genericInjuryPublicId')) {
            setMultipleInjuriesError('There are missing values');
            shouldExit = true;
          }
        });

        if (shouldExit) {
          setIsLoading(false);
          return;
        }

        data.applicationPlantiffInjuries = list;

        if (!injury.hasOwnProperty('publicId')) {
          if (!data.hasOwnProperty('injuriesSatisfyNystateThreashold'))
            data.injuriesSatisfyNystateThreashold = 'N/A';

          ApplicationInjuryInfoService.create(data).then((response3) => {
            appContext.toastMessage.message = 'Your changes has been saved sucessfully.';

            // business rule
            if (
              data?.injuriesSatisfyNystateThreashold != 'Yes' &&
              data?.injuriesSatisfyNystateThreashold != 'N/A'
            ) {
              // search to see if there is already a flag. create a flag if no flag found
              // ApplicationFlagService.searchExact(
              //   { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
              //   null,
              //   null,
              //   1,
              //   null,
              //   1
              // )
              //   .then((response4) => {
              //     if (response4?.data?.data[0]?.flagName != 'Application Stopped2') {
              //       let requestObject = {
              //         applicationPublicId: applicationPublicId,
              //         isCustomFlag: true,
              //         flagName: 'Application Stopped2',
              //       };
              //       ApplicationFlagService.create(requestObject)
              //         .then((response5) => {
              //           setDisableTabs(2);
              //           setIsLoading(false);
              //           toggle();
              //           return;
              //         })
              //         .catch((err) => {})
              //         .finally(() => {
              //           setIsLoading(false);
              //         });
              //     }
              //   })
              //   .catch((err) => {})
              //   .finally(() => {
              //     setIsLoading(false);
              //   });
              // setDisableTabs(2);
              // toggle();
              // return;
            } else {
              // delete flag if exists
              ApplicationFlagService.searchExact(
                { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
                null,
                null,
                1,
                null,
                1,
              )
                .then((response) => {
                  if (response?.data?.data[0]?.flagName === 'Application Stopped2') {
                    ApplicationFlagService.delete(response?.data?.data[0]?.publicId).then(
                      (response2) => {
                        setDisableTabs(false);
                      },
                    );
                  }
                })
                .catch((err) => {})
                .finally(() => {
                  setIsLoading(false);
                });
            }

            appContext.newAppllicationStep += 1;
            setIsLoading(false);
            navigate('/underwriting/applications/' + applicationNumber + '/coverage');
          });
        } else {
          let updatedInjury = { ...injury };
          updatedInjury.applicationPlantiffInjuries = selectedInjuryList;
          updatedInjury.applicationPublicId = applicationPublicId;

          if (!updatedInjury.hasOwnProperty('injuriesSatisfyNystateThreashold'))
            updatedInjury.injuriesSatisfyNystateThreashold = 'N/A';

          ApplicationInjuryInfoService.update(updatedInjury).then((response) => {
            appContext.toastMessage.message = 'Your changes has been saved sucessfully.';

            // business rule
            if (
              updatedInjury?.injuriesSatisfyNystateThreashold != 'Yes' &&
              updatedInjury.injuriesSatisfyNystateThreashold != 'N/A'
            ) {
              // search to see if there is already a flag. create a flag if no flag found
              ApplicationFlagService.searchExact(
                { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
                null,
                null,
                1,
                null,
                1,
              )
                .then((response4) => {
                  if (response4?.data?.data[0]?.flagName != 'Application Stopped2') {
                    let requestObject = {
                      applicationPublicId: applicationPublicId,
                      isCustomFlag: true,
                      flagName: 'Application Stopped2',
                    };

                    ApplicationFlagService.create(requestObject)
                      .then((response5) => {
                        setDisableTabs(2);
                        setIsLoading(false);
                        toggle();
                        return;
                      })
                      .catch((err) => {})
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }
                })
                .catch((err) => {})
                .finally(() => {
                  setIsLoading(false);
                });

              setIsLoading(false);
              setDisableTabs(2);
              toggle();
              return;
            } else {
              // delete flag if exists
              ApplicationFlagService.searchExact(
                { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
                null,
                null,
                1,
                null,
                1,
              )
                .then((response) => {
                  if (response?.data?.data[0]?.flagName === 'Application Stopped2') {
                    ApplicationFlagService.delete(response?.data?.data[0]?.publicId)
                      .then((response2) => {
                        setDisableTabs(false);
                        appContext.newAppllicationStep += 1;
                        setIsLoading(false);
                        navigate('/underwriting/applications/' + applicationNumber + '/coverage');
                        return;
                      })
                      .catch((err) => {})
                      .finally(() => {
                        setIsLoading(false);
                      });
                  } else {
                    setDisableTabs(false);
                    appContext.newAppllicationStep += 1;
                    setIsLoading(false);
                    navigate('/underwriting/applications/' + applicationNumber + '/coverage');
                    return;
                  }
                })
                .catch((err) => {})
                .finally(() => {
                  setIsLoading(false);
                });
            }
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelButtonClicked = () => {
    navigate('/underwriting/applications');
  };

  const handleSaveAndExit = () => {
    setHasFirstButtonSubbmited(false);
    document.querySelector('button.btn.btn-primary.mr-5').click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let injuryUpdated = { ...injury };
    injuryUpdated[name] = value;
    setInjury(injuryUpdated);
  };

  const toggle = () => setModal(!modal);

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">
              {disableTabs != null && (
                <Header applicationId={applicationId} handleSaveAndExit={handleSaveAndExit} />
              )}

              {disableTabs != null && (
                <ApplicationsNewMenu
                  disableTabs={disableTabs}
                  applicationId={applicationId}
                  menuItemIndex="3"
                />
              )}

              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="container mt-10 mb-10">
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Application has stopped</ModalHeader>
          <ModalBody>
            Application cannot continue because a business rule has applied. Some Tabs/actions are
            disabled.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                {disableTabs != null && (
                  <Header
                    disableTabs={disableTabs}
                    applicationId={applicationNumber}
                    handleSaveAndExit={handleSaveAndExit}
                  />
                )}

                {disableTabs != null && (
                  <ApplicationsNewMenu
                    disableTabs={disableTabs}
                    applicationId={applicationNumber}
                    menuItemIndex="3"
                  />
                )}

                <form onSubmit={handleSubmit(handleFormSubmitted)} noValidate>
                  <Container className="p-0">
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="whenPlaintiffFirstSeekAttention">
                                When did the PLAINTIFF first seek medical attention?
                              </label>
                              <select
                                name="whenPlaintiffFirstSeekAttention"
                                className="form-control"
                                ref={register({ required: 'A value is required.' })}
                                onChange={handleInputChange}
                                value={injury?.whenPlaintiffFirstSeekAttention}
                                required="required"
                              >
                                <option value="">Please select a value</option>
                                <option value="Same day">Same day</option>
                                <option value="Next day">Next day</option>
                                <option value="Later that week">Later that week</option>
                                <option value="Within a month">Within a month</option>
                                <option value="Over a month">Over a month</option>
                              </select>
                              {errors.whenPlaintiffFirstSeekAttention && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage
                                    errors={errors}
                                    name="whenPlaintiffFirstSeekAttention"
                                  />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="lengthofHospitalStay">
                                Length of hospital stay &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                              </label>
                              <br />
                              <select
                                name="lengthofHospitalStay"
                                className="form-control"
                                ref={register({ required: 'A value is required.' })}
                                onChange={handleInputChange}
                                value={injury?.lengthofHospitalStay}
                                required="required"
                              >
                                <option value="">Please select a value</option>
                                <option value="No hospital visit">No hospital visit</option>
                                <option value="A couple of hours">A couple of hours</option>
                                <option value="Overnight">Overnight</option>
                                <option value="Two to seven days">Two to seven days</option>
                                <option value="More than seven days">More than seven days</option>
                                <option value="Not Answered">Not Answered</option>
                              </select>
                              {errors.lengthofHospitalStay && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="lengthofHospitalStay" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <fieldset>
                          <Row>
                            <Col md="4" style={{ display: 'flex', alignItems: 'center' }}>
                              <Label style={{ marginBottom: 'unset' }}>
                                What injuries did the PLAINTIFF suffered in the accident?
                              </Label>
                            </Col>
                            <Col md="4" style={{ display: 'flex', alignItems: 'center' }}>
                              <div>
                                <button
                                  type="button"
                                  className="ml-5 btn btn-primary"
                                  onClick={handleAddClick}
                                  style={{
                                    backgroundColor: 'var(--green)',
                                    borderColor: 'var(--green)',
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-5">
                            <Col md="12">
                              {selectedInjuryList.map((x, i) => (
                                <MultipleSelect
                                  x={x}
                                  i={i}
                                  injuriesList={injuriesList}
                                  selectedInjuryList={selectedInjuryList}
                                  setSelectedInjuryList={setSelectedInjuryList}
                                  handleRemoveClick={handleRemoveClick}
                                  handleAddClick={handleAddClick}
                                  genericInjuryOptions={genericInjuryOptions}
                                />
                              ))}
                              {multipleInjuriesError && (
                                <Alert color="danger" className="mt-5">
                                  There are missing values.
                                </Alert>
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col md="5">
                              <div className="form-group">
                                <label htmlFor="descriptionofMedicalTreatment">
                                  Description Of Medical Treatment
                                </label>
                                <textarea
                                  type="text"
                                  rows="8"
                                  name="descriptionofMedicalTreatment"
                                  ref={register({ required: 'A description is required.' })}
                                  onChange={handleInputChange}
                                  value={injury?.descriptionofMedicalTreatment}
                                  className="form-control"
                                  required="required"
                                />
                                {errors.descriptionofMedicalTreatment && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage
                                      errors={errors}
                                      name="descriptionofMedicalTreatment"
                                    />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="surgeryResultingfromAccident">
                                  Any surgery resulting from the accident?
                                </label>
                                <select
                                  type="text"
                                  name="surgeryResultingfromAccident"
                                  className="form-control"
                                  ref={register({ required: 'A value is required.' })}
                                  onChange={handleInputChange}
                                  value={injury?.surgeryResultingfromAccident}
                                  required="required"
                                >
                                  <option value="">Please select a value</option>
                                  <option value="Yes, surgery performed">
                                    Yes, surgery performed
                                  </option>
                                  <option value="Yes, surgery required but not yet performed">
                                    Yes, surgery required but not yet performed
                                  </option>
                                  <option value="No">No</option>
                                  <option value="Not Known at this time" selected>
                                    Not Known at this time
                                  </option>
                                  <option value="Not Answered">Not Answered</option>
                                </select>
                                {errors.surgeryResultingfromAccident && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage
                                      errors={errors}
                                      name="surgeryResultingfromAccident"
                                    />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="accidentRelatedMedicalRecords">
                                  Do you have accident related medical records?
                                </label>
                                <select
                                  name="accidentRelatedMedicalRecords"
                                  className="form-control"
                                  ref={register({ required: 'A value is required.' })}
                                  onChange={handleInputChange}
                                  value={injury?.accidentRelatedMedicalRecords}
                                  required="required"
                                >
                                  <option value="">Please select a value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="Not Known at this time" selected>
                                    Not Known at this time
                                  </option>
                                  <option value="Not Answered">Not Answered</option>
                                </select>
                                {errors.accidentRelatedMedicalRecords && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage
                                      errors={errors}
                                      name="accidentRelatedMedicalRecords"
                                    />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="plaintiffStillTreating">
                                  Is PLAINTIFF still treating? &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp;
                                </label>
                                <select
                                  name="plaintiffStillTreating"
                                  className="form-control"
                                  ref={register({ required: 'A value is required.' })}
                                  onChange={handleInputChange}
                                  value={injury?.plaintiffStillTreating}
                                  required="required"
                                >
                                  <option value="">Please select a value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="Not Known at this time" selected>
                                    Not Known at this time
                                  </option>
                                  <option value="Not Answered">Not Answered</option>
                                </select>
                                {errors.plaintiffStillTreating && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="plaintiffStillTreating" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="plantiffPreExistingCondition">
                                  Does the PLAINTIFF have a pre-existing condition?
                                </label>
                                <select
                                  name="plantiffPreExistingCondition"
                                  className="form-control"
                                  ref={register({ required: 'A value is required.' })}
                                  onChange={handleInputChange}
                                  value={injury?.plantiffPreExistingCondition}
                                  required="required"
                                >
                                  <option value="">Please select a value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="Not Known at this time" selected>
                                    Not Known at this time
                                  </option>
                                  <option value="Not Answered">Not Answered</option>
                                </select>
                                {errors.plantiffPreExistingCondition && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage
                                      errors={errors}
                                      name="plantiffPreExistingCondition"
                                    />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          {showNYstateSeriousInjury && (
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="injuriesSatisfyNystateThreashold">
                                    Injuries satisfy NY state&apos;s Serious injury threshold?
                                  </label>
                                  <select
                                    name="injuriesSatisfyNystateThreashold"
                                    className="form-control"
                                    ref={register({ required: 'A value is required.' })}
                                    onChange={handleInputChange}
                                    value={injury?.injuriesSatisfyNystateThreashold}
                                    required="required"
                                  >
                                    <option value="">Please select a value</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="Not Known at this time" selected>
                                      Not Known at this time
                                    </option>
                                    <option value="Not Answered">Not Answered</option>
                                  </select>
                                  {errors.injuriesSatisfyNystateThreashold && (
                                    <Alert color="danger" className="mt-5">
                                      <ErrorMessage
                                        errors={errors}
                                        name="injuriesSatisfyNystateThreashold"
                                      />
                                    </Alert>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                {injury?.injuriesSatisfyNystateThreashold === 'Yes' && (
                                  <div className="form-group">
                                    <label htmlFor="injuriesSatisfyNystateThreasholdExplain">
                                      Explain: &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                                      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                      &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                                    </label>
                                    <select
                                      name="injuriesSatisfyNystateThreasholdExplain"
                                      className="form-control"
                                      ref={register({ required: 'A value is required.' })}
                                      onChange={handleInputChange}
                                      value={injury?.injuriesSatisfyNystateThreasholdExplain}
                                      required="required"
                                    >
                                      <option value="">Please select a value</option>
                                      <option value="90/180">90/180</option>
                                      <option value="Death">Death</option>
                                      <option value="Dismemberment">Dismemberment</option>
                                      <option value="Significant disfigurement">
                                        Significant disfigurement
                                      </option>
                                      <option value="Fracture">Fracture</option>
                                      <option value="Loss of a fetus">Loss of a fetus</option>
                                      <option value="Permanent loss of use of a body organ, member, function or system">
                                        Permanent loss of use of a body organ, member, function or
                                        system
                                      </option>
                                      <option value="Permanent consequential limitation of a body organ or member">
                                        Permanent consequential limitation of a body organ or member
                                      </option>
                                      <option value="Significant limitation of use of a body function or system">
                                        Significant limitation of use of a body function or system
                                      </option>
                                      <option value="Not Known at this time" selected>
                                        Not Known at this time
                                      </option>
                                      <option value="Not Answered">Not Answered</option>
                                    </select>
                                    {errors.injuriesSatisfyNystateThreasholdExplain && (
                                      <Alert color="danger" className="mt-5">
                                        <ErrorMessage
                                          errors={errors}
                                          name="injuriesSatisfyNystateThreasholdExplain"
                                        />
                                      </Alert>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <button type="submit" className="btn btn-primary mr-5">
                            Save
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelButtonClicked}
                            className="btn btn-secondary"
                          >
                            Cancel
                          </button>
                        </fieldset>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Injury;
