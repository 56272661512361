import React, { useEffect, useState, useContext, Fragment } from 'react';
import { Row, Col, Alert, Nav, NavItem, NavLink, FormGroup, TabContent, TabPane,  Container, } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import AppContext from '../../../../contexts/AppContext';
import LawFirmAutoSuggest from '../../../../components/LawFirmAutoSuggest';
import UsersAutoSuggest from '../../../../components/UsersAutoSuggest';
import AttorneyService from '../../../../services/AttorneyService';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import Notes from '../Notes';

const Attorney = () => {
  let isChanged = false;
  const { id } = useParams();
  const [selectedLawFirm, setSelectedLawFirm] = useState(undefined);
  const [selectedLawFirmName, setSelectedLawFirmName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [selectedUserName, setSelectedUserName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLawFirmError, setSelectedLawFirmError] = useState(false);
  const [selectedUserError, setSelectedUserError] = useState(false);
  const { register, handleSubmit, formState: { errors },} = useForm({ mode: 'onBlur' });
  const [activeTab, setActiveTab] = useState('2');
  const [formVisible, setFormVisible] = useState(true);
  const [attorney, setAttorney] = useState({
    firstName: '',
    lastName: '',
    firmId: undefined,
    accountManagerId: undefined,
    workingPhoneNo: '',
    workingPhoneExt: '',
    mobilePhoneNo: '',
    emailAddress: '',
    paralegalEmailAddress: '',
    nonCompoundRate: '',
    isActive: true,
    lockccountManager: true,
    satisfactoryDealings: undefined,
    notes: '',
  });

  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const toggle = (tab) => {
    // if (activeTab !== tab) {
    setActiveTab(tab);
    // }
  };
  useEffect(() => {
    const at = { ...attorney };
    isChanged = true;
    setAttorney(at);
  }, [selectedLawFirm]);

  useEffect(() => {
    const at = { ...attorney };
    at.accountManagerId = selectedUser;
    isChanged = true;
    setAttorney(at);
  }, [selectedUser]);

  useEffect(() => {
    const makeMenuItemActive = () => {
      document.querySelector('#menuitem-contacts').click();
      document
        .querySelector('#kt_header_tab_7 > div > div > ul > li:nth-child(2)')
        .classList.add('menu-item-active');
    };
    makeMenuItemActive();
  }, []);

  useEffect(() => {
    setSelectedUserError(false);
  }, [selectedUser]);

  useEffect(() => {
    setSelectedLawFirmError(false);
  }, [selectedLawFirm]);

  useEffect(() => {
    const LoadAttorneyDetailsIfIdPresent = () => {
      if (id) {
        setIsLoading(true);
        AttorneyService.read(id)
          .then((response) => {
            if (response.data) {
              setAttorney(response.data);
              setSelectedLawFirm(response.data.lawFirm);
              setSelectedLawFirmName(response.data.lawFirm.lawFirmName);
              setSelectedUser(response.data.accountManagerPublicId ?? undefined);
              setSelectedUserName(response.data.accountManagerName ?? '');
              setIsLoading(false);
            }
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });
      }
    };

    LoadAttorneyDetailsIfIdPresent();
  }, []);

  const hideForm = () => {
    form.current.style.display = 'none';
  };

  const attorneySave = (data) => {
    if (selectedLawFirm == undefined) {
      setSelectedLawFirmError(true);
      return;
    }

    if (selectedUser == undefined) {
      setSelectedUserError(true);
      return;
    }

    setIsLoading(true);

    data.satisfactoryDealings = data?.satisfactoryDealings === 'true' ? true : false;
    data.lawFirmPublicId = selectedLawFirm?.publicId;
    data.accountManagerPublicId = selectedUser;
    data.nonCompoundRate = parseInt(data?.nonCompoundRate);

    if (!id) {
      AttorneyService.create(data)
        .then((response) => {
          if (response.status != 406) {
            appContext.toastMessage.message = 'Attorney has been successfully saved.';
            setIsLoading(false);
            navigate('/contacts/attorneys');
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (!(appContext?.userPermissions?.indexOf('Edit Attorneys') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      data.publicId = attorney.publicId;

      AttorneyService.update(data)
        .then((response) => {
          if (response.status != 406) {
            appContext.toastMessage.message = 'Attorney has been successfully updated.';
            setIsLoading(false);
            navigate('/contacts/attorneys');
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCancelButtonClicked = () => {
    navigate('/contacts/attorneys');
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container className="mt-10 mb-10">
      <Row>
        <Col md="12" className="mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div className="mt-5 mb-10">
                <h1>{ id === 'undefined' || "" ? 'New Attorney' : 'Attorney Details'}</h1>
              </div>

              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      toggle('1');
                    }}
                  >
                    Details
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      toggle('2');
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col md="7 pt-5 pb-5">
                      {successMessage && <Alert color="success">{successMessage}</Alert>}
                      {successMessage && hideForm()}
                      {errorMessage && (
                        <Alert color="danger" className="mt-3">
                          {errorMessage}
                        </Alert>
                      )}
                    </Col>
                  </Row>
                  <Container className="formContainer p-0">
                    {formVisible && (
                      <form onSubmit={handleSubmit(attorneySave)} noValidate>
                      <Row>
                        <Col md="8">
                          <Row className="row">
                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                  type="text"
                                  defaultValue={attorney?.firstName || ''}
                                  name="firstName"
                                  className="form-control"
                                  maxLength="50"
                                  {...register("firstName",{
                                    required: 'First Name is required.',
                                    maxLength: {
                                      value: 50,
                                      message: 'Max length is 50 characters.',
                                    },
                                  })}
                                />
                                {errors.firstName && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="firstName" />
                                  </Alert>
                                )}
                              </div>
                            </Col>

                            <Col md="3">
                              <div className="form-group">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                  type="text"
                                  defaultValue={attorney?.lastName || ''}
                                  name="lastName"
                                  className="form-control"
                                  maxLength="30"
                                  {...register("lastName",{
                                    required: 'Last Name is required.',
                                    maxLength: {
                                      value: 30,
                                      message: 'Max length is 30 characters.',
                                    },
                                  })}
                                />
                                {errors.lastName && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="lastName" />
                                  </Alert>
                                )}
                              </div>
                            </Col>

                            <div className="col-md-1">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  name="isActive"
                                  className="mr-2"
                                  {...(id === 'undefined' || "" && { disabled: true })}
                                  defaultChecked={attorney?.isActive}
                                  {...register}
                                />
                                <label htmlFor="isActive">Active</label>
                              </div>
                            </div>
                          </Row>

                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <label htmlFor="firmId">Law Firm</label>

                                <LawFirmAutoSuggest
                                  defaultLawFirm={selectedLawFirm}
                                  selectedLawFirm={setSelectedLawFirm}
                                  selectedLawFirmName={() => {}}
                                  {...register}
                                />

                                {errors.firmId && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="firmId" />
                                  </Alert>
                                )}

                                {selectedLawFirmError && (
                                  <Alert color="danger" className="mt-5">
                                    Law Firm is required.
                                  </Alert>
                                )}
                              </FormGroup>
                            </Col>

                            <Col md="3">
                              <FormGroup>
                                <label htmlFor="attorney-account-manager">Account Manager</label>

                                <UsersAutoSuggest
                                  defaultUser={selectedUserName}
                                  selectedUser={setSelectedUser}
                                  {...register}
                                />

                                {errors && errors['AccountManagerId'] && (
                                  <Alert color="danger" className="mt-3">
                                    {errors['AccountManagerId']}
                                  </Alert>
                                )}

                                {selectedUserError && (
                                  <Alert color="danger" className="mt-5">
                                    Account Manager is required.
                                  </Alert>
                                )}
                              </FormGroup>
                            </Col>

                            <div className="col-md-1">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  name="lockccountManager"
                                  className="mr-2"
                                  {...(id === 'undefined' || "" && { disabled: true })}
                                  defaultChecked={attorney?.lockccountManager || ''}
                                  {...register}
                                />
                                <label htmlFor="lockccountManager">Lock</label>
                              </div>
                            </div>
                          </Row>

                          <Row className="row">
                            <Col md="3">
                              <div className="form-group">
                                <label htmlFor="workingPhoneNo"> Working Phone #</label>
                                <input
                                  type="text"
                                  defaultValue={attorney?.workingPhoneNo || ''}
                                  name="workingPhoneNo"
                                  className="form-control"
                                  maxLength="20"
                                  {...register("workingPhoneNo",{
                                    required: 'Working Phone# is required.',
                                    maxLength: {
                                      value: 20,
                                      message: 'Max length is 20 characters.',
                                    },
                                  })}
                                />
                                {errors.workingPhoneNo && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="workingPhoneNo" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                            <Col md="1">
                              <div className="form-group">
                                <label htmlFor="workingPhoneExt">Ext</label>
                                <input
                                  type="text"
                                  {...register}
                                  defaultValue={attorney?.workingPhoneExt || ''}
                                  name="workingPhoneExt"
                                  className="form-control"
                                  maxLength="5"
                                  style={{ width: '50px' }}
                                />
                              </div>
                            </Col>

                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="mobilePhoneNo">Mobile #</label>
                                <input
                                  type="text"
                                  defaultValue={attorney?.mobilePhoneNo || ''}
                                  name="mobilePhoneNo"
                                  className="form-control"
                                  maxLength="20"
                                  {...register("mobilePhoneNo",{
                                    maxLength: {
                                      value: 20,
                                      message: 'Max length is 20 characters.',
                                    },
                                  })}
                                />
                                {errors.mobilePhoneNo && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="mobilePhoneNo" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="emailAddress">Attorney email</label>
                                <input
                                  type="text"
                                  defaultValue={attorney?.emailAddress || ''}
                                  name="emailAddress"
                                  className="form-control"
                                  maxLength="100"
                                  {...register("emailAddress",{
                                    required: 'Attorney Email Address is required.',
                                    maxLength: {
                                      value: 100,
                                      message: 'Max length is 100 characters.',
                                    },
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: 'Invalid email address provided.',
                                    },
                                  })}
                                />
                                {errors.emailAddress && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="emailAddress" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="8">
                              <div className="form-group">
                                <label htmlFor="paralegalEmailAddress">Paralegal email</label>
                                <input
                                  type="text"
                                  defaultValue={attorney?.paralegalEmailAddress || ''}
                                  name="paralegalEmailAddress"
                                  className="form-control"
                                  maxLength="100"
                                  {...register("paralegalEmailAddress",{
                                    required: 'Paralegal Email Address is required.',
                                    maxLength: {
                                      value: 100,
                                      message: 'Max length is 100 characters.',
                                    },
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: 'Invalid email address provided.',
                                    },
                                  })}
                                />
                                {errors.paralegalEmailAddress && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="paralegalEmailAddress" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="4">
                              <label htmlFor="nonCompoundRate">Non-Compound Rate</label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  defaultValue={attorney?.nonCompoundRate || ''}
                                  name="nonCompoundRate"
                                  className="form-control"
                                  style={{ width: '50px' }}
                                  {...register("nonCompoundRate",{
                                    required: 'Non compound rate is required.',
                                    pattern: {
                                      value: /^\d{1,}(\.\d{0,2})?$/,
                                      message: 'Only numeric values allowed.',
                                    },
                                  })}
                                />
                                {errors.nonCompoundRate && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="nonCompoundRate" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="satisfactoryDealings">
                                  Satisfactory Dealings &nbsp;
                                </label>
                                <input
                                  type="radio"
                                  name="satisfactoryDealings"
                                  id="satisfactoryDealingsTrue"
                                  {...register}
                                  defaultChecked={
                                    attorney?.satisfactoryDealings === true ? 'checked' : undefined
                                  }
                                  value={true}
                                />
                                &nbsp;Yes &nbsp;
                                <input
                                  type="radio"
                                  name="satisfactoryDealings"
                                  id="satisfactoryDealingsFalse"
                                 {...register}
                                  defaultChecked={
                                    !attorney?.satisfactoryDealings &&
                                    attorney?.satisfactoryDealings != undefined
                                      ? 'checked'
                                      : undefined
                                  }
                                  value={false}
                                />
                                &nbsp;No
                                {errors && errors['SatisfactoryDealings'] && (
                                  <Alert color="danger" className="mt-3">
                                    {errors['SatisfactoryDealings']}
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>

                          <Row></Row>
                          <Row>
                            <Col md="8">
                              <FormGroup>
                                <label htmlFor="notes">Notes</label>
                                <textarea
                                  name="notes"
                                  rows="8"
                                  className="form-control"
                                  defaultValue={attorney?.notes || ''}
                                  {...register("notes",{
                                    maxLength: {
                                      value: 1000,
                                      message: 'Max length is 1000 characters.',
                                    },
                                  })}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="8">
                              <button type="submit" className="btn btn-primary mr-5">
                                Save
                              </button>
                              <button
                                type="button"
                                onClick={handleCancelButtonClicked}
                                className="btn btn-secondary"
                              >
                                Cancel
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </form>
                  )}
                  </Container>
                </TabPane>

                <TabPane tabId="2">
                  <Notes />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Attorney;