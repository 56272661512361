import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';

const SendEmailPopup = (props) => {
  const {
    emailModal,
    toggleEmail,
    handleFormSubmit,
    setClient,
    client,
    setAccountManager,
    accountManager,
    setAttorney,
    attorney,
    setBroker,
    broker,
    setBody,
    setSystemUsers,
    systemUsers,
    setEmails,
    setSubject,
    setFiles,
    document,
  } = props;
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });

  return (
    <div>
      <Modal isOpen={emailModal} toggle={toggleEmail}>
        <ModalHeader>Receipients</ModalHeader>
        <ModalBody>
          <form method="POST" onSubmit={handleSubmit(handleFormSubmit)} noValidate>
            <h4>Application Contacts</h4>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setClient(e.target.checked);
                          }}
                          name={'Client'}
                          color="primary"
                        />
                      }
                      label={'Client'}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setAccountManager(e.target.checked);
                          }}
                          name={'AccountManager'}
                          color="primary"
                        />
                      }
                      label={'Account Manager'}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setAttorney(e.target.checked);
                          }}
                          name={'Attorney'}
                          color="primary"
                        />
                      }
                      label={'Attorney'}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setBroker(e.target.checked);
                          }}
                          name={'Broker'}
                          color="primary"
                        />
                      }
                      label={'Broker'}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <h4>System Users (by role)</h4>
            <div className="row">
              {systemUsers.map((users, ind) => {
                return (
                  <div className="col-md-6" margindense>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => {
                              let tempSystemUsers = [...systemUsers];
                              tempSystemUsers[ind].checked = e.target.checked;
                              setSystemUsers(tempSystemUsers);
                            }}
                            name={users.roleName}
                            color="primary"
                          />
                        }
                        label={users.roleName}
                      />
                    </FormGroup>
                  </div>
                );
              })}
            </div>
            <h4>Other</h4>
            <div className="form-group">
              <p>Enter multiple emails seperated by semicolon</p>
              <input
                required="required"
                className="form-control"
                onChange={(e) => setEmails(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Subject</label>
              <input
                name="subject"
                id="subject"
                ref={register({ required: 'Subject is required.' })}
                required="required"
                style={{ resize: 'none' }}
                className="form-control"
                onChange={(e) => setSubject(e.target.value)}
              />
              {errors.subject && (
                <Alert color="danger" className="mt-5">
                  <ErrorMessage errors={errors} name="subject" />
                </Alert>
              )}
            </div>
            <div className="form-group">
              <label>Body</label>
              <textarea
                name="body"
                id="body"
                ref={register({ required: 'Body is required.' })}
                required="required"
                rows={10}
                style={{ resize: 'none' }}
                className="form-control"
                onChange={(e) => setBody(e.target.value)}
              />
              {errors.body && (
                <Alert color="danger" className="mt-5">
                  <ErrorMessage errors={errors} name="body" />
                </Alert>
              )}
            </div>
            <div className="form-group">
              <label>Attachments</label>
              <input
                name="file"
                id="file"
                type="file"
                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                className="form-control"
                required="required"
                onChange={(e) => {
                  setFiles(e.target.files);
                }}
              />
              <p>{document?.documentName}</p>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit(handleFormSubmit)}
            >
              Send Email
            </button>
            <button type="button" className="ml-5 btn btn-secondary" onClick={toggleEmail}>
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SendEmailPopup;
