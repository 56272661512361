import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/users/',
});

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => resError(error),
);

class UserService {
  constructor() {
    if (!UserService.instance) {
      UserService.instance = this;
    }
    return UserService.instance;
  }

  search = (filters, orderBy, orderDir, p, fields, perPage) => {
    return api.post('/search', {
      filters: Object.keys(filters).length > 0 ? filters : null,
      orderBy,
      orderDir,
      page: p,
      fields: undefined,
      perPage: parseInt(perPage),
    });
  };

  searchAccountManagers = (value) => {
    return api.post('/searchAccountManagers', {
      filters: { displayName: value, isActive: true },
      perPage: 15,
      page: 1,
    });
  };

  searchEmail = (filters, orderBy, orderDir, p, fields, perPage) => {
    return api.post('/search', {
      filters: Object.keys(filters).length > 0 ? filters : null,
      orderBy,
      orderDir,
      page: p,
      fields: undefined,
      perPage: parseInt(perPage),
    });
  };

  getAllPermittedTask = (id) => {
    return api.get('/GetPermissionsByUserRoleId/' + id);
  };

  create = (entity) => {
    return api.post('/', entity);
  };

  read = (id) => {
    return api.get('/' + id);
  };

  update = (entity) => {
    return api.put('/', entity);
  };

  delete = (id) => {
    return api.delete('/' + id);
  };

  autoSuggest = (value) => {
    return api.post('/search', {
      filters: { displayName: value, isActive: true },
      perPage: 15,
      page: 1,
    });
  };

  getUserPermissionsByUserPublicId = (userPublicId) => {
    return api.get('/GetUserPermissionsByUserPublicId/' + userPublicId);
  };
}

const instance = new UserService();
Object.freeze(instance);

export default instance;
