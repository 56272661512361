import React, { useEffect, useContext, useRef, useState } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import { MultiSelect } from 'react-multi-select-component';
import PortfolioService from '@services/PortfolioService';
import AccidentTypeService from '@services/AccidentTypeService';
import AccountManagersAutoSuggest from '@components/AccountManagersAutoSuggest/AccountManagersAutoSuggest';
import BrokerAutoSuggest from './BrokerAutoSuggest';
import StateSelect from './StateSelect';
import AttorneyAutoSuggest from '@components/AttorneyAutoSuggest/AttorneyAutoSuggest';
import LawFirmAutoSuggest from '@components/LawFirmAutoSuggest/LawFirmAutoSuggest';

const FilteringFields = (props) => {
  const appContext = useContext(AppContext);
  const payoffStaticPoolAnalysisReportFilters = appContext.payoffStaticPoolAnalysisReportFilters;

  let advanceDateFilter = useRef();
  let payoffDateFilter = useRef();
  let accountManagerFilter = useRef();
  let brokerFilter = useRef();
  let accidentTypeFilter = useRef();
  let attorneyFilter = useRef();
  let lawFirmFilter = useRef();

  const [dateRange, setDaterange] = useState(false);
  const [dateValue, setDateValue] = useState('YTD');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [payoffDateRange, setPayoffDateRange] = useState(false);
  const [payoffDateValue, setPayoffDateValue] = useState('YTD');
  const [payoffFromDate, setPayoffFromDate] = useState(null);
  const [payoffToDate, setPayoffToDate] = useState(null);

  const [accidentTypes, setAccidentTypes] = useState([]);
  const [accidentTypesOptions, setAccidentTypesOptions] = useState([]);

  const [portfolio, setPortfolio] = useState([]);
  const [portfolioOptions, setPortfolioOptions] = useState([]);

  const [selectedAccountManager, setSelectedAccountManager] = useState({});
  const [selectedBroker, setSelectedBroker] = useState({});
  const [selectedState, setSelectedState] = useState({ stateCode: '' });

  const [selectedAttorney, setSelectedAttorney] = useState({});
  const [selectedAttorneyError, setSelectedAttorneyError] = useState(undefined);
  const [selectedLawFirm, setSelectedLawFirm] = useState({});
  const [selectedClient, setSelectedClient] = useState({});

  const clearFilters = () => {
    appContext.payoffStaticPoolAnalysisReportFilters = {};

    setDateValue('YTD');
    advanceDateFilter.current.value = 'YTD';
    setDaterange(false);
    setFromDate(null);
    setToDate(null);

    setPayoffDateValue('YTD');
    payoffDateFilter.current.value = 'YTD';
    setPayoffDateRange(false);
    setPayoffFromDate(null);
    setPayoffToDate(null);

    setSelectedClient({});
    setAccidentTypes([]);
    setPortfolio([]);
    setSelectedAttorney({});
    setSelectedLawFirm({});
    accidentTypeFilter.current.value = '';

    setSelectedAccountManager({});
    setSelectedBroker({});
    setSelectedState({ stateCode: '' });

    props.getAll();
  };

  function changeFunc() {
    const dateFilter = document.getElementById('advanceDateRange');

    const selectedDate = dateFilter.options[dateFilter.selectedIndex].value;

    if (selectedDate === 'Custom') {
      setDaterange(true);
      setDateValue(null);
    } else {
      setDaterange(false);
      setDateValue(selectedDate);
      setFromDate(null);
      setToDate(null);
    }
  }

  function changeFuncPayoffDate() {
    const dateFilter = document.getElementById('payoffDateRange');
    const selectedDate = dateFilter.options[dateFilter.selectedIndex].value;

    if (selectedDate === 'Custom') {
      setPayoffDateRange(true);
      setPayoffDateValue(null);
    } else {
      setPayoffDateRange(false);
      setPayoffDateValue(selectedDate);
      setPayoffFromDate(null);
      setPayoffToDate(null);
    }
  }

  function changeFromDate() {
    const fromDate = document.getElementById('fromDate');
    const selectedFromDate = fromDate.value;
    setFromDate(selectedFromDate);
  }

  function changePayoffFromDate() {
    const fromDate = document.getElementById('payoffFromDate');
    const selectedFromDate = fromDate.value;
    setPayoffFromDate(selectedFromDate);
  }

  function changeToDate() {
    const toDate = document.getElementById('toDate');
    const selectedToDate = toDate.value;

    if (fromDate < selectedToDate) {
      setToDate(selectedToDate);
    } else {
      if (fromDate != null) {
      } else {
        alert('Select from date first');
      }
    }
  }

  function changePayoffToDate() {
    const toDate = document.getElementById('payoffToDate');
    const selectedToDate = toDate.value;

    if (payoffFromDate < selectedToDate) {
      setPayoffToDate(selectedToDate);
    } else {
      if (payoffFromDate != null) {
      } else {
        alert('Select from date first');
      }
    }
  }

  function selectAdvanceDateRange() {
    if (dateValue != null) {
      return dateValue;
    } else {
      if (fromDate != null && toDate != null) {
        let dateRange = {
          fromDate: new Date(fromDate).toISOString(),
          toDate: new Date(toDate).toISOString(),
        };
        return dateRange;
      } else {
        return '';
      }
    }
  }

  function selectAdvancePayoffDateRange() {
    if (payoffDateValue != null) {
      return payoffDateValue;
    } else {
      if (payoffFromDate != null && payoffToDate != null) {
        let dateRange = {
          fromDate: new Date(payoffFromDate).toISOString(),
          toDate: new Date(payoffToDate).toISOString(),
        };
        return dateRange;
      } else {
        return '';
      }
    }
  }

  useEffect(() => {
    const isFiltersEmpty = Object.keys(payoffStaticPoolAnalysisReportFilters).length == 0;

    //Get portfolio list
    PortfolioService.search({}, '', '', 1, null, 50).then((response) => {
      let portfolioList = [];

      if (!response.data) return;

      if (response.data.data.length > 0) {
        response.data.data.map((item) => {
          portfolioList.push({
            label: item.portfolioName,
            value: item.publicId,
          });
        });
        if (!isFiltersEmpty) {
          if (payoffStaticPoolAnalysisReportFilters.portfolioName) {
            if (portfolioList.length > 0) {
              let tempportfolioName = [];
              payoffStaticPoolAnalysisReportFilters.portfolioName.map((item) => {
                const portfolio = portfolioList.find((portfolio) => portfolio.value === item);
                tempportfolioName.push({
                  label: portfolio.label,
                  value: item,
                });
              });
              setPortfolio(tempportfolioName);
            }
          }
        }
      }
      setPortfolioOptions(portfolioList);
    });

    //Get Accident types
    AccidentTypeService.search2({}, '', '', 1, null, 100).then((response) => {
      if (!response.data) return;
      if (response.data.data.length > 0) {
        setAccidentTypesOptions(response.data.data);
      }
      if (payoffStaticPoolAnalysisReportFilters?.accidentTypeName) {
        accidentTypeFilter.current.value = payoffStaticPoolAnalysisReportFilters.accidentTypeName
          ? payoffStaticPoolAnalysisReportFilters.accidentTypeName
          : '';
      }
    });

    advanceDateFilter.current.value = 'YTD';
    payoffDateFilter.current.value = 'YTD';

    setDateValue('YTD');
    setPayoffDateValue('YTD');

    //Retaining data by checking with appcontext
    if (!isFiltersEmpty) {
      accidentTypeFilter.current.value = payoffStaticPoolAnalysisReportFilters.accidentTypeName
        ? payoffStaticPoolAnalysisReportFilters.accidentTypeName
        : '';

      if (payoffStaticPoolAnalysisReportFilters.dateRange) {
        if (typeof payoffStaticPoolAnalysisReportFilters.dateRange == 'string') {
          advanceDateFilter.current.value = payoffStaticPoolAnalysisReportFilters.dateRange;
          setDateValue(payoffStaticPoolAnalysisReportFilters.dateRange);
          setDaterange(false);
          setFromDate(null);
          setToDate(null);
        } else {
          advanceDateFilter.current.value = 'Custom';
          setDaterange(true);
          setPayoffDateRange(true);
          setFromDate(payoffStaticPoolAnalysisReportFilters.dateRange.fromDate.split('T')[0]);
          setToDate(payoffStaticPoolAnalysisReportFilters.dateRange.toDate.split('T')[0]);
        }
      }

      if (payoffStaticPoolAnalysisReportFilters.portfolioName) {
        let tempportfolioName = [];
        payoffStaticPoolAnalysisReportFilters.portfolioName.map((item) => {
          tempportfolioName.push({
            label: item,
            value: item,
          });
        });
        setPortfolio(tempportfolioName);
      }

      if (payoffStaticPoolAnalysisReportFilters.accountManager) {
        setSelectedAccountManager(payoffStaticPoolAnalysisReportFilters.accountManager);
      }

      if (payoffStaticPoolAnalysisReportFilters.broker) {
        setSelectedBroker(payoffStaticPoolAnalysisReportFilters.broker);
      }

      if (payoffStaticPoolAnalysisReportFilters.state) {
        setSelectedState(payoffStaticPoolAnalysisReportFilters.state);
      }
    }
  }, []);

  const search = () => {
    const tempPortfolio = [];

    portfolio.map((port) => {
      tempPortfolio.push(port.value);
    });

    let filters = {
      advanceDateRange: selectAdvanceDateRange(),
      payoffDateRange: selectAdvancePayoffDateRange(),
      accountManager: selectedAccountManager,
      broker: selectedBroker,
      accidentTypeName: accidentTypeFilter.current.value,
      attorneyPublicId: selectedAttorney.publicId,
      lawFirmPublicId: selectedLawFirm.publicId,
      portfolioName: tempPortfolio.length > 0 ? tempPortfolio : null,
      state: selectedState,
    };

    if (filters.advanceDateRange === '') delete filters.advanceDateRange;

    props.searchWithFilters(filters);
  };

  return (
    <>
      <Col md="2" className="float-left mr-10 mb-15" style={{ backgroundColor: '#e9e9e9' }}>
        <Row className="mt-5">
          <Col md="6">
            <h2>Filters</h2>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="advanceDateRange">Advance Date Range</label>
          </Col>
          <Col md="12">
            <select
              id="advanceDateRange"
              name="advanceDateRange"
              onChange={changeFunc}
              className="form-control"
              ref={advanceDateFilter}
            >
              <option value="">Select date range</option>
              <option value="This month">This month</option>
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="This week">This week</option>
              <option value="Last week">Last week</option>
              <option value="Last month">Last month</option>
              <option value="YTD">YTD</option>
              <option value="Custom">Custom</option>
            </select>
          </Col>
        </Row>
        {dateRange == true && (
          <>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">Advance Date From</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeFromDate}
                  type="date"
                  id="fromDate"
                  className="form-control"
                  required="required"
                  defaultValue={fromDate}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">Advance Date To</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeToDate}
                  type="date"
                  id="toDate"
                  className="form-control"
                  required="required"
                  defaultValue={toDate}
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="payoffDateRange">Payoff Date Range</label>
          </Col>
          <Col md="12">
            <select
              id="payoffDateRange"
              name="payoffDateRange"
              onChange={changeFuncPayoffDate}
              className="form-control"
              ref={payoffDateFilter}
            >
              <option value="">Select date range</option>
              <option value="This month">This month</option>
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="This week">This week</option>
              <option value="Last week">Last week</option>
              <option value="Last month">Last month</option>
              <option value="YTD">YTD</option>
              <option value="Custom">Custom</option>
            </select>
          </Col>
        </Row>
        {payoffDateRange == true && (
          <>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="payoffFromDate">Payoff Date From</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changePayoffFromDate}
                  type="date"
                  id="payoffFromDate"
                  className="form-control"
                  required="required"
                  defaultValue={payoffFromDate}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="payoffToDate">Payoff Date To</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changePayoffToDate}
                  type="date"
                  id="payoffToDate"
                  className="form-control"
                  required="required"
                  defaultValue={payoffToDate}
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Portfolio</label>
          </Col>
          <Col md="12">
            <div>
              <MultiSelect
                value={portfolio}
                onChange={setPortfolio}
                labelledBy={'Portfolio'}
                options={portfolioOptions}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="accountManager">Account Manager</label>
          </Col>
          <Col md="12">
            <AccountManagersAutoSuggest
              ref={accountManagerFilter}
              selectedAccountManager={(value) => {
                setSelectedAccountManager(value);
              }}
              selectedAccountManagerName={() => {}}
              defaultAccountManager={selectedAccountManager}
            />

            {selectedAttorneyError && (
              <Alert color="danger" className="mt-5">
                <span>Select an attorney</span>
              </Alert>
            )}
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="broker">Broker</label>
          </Col>
          <Col md="12">
            <BrokerAutoSuggest
              ref={brokerFilter}
              selectedBroker={(value) => {
                setSelectedBroker(value);
              }}
              defaultBroker={selectedBroker}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Type of Accident</label>
          </Col>
          <Col md="12">
            <select className="form-control" ref={accidentTypeFilter}>
              <option value="">Select Accident Type</option>
              {accidentTypesOptions.map((item) => {
                return <option value={item.accidentTypeName}>{item.accidentTypeName}</option>;
              })}
            </select>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="attorney">Attorney</label>
          </Col>
          <Col md="12">
            <AttorneyAutoSuggest
              ref={attorneyFilter}
              selectedAttorney={(value) => {
                setSelectedAttorney(value);
              }}
              selectedAttorneyName={() => {}}
              defaultAttorney={selectedAttorney}
            />
            {selectedAttorneyError && (
              <Alert color="danger" className="mt-5">
                <span>Select an attorney</span>
              </Alert>
            )}
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="lawFirm">Law Firm</label>
          </Col>
          <Col md="12">
            <LawFirmAutoSuggest
              ref={lawFirmFilter}
              selectedLawFirm={(value) => {
                setSelectedLawFirm(value);
              }}
              selectedLawFirmName={() => {}}
              defaultLawFirm={selectedLawFirm}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">State</label>
          </Col>
          <Col md="12">
            <StateSelect
              name="stateCode"
              id="stateCode"
              givenObject={selectedState}
              setObject={setSelectedState}
              defaultValue={selectedState?.stateCode}
            />
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col md="7">
            <span className="a-link">
              <a
                className="btn"
                style={{ color: '#000000', background: 'white' }}
                onClick={clearFilters}
              >
                Clear filters
              </a>
            </span>
          </Col>
          <Col md="4">
            <span className="row">
              <a className="btn btn-primary row" style={{ color: '#fff' }} onClick={search}>
                <i className="fas fa-search"></i>
                Search
              </a>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default FilteringFields;
