import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AppContext from '@contexts/AppContext';

const Logout = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('isUserAuthenticated') === 'true') {
      appContext.toastMessage.message = 'You have logged out sucessfully.';
      appContext.userPermissions = [];
      localStorage.removeItem('lasuris-user');
      localStorage.removeItem('lasuris-jwt');
      localStorage.removeItem('userPermissions');
      localStorage.setItem('isUserAuthenticated', false);
      navigate('/login');
    }

    navigate('/login');
  });

  return null;
};

export default Logout;
