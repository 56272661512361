import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApplicationsService from '@services/ApplicationsService';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ApplicationsLienService from '@services/ApplicationsLienService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@mui/material/TableSortLabel';
import ReactLoading from 'react-loading';
import { FormattedAmount } from '../../../../../../../components/Utils/FormattedAmount';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: '14px', // Set font size for the body cells only
  },
}));

const useStyles2 = {
  minWidth: 500,
};

const CustomPaginationActionsTable = () => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const [applicationPublicId, setApplicationPublicId] = useState();
  const appContext = useContext(AppContext);
  const params = useParams();

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const update = () => {
    if (!params.applicationId || params.applicationId === 'undefined') return;

    setIsLoading(true);

    ApplicationsService.search({ applicationNo: params.applicationId }, null, null, 1, {}, 10)
      .then((response) => {
        setApplicationPublicId(response.data.data[0].publicId);

        var newFilters = filters;
        newFilters['applicationPublicId'] = response.data.data[0].publicId;
        setFilters(newFilters);
        setIsLoading(true);
        ApplicationsLienService.search(filters, orderBy, orderDir, page, null, rowsPerPage)
          .then((response) => {
            if (!response.data) return;
            setRows(response.data.data);
            setPages(response.data.pages.slice(-1)[0]);
            setIsLoading(false);
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchColumn = (event, columnName) => {
    var newFilters = filters;
    if (columnName === 'isActive' && event.target.value != 'All') {
      newFilters[columnName] = event.target.value === 'true' ? true : false;
    } else if (columnName === 'isActive' && event.target.value === 'All') {
      newFilters[columnName] = undefined;
    } else if (columnName === 'lienAmount') {
      newFilters[columnName] = parseFloat(event.target.value);
    } else {
      newFilters[columnName] = event.target.value;
    }
    setFilters(newFilters);
    update();
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const deleteItem = () => {
    if (!(appContext?.userPermissions?.indexOf('Delete Liens') > -1)) {
      toggle();
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    ApplicationsLienService.delete(givenRows[rowIndex].publicId)
      .then((result) => {
        let items = [...givenRows];
        items.splice(rowIndex, 1);
        setRows(items);
        toggle();
        appContext.toastMessage.message = 'Lien has been successfully deleted.';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const EditItem = (id) => {
    navigate('liens/new-lien/' + id);
  };

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  useEffect(() => {
    update();
  }, [orderBy, orderDir, rowsPerPage, page]);

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
          <ModalBody>Are you sure you want to delete this lien?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deleteItem}>
              Delete
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <TableContainer component={Paper}>
        <Table className={useStyles2} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Id</StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('lienTypeName');
                  setActiveFieldForSorting('lienTypeName');
                }}
              >
                LIEN TYPE
                <TableSortLabel
                  active={activeFieldForSorting === 'lienTypeName' ? true : false}
                  direction={
                    activeFieldForSorting === 'lienTypeName' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('lienTypeName')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('lienTo');
                  setActiveFieldForSorting('lienTo');
                }}
              >
                LIEN TO
                <TableSortLabel
                  active={activeFieldForSorting === 'lienTo' ? true : false}
                  direction={activeFieldForSorting === 'lienTo' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('lienTo')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('lienAmount');
                  setActiveFieldForSorting('lienAmount');
                }}
              >
                LIEN AMOUNT
                <TableSortLabel
                  active={activeFieldForSorting === 'lienAmount' ? true : false}
                  direction={
                    activeFieldForSorting === 'lienAmount' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('lienAmount')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'id')}
                />
              </StyledTableCell>

              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'lienTypeName')}
                />
              </StyledTableCell>

              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'lienTo')}
                />
              </StyledTableCell>

              <StyledTableCell>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => searchColumn(e, 'lienAmount')}
                />
              </StyledTableCell>

              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          {isLoading === false && (
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>

                    <TableCell>{row.lienTypeName}</TableCell>
                    <TableCell>{row.lienTo}</TableCell>
                    <TableCell>
                      {row.lienAmount &&
                        FormattedAmount.format(parseFloat(row.lienAmount).toFixed(2))}
                    </TableCell>

                    <TableCell className="p-2">
                      <a
                        className="btn float-right"
                        onClick={() => {
                          EditItem(row.publicId);
                        }}
                      >
                        <EditIcon />
                      </a>
                    </TableCell>

                    <TableCell className="p-2" style={{ width: '30px' }}>
                      <a
                        className="btn float-left"
                        onClick={() => {
                          setRowIndex(index);
                          setGivenRows(rows);
                          toggle();
                        }}
                      >
                        <DeleteIcon style={{ color: 'var(--red)' }} />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {isLoading && (
            <TableBody>
              <TableRow>
                <td></td>
                <td>
                  <ReactLoading type="bars" color="#7E8299" />
                </td>
                <td></td>
              </TableRow>
            </TableBody>
          )}

          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      <div className="float-left mt-5">
        <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
          {[5, 10, 15, 25, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="float-right mt-5">
        <Pagination
          count={pages}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default CustomPaginationActionsTable;
