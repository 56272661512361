import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Alert } from 'reactstrap';

function CustomDateRange({
  dateValue,
  setDateValue,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  labelText,
}) {
  const [isDateRange, setIsDaterange] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (dateValue === 'Custom' || dateValue === null) {
      setIsDaterange(true);
    } else {
      setIsDaterange(false);
      setFromDate(null);
      setToDate(null);
    }
  }, [dateValue, setFromDate, setToDate]);

  useEffect(() => {
    if (fromDate || toDate) {
      setDateValue('Custom');
    }
  }, [fromDate, toDate, setDateValue]);

  const onChangeDateFilter = (e) => {
    setDateValue(e.target.value);
  };

  const onChangeFromDate = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);

    if (selectedFromDate && toDate) {
      const from = new Date(selectedFromDate);
      const to = new Date(toDate);
      if (from <= to) {
        setErrorMessage('');
      } else {
        setErrorMessage('The To date cannot be before From date');
      }
    } else {
      setErrorMessage('');
    }
  };

  const onChangeToDate = (e) => {
    const selectedToDate = e.target.value;
    if (fromDate && selectedToDate) {
      const from = new Date(fromDate);
      const to = new Date(selectedToDate);
      if (from <= to) {
        setToDate(selectedToDate);
        setErrorMessage('');
      } else {
        setErrorMessage('The To date cannot be before From date');
      }
    } else {
      setErrorMessage('');
    }
  };

  return (
    <>
      <Row className="mt-5">
        <Col md="12">
          <label htmlFor="dateFilter">{labelText || 'Select date range'}</label>
          <select
            id="dateFilter"
            value={dateValue}
            onChange={onChangeDateFilter}
            className="form-control"
          >
            <option value="">Select date range</option>
            <option value="This month">This month</option>
            <option value="Today">Today</option>
            <option value="Yesterday">Yesterday</option>
            <option value="This week">This week</option>
            <option value="Last week">Last week</option>
            <option value="Last month">Last month</option>
            <option value="YTD">YTD</option>
            <option value="Custom">Custom</option>
          </select>
        </Col>
      </Row>

      {isDateRange && (
        <>
          <Row className="mt-5">
            <Col md="12">
              <label htmlFor="fromDate">From</label>
              <input
                type="date"
                id="fromDate"
                className="form-control"
                required
                value={fromDate || ''}
                onChange={onChangeFromDate}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md="12">
              <label htmlFor="toDate">To</label>
              <input
                type="date"
                id="toDate"
                className="form-control"
                required
                value={toDate || ''}
                onChange={onChangeToDate}
              />
            </Col>
            {errorMessage && (
              <Col md="12">
                <Alert color="danger" className="mt-5">
                  {errorMessage}
                </Alert>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
}

CustomDateRange.propTypes = {
  dateValue: PropTypes.string,
  setDateValue: PropTypes.func.isRequired,
  fromDate: PropTypes.string,
  setFromDate: PropTypes.func.isRequired,
  toDate: PropTypes.string,
  setToDate: PropTypes.func.isRequired,
  labelText: PropTypes.string,
};

CustomDateRange.defaultProps = {
  dateValue: '',
  fromDate: '',
  toDate: '',
  labelText: 'Select date range',
};

export default CustomDateRange;
