import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Input, Label, Alert, Button, FormGroup, Table } from 'reactstrap';
import ApplicationsService from '@services/ApplicationsService';
import BrokerService from '@services/BrokerService';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import AppContext from '@contexts/AppContext';
import Header from '../../Header';
import ApplicationsNewMenu from '../../Menu';
import ApplicationAdvanceFinancialsService from '@services/ApplicationAdvanceFinancialsService';
import GridService from '@services/GridService';
import Moment from 'moment';
import { toast } from 'react-toastify';

const Product = () => {
  const { applicationId, advanceId } = useParams();
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [productOptionSelected, setProductOptionSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [multiplierRangesList, setMultiplierRangesList] = useState([{}]);
  const [brokerRefFee, setBrokerRefFee] = useState(null);
  const [disableTabs, setDisableTabs] = useState(false);
  const [accrualMonthlyInterval, setAccrualMonthlyInterval] = useState(null);
  const [advanceDate, setAdvanceDate] = useState(null);
  const [advanceObject, setAdvanceObject] = useState({});
  const [tab, setTab] = useState(0);
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const [accrualUntil, setAccrualUntil] = useState(null);
  const selectBox = useRef();
  const isPostSettlementFundingCheckBox = useRef();
  const isBuyoutCaseCheckBox = useRef();
  const [financialCalculations, setFinancialCalculations] = useState([]);

  const handleAddMultiplier = () => {
    let updatedMultiplierRangesList = [...multiplierRangesList];
    updatedMultiplierRangesList.push({});
    setMultiplierRangesList(updatedMultiplierRangesList);
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const handleDeleteMultiplier = (index) => {
    let updatedMultiplierRangesList = [...multiplierRangesList];
    updatedMultiplierRangesList.splice(index, 1);
    setMultiplierRangesList(updatedMultiplierRangesList);
  };

  const handleMultiplierInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...multiplierRangesList];
    list[index][name] = parseFloat(value);
    setMultiplierRangesList(list);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedAdvanceObject = { ...advanceObject };
    updatedAdvanceObject[name] = parseFloat(value);
    setAdvanceObject(updatedAdvanceObject);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    let updatedAdvanceObject = { ...advanceObject };
    updatedAdvanceObject[name] = new Date(value);
    setAdvanceObject(updatedAdvanceObject);
  };

  useEffect(() => {
    setIsLoading(true);

    // GET AdvanceFinancialsByAdvanceID
    ApplicationAdvanceFinancialsService.read(advanceId)
      .then((response) => {
        let updatedAdvance = { ...advanceObject };
        updatedAdvance = response.data;

        if (response.data.productType === 'Accrued') {
          updatedAdvance.accrualPerc = parseFloat(response.data.accrualPerc);
          if (response.data.accrueUntilDate)
            updatedAdvance.accrualUntil = response.data.accrueUntilDate.split('T')[0];

          setAdvanceObject(updatedAdvance);

          selectBox.current.value = 'accrued';

          setProductOptionSelected('accruedCalledOnLoad');
        } else if (response.data.productType === 'Multiplier') {
          updatedAdvance.accrualPerc = null;
          updatedAdvance.accrualUntil = null;
          setMultiplierRangesList(response?.data?.multiplierRates);
          setAdvanceObject(updatedAdvance);

          selectBox.current.value = 'multiplier';

          setProductOptionSelected('multiplierCalledOnLoad');
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);

    // business rule here - preload Referral Party Fee.
    ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, null, 1)
      .then((response) => {
        if (response.data.data[0].brokerPublicId != '00000000-0000-0000-0000-000000000000') {
          BrokerService.read(response?.data?.data[0]?.brokerPublicId, null, null, 1, null, 1).then(
            (response) => {
              setBrokerRefFee(response?.data?.referralFee);
            },
          );
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    //Financial Calculaions
    recalculate();
  }, []);

  const handleAccruedFormSubmitted = (data) => {
    if (!(appContext?.userPermissions?.indexOf('Edit Advance Financial Info') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    var reqObject = {};
    reqObject.productType = 'Accrued';
    reqObject.applicationAdvancePublicId = advanceId;

    reqObject.accrualPerc = advanceObject?.accrualPerc;
    reqObject.accrueUntilDate = new Date(advanceObject?.accrualUntil);

    reqObject.isPostSettlementFunding = advanceObject.isPostSettlementFunding;
    reqObject.isBuyoutCase = advanceObject.isBuyoutCase;

    setIsLoading(true);

    ApplicationAdvanceFinancialsService.update(reqObject)
      .then((response) => {
        appContext.toastMessage.message = 'Your changes have been saved.';
        navigate('../');
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleMultiplierFormSubmit = (data) => {
    if (!(appContext?.userPermissions?.indexOf('Edit Advance Financial Info') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    let requestObject = {};
    requestObject.productType = 'Multiplier';
    requestObject.applicationAdvancePublicId = advanceId;

    multiplierRangesList.map((item) => {
      item.applicationAdvancePublicId = advanceId;
    });
    requestObject.multiplierRates = multiplierRangesList;

    requestObject.isPostSettlementFunding = advanceObject.isPostSettlementFunding;
    requestObject.isBuyoutCase = advanceObject.isBuyoutCase;

    setIsLoading(true);

    ApplicationAdvanceFinancialsService.update(requestObject)
      .then((response) => {
        appContext.toastMessage.message = 'Your changes have been saved.';
        navigate('../');
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const recalculate = () => {
    setIsLoading(true);

    let req = {
      publicId: advanceId,
      calculatedForContract: false,
    };

    //Recalculate API
    //let endpoint = `/ApplicationFinancialCalculations/CalculateSchedule/${advanceId}`;
    let endpoint = `/ApplicationFinancialCalculations/CalculateSchedule`;

    GridService.create(endpoint, req)
      .then((res) => {
        if (!res.data) return;
        if (res.data.length > 0) setFinancialCalculations(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (productOptionSelected === 'multiplier') {
      let requestObject = {};
      requestObject.applicationAdvancePublicId = advanceId;
      requestObject.productType = 'Multiplier';

      setIsLoading(true);

      ApplicationAdvanceFinancialsService.advanceFinancialsGetProduct(requestObject)
        .then((response) => {
          let updatedAdvance = { ...advanceObject };
          updatedAdvance.accrualPerc = null;
          updatedAdvance.accrualUntil = null;

          setMultiplierRangesList(response?.data?.multiplierRates);
          setAdvanceObject(updatedAdvance);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else if (productOptionSelected === 'accrued') {
      let requestObject = {};
      requestObject.applicationAdvancePublicId = advanceId;
      requestObject.productType = 'Accrued';

      setIsLoading(true);

      ApplicationAdvanceFinancialsService.advanceFinancialsGetProduct(requestObject)
        .then((response) => {
          let updatedAdvance = { ...advanceObject };
          updatedAdvance.accrualPerc = parseFloat(response.data.accrualPerc);
          updatedAdvance.accrualUntil =
            response.data.accrueUntilDate == null
              ? null
              : response.data.accrueUntilDate.split('T')[0];

          setMultiplierRangesList([]);
          setAdvanceObject(updatedAdvance);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [productOptionSelected]);

  const isPostSettlementFundingCheckboxInputChange = (e) => {
    var fs = { ...advanceObject };
    fs.isPostSettlementFunding = e.target.checked;
    setAdvanceObject(fs);
  };

  const isBuyoutCaseCheckboxInputChange = (e) => {
    var fs = { ...advanceObject };
    fs.isBuyoutCase = e.target.checked;
    setAdvanceObject(fs);
  };

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                <Header applicationId={applicationId} />
                <ApplicationsNewMenu applicationId={applicationId} menuItemIndex={8} />
                <div
                  className="row"
                  style={{
                    justifyContent: 'space-between',
                    width: '15%',
                    marginLeft: '0px',
                    paddingBottom: '3px',
                    marginBottom: '10px',
                    cursor: 'pointer',
                  }}
                >
                  <p
                    style={{
                      borderBottom: '1px solid #3699FF',
                      color: '#3699FF',
                    }}
                  >
                    Financials
                  </p>
                  <p
                    onClick={() => {
                      navigate(
                        `/underwriting/applications/${applicationId}/advances/${advanceId}/disbursement`,
                      );
                    }}
                  >
                    Disbursements
                  </p>
                </div>
                <>
                  <Row>
                    <Col md="1" style={{ display: 'flex', alignItems: 'center' }}>
                      <h4 className="p-0 m-0">Product</h4>
                    </Col>
                    <Col md="2" style={{ display: 'flex', alignItems: 'center' }}>
                      <select
                        ref={selectBox}
                        className="form-control"
                        onChange={(e) => {
                          setProductOptionSelected(e.target.value);
                          setFinancialCalculations([]);
                        }}
                      >
                        <option value="">Please select a product</option>
                        <option value="multiplier">Multiplier</option>
                        <option value="accrued">Accrued</option>
                      </select>
                    </Col>
                    <Col md="0" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        ref={isPostSettlementFundingCheckBox}
                        className="mr-2"
                        name="isPostSettlementFunding"
                        type="checkbox"
                        checked={advanceObject.isPostSettlementFunding}
                        onChange={isPostSettlementFundingCheckboxInputChange}
                      />
                    </Col>
                    <Col md="2" style={{ display: 'flex', alignItems: 'center' }}>
                      <Label className="p-0 m-0">Post Settlement Funding</Label>
                    </Col>
                    <Col md="0" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        ref={isBuyoutCaseCheckBox}
                        className="mr-2"
                        name="isBuyoutCaseCheckBox"
                        type="checkbox"
                        checked={advanceObject.isBuyoutCase}
                        onChange={isBuyoutCaseCheckboxInputChange}
                      />
                    </Col>
                    <Col md="2" style={{ display: 'flex', alignItems: 'center' }}>
                      <Label className="p-0 m-0">Buyout Case</Label>
                    </Col>
                  </Row>
                  <br />

                  {(productOptionSelected === 'multiplier' ||
                    productOptionSelected === 'multiplierCalledOnLoad') && (
                    <>
                      <form onSubmit={handleSubmit(handleMultiplierFormSubmit)} noValidate>
                        <Row className="mt-10">
                          <Col md="4">
                            <Row className="mb-5">
                              <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                <h5 className="p-0 m-0">Multiplier Ranges</h5>
                              </Col>
                              <Col md="2" style={{ display: 'flex', alignItems: 'center' }}>
                                <h4 className="m-0 p-0">
                                  <Button
                                    onClick={() => handleAddMultiplier()}
                                    style={{
                                      backgroundColor: 'var(--green)',
                                      borderColor: 'var(--green)',
                                      color: 'white',
                                    }}
                                  >
                                    Add
                                  </Button>
                                </h4>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="12">
                                {multiplierRangesList.map((x, i) => {
                                  return (
                                    <div className="form-group mb-5 form-inline" key={x.publicId}>
                                      <>
                                        <Input
                                          name="fromMonthCount"
                                          defaultValue={x.fromMonthCount}
                                          onChange={(e) => handleMultiplierInputChange(e, i)}
                                          style={{ width: '70px' }}
                                        />
                                        <span
                                          className="form-control"
                                          style={{ border: '0px', width: '5px' }}
                                        >
                                          -
                                        </span>
                                        <Input
                                          name="toMonthCount"
                                          defaultValue={x.toMonthCount}
                                          onChange={(e) => handleMultiplierInputChange(e, i)}
                                          style={{ width: '70px' }}
                                        />
                                        <span
                                          className="form-control"
                                          style={{ border: '0px', width: '5px' }}
                                        >
                                          =
                                        </span>
                                        <Input
                                          name="rate"
                                          defaultValue={x.rate}
                                          onChange={(e) => handleMultiplierInputChange(e, i)}
                                          style={{ width: '70px' }}
                                        />
                                        <span
                                          className="form-control"
                                          style={{ border: '0px', width: '5px' }}
                                        >
                                          &nbsp;
                                        </span>
                                        <i
                                          class="flaticon-circle text-secondary ml-2"
                                          onClick={() => handleDeleteMultiplier(i)}
                                          style={{ cursor: 'pointer' }}
                                        ></i>
                                      </>
                                    </div>
                                  );
                                })}
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6">
                            <Row>
                              <Col md="8" style={{ display: 'flex', alignItems: 'center' }}>
                                <h4>Schedule</h4>
                              </Col>
                              <Col md="4" style={{ display: 'flex', alignItems: 'center' }}></Col>
                              <Col md="12">
                                <Table striped>
                                  <thead>
                                    <tr>
                                      <th>From</th>
                                      <th>To</th>
                                      <th>Payoff Amount</th>
                                      <th>MOIC</th>
                                      <th>Multiplier</th>
                                      <th>Days Outstanding</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {financialCalculations?.map((row, index) => (
                                      <tr>
                                        <td>
                                          {row?.fromDate &&
                                            Moment(row?.fromDate?.split('T')[0]).format(
                                              'MM-DD-YYYY',
                                            )}
                                        </td>
                                        <td>
                                          {row?.toDate &&
                                            Moment(row?.toDate?.split('T')[0]).format('MM-DD-YYYY')}
                                        </td>
                                        <td className="text-right">
                                          {row?.payoffAmount
                                            ? moneyFormatter.format(
                                                parseFloat(row?.payoffAmount).toFixed(2),
                                              )
                                            : '-'}
                                        </td>
                                        <td className="text-right">{row?.moic}</td>
                                        {row?.multiplier != null ? (
                                          <td className="text-right">{row?.multiplier}</td>
                                        ) : undefined}
                                        <td className="text-right">{row?.daysOutstanding}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr />
                        <Button color="primary" type="submit">
                          Save
                        </Button>{' '}
                        &nbsp;
                        <Button
                          onClick={() =>
                            navigate('/underwriting/applications/' + applicationId + '/advances/')
                          }
                        >
                          Cancel
                        </Button>
                      </form>
                    </>
                  )}

                  {(productOptionSelected === 'accrued' ||
                    productOptionSelected === 'accruedCalledOnLoad') && (
                    <form onSubmit={handleSubmit(handleAccruedFormSubmitted)}>
                      <Row className="mt-10">
                        <Col md="4">
                          <h4>Accrual Settings</h4>

                          <Row>
                            <Col md="4" style={{ display: 'flex', alignItems: 'center' }}>
                              <FormGroup>
                                <Label className="p-0 m-0">Accrual Rate (%)</Label>
                              </FormGroup>
                            </Col>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                              <FormGroup>
                                <input
                                  type="number"
                                  ref={register({ required: 'A value is required.' })}
                                  defaultValue={advanceObject?.accrualPerc}
                                  onChange={handleInputChange}
                                  name="accrualPerc"
                                  className="form-control"
                                />
                                {errors.accrualPerc && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="accrualPerc" />
                                  </Alert>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4" style={{ display: 'flex', alignItems: 'center' }}>
                              <FormGroup>
                                <Label className="p-0 m-0">Accrual Until</Label>
                              </FormGroup>
                            </Col>
                            <Col md="6" style={{ display: 'flex', alignItems: 'center' }}>
                              <FormGroup>
                                <input
                                  type="date"
                                  name="accrualUntil"
                                  defaultValue={advanceObject?.accrualUntil}
                                  onChange={handleDateChange}
                                  ref={register()}
                                  className="form-control"
                                />

                                {errors.accrualUntil &&
                                  errors.accrualUntil.type === 'validateDate' && (
                                    <Alert color="danger" className="mt-5">
                                      <span>
                                        Acrrual Until date must be bigger than the advance date.
                                      </span>
                                    </Alert>
                                  )}

                                {errors.accrualUntil &&
                                  errors.accrualUntil &&
                                  errors.accrualUntil.type != 'validateDate' && (
                                    <Alert color="danger" className="mt-5">
                                      <ErrorMessage errors={errors} name="accrualUntil" />
                                    </Alert>
                                  )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row>
                            <Col md="8" style={{ display: 'flex', alignItems: 'center' }}>
                              <h4>Schedule</h4>
                            </Col>
                            <Col md="4" style={{ display: 'flex', alignItems: 'center' }}></Col>
                            <Col md="12">
                              <Table striped>
                                <thead>
                                  <tr>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Payoff Amount</th>
                                    <th>MOIC</th>
                                    <th>Days Outstanding</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {financialCalculations?.map((row, index) => (
                                    <tr>
                                      <td>
                                        {row?.fromDate &&
                                          Moment(row?.fromDate?.split('T')[0]).format('MM-DD-YYYY')}
                                      </td>
                                      <td>
                                        {row?.toDate &&
                                          Moment(row?.toDate?.split('T')[0]).format('MM-DD-YYYY')}
                                      </td>
                                      <td className="text-right">
                                        {row?.payoffAmount
                                          ? moneyFormatter.format(
                                              parseFloat(row?.payoffAmount).toFixed(2),
                                            )
                                          : '-'}{' '}
                                      </td>
                                      <td className="text-right">{row?.moic}</td>
                                      <td className="text-right">{row?.daysOutstanding}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr />
                      <Button color="primary" type="submit">
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        onClick={() =>
                          navigate('/underwriting/applications/' + applicationId + '/advances/')
                        }
                      >
                        Cancel
                      </Button>
                    </form>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
