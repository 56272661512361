import axios from 'axios';
import authHeader from '@services/AuthHeader';
import history from '@src/history';
import { toast } from 'react-toastify';

export const auth = (config) => {
  config.headers['Authorization'] = 'Bearer ' + authHeader();
  return config;
};

export const resError = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('lasuris-user');
    localStorage.removeItem('lasuris-jwt');
    localStorage.setItem('isUserAuthenticated', false);
    navigate('/login');
    window.location.reload(false);
  } else if (error.response.status === 404) {
    navigate('/not-found');
    window.location.reload(false);
  } else if (error.response.status === 406) {
    if (error.response.request.responseType === 'blob') {
      const { data } = error.response;
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const res = e.target?.result;
        const { errors } = JSON.parse(res);
        toast.error(errors, { autoClose: 3000 });
      };
      let file = fileReader.readAsText(data);
    } else {
      toast.error(error.response.data.errors ?? error.message, { autoClose: 3000 });
    }
  } else {
    toast.error('An error has occured. Please contact your system administrator.', {
      autoClose: 3000,
    });
  }
  throw new Error(error.response.data.errors ?? error.message);
};

//axios.interceptors.request.eject(reqInterceptor);
//axios.interceptors.request.eject(resInterceptor);
