import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import ApplicationsNewMenu from '../Menu/ApplicationsNewMenu';
import Header from '../Header';
import ActivityGrid from './ActivityGrid';
import ApplicationFlagService from '@services/ApplicationFlagService';

const Activity = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { applicationId } = useParams();
  let application = appContext.currentApplication;
  const [disableTabs, setDisableTabs] = useState(false);

  useEffect(() => {
    if (!applicationId) {
      appContext.toastMessage.message = 'Application No was not found!';
      navigate('/underwriting/applications/new-application/');
      return;
    }
    let item = document.querySelector('.activity');
    if (item) {
      item.classList.add('active');
    }

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped' },
      null,
      null,
      1,
      null,
      1,
    ).then((response) => {
      if (response?.data?.data[0]?.flagName === 'Application Stopped') {
        setDisableTabs(1);
      }
    });

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
      null,
      null,
      1,
      null,
      1,
    ).then((response) => {
      if (response?.data?.data[0]?.flagName === 'Application Stopped2') {
        setDisableTabs(2);
      }
    });

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped3' },
      null,
      null,
      1,
      null,
      1,
    ).then((response) => {
      if (response?.data?.data[0]?.flagName === 'Application Stopped3') {
        setDisableTabs(3);
      }
    });
  }, []);

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  }, [appContext.toastMessage.message, history]);

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              {disableTabs != null && (
                <Header disableTabs={disableTabs} applicationId={applicationId} />
              )}

              {disableTabs != null && (
                <ApplicationsNewMenu disableTabs={disableTabs} applicationId={applicationId} />
              )}
            </div>

            <Row>
              <Col md="12">
                <ActivityGrid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
