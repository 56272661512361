import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@mui/material/TableSortLabel';
import ReactLoading from 'react-loading';
import ApplicationPayoffRequestService from '@services/ApplicationPayoffRequestService';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Tooltip } from '@mui/material';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { toast } from 'react-toastify';
import Moment from 'moment';
import GridService from '@services/GridService';
import fileDownload from 'js-file-download';
import DeleteDocumentPopup from '@components/DeleteDocumentPopup';
import SendEmailPopup from '@components/SendEmailPopup';
import SystemRolesService from '@services/SystemRolesService';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: '14px', // Set font size for the body cells only
  },
}));

const useStyles2 = {
  minWidth: 500,
};

const PayoffRequestGrid = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const { applicationId } = useParams();

  const appContext = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  //email form
  const [emailModal, setEmailModal] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleEmail = () => setEmailModal(!emailModal);
  const [systemUsers, setSystemUsers] = useState([]);
  const [emails, setEmails] = useState(null);
  const [files, setFiles] = useState([]);
  const [client, setClient] = useState(false);
  const [attorney, setAttorney] = useState(false);
  const [broker, setBroker] = useState(false);
  const [accountManager, setAccountManager] = useState(false);
  const [subject, setSubject] = useState(null);
  const [body, setBody] = useState(null);
  const [selectedItemPublicId, setSelectedItemPublicId] = useState(null);
  const [applicationPublicId, setApplicationPublicId] = useState(null);
  const [document, setDocument] = useState({});
  const [isPayoffNewEnabled, setIsPayoffNewEnabled] = useState(true);
  const [activeFieldForSorting, setActiveFieldForSorting] = useState();
  const mountedRef = useRef();

  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!mountedRef.current) return;
    update();
  }, [orderBy, orderDir, rowsPerPage]);

  useEffect(() => {
    if (!mountedRef.current) return;
    update();
  }, [page]);

  useEffect(() => {
    mountedRef.current = true;
    setPage(1);
  }, []);

  const update = () => {
    setIsLoading(true);
    ApplicationPayoffRequestService.search(
      { applicationNo: applicationId },
      orderBy,
      orderDir,
      page,
      null,
      rowsPerPage,
    )
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteItem = () => {
    if (!(appContext?.userPermissions?.indexOf('Delete Payoff Requests') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    ApplicationPayoffRequestService.delete(givenRows[rowIndex].publicId)
      .then((result) => {
        let items = [...givenRows];
        items.splice(rowIndex, 1);
        setRows(items);
        toggle();
        toast('Payoff request has been successfully deleted.', { type: 'success' });
      })
      .catch((e) => {
        toggle();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleDownloadFile = (item) => {
    if (!(appContext?.userPermissions?.indexOf('Download Documents') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    let config = { responseType: 'blob' };
    let endpoint = `/ApplicationPayoffRequest/getfile/${item.publicId}`;

    GridService.read(endpoint, config)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
      })
      .catch((e) => {});
  };

  const handleEmailPopup = (row) => {
    SystemRolesService.search(filters, orderBy, orderDir, 1, null, 20)
      .then((response) => {
        if (!response.data) return;
        setSystemUsers(response.data.data);
        setDocument({
          documentName: `${applicationId}-Payoff Letter Request.pdf`,
        });
        setSelectedItemPublicId(row.publicId);
        setApplicationPublicId(row.applicationPublicId);
        toggleEmail();
      })
      .catch((err) => {
        toast.error('An error has occured. Please contact your system administrator.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFormSubmit = (item) => {
    if (!(appContext?.userPermissions?.indexOf('Email Documents') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    let formData = new FormData(); //formdata object
    let tempSysUsers =
      systemUsers.length > 0
        ? systemUsers.reduce((a, o) => (o.checked && a.push(o.publicId), a), [])
        : [];
    let tempEmails = emails?.split(';');
    let attachments = files;
    for (var i = 0; i < tempSysUsers?.length; i++) {
      formData.append('SystemRoles', tempSysUsers[i]);
    }
    for (var i = 0; i < tempEmails?.length; i++) {
      formData.append('ToAdditionalEmails', tempEmails[i]);
    }
    for (var i = 0; i < attachments?.length; i++) {
      formData.append('Attachments', attachments[i]);
    }

    formData.append('publicGuid', selectedItemPublicId);
    formData.append('ApplicationPublicGuid', applicationPublicId);
    formData.append('ToClient', client);
    formData.append('ToAttorney', attorney);
    formData.append('ToBroker', broker);
    formData.append('ToAccountManager', accountManager);
    formData.append('Subject', subject);
    formData.append('Body', body);
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    let endpoint = '/ApplicationPayoffRequest/sendemail';
    GridService.create(endpoint, formData, config)
      .then((res) => {
        if (res?.data === true) {
          setEmailModal(false);
          toast.success('Email sent successfully', { autoClose: 3000 });
        } else {
          if (res?.response?.data?.errors) {
            toast.error(res.response.data?.errors, { autoClose: 5000 });
          }
        }
      })
      .catch((_) => {});
  };

  return (
    <div>
      <DeleteDocumentPopup modal={modal} toggle={toggle} deleteItem={deleteItem} />

      <SendEmailPopup
        emailModal={emailModal}
        toggleEmail={toggleEmail}
        handleFormSubmit={handleFormSubmit}
        setClient={setClient}
        client={client}
        setAccountManager={setAccountManager}
        accountManager={accountManager}
        setAttorney={setAttorney}
        attorney={attorney}
        setBroker={setBroker}
        broker={broker}
        setBody={setBody}
        setSystemUsers={setSystemUsers}
        systemUsers={systemUsers}
        setEmails={setEmails}
        setSubject={setSubject}
        setFiles={setFiles}
        document={document}
      />

      <TableContainer component={Paper}>
        <Table className={useStyles2} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Id</StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('requestDate');
                  setActiveFieldForSorting('requestDate');
                }}
              >
                REQUEST DATE
                <TableSortLabel
                  active={activeFieldForSorting === 'requestDate' ? true : false}
                  direction={
                    activeFieldForSorting === 'requestDate' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('requestDate')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('payoffGoodUntilDate');
                  setActiveFieldForSorting('payoffGoodUntilDate');
                }}
              >
                PAYOFF GOOD UNTIL DATE
                <TableSortLabel
                  active={activeFieldForSorting === 'payoffGoodUntilDate' ? true : false}
                  direction={
                    activeFieldForSorting === 'payoffGoodUntilDate' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('payoffGoodUntilDate')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('hasCaseSettled');
                  setActiveFieldForSorting('hasCaseSettled');
                }}
              >
                HAS CASE SETTLED
                <TableSortLabel
                  active={activeFieldForSorting === 'hasCaseSettled' ? true : false}
                  direction={
                    activeFieldForSorting === 'hasCaseSettled' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('hasCaseSettled')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('settlementoptions');
                  setActiveFieldForSorting('settlementoptions');
                }}
              >
                SETTLEMENT OPTIONS
                <TableSortLabel
                  active={activeFieldForSorting === 'settlementoptions' ? true : false}
                  direction={
                    activeFieldForSorting === 'settlementoptions' ? orderDir.toLowerCase() : 'asc'
                  }
                  onClick={() => arrange('settlementoptions')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell
                onClick={() => {
                  arrange('notes');
                  setActiveFieldForSorting('notes');
                }}
              >
                NOTE
                <TableSortLabel
                  active={activeFieldForSorting === 'notes' ? true : false}
                  direction={activeFieldForSorting === 'notes' ? orderDir.toLowerCase() : 'asc'}
                  onClick={() => arrange('notes')}
                ></TableSortLabel>
              </StyledTableCell>

              <StyledTableCell align="right">ACTIONS</StyledTableCell>
            </TableRow>
          </TableHead>
          {isLoading === false && (
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>
                      {row?.requestDate &&
                        Moment(row?.requestDate?.split('T')[0]).format('MM-DD-YYYY')}{' '}
                      {Moment(row?.requestDate).format('h:mm:ss A')}
                    </TableCell>
                    <TableCell>
                      {row?.payoffGoodUntilDate &&
                        Moment(row?.payoffGoodUntilDate?.split('T')[0]).format('MM-DD-YYYY')}{' '}
                      {Moment(row?.payoffGoodUntilDate).format('h:mm:ss A')}
                    </TableCell>
                    <TableCell>{row.hasCaseSettled == true ? 'YES' : 'NO'}</TableCell>
                    <TableCell>{row.caseSettlementOption}</TableCell>
                    <TableCell>{row.notes}</TableCell>
                    <TableCell className="p-2" align="right">
                      <a
                        className="btn mr-1 btn-sm"
                        onClick={() =>
                          navigate(
                            `/underwriting/applications/${applicationId}/payoffs/${row.publicId}`,
                          )
                        }
                      >
                        <EditIcon />
                      </a>
                      <Tooltip title="Email Payoff Request Letter">
                        <button
                          className="btn mr-1 btn-sm"
                          onClick={(row) => handleEmailPopup(row)}
                        >
                          <MailOutlineOutlinedIcon />
                        </button>
                      </Tooltip>
                      <Tooltip title="Download Payoff Request Letter">
                        <button className="btn mr-1 btn-sm" onClick={() => handleDownloadFile(row)}>
                          <CloudDownloadOutlinedIcon />
                        </button>
                      </Tooltip>
                      <a
                        className="btn btn-sm"
                        onClick={() => {
                          setRowIndex(index);
                          setGivenRows(rows);
                          toggle();
                        }}
                      >
                        <DeleteIcon style={{ color: 'var(--red)' }} />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {isLoading && (
            <TableBody>
              <TableRow>
                <td></td>
                <td>
                  <ReactLoading type="bars" color="#7E8299" />
                </td>
                <td></td>
              </TableRow>
            </TableBody>
          )}

          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      <div className="float-left mt-5">
        <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
          {[5, 10, 15, 25, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <div className="float-right mt-5">
        <Pagination
          count={pages}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default PayoffRequestGrid;
