import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Chart from 'react-google-charts';
import GridService from '@services/GridService';
import AppContext from '@contexts/AppContext';
import './Dashboard.css';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Text } from 'recharts';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);

  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const sampleObject = {
    mtd: 0,
    mtdlastYear: 0,
    lastMonth: 0,
    ytdthisYear: 0,
    ytdlastYear: 0,
  };
  const collectionObject = {
    mtdCashCollected: 0,
    mtdConcessionGranted: 0,
    mtdPresentValue: 0,
    mtdLastYearCashCollected: 0,
    mtdLastYearConcessionGranted: 0,
    mtdLastYearPresentValue: 0,
    lastMonthCashCollected: 0,
    lastMonthConcessionGranted: 0,
    lastMonthPresentValue: 0,
    ytdthisYearCashCollected: 0,
    ytdthisYearConcessionGranted: 0,
    ytdthisYearPresentValue: 0,
    ytdlastYearCashCollected: 0,
    ytdlastYearConcessionGranted: 0,
    ytdlastYearPresentValue: 0,
  };
  const [showChart, setShowChart] = useState(false);
  const [advance, setAdvance] = useState(sampleObject);
  const [advanceTab, setAdvanceTab] = useState(1);
  const [writeOff, setWriteOff] = useState(sampleObject);
  const [writeOffTab, setWriteOffTab] = useState(1);
  const [collections, setCollections] = useState(collectionObject);
  const [collectionTab, setCollectionTab] = useState(1);
  const [collectionTabName, setCollectionTabName] = useState('mtd');

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-home').click();
    document.querySelector('#kt_header_menu > ul > li').classList.add('menu-item-active');
  };

  const user = JSON.parse(localStorage.getItem('lasuris-user'));
  const executive = 'Executive Management';
  const lead = 'Lead Underwriter';

  useEffect(() => {
    makeMenuItemActive();
    fetchAdvanceData('/VDashboardsAdvancesCashOutlay/');
    fetchWriteOffData('/VDashboardsWritteoffsPresentValue/');
    fetchCollectionData('/VDashboardsCollection/');
  }, []);

  useEffect(() => {
    if (user.roles) {
      let showChart = user.roles.some(
        (item) => item.roleName === executive || item.roleName === lead,
      );
      setShowChart(showChart);
    }
  });

  const fetchAdvanceData = (endpoint) => {
    setIsLoading(true);

    GridService.search(endpoint, {}, '', '', 1, null, 10)
      .then((res) => {
        if (!res.data) return;

        setAdvance(res.data.data[0]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchWriteOffData = (endpoint) => {
    setIsLoading(true);

    GridService.search(endpoint, {}, '', '', 1, null, 10)
      .then((res) => {
        if (!res.data) return;

        let formattedWriteOffs = {};
        for (const key in res.data.data[0]) {
          if (res.data.data[0][key] != 0) {
            formattedWriteOffs[key] = res.data.data[0][key];
          } else {
            formattedWriteOffs[key] = res.data.data[0][key];
          }
        }

        setWriteOff(formattedWriteOffs);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openReport = (dateRange) => {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth();
    let day = today.getDate();

    var lastDayOfMonth = new Date();
    lastDayOfMonth.setDate(1);
    lastDayOfMonth.setHours(-3);

    let YTD = {
      fromDate: new Date(year, 0, 1).toISOString(),
      toDate: new Date(today).toISOString(),
    };
    let YTDlastYear = {
      fromDate: new Date(year - 1, 0, 1).toISOString(),
      toDate: new Date(year - 1, month, day).toISOString(),
    };
    let MTD = {
      fromDate: new Date(year, month, 1).toISOString(),
      toDate: new Date(today).toISOString(),
    };
    let MTDlastyear = {
      fromDate: new Date(year - 1, month, 1).toISOString(),
      toDate: new Date(year - 1, month, day).toISOString(),
    };
    let LastMonth = {
      fromDate: new Date(year, month - 1, 1).toISOString(),
      toDate: new Date(lastDayOfMonth).toISOString(),
    };

    let filter = 'today';
    if (dateRange === 'YTD') filter = YTD;
    if (dateRange === 'YTDlastYear') filter = YTDlastYear;
    if (dateRange === 'MTD') filter = MTD;
    if (dateRange === 'MTDlastyear') filter = MTDlastyear;
    if (dateRange === 'Last month') filter = LastMonth;

    appContext.financialSummaryReportFilters.dateRange = filter;
    localStorage.setItem(
      'financialSummaryReportFilters',
      JSON.stringify(appContext.financialSummaryReportFilters),
    );

    navigate('reports/financial-summary');
  };

  const fetchCollectionData = (endpoint) => {
    setIsLoading(true);

    GridService.search(endpoint, {}, '', '', 1, null, 10)
      .then((res) => {
        if (!res.data) return;

        let formattedCollections = {};
        for (const key in res.data.data[0]) {
          if (res.data.data[0][key] != 0) {
            formattedCollections[key] = res.data.data[0][key];
          } else {
            formattedCollections[key] = res.data.data[0][key];
          }
        }

        setCollections(formattedCollections);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  let advanceData = [
    {
      name: 'MTD',
      advances: advance.mtd,
    },
    {
      name: 'MTD Last Year',
      advances: advance.mtdlastYear,
    },
    {
      name: 'Last Month',
      advances: advance.lastMonth,
    },
    {
      name: 'YTD',
      advances: advance.ytdthisYear,
    },
    {
      name: 'YTD Last Year',
      advances: advance.ytdlastYear,
    },
  ];

  const data2 = [
    {
      name: 'MTD',
      advances: advance.mtd,
    },
    {
      name: 'MTD Last Year',
      advances: advance.mtdlastYear,
    },
    {
      name: 'Last Month',
      advances: advance.lastMonth,
    },
    {
      name: 'YTD',
      advances: advance.ytdthisYear,
    },
    {
      name: 'YTD Last Year',
      advances: advance.ytdlastYear,
    },
  ];

  let writeOffData = [
    {
      name: 'MTD',
      advances: writeOff.mtd,
    },
    {
      name: 'MTD Last Year',
      advances: writeOff.mtdlastYear,
    },
    {
      name: 'Last Month',
      advances: writeOff.lastMonth,
    },
    {
      name: 'YTD',
      advances: writeOff.ytdthisYear,
    },
    {
      name: 'YTD Last Year',
      advances: writeOff.ytdlastYear,
    },
  ];

  const collectionData = [
    {
      name: 'Present Value',
      value: collections[`${collectionTabName}PresentValue`], // value is the part of the graph we want to show
      pv: 0, // pv is the floating part (transparent)
    },
    {
      name: 'Concession Granted',
      value: collections[`${collectionTabName}ConcessionGranted`],
      pv: collections[`${collectionTabName}CashCollected`],
    },
    {
      name: 'Cash Collected',
      value: collections[`${collectionTabName}CashCollected`],
      pv: 0,
    },
  ];

  const CustomTooltipAdvances = (props) => {
    const { active, payload, label } = props;

    return (
      <div style={{ background: 'white', padding: '5px' }}>
        <p>{`${label}`} </p>
        {payload[0] && (
          <p className="intro">{`Applications : ${payload[0].value.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`}</p>
        )}
      </div>
    );
  };

  const CustomTooltipCashOutlay = (props) => {
    const { active, payload, label } = props;

    return (
      <div style={{ background: 'white', padding: '5px' }}>
        <p>{`${label}`} </p>
        {payload[0] && (
          <p className="intro">{`Cash Outlay : $ ${payload[0].value.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`}</p>
        )}
      </div>
    );
  };

  const CustomTooltipACollections = (props) => {
    const { active, payload, label } = props;

    let percentageValue;
    if (payload[1] && collectionData[0])
      percentageValue = (payload[1].value / collectionData[0].value) * 100;

    return (
      <div style={{ background: 'white', padding: '5px' }}>
        <p>{`${label}`} </p>
        {payload[1] && (
          <>
            <p className="intro">{`Value : $ ${payload[1].value.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`}</p>
            {percentageValue && percentageValue !== 100 && (
              <p className="intro">{`Percentage of PV :  ${percentageValue.toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 2,
                },
              )} %`}</p>
            )}
          </>
        )}
      </div>
    );
  };

  const CustomizedTick = (props) => {
    const { x, y, payload } = props;
    console.log(payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={0} textAnchor="end" fill="#666" className="customized-axis-tick-text">
          {`$ ${payload.value.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`}
        </text>
      </g>
    );
  };

  function Waterfall() {
    return (
      <BarChart
        width={750}
        height={300}
        data={collectionData}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 0,
        }}
      >
        <CartesianGrid vertical={false} stroke="#ebf3f0" />

        <XAxis dataKey="name" />
        <YAxis
          type="number"
          tick={<CustomizedTick />}
          domain={[0, collections[`${collectionTabName}PresentValue`]]}
          // label={{ value: 'Amount in USD ($)', angle: -90, position: 'left' }}
        />
        <Tooltip content={CustomTooltipACollections} />
        <Tooltip />
        <Bar dataKey="pv" stackId="a" fill="transparent" />
        <Bar dataKey="value" stackId="a">
          {collectionData.map((item, index) => {
            if (item.name === 'Present Value') {
              return <Cell key={index} fill="#87C8D9" />;
            } else if (item.name === 'Cash Collected') {
              return <Cell key={index} fill="#E3B1B0" />;
            } else {
              return <Cell key={index} fill="#FF7373" />;
            }
          })}
        </Bar>
      </BarChart>
    );
  }

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <h4>Dashboard</h4>
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="d-flex mt-5 mb-10">
              {showChart ? (
                <div className="col-md-12">
                  {/* //Display charts */}
                  <h2>Advances</h2>
                  <div
                    style={{
                      marginTop: '30px',
                      display: 'flex',
                    }}
                  >
                    <p
                      onClick={() => {
                        setAdvanceTab(1);
                        fetchAdvanceData('/VDashboardsAdvancesCashOutlay/');
                      }}
                      className={`tab ${advanceTab === 1 && `activeTab`}`}
                    >
                      Cash Outlay
                    </p>
                    <p
                      className={`tab ${advanceTab === 2 && `activeTab`}`}
                      onClick={() => {
                        setAdvanceTab(2);
                        fetchAdvanceData('/VDashboardsAdvancesTotalCas/');
                      }}
                    >
                      # of Cases
                    </p>
                  </div>
                  <div className="col-md-6">
                    {advanceTab === 1 && (
                      <BarChart
                        width={750}
                        height={300}
                        data={data2}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0,
                        }}
                      >
                        <XAxis dataKey="name" />
                        <YAxis
                          type="number"
                          tick={<CustomizedTick />}
                          // label={{ value: 'Amount in USD ($)', angle: -90, position: 'left' }}
                        />
                        <Tooltip content={CustomTooltipCashOutlay} />
                        <CartesianGrid vertical={false} stroke="#ebf3f0" />
                        {/* <Legend /> */}
                        <Bar dataKey="advances" fill="#8884d8">
                          {data2.map((item, index) => {
                            if (item.name === 'MTD') {
                              return (
                                <Cell
                                  key={index}
                                  fill="#FF7373"
                                  onClick={() => openReport('MTD')}
                                />
                              );
                            } else if (item.name === 'MTD Last Year') {
                              return (
                                <Cell
                                  key={index}
                                  fill="#FF7373"
                                  onClick={() => openReport('MTDlastyear')}
                                />
                              );
                            } else if (item.name === 'Last Month') {
                              return (
                                <Cell
                                  key={index}
                                  fill="#FF7373"
                                  onClick={() => openReport('Last month')}
                                />
                              );
                            } else if (item.name === 'YTD') {
                              return (
                                <Cell
                                  key={index}
                                  fill="#8884d8"
                                  onClick={() => openReport('YTD')}
                                />
                              );
                            } else {
                              return (
                                <Cell
                                  key={index}
                                  fill="#8884d8"
                                  onClick={() => openReport('YTDlastYear')}
                                />
                              );
                            }
                          })}
                        </Bar>
                      </BarChart>
                    )}

                    {advanceTab === 2 && (
                      <BarChart
                        width={750}
                        height={300}
                        data={advanceData}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 20,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          // label={{
                          //   value: 'Time period',
                          //   angle: 0,
                          //   position: 'middle',
                          // }}
                        />
                        <YAxis
                          type="number"
                          // tick={<CustomizedTick />}
                          label={{
                            value: 'Number of cases',
                            dx: -50,
                            angle: -90,
                            marginRigth: 10,
                            position: 'middle',
                          }}
                        />
                        {/* <Tooltip content={CustomTooltipAdvances} /> */}
                        <Tooltip />
                        <CartesianGrid vertical={false} stroke="#ebf3f0" />
                        {/* <Legend /> */}
                        <Bar dataKey="advances" fill="#8884d8">
                          {advanceData.map((item, index) => {
                            if (item.name === 'MTD') {
                              return (
                                <Cell
                                  key={index}
                                  fill="#FF7373"
                                  onClick={() => openReport('MTD')}
                                />
                              );
                            } else if (item.name === 'MTD Last Year') {
                              return (
                                <Cell
                                  key={index}
                                  fill="#FF7373"
                                  onClick={() => openReport('MTDlastyear')}
                                />
                              );
                            } else if (item.name === 'Last Month') {
                              return (
                                <Cell
                                  key={index}
                                  fill="#FF7373"
                                  onClick={() => openReport('Last month')}
                                />
                              );
                            } else if (item.name === 'YTD') {
                              return (
                                <Cell
                                  key={index}
                                  fill="#8884d8"
                                  onClick={() => openReport('YTD')}
                                />
                              );
                            } else {
                              return (
                                <Cell
                                  key={index}
                                  fill="#8884d8"
                                  onClick={() => openReport('YTDlastYear')}
                                />
                              );
                            }
                          })}
                        </Bar>
                      </BarChart>
                    )}
                  </div>
                  <h2>Writeoffs</h2>
                  <div
                    style={{
                      marginTop: '30px',
                      display: 'flex',
                    }}
                  >
                    <p
                      onClick={() => {
                        setWriteOffTab(1);
                        fetchWriteOffData('/VDashboardsWritteoffsPresentValue/');
                      }}
                      className={`tab ${writeOffTab === 1 && `activeTab`}`}
                    >
                      Present Value
                    </p>
                    <p
                      onClick={() => {
                        setWriteOffTab(2);
                        fetchWriteOffData('/VDashboardsWritteoffsCashOutlay/');
                      }}
                      className={`tab ${writeOffTab === 2 && `activeTab`}`}
                    >
                      Cash Outlay
                    </p>
                    <p
                      className={`tab ${writeOffTab === 3 && `activeTab`}`}
                      onClick={() => {
                        setWriteOffTab(3);
                        fetchWriteOffData('/VDashboardsWritteoffsTotalCas/');
                      }}
                    >
                      # of Cases
                    </p>
                  </div>
                  <div className="col-md-6">
                    {(writeOffTab === 1 || writeOffTab === 2) && (
                      <BarChart
                        width={750}
                        height={300}
                        data={writeOffData}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0,
                        }}
                      >
                        <XAxis dataKey="name" />
                        <YAxis
                          type="number"
                          tick={<CustomizedTick />}
                          // label={{ value: 'Amount in USD ($)', angle: -90, position: 'left' }}
                        />
                        <Tooltip content={CustomTooltipAdvances} />

                        <CartesianGrid vertical={false} stroke="#ebf3f0" />
                        {/* <Legend /> */}
                        <Bar dataKey="advances" fill="#8884d8">
                          {collectionData.map((item, index) => {
                            if (item.name === 'MTD') {
                              return <Cell key={index} fill="#DADADA" />;
                            } else if (item.name === 'MTD Last Year') {
                              return <Cell key={index} fill="#DADADA" />;
                            } else if (item.name === 'Last Month') {
                              return <Cell key={index} fill="#B9B9B9" />;
                            } else if (item.name === 'YTD') {
                              return <Cell key={index} fill="#DADADA" />;
                            } else {
                              return <Cell key={index} fill="#FF7373" />;
                            }
                          })}
                        </Bar>
                      </BarChart>
                    )}

                    {writeOffTab === 3 && (
                      <BarChart
                        width={750}
                        height={300}
                        data={writeOffData}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 50,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          // label={{
                          //   value: 'Time period',
                          //   angle: 0,
                          //   position: 'middle',
                          // }}
                        />
                        <YAxis
                          type="number"
                          // tick={<CustomizedTick />}
                          label={{
                            value: 'Number of cases',
                            dx: -50,
                            angle: -90,
                            marginRigth: 10,
                            position: 'middle',
                          }}
                        />
                        <Tooltip content={CustomTooltipAdvances} />
                        <CartesianGrid vertical={false} stroke="#ebf3f0" />
                        {/* <Legend /> */}
                        <Bar dataKey="advances" fill="#8884d8">
                          {collectionData.map((item, index) => {
                            if (item.name === 'MTD') {
                              return <Cell key={index} fill="#DADADA" />;
                            } else if (item.name === 'MTD Last Year') {
                              return <Cell key={index} fill="#DADADA" />;
                            } else if (item.name === 'Last Month') {
                              return <Cell key={index} fill="#B9B9B9" />;
                            } else if (item.name === 'YTD') {
                              return <Cell key={index} fill="#DADADA" />;
                            } else {
                              return <Cell key={index} fill="#FF7373" />;
                            }
                          })}
                        </Bar>
                      </BarChart>
                    )}
                  </div>

                  <h2>Collections</h2>
                  <div
                    style={{
                      marginTop: '30px',
                      display: 'flex',
                    }}
                  >
                    <p
                      onClick={() => {
                        setCollectionTabName('mtd');
                        setCollectionTab(1);
                      }}
                      className={`tab ${collectionTab === 1 && `activeTab`}`}
                    >
                      MTD
                    </p>
                    <p
                      onClick={() => {
                        setCollectionTabName('mtdLastYear');
                        setCollectionTab(2);
                      }}
                      className={`tab ${collectionTab === 2 && `activeTab`}`}
                    >
                      MTD Last Year
                    </p>
                    <p
                      onClick={() => {
                        setCollectionTabName('lastMonth');
                        setCollectionTab(3);
                      }}
                      className={`tab ${collectionTab === 3 && `activeTab`}`}
                    >
                      Last Month
                    </p>
                    <p
                      onClick={() => {
                        setCollectionTabName('ytdthisYear');
                        setCollectionTab(4);
                      }}
                      className={`tab ${collectionTab === 4 && `activeTab`}`}
                    >
                      YTD
                    </p>
                    <p
                      onClick={() => {
                        setCollectionTabName('ytdlastYear');
                        setCollectionTab(5);
                      }}
                      className={`tab ${collectionTab === 5 && `activeTab`}`}
                    >
                      YTD Last Year
                    </p>
                  </div>
                  <div className="col-md-6">
                    <Waterfall />
                  </div>
                </div>
              ) : (
                <span>Welcome to LitFinPro.</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
