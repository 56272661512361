import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Alert,
  FormGroup,
  Container,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import ApplicationsNewMenu from '../Menu';
import ApplicationsService from '@services/ApplicationsService';
import ApplicationFlagService from '@services/ApplicationFlagService';
import UsZipCodeService from '@services/UsZipCodeService';

import AppContext from '@contexts/AppContext';
import Header from '../Header';
import StateSelect from '@components/StateSelect';
import ClientsAutoSuggest from '@components/ClientsAutoSuggest/ClientsAutoSuggestInsideApplication';
import ClientService from '@services/ClientService';
import ReactLoading from 'react-loading';

const Plaintiff = () => {
  const appContext = useContext(AppContext);
  const { applicationId } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState(undefined);
  const navigate = useNavigate();
  const [hasObligations, setHasObligations] = useState(false);
  const [hasBankruptchy, setHasBankruptchy] = useState(false);
  const [HasFirstButtonSubbmited, setHasFirstButtonSubbmited] = useState(true);
  const [modal, setModal] = useState(false);
  const [disableTabs, setDisableTabs] = useState(false);
  const [refreshFlags, setRefreshFlags] = useState(false);

  const toggle = () => setModal(!modal);

  const [client, setClient] = useState({
    publicId: '',
    title: '',
    firstName: '',
    lastName: '',
    homePhone: '',
    dob: '',
    mobilePhoneNo: '',
    emailAddress: '',
    sslast4Digits: '',
    streetAddress: '',
    city: '',
    stateCode: '',
    zipCode: '',
    hasBankruptcy: undefined,
    hasFamilyObligations: undefined,
    notes: '',
  });

  const [clientName, setClientName] = useState('');
  const [selectedClient, setSelectedClient] = useState('');

  const { register, handleSubmit, formState: { errors }, } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  }, []);

  const handleSaveAndExit = () => {
    setHasFirstButtonSubbmited(false);
    document.querySelector('button.btn.btn-primary.mr-5').click();
  };

  const handleFormSubmitted = (data) => {
    if (!(appContext?.userPermissions?.indexOf('Edit Plaintiff Info') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    setIsLoading(true);

    application.clientPublicId = client.publicId;

    if (selectedClient != '') {
      ApplicationsService.update(application).then((response) => {
        appContext.currentApplication = response.data;
        appContext.toastMessage.message = 'Your changes has been saved sucessfully.';

        if (client?.hasFamilyObligations === true || client?.hasBankruptcy === true) {
          appContext.newAppllicationStep = 1;

          // search to see if there is already a flag. create a flag if no flag found
          ApplicationFlagService.searchExact(
            { applicationNo: applicationId },
            null,
            null,
            1,
            null,
            1,
          )
            .then((response) => {
              if (response?.data?.data[0]?.flagName != 'Application Stopped') {
                let requestObject = {
                  applicationPublicId: application.publicId,
                  isCustomFlag: true,
                  flagName: 'Application Stopped',
                };

                ApplicationFlagService.create(requestObject).then((response2) => {
                  setDisableTabs(1);
                  setIsLoading(false);
                  toggle();
                  return;
                });
              }
            })
            .catch((err) => {})
            .finally(() => {
              setIsLoading(false);
            });

          setDisableTabs(1);
          setIsLoading(false);
          toggle();
          return;
        } else {
          appContext.newAppllicationStep = 2;

          // delete flag if exists
          ApplicationFlagService.searchExact(
            { applicationNo: applicationId, flagNameExact: 'Application Stopped' },
            null,
            null,
            1,
            null,
            1,
          )
            .then((response) => {
              if (response?.data?.data[0]?.flagName === 'Application Stopped') {
                ApplicationFlagService.delete(response?.data?.data[0]?.publicId).then(
                  (response2) => {
                    setDisableTabs(false);
                    navigate('/underwriting/applications/' + applicationId + '/accident');
                    return;
                  },
                );
              } else {
                setDisableTabs(false);
                navigate('/underwriting/applications/' + applicationId + '/accident');
                return;
              }
            })
            .catch((err) => {})
            .finally(() => {
              setIsLoading(false);
            });
        }
      });
    } else {
      data.hasFamilyObligations = data.hasFamilyObligations === 'true' ? true : false;
      data.hasBankruptcy = data.hasBankruptcy === 'true' ? true : false;
      data.dob = new Date(data.dob);

      ClientService.create(data)
        .then((response) => {
          setClient(response.data);
          application.clientPublicId = response.data.publicId;

          ApplicationsService.update(application)
            .then((applicationResponse) => {
              appContext.currentApplication = applicationResponse.data;
              appContext.toastMessage.message = 'Your changes has been saved sucessfully.';

              if (data.hasFamilyObligations === true || data.hasBankruptcy === true) {
                // search to see if there is already a flag. create a flag if no flag found
                ApplicationFlagService.searchExact(
                  { applicationNo: applicationId },
                  null,
                  null,
                  1,
                  null,
                  1,
                )
                  .then((response) => {
                    if (response?.data?.data[0]?.flagName != 'Application Stopped') {
                      let requestObject = {
                        applicationPublicId: application.publicId,
                        isCustomFlag: true,
                        flagName: 'Application Stopped',
                      };

                      ApplicationFlagService.create(requestObject).then((response2) => {
                        setDisableTabs(1);
                        setIsLoading(false);
                        toggle();
                        return;
                      });
                    }
                  })
                  .catch((err) => {})
                  .finally(() => {
                    setIsLoading(false);
                  });

                setDisableTabs(1);
                setIsLoading(false);
                toggle();
                return;
              } else {
                appContext.newAppllicationStep = 2;
                setIsLoading(false);
                navigate('/underwriting/applications/' + applicationId + '/accident');
                return;
              }
            })
            .catch((err) => {})
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCancelButtonClicked = () => {
    navigate('/underwriting/applications/');
  };

  const [isNewClient, setIsNewClient] = useState(undefined);

  useEffect(() => {
    if (selectedClient != null && selectedClient.publicId != undefined) {
      ClientService.read(selectedClient.publicId)
        .then((response) => {
          setClient(response?.data);
          setClientName(response?.data?.displayName);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedClient]);

  useEffect(() => {
    if (isNewClient == true) {
      setClient({
        publicId: '',
        title: '',
        firstName: '',
        lastName: '',
        homePhone: '',
        dob: '',
        mobilePhoneNo: '',
        emailAddress: '',
        sslast4Digits: '',
        streetAddress: '',
        city: '',
        stateCode: '',
        zipCode: '',
        hasBankruptcy: undefined,
        hasFamilyObligations: undefined,
        notes: '',
      });

      setSelectedClient('');
      setClientName('');
    }
  }, [isNewClient]);

  useEffect(() => {
    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      navigate('/underwriting/applications/new-application/');
      return;
    }

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    if (applicationId) {
      setIsLoading(true);

      ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1)
        .then((response) => {
          setApplication(response?.data?.data[0]);

          const urlParams = new URLSearchParams(window.location.search);
          const fromEditClient = urlParams.get('fromEditClient');

          if (fromEditClient != undefined) {
            setIsNewClient(false);
            ClientService.read(fromEditClient)
              .then((response) => {
                setClient(response?.data);
                setSelectedClient(response?.data?.publicId);
                setClientName(response?.data?.displayName);
                setIsLoading(false);
              })
              .catch((err) => {})
              .finally(() => {
                setIsLoading(false);
              });
          } else {
            if (response?.data?.data[0]?.clientPublicId != '00000000-0000-0000-0000-000000000000') {
              setIsNewClient(false);
              ClientService.read(response?.data?.data[0]?.clientPublicId)
                .then((response) => {
                  setClient(response?.data);
                  setSelectedClient(response?.data?.publicId);
                  setClientName(response?.data?.displayName);
                  setIsLoading(false);
                })
                .catch((err) => {})
                .finally(() => {
                  setIsLoading(false);
                });
            }
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      appContext.toastMessage.message =
        'You cannot perform this action. You must complete required data first.';
      setIsLoading(false);
      navigate('/underwriting/applications/new-application');
      return;
    }

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped' },
      null,
      null,
      1,
      null,
      1,
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped') {
          setDisableTabs(1);
          setIsLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
      null,
      null,
      1,
      null,
      1,
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped2') {
          setDisableTabs(2);
          setIsLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });

    ApplicationFlagService.searchExact(
      { applicationNo: applicationId, flagNameExact: 'Application Stopped3' },
      null,
      null,
      1,
      null,
      1,
    )
      .then((response) => {
        if (response?.data?.data[0]?.flagName === 'Application Stopped3') {
          setIsLoading(false);
          setDisableTabs(3);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getStateFromZipCode = (event) => {
    if (event.target.value.length > 4) {
      var filters = {};
      filters.zipCode = event.target.value;

      UsZipCodeService.search(filters).then((response) => {
        let newClient = { ...client };
        newClient.county = response.data?.data[0]?.countyName;
        setClient(newClient);
      });
    }
  };

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">
              {disableTabs != null && (
                <Header applicationId={applicationId} handleSaveAndExit={handleSaveAndExit} />
              )}

              {disableTabs != null && (
                <ApplicationsNewMenu
                  disableTabs={disableTabs}
                  applicationId={applicationId}
                  menuItemIndex="1"
                />
              )}

              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="mt-10 mb-10">
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Application has stopped</ModalHeader>
          <ModalBody>
            Application cannot continue because a business rule has applied. Some Tabs/actions are
            disabled.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <Row>
        <Col md="12" className="mx-auto bg-white box-shadow-border">
          <div className="p-10 mt-5 mb-10">
            {disableTabs != null && (
              <Header
                refreshFlags={refreshFlags}
                applicationId={applicationId}
                disableTabs={disableTabs}
                handleSaveAndExit={handleSaveAndExit}
              />
            )}

            {disableTabs != null && (
              <ApplicationsNewMenu
                disableTabs={disableTabs}
                applicationId={applicationId}
                menuItemIndex="1"
              />
            )}

            <form onSubmit={handleSubmit(handleFormSubmitted)} noValidate>
              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          Is the Plaintiff a new client? &nbsp;
                          <Label htmlFor="isNewClient">Yes</Label>
                          &nbsp;
                          <input
                            type="radio"
                            name="isNewClient"
                            defaultChecked={isNewClient ? 'checked' : undefined}
                            value={true}
                            onChange={() => setIsNewClient(true)}
                          />
                          &nbsp;
                          <Label htmlFor="isNewClient">No</Label>
                          &nbsp;
                          <input
                            type="radio"
                            name="isNewClient"
                            defaultChecked={
                              !isNewClient && isNewClient != undefined ? 'checked' : undefined
                            }
                            value={false}
                            onChange={() => setIsNewClient(false)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        {!isNewClient && isNewClient != undefined && client?.publicId && (
                          <Button
                            onClick={() => {
                              navigate(
                                '/underwriting/clients/' +
                                  client.publicId +
                                  '?fromNewApplication=' +
                                  applicationId,
                              );
                            }}
                          >
                            Edit Client
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {(isNewClient == true || (!isNewClient && isNewClient !== undefined)) && (
                      <div>
                        {!isNewClient && isNewClient !== undefined && (
                          <Row>
                            <Col md="8">
                              <FormGroup>
                                <ClientsAutoSuggest
                                  selectedClient={setSelectedClient}
                                  selectedClientName={() => {}}
                                  defaultClient={clientName}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col md="2">
                            <FormGroup>
                              <Label>Mr / Mrs</Label>
                              <select
                                name="title"
                                // required
                                defaultValue={client?.title || ''}
                                onChange={(selectedValue) => {
                                  if (selectedValue) {
                                    setClient({
                                      ...client,
                                      title: selectedValue.value,
                                    });
                                  }
                                }}
                                className="form-control"
                                {...register("title")}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                              >
                                <option value="">Please select a title</option>
                                <option key="mr" value="mr">
                                  Mr
                                </option>
                                <option key="mrs" value="mrs">
                                  Mrs
                                </option>
                                <option key="ms" value="ms">
                                  Ms
                                </option>
                                <option key="estateof" value="estateof">
                                  Estate Of
                                </option>
                              </select>
                              {errors.title && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="title" />
                                </Alert>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <div className="form-group">
                              <label htmlFor="firstName">First Name</label>
                              <input
                                type="text"
                                name="firstName"
                                maxLength="30"
                                className="form-control"
                                {...register("firstName",{
                                  required: 'First Name is required.',
                                  maxLength: { value: 30, message: 'Max length is 30 characters.' },
                                })}
                                defaultValue={client?.firstName || ''}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                              />
                              {errors.firstName && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="firstName" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="form-group">
                              <label htmlFor="lastName">Last Name</label>
                              <input
                                type="text"
                                name="lastName"
                                className="form-control"
                                maxLength="20"
                                {...register("lastName", {
                                  required: 'Last Name is required.',
                                  maxLength: { value: 20, message: 'Max length is 20 characters.' },
                                })}
                                defaultValue={client?.lastName || ''}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                              />
                              {errors.lastName && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="lastName" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="homePhone">Home Phone Number</label>
                              <input
                                type="tel"
                                name="homePhone"
                                className="form-control"
                                maxLength="20"
                                {...register("homePhone", {
                                  // required: 'Home Phone Number is required.',
                                  maxLength: { value: 20, message: 'Max length is 20 characters.' },
                                })}
                                defaultValue={client?.homePhone || ''}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                style={{ width: '250px' }}
                              />
                              {errors.homePhone && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="homePhone" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="mobilePhoneNo">Mobile Phone Number</label>
                              <input
                                type="tel"
                                name="mobilePhoneNo"
                                className="form-control"
                                maxLength="20"
                                {...register("mobilePhoneNo", {
                                  // required: 'Mobile Phone Number is required.',
                                  maxLength: { value: 20, message: 'Max length is 20 characters.' },
                                })}
                                defaultValue={client?.mobilePhoneNo || ''}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                style={{ width: '250px' }}
                              />
                              {errors.mobilePhoneNo && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="mobilePhoneNo" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="dob">Date of birth</label>
                              <input
                                type="date"
                                name="dob"
                                className="form-control"
                                // required="required"
                                {...register("dob")}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                defaultValue={client.dob === null ? null : client.dob.split('T')[0]}
                              />
                              {errors.dob && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="dob" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="ssLast4Digits">Last 4 digits of SS</label>
                              <input
                                type="text"
                                minLength="4"
                                maxLength="4"
                                inputMode="numeric"
                                name="ssLast4Digits"
                                className="form-control"
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                {...register("ssLast4Digits", {
                                  minLength: { value: 4, message: 'Four digits must be entered.' },
                                  maxLength: { value: 4, message: 'Max length is 4 characters.' },
                                  // required: 'Last 4 digits of SS are required.',
                                  pattern: {
                                    value: /^[0-9]*$/,
                                    message: 'Only numeric values allowed.',
                                  },
                                })}
                                defaultValue={client?.sslast4Digits || ''}
                              />
                              {errors.ssLast4Digits && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="ssLast4Digits" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="emailAddress">Email address</label>
                              <input
                                type="email"
                                name="emailAddress"
                                maxLength="50"
                                className="form-control"
                                {...register("emailAddress", {
                                  // required: 'Email address is required.',
                                  maxLength: { value: 50, message: 'Max length is 50 characters.' },
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address provided.',
                                  },
                                })}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                defaultValue={client?.emailAddress || ''}
                              />
                              {errors.emailAddress && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="emailAddress" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label htmlFor="streetAddress">Address</label>
                              <input
                                type="text"
                                name="streetAddress"
                                maxLength="30"
                                className="form-control"
                                {...register("streetAddress", {
                                  // required: 'Address is required.',
                                  maxLength: { value: 30, message: 'Max length is 30 characters.' },
                                })}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                defaultValue={client?.streetAddress || ''}
                              />
                              {errors.streetAddress && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="streetAddress" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="city">City</label>
                              <input
                                type="text"
                                name="city"
                                maxLength="30"
                                className="form-control"
                                // required="required"
                                {...register("city", {
                                  // required: 'City is required.',
                                  maxLength: { value: 30, message: 'Max length is 30 characters.' },
                                })}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                defaultValue={client?.city || ''}
                              />
                              {errors.city && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="city" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <StateSelect
                              name="stateCode"
                              label="State"
                              {...register("stateCode")}
                              defaultValue={client?.stateCode || ''}
                              setObject={setClient}
                              disabled={
                                !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                              }
                              givenObject={client}
                              errors={errors}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="zipCode">Zip Code</label>
                              <input
                                type="text"
                                inputMode="numeric"
                                maxLength="10"
                                name="zipCode"
                                className="form-control"
                                onKeyUp={getStateFromZipCode}
                                // required="required"
                                {...register("zipCode",{
                                  // required: 'Zip Code is required.',
                                  maxLength: { value: 10, message: 'Max length is 10 characters.' },
                                  pattern: {
                                    // value: /^\d{5}(?:[-\s]\d{4})?$/,
                                    message: 'The US Zip code provided is wrong.',
                                  },
                                })}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                defaultValue={client?.zipCode || ''}
                              />
                              {errors.zipCode && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="zipCode" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="county">County</label>
                              <input
                                type="text"
                                name="county"
                                maxLength="30"
                                className="form-control"
                                {...register("county", {
                                  // required: 'County Code is required.',
                                  maxLength: { value: 30, message: 'Max length is 30 characters.' },
                                })}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                defaultValue={client?.county || ''}
                              />
                              {errors.county && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="county" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label>
                                Has PLAINTIFF have any family maintenance obligations (e.g. child or{' '}
                                <br /> spousal support) or obligations to the goverment (e.g. social
                                services)?
                              </label>
                              <br />
                              <input
                                type="radio"
                                name="hasFamilyObligations"
                                value={true}
                                required="required"
                                {...register("hasFamilyObligations",)}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                defaultChecked={
                                  client?.hasFamilyObligations ? 'checked' : undefined
                                }
                              />
                              <label htmlFor="obligationsYes" className="ml-2 mr-2">
                                Yes
                              </label>
                              <input
                                type="radio"
                                name="hasFamilyObligations"
                                value={false}
                                required="required"
                                {...register("hasFamilyObligations")}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                defaultChecked={
                                  !client?.hasFamilyObligations &&
                                  client?.hasFamilyObligations != undefined
                                    ? 'checked'
                                    : undefined
                                }
                              />
                              <label htmlFor="obligationsNo" className="ml-2 mr-2">
                                No
                              </label>

                              {errors.hasFamilyObligations && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="hasFamilyObligations" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <div className="form-group">
                              <label>
                                Has PLAINTIFF declared bankruptcy or in the process of filling for{' '}
                                <br /> bankruptcy ?
                              </label>
                              <br />
                              <input
                                type="radio"
                                name="hasBankruptcy"
                                value={true}
                                {...register("hasBankruptcy")}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                required="required"
                                defaultChecked={client?.hasBankruptcy ? 'checked' : undefined}
                              />
                              <label htmlFor="bankruptcyYes" className="ml-2 mr-2">
                                Yes
                              </label>
                              <input
                                type="radio"
                                name="hasBankruptcy"
                                value={false}
                                {...register("hasBankruptcy")}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                required="required"
                                defaultChecked={
                                  !client?.hasBankruptcy && client?.hasBankruptcy != undefined
                                    ? 'checked'
                                    : undefined
                                }
                              />
                              <label htmlFor="bankruptcyYes" className="ml-2 mr-2">
                                No
                              </label>

                              {errors.hasBankruptcy && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="hasBankruptcy" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <FormGroup>
                              <Label htmlFor="notes">Notes</Label>
                              <textarea
                                name="notes"
                                rows="6"
                               {...register("notes")}
                                disabled={
                                  !isNewClient && isNewClient != undefined ? 'disabled' : undefined
                                }
                                defaultValue={client?.notes}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <button type="submit" className="btn btn-primary mr-5">
                              Save
                            </button>
                            <button
                              type="button"
                              onClick={handleCancelButtonClicked}
                              className="btn btn-secondary"
                            >
                              Cancel
                            </button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Plaintiff;
