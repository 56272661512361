import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CasesRequireReviewGrid from './CasesRequireReviewGrid';
import { Col, Row } from 'reactstrap';
import AppContext from '@contexts/AppContext';

const PayoffLetterRequests = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-portfolios').click();
    document
      .querySelector('#kt_header_tab_3 > div > div > ul > li:nth-child(3)')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  const createNewPayoffLetterRequest = () => {
    appContext.newAppllicationStep = 1;
    if (appContext.currentApplication) appContext.currentApplication.attorneyPublicId = undefined;

    navigate('/portfolios/payoff-letter-requests/new-portfolio');
  };

  return (
    <div className="container mt-10 mb-10" style={{ maxWidth: '95%' }}>
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div style={{ padding: '0.5rem' }}>
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Cases Require Review</h1>
              </div>
            </div>

            <Row>
              <Col md="12">
                <CasesRequireReviewGrid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoffLetterRequests;
