import React from 'react';

const user = JSON.parse(localStorage.getItem('lasuris-user'));
const userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
const currentApplication = JSON.parse(localStorage.getItem('currentApplication'));
const underwritingApplicationFilters = JSON.parse(
  localStorage.getItem('underwritingApplicationFilters'),
);
const underwritingClientFilters = JSON.parse(localStorage.getItem('underwritingClientFilters'));
const underwritingApplicationToBeReviewedFilters = JSON.parse(
  localStorage.getItem('underwritingApplicationToBeReviewedFilters'),
);
const lawFirmDetailFilters = JSON.parse(localStorage.getItem('lawFirmDetailFilters'));
const payoffLetterRequestsFilters = JSON.parse(localStorage.getItem('payoffLetterRequestsFilters'));
const concessionDiaryFilters = JSON.parse(localStorage.getItem('concessionDiaryFilters'));
const casesRequireReviewFilters = JSON.parse(localStorage.getItem('casesRequireReviewFilters'));
const payoffsFilters = JSON.parse(localStorage.getItem('payoffsFilters'));
const payoffRequestsFilters = JSON.parse(localStorage.getItem('payoffRequestsFilters'));
const casesToBeAdvancedFilters = JSON.parse(localStorage.getItem('casesToBeAdvancedFilters'));
const accountingCasesToBeAdvancedFilters = JSON.parse(
  localStorage.getItem('accountingCasesToBeAdvancedFilters'),
);
const activeAdvanceReceivablesFilters = JSON.parse(
  localStorage.getItem('activeAdvanceReceivablesFilters'),
);
const accountsActiveAdvanceReceivablesFilters = JSON.parse(
  localStorage.getItem('accountsActiveAdvanceReceivablesFilters'),
);
const accountingActiveAdvanceReceivableFilters = JSON.parse(
  localStorage.getItem('accountingActiveAdvanceReceivableFilters'),
);
const accountsLawFirmContactsFilters = JSON.parse(
  localStorage.getItem('accountsLawFirmContactsFilters'),
);
const accountingPayoffsFilters = JSON.parse(localStorage.getItem('accountingPayoffsFilters'));
const accountsAccountFindingFilters = JSON.parse(
  localStorage.getItem('accountsAccountFindingFilters'),
);
const accountsAccountManagerPipelineFilters = JSON.parse(
  localStorage.getItem('accountsAccountManagerPipelineFilters'),
);
// REPORTS CONTEXTS
const lawFirmSummaryReportFilters = JSON.parse(localStorage.getItem('lawFirmSummaryReportFilters'));
const accountManagerDetailsReportFilters = JSON.parse(
  localStorage.getItem('accountManagerDetailsReportFilters'),
);
const accountManagerSummaryReportFilters = JSON.parse(
  localStorage.getItem('accountManagerSummaryReportFilters'),
);
const payoffStaticPoolAnalysisReportFilters = JSON.parse(
  localStorage.getItem('payoffStaticPoolAnalysisReportFilters'),
);
const accidentSummaryReportFilters = JSON.parse(
  localStorage.getItem('accidentSummaryReportFilters'),
);
const attorneyDataReportFilters = JSON.parse(localStorage.getItem('attorneyDataReportFilters'));
const financialSummaryReportFilters = JSON.parse(
  localStorage.getItem('financialSummaryReportFilters'),
);
const financialCenterReportFilters = JSON.parse(
  localStorage.getItem('financialCenterReportFilters'),
);

const presentValueReportFilters = JSON.parse(localStorage.getItem('presentValueReportFilters'));

const commissionReportFilters = JSON.parse(localStorage.getItem('commissionReportFilters'));
const caseStatusUpdateActivityFilters = JSON.parse(
  localStorage.getItem('caseStatusUpdateActivityFilters'),
);

const applicationCaseStatuses = [
  'Answer Received',
  'Appeal',
  'Arbitration (binding in NY)',
  'Bill of Particulars filed',
  'Closed Case',
  'Compliance Conference',
  'Default Motion Filed- No Answer',
  'Dismiss or SJ',
  "EBT's Held",
  "EBT's Scheduled",
  'Expert Witness',
  'ICO (infant compromise order)',
  'Inquest Hearing',
  'Judgement (For/against)',
  "Liability IME's held",
  'Mediation',
  'Motions',
  'NOI FILED',
  'PC Conference (Preliminary)',
  'Pre Trial Conference',
  'Remanded',
  'Stayed',
  'Summons & Complaint Filed/Index',
  'Trial',
];

const AppContext = React.createContext({
  isUserAuthenticated: false,
  user,
  userPermissions,
  applicationCaseStatuses,
  currentApplication: currentApplication || {},
  toastMessage: { message: '', messageType: '' },
  underwritingApplicationFilters: underwritingApplicationFilters || {},
  accountingActiveAdvanceReceivableFilters: accountingActiveAdvanceReceivableFilters || {},
  underwritingClientFilters: underwritingClientFilters || {},
  underwritingApplicationToBeReviewedFilters: underwritingApplicationToBeReviewedFilters || {},
  lawFirmDetailFilters: lawFirmDetailFilters || {},
  payoffLetterRequestsFilters: payoffLetterRequestsFilters || {},
  concessionDiaryFilters: concessionDiaryFilters || {},
  casesRequireReviewFilters: casesRequireReviewFilters || {},
  activeAdvanceReceivablesFilters: activeAdvanceReceivablesFilters || {},
  accountsActiveAdvanceReceivablesFilters: accountsActiveAdvanceReceivablesFilters || {},
  payoffsFilters: payoffsFilters || {},
  payoffRequestsFilters: payoffRequestsFilters || {},
  casesToBeAdvancedFilters: casesToBeAdvancedFilters || {},
  accountingCasesToBeAdvancedFilters: accountingCasesToBeAdvancedFilters || {},
  accountsLawFirmContactsFilters: accountsLawFirmContactsFilters || {},
  accountingPayoffsFilters: accountingPayoffsFilters || {},
  accountsAccountManagerPipelineFilters: accountsAccountManagerPipelineFilters || {},
  accountsAccountFindingFilters: accountsAccountFindingFilters || {},

  // REPORTS CONTEXTS
  lawFirmSummaryReportFilters: lawFirmSummaryReportFilters || {},
  accountManagerDetailsReportFilters: accountManagerDetailsReportFilters || {},
  accountManagerSummaryReportFilters: accountManagerSummaryReportFilters || {},
  payoffStaticPoolAnalysisReportFilters: payoffStaticPoolAnalysisReportFilters || {},
  accidentSummaryReportFilters: accidentSummaryReportFilters || {},
  attorneyDataReportFilters: attorneyDataReportFilters || {},
  financialSummaryReportFilters: financialSummaryReportFilters || {},
  financialCenterReportFilters: financialCenterReportFilters || {},
  presentValueReportFilters: presentValueReportFilters || {},
  commissionReportFilters: commissionReportFilters || {},
  caseStatusUpdateActivityFilters: caseStatusUpdateActivityFilters || {},
});

export default AppContext;
