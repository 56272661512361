/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Alert,
  FormGroup,
  Container,
} from 'reactstrap';
import ApplicationCoverageService from '@services/ApplicationCoverageService';
import ApplicationsService from '@services/ApplicationsService';
import ApplicationAccidentInfoService from '@services/ApplicationAccidentInfoService';
import StateMinimumInsuranceService from '@services/StateMinimumInsuranceService';
import ApplicationFlagService from '@services/ApplicationFlagService';
import ApplicationsNewMenu from '../Menu';
import AppContext from '@contexts/AppContext';
import Header from '../Header';
import ReactLoading from 'react-loading';
import { FormattedAmount } from '@components/Utils/FormattedAmount';

const Coverage = (props) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const applicationNumber = props.match.params.applicationId;

  const [minimumInsuranceRequired, setMinimumInsuranceRequired] = useState();
  const [minimumInsuranceRequiredIfDiseased, setMinimumInsuranceRequiredIfDiseased] = useState();

  const [accidentStateCode, setAccidentStateCode] = useState();
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [isFirstAttorney, setIsFirstAttorney] = useState(undefined);
  const [hasAllegations, setHasAllegations] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [hasLostWages, setHasLostWages] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [insuranceProvidedSettlementOffer, setInsuranceProvidedSettlementOffer] = useState(false);
  const [supportPaymentsObligation, setSupportPaymentsObligation] = useState(false);
  const [disableTabs, setDisableTabs] = useState(false);
  const [defendandInfoMultipleError, setDefendandInfoMultipleError] = useState(undefined);
  const [coverage, setCoverage] = useState({});
  const [accidentType, setAccidentType] = useState(null);
  const [modal, setModal] = useState(false);
  const { applicationId } = useParams();

  const toggle = () => setModal(!modal);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setDefendandInfoMultipleError(false);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let coverageUpdated = { ...coverage };
    coverageUpdated[name] = value;
    setCoverage(coverageUpdated);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, {}]);
  };

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;

    if (!applicationId || applicationId == 'undefined') {
      appContext.toastMessage.message = 'Application No was not found!';
      navigate('/underwriting/applications/new-application/');
      return;
    }

    if (applicationNumber) {
      setIsLoading(true);

      ApplicationFlagService.searchExact(
        { applicationNo: applicationId, flagNameExact: 'Application Stopped' },
        null,
        null,
        1,
        null,
        1,
      )
        .then((response) => {
          if (response?.data?.data[0]?.flagName === 'Application Stopped') {
            setDisableTabs(1);
            setIsLoading(false);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });

      setIsLoading(true);

      ApplicationFlagService.searchExact(
        { applicationNo: applicationId, flagNameExact: 'Application Stopped2' },
        null,
        null,
        1,
        null,
        1,
      )
        .then((response) => {
          if (response?.data?.data[0]?.flagName === 'Application Stopped2') {
            setDisableTabs(2);
            setIsLoading(false);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });

      setIsLoading(true);

      ApplicationFlagService.searchExact(
        { applicationNo: applicationId, flagNameExact: 'Application Stopped3' },
        null,
        null,
        1,
        null,
        1,
      )
        .then((response) => {
          if (response?.data?.data[0]?.flagName === 'Application Stopped3') {
            setDisableTabs(3);
            setIsLoading(false);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });

      setIsLoading(true);

      // business rule here
      ApplicationAccidentInfoService.search({ applicationNo: applicationNumber }, 1, {})
        .then((response) => {
          if (response.data.data.length === 0) {
            appContext.toastMessage.message =
              'There are missing required data. You cannot continue to this tab.';
            appContext.toastMessage.type = 'error';
            setIsLoading(false);
            navigate('/underwriting/applications/' + applicationNumber + '/accident');
            return;
          }

          setAccidentType(response?.data?.data[0]?.accidentTypePublicId);
          setAccidentStateCode(response?.data?.data[0]?.accidentStateCode);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });

      setIsLoading(true);

      ApplicationCoverageService.search({ applicationNo: applicationNumber }, 1, {})
        .then((response) => {
          if (response?.data?.data[0] != undefined) {
            setCoverage(response.data.data[0]);

            setInputList(response?.data?.data[0]?.applicationCoverageDefentantInsuranceInfoes);

            // fire events programmatically for hidden select boxes
            if (response?.data?.data[0]?.firstAttorneyonFile === false) {
              let event = new MouseEvent('click', { bubbles: true });
              var element = document.querySelector('#firstAttorneyonFileNo');
              if (element != null) element.dispatchEvent(event);
            }

            if (response?.data?.data[0]?.hasAllegationsofContributoryNegligence === 'Yes') {
              let event = new Event('change', { bubbles: true });
              var element = document.querySelector('#hasAllegationsofContributoryNegligence');
              if (element != null) element.dispatchEvent(event);
            }

            if (response?.data?.data[0]?.hasLostWages === 'Yes') {
              let event = new Event('change', { bubbles: true });
              var element = document.querySelector('#hasLostWages');
              if (element != null) element.dispatchEvent(event);
            }

            if (response?.data?.data[0]?.hasInsuranceProvidedSettlementOffer === 'Yes') {
              let event = new Event('change', { bubbles: true });
              var element = document.querySelector('#hasInsuranceProvidedSettlementOffer');
              if (element != null) element.dispatchEvent(event);
            }

            if (response?.data?.data[0]?.supportPaymentObligation === 'Yes') {
              let event = new Event('change', { bubbles: true });
              var element = document.querySelector('#supportPaymentObligation');
              if (element != null) element.dispatchEvent(event);
            }

            if (response?.data?.data[0]?.applicationCoverageDefentantInsuranceInfoes.length === 0) {
              setInputList([]);
            }

            setIsLoading(false);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (accidentStateCode != '') {
      StateMinimumInsuranceService.search({ stateCode: accidentStateCode }, null, null, 1, {}, 1)
        .then((response) => {
          setMinimumInsuranceRequired(response.data.data[0].amount);
          setMinimumInsuranceRequiredIfDiseased(response.data.data[0].amountifDiseased);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [accidentStateCode]);

  useEffect(() => {
    if (coverage.firstAttorneyonFile) setIsFirstAttorney(coverage.firstAttorneyonFile);
  }, [coverage]);

  const handleSaveAndExit = () => {
    document.querySelector('button.btn.btn-primary.mr-5').click();
  };

  const handlehasLostWages = (e) => {
    let updatedCoverage = { ...coverage };
    updatedCoverage.hasLostWages = e.target.value;
    setCoverage(updatedCoverage);

    e.target.value === 'Yes' ? setHasLostWages(true) : setHasLostWages(false);
  };

  const handleInsuranceProvidedSettlementOffer = (e) => {
    let updatedCoverage = { ...coverage };
    updatedCoverage.hasInsuranceProvidedSettlementOffer = e.target.value;
    setCoverage(updatedCoverage);

    e.target.value === 'Yes'
      ? setInsuranceProvidedSettlementOffer(true)
      : setInsuranceProvidedSettlementOffer(false);
  };

  const handleSupportPaymentsObligation = (e) => {
    let updatedCoverage = { ...coverage };
    updatedCoverage.supportPaymentObligation = e.target.value;
    setCoverage(updatedCoverage);

    e.target.value === 'Yes'
      ? setSupportPaymentsObligation(true)
      : setSupportPaymentsObligation(false);
  };

  const handlehasAllegationsofContributoryNegligence = (e) => {
    let updatedCoverage = { ...coverage };
    updatedCoverage.hasAllegationsofContributoryNegligence = e.target.value;
    setCoverage(updatedCoverage);

    e.target.value === 'Yes' ? setHasAllegations(true) : setHasAllegations(false);
  };

  const handleFormSubmitted = (data) => {
    setIsLoading(true);

    if (!(appContext?.userPermissions?.indexOf('Edit Coverage Info') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      setIsLoading(false);
      appContext.toastMessage.message = null;
      return;
    }

    ApplicationsService.search({ applicationNo: applicationNumber }, null, null, 1, {}, 10)
      .then((response) => {
        data.applicationPublicId = response.data.data[0].publicId;
        data.firstAttorneyonFile = data.firstAttorneyonFile === 'true' ? true : false;
        data.approxLitigationCostsAmount = parseFloat(data.approxLitigationCostsAmount);
        data.supportPaymentObligationAmount = parseFloat(data.supportPaymentObligationAmount);
        data.estimatedSettlementValue = parseFloat(data.estimatedSettlementValue);
        data.expectedLiens = parseFloat(data.expectedLiens);
        data.attorneyFeePerc = parseFloat(data.attorneyFeePerc);
        data.lostWagesAmount = parseFloat(data.lostWagesAmount);
        data.hasInsuranceProvidedSettlementOfferAmount = parseFloat(
          data.hasInsuranceProvidedSettlementOfferAmount,
        );

        var list = [...inputList];

        let shouldExit = false;

        list.forEach((item) => {
          if (
            !item.hasOwnProperty('claimNo') ||
            !item.hasOwnProperty('coverageAmount') ||
            !item.hasOwnProperty('defentantName') ||
            !item.hasOwnProperty('insuranceCompanyName')
          ) {
            setDefendandInfoMultipleError('There are missing values');
            shouldExit = true;
          }
        });

        if (shouldExit) {
          setIsLoading(false);
          return;
        }

        list.forEach((item) => {
          item.coverageAmount = parseFloat(item.coverageAmount);
        });

        data.applicationCoverageDefentantInsuranceInfoes = list;

        if (!coverage.hasOwnProperty('publicId')) {
          ApplicationCoverageService.create(data)
            .then((response) => {
              appContext.toastMessage.message = 'Your changes has been saved sucessfully.';

              // business rule here
              if (
                (accidentType == '2469b696-9388-3435-5399-68b8d3008546' &&
                  data.hasDefentantInsCompanyAdmittedLiability === 'No') ||
                (accidentType == '2469b696-9388-3435-5399-68b8d3008546' &&
                  data.isThereaStatuteofLimitation === 'Yes')
              ) {
                ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1)
                  .then((response1) => {
                    // search to see if there is already a flag. create a flag if no flag found
                    ApplicationFlagService.searchExact(
                      { applicationNo: applicationId },
                      null,
                      null,
                      1,
                      null,
                      1,
                    )
                      .then((response) => {
                        if (response?.data?.data[0]?.flagName != 'Application Stopped3') {
                          let requestObject = {
                            applicationPublicId: response1?.data?.data[0].publicId,
                            isCustomFlag: true,
                            flagName: 'Application Stopped3',
                          };

                          ApplicationFlagService.create(requestObject)
                            .then((response2) => {
                              setDisableTabs(3);
                              setIsLoading(false);
                              toggle();
                              return;
                            })
                            .catch((err) => {
                              setIsLoading(false);
                            });
                        } else {
                          setDisableTabs(3);
                          setIsLoading(false);
                          toggle();
                          return;
                        }
                      })
                      .catch((err) => {
                        setIsLoading(false);
                      });
                  })
                  .catch((err) => {
                    setIsLoading(false);
                  });
              } else {
                // delete flag if exists
                ApplicationFlagService.searchExact(
                  { applicationNo: applicationId, flagNameExact: 'Application Stopped3' },
                  null,
                  null,
                  1,
                  null,
                  1,
                )
                  .then((response) => {
                    if (response?.data?.data[0]?.flagName === 'Application Stopped3') {
                      ApplicationFlagService.delete(response?.data?.data[0]?.publicId)
                        .then((response2) => {
                          setDisableTabs(false);
                          setIsLoading(false);
                          navigate('/underwriting/applications/' + applicationId + '/misc');
                          return;
                        })
                        .catch((err) => {
                          setIsLoading(false);
                        });
                    } else {
                      setDisableTabs(false);
                      setIsLoading(false);
                      navigate('/underwriting/applications/' + applicationId + '/misc');
                      return;
                    }
                  })
                  .catch((err) => {
                    setIsLoading(false);
                  });
              }
            })
            .catch((err) => {
              setIsLoading(false);
            });
        } else {
          data.publicId = coverage.publicId;

          ApplicationCoverageService.update(data)
            .then((response) => {
              appContext.toastMessage.message = 'Your changes has been saved sucessfully.';

              // business rule here
              if (
                (accidentType == '2469b696-9388-3435-5399-68b8d3008546' &&
                  data.hasDefentantInsCompanyAdmittedLiability === 'No') ||
                (accidentType == '2469b696-9388-3435-5399-68b8d3008546' &&
                  data.isThereaStatuteofLimitation === 'Yes')
              ) {
                ApplicationsService.search({ applicationNo: applicationId }, null, null, 1, {}, 1)
                  .then((response1) => {
                    // search to see if there is already a flag. create a flag if no flag found
                    ApplicationFlagService.searchExact(
                      { applicationNo: applicationId },
                      null,
                      null,
                      1,
                      null,
                      1,
                    )
                      .then((response) => {
                        if (response?.data?.data[0]?.flagName != 'Application Stopped3') {
                          let requestObject = {
                            applicationPublicId: response1?.data?.data[0].publicId,
                            isCustomFlag: true,
                            flagName: 'Application Stopped3',
                          };

                          ApplicationFlagService.create(requestObject)
                            .then((response2) => {
                              setDisableTabs(3);
                              setIsLoading(false);
                              toggle();
                              return;
                            })
                            .catch((err) => {})
                            .finally(() => {
                              setIsLoading(false);
                            });
                        } else {
                          setDisableTabs(3);
                          setIsLoading(false);
                          toggle();
                          return;
                        }
                      })
                      .catch((err) => {})
                      .finally(() => {
                        setIsLoading(false);
                      });
                  })
                  .catch((err) => {})
                  .finally(() => {
                    setIsLoading(false);
                  });
              } else {
                // delete flag if exists
                ApplicationFlagService.searchExact(
                  { applicationNo: applicationId, flagNameExact: 'Application Stopped3' },
                  null,
                  null,
                  1,
                  null,
                  1,
                )
                  .then((response) => {
                    if (response?.data?.data[0]?.flagName === 'Application Stopped3') {
                      ApplicationFlagService.delete(response?.data?.data[0]?.publicId)
                        .then((response2) => {
                          setDisableTabs(false);
                          setIsLoading(false);
                          navigate('/underwriting/applications/' + applicationId + '/misc');
                          return;
                        })
                        .catch((err) => {})
                        .finally(() => {
                          setIsLoading(false);
                        });
                    } else {
                      setDisableTabs(false);
                      setIsLoading(false);
                      navigate('/underwriting/applications/' + applicationId + '/misc');
                      return;
                    }
                  })
                  .catch((err) => {})
                  .finally(() => {
                    setIsLoading(false);
                  });
              }
            })
            .catch((err) => {})
            .finally(() => {
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelButtonClicked = () => {
    navigate('/underwriting/applications');
  };

  if (isLoading) {
    return (
      <Container className="mt-10 mb-10">
        <Row>
          <Col md="12" className="mx-auto bg-white box-shadow-border">
            <div className="p-10 mt-5 mb-10">
              ''
              {disableTabs != null && (
                <Header applicationId={applicationId} handleSaveAndExit={handleSaveAndExit} />
              )}
              {disableTabs != null && (
                <ApplicationsNewMenu
                  disableTabs={disableTabs}
                  applicationId={applicationId}
                  menuItemIndex="4"
                />
              )}
              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <ReactLoading type="bars" color="#7E8299" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="container mt-10 mb-10">
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Application has stopped</ModalHeader>
          <ModalBody>
            Application cannot continue because a business rule has applied. Some Tabs/actions are
            disabled.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              <div>
                {disableTabs != null && (
                  <Header
                    disableTabs={disableTabs}
                    applicationId={applicationNumber}
                    handleSaveAndExit={handleSaveAndExit}
                  />
                )}

                {disableTabs != null && (
                  <ApplicationsNewMenu
                    disableTabs={disableTabs}
                    applicationId={applicationNumber}
                    menuItemIndex="4"
                  />
                )}

                <form onSubmit={handleSubmit(handleFormSubmitted)} noValidate>
                  <Container className="p-0">
                    <Row>
                      <Col md="12">
                        <Row>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="firstAttorneyonFile">
                                Are you the first attorney on this file?
                              </label>
                              <br />
                              <label className="ml-2 mr-2">
                                <input
                                  type="radio"
                                  name="firstAttorneyonFile"
                                  ref={register({ required: 'A value is required.' })}
                                  value={true}
                                  checked={isFirstAttorney === true}
                                  onClick={() => setIsFirstAttorney(true)}
                                  required="required"
                                />
                                &nbsp; Yes
                              </label>
                              <label className="ml-2 mr-2">
                                <input
                                  type="radio"
                                  name="firstAttorneyonFile"
                                  id="firstAttorneyonFileNo"
                                  ref={register({ required: 'A value is required.' })}
                                  value={false}
                                  checked={isFirstAttorney === false}
                                  onClick={() => setIsFirstAttorney(false)}
                                  required="required"
                                />
                                &nbsp; No
                              </label>
                              {errors.firstAttorneyonFile && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="firstAttorneyonFile" />
                                </Alert>
                              )}
                            </div>
                          </div>

                          <div className="col-md-4">
                            {!isFirstAttorney && isFirstAttorney !== undefined && (
                              <div className="form-group">
                                <label htmlFor="previousLawFirm">Previous Law Firm</label>
                                <input
                                  type="text"
                                  name="previousLawFirm"
                                  defaultValue={coverage.previousLawFirm}
                                  ref={register({ required: 'A value is required.' })}
                                  className="form-control"
                                  required="required"
                                />
                                {errors.previousLawFirm && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="previousLawFirm" />
                                  </Alert>
                                )}
                              </div>
                            )}
                          </div>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="whenFileopened">
                                When was this file opened at your firm?
                              </label>
                              <select
                                name="whenFileopened"
                                value={coverage.whenFileopened}
                                onChange={handleChange}
                                ref={register({ required: 'A value is required.' })}
                                className="form-control"
                              >
                                <option value="">Please select a value</option>
                                <option value="Less than 1 Month">Less than 1 Month</option>
                                <option value="1 to 2 Months">1 to 2 Months</option>
                                <option value="3 to 5 Months">3 to 5 Months</option>
                                <option value="6 to 23 Months">6 to 23 Months</option>
                                <option value="24 to 47 Months">24 to 47 Months</option>
                                <option value="More than 48 Months">More than 48 Months</option>
                                <option value="Not Answered" selected>
                                  Not Answered
                                </option>
                              </select>
                              {errors.whenFileopened && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="whenFileopened" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="caseStatus">
                                Case Status &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp;{' '}
                              </label>
                              <select
                                name="caseStatus"
                                ref={register({ required: 'A value is required.' })}
                                value={coverage.caseStatus}
                                onChange={handleChange}
                                className="form-control"
                              >
                                <option value="">Please select a value</option>
                                <option value="At Trial">At Trial</option>
                                <option value="Bill of Particulars Completed">
                                  Bill of Particulars Completed
                                </option>
                                <option value="Client Treating">Client Treating</option>
                                <option value="Examination Before Trial (EBT) Completed">
                                  Examination Before Trial (EBT) Completed
                                </option>
                                <option value="Not Answered" selected>
                                  Not Answered
                                </option>
                                <option value="Note of Issue Filed">Note of Issue Filed</option>
                                <option value="On Appeal">On Appeal</option>
                                <option value="Other">Other</option>
                                <option value="Pre-Suit/Claims Level">Pre-Suit/Claims Level</option>
                                <option value="Settled">Settled</option>
                                <option value="Settlement Discussions &/or Pre-Trial">
                                  Settlement Discussions &/or Pre-Trial
                                </option>
                                <option value="Summons & Complaint Filed">
                                  Summons & Complaint Filed
                                </option>
                                <option value="Trial Scheduled">Trial Scheduled</option>
                              </select>
                              {errors.caseStatus && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="caseStatus" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="caseInvolveWorkersCompensationBoard">
                                Does this case involve the Worker&apos;s Compensation Board? &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                              </label>
                              <br />
                              <select
                                name="caseInvolveWorkersCompensationBoard"
                                ref={register({ required: 'A value is required.' })}
                                value={coverage.caseInvolveWorkersCompensationBoard}
                                onChange={handleChange}
                                className="form-control"
                              >
                                <option value="">Please select a value</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Not Known at this time" selected>
                                  Not Known at this time
                                </option>
                                <option value="Not Answered">Not Answered</option>
                              </select>
                              {errors.caseInvolveWorkersCompensationBoard && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage
                                    errors={errors}
                                    name="caseInvolveWorkersCompensationBoard"
                                  />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            {/* <div className="form-group">
                              <label htmlFor="hasDefentantInsCompanyAdmittedLiability">
                                Has the DEFENTANT or their insurance company admitted liability?
                              </label>
                              <br />
                              <select
                                name="hasDefentantInsCompanyAdmittedLiability"
                                ref={register({ required: 'A value is required.' })}
                                value={coverage.hasDefentantInsCompanyAdmittedLiability}
                                onChange={handleChange}
                                className="form-control"
                              >
                                <option value="">Please select a value</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Not Known at this time" selected>
                                  Not Known at this time
                                </option>
                                <option value="Not Answered">Not Answered</option>
                              </select>
                              {errors.hasDefentantInsCompanyAdmittedLiability && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage
                                    errors={errors}
                                    name="hasDefentantInsCompanyAdmittedLiability"
                                  />
                                </Alert>
                              )}
                            </div> */}
                          </Col>
                        </Row>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="hasAllegationsofContributoryNegligence">
                                Are there allegations of contributory/comparative negligence?
                              </label>
                              <br />
                              <select
                                name="hasAllegationsofContributoryNegligence"
                                ref={register({ required: 'A value is required.' })}
                                id="hasAllegationsofContributoryNegligence"
                                value={coverage.hasAllegationsofContributoryNegligence}
                                onChange={(e) => handlehasAllegationsofContributoryNegligence(e)}
                                className="form-control"
                              >
                                <option value="">Please select a value</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Not Known at this time" selected>
                                  Not Known at this time
                                </option>
                                <option value="Not Answered">Not Answered</option>
                              </select>
                              {errors.hasAllegationsofContributoryNegligence && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage
                                    errors={errors}
                                    name="hasAllegationsofContributoryNegligence"
                                  />
                                </Alert>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            {hasAllegations && (
                              <div className="form-group">
                                <label htmlFor="hasAllegationsofContributoryNegligenceExplain">
                                  Explain
                                </label>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp;
                                <input
                                  type="text"
                                  name="hasAllegationsofContributoryNegligenceExplain"
                                  value={coverage.hasAllegationsofContributoryNegligenceExplain}
                                  onChange={handleChange}
                                  ref={register({ required: 'A value is required.' })}
                                  className="form-control"
                                  required="required"
                                />
                                {errors.hasAllegationsofContributoryNegligenceExplain && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage
                                      errors={errors}
                                      name="hasAllegationsofContributoryNegligenceExplain"
                                    />
                                  </Alert>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="hasDemandLeeterSent">
                                Has a Demand Letter Been sent? &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp;
                              </label>
                              <br />
                              <select
                                name="hasDemandLeeterSent"
                                className="form-control"
                                value={coverage.hasDemandLeeterSent}
                                onChange={handleChange}
                                ref={register({ required: 'A value is required.' })}
                              >
                                <option value="">Please select a value</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Not Known at this time" selected>
                                  Not Known at this time
                                </option>
                                <option value="Not Answered">Not Answered</option>
                              </select>
                              {errors.hasDemandLeeterSent && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="hasDemandLeeterSent" />
                                </Alert>
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <label htmlFor="isThereaStatuteofLimitation">
                                Is there a statute of limitation issue <br />
                                with respect to this claim (e.g. 2 years from accident)?
                              </label>
                              <br />
                              <select
                                name="isThereaStatuteofLimitation"
                                value={coverage.isThereaStatuteofLimitation}
                                onChange={handleChange}
                                ref={register({ required: 'A value is required.' })}
                                className="form-control"
                              >
                                <option value="">Please select a value</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Not Known at this time" selected>
                                  Not Known at this time
                                </option>
                                <option value="Not Answered">Not Answered</option>
                              </select>
                              {errors.isThereaStatuteofLimitation && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage
                                    errors={errors}
                                    name="isThereaStatuteofLimitation"
                                  />
                                </Alert>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <h4 className="mt-5">STATE: {accidentStateCode}</h4>
                            <p>
                              {' '}
                              Minimum Insurance Required:{' '}
                              {minimumInsuranceRequired &&
                                FormattedAmount.format(
                                  parseFloat(minimumInsuranceRequired).toFixed(2),
                                )}
                            </p>
                            <p>
                              {' '}
                              Minimum Insurance Required (if diseased):{' '}
                              {minimumInsuranceRequiredIfDiseased &&
                                FormattedAmount.format(
                                  parseFloat(minimumInsuranceRequiredIfDiseased).toFixed(2),
                                )}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8" className="mt-10">
                            <Row>
                              <Col md="4">
                                <legend>Defentant Insurance Info</legend>
                              </Col>
                              <Col md="4">
                                <button
                                  name="addButton"
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={handleAddClick}
                                  style={{
                                    backgroundColor: 'var(--green)',
                                    borderColor: 'var(--green)',
                                  }}
                                >
                                  Add
                                </button>
                              </Col>
                            </Row>

                            <div className="form-group form-inline">
                              <div>
                                {inputList.map((x, i) => {
                                  return (
                                    <div className="form-group mb-10 form-inline" key={x.publicId}>
                                      <div className="row">
                                        <div className="col-md-3">
                                          <div className="form-group">
                                            <label htmlFor="defentantName">
                                              Defentant&apos;s name
                                            </label>
                                            <input
                                              type="text"
                                              name="defentantName"
                                              className="form-control"
                                              defaultValue={x.defentantName}
                                              onChange={(e) => handleInputChange(e, i)}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          <div className="form-group">
                                            <label htmlFor="insuranceCompanyName">
                                              Insurance Co.
                                            </label>
                                            <input
                                              type="text"
                                              name="insuranceCompanyName"
                                              className="form-control"
                                              defaultValue={x.insuranceCompanyName}
                                              onChange={(e) => handleInputChange(e, i)}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          <div className="form-group">
                                            <label htmlFor="coverageAmount">Coverage</label>
                                            <input
                                              type="number"
                                              name="coverageAmount"
                                              className="form-control"
                                              defaultValue={x.coverageAmount}
                                              pattern="/^\d{1,}(\.\d{0,2})?$/"
                                              onChange={(e) => handleInputChange(e, i)}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-2">
                                          <div className="form-group">
                                            <label htmlFor="claimNo">Claim #</label>
                                            <input
                                              type="text"
                                              name="claimNo"
                                              className="form-control"
                                              defaultValue={x.claimNo}
                                              onChange={(e) => handleInputChange(e, i)}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-3 form-inline">
                                          <button
                                            name="removeButton"
                                            type="button"
                                            className="btn btn-secondary mt-6"
                                            onClick={() => handleRemoveClick(i)}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                                {defendandInfoMultipleError && (
                                  <Alert color="danger" className="mt-5">
                                    {defendandInfoMultipleError}
                                  </Alert>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <fieldset>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="hasLostWages">Lost Wages</label>
                                <br />
                                <select
                                  name="hasLostWages"
                                  id="hasLostWages"
                                  ref={register({ required: 'A value is required.' })}
                                  value={coverage.hasLostWages}
                                  className="form-control"
                                  onChange={(e) => handlehasLostWages(e)}
                                >
                                  <option value="">Please select a value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="Not Known at this time" selected>
                                    Not Known at this time
                                  </option>
                                  <option value="Not Answered">Not Answered</option>
                                </select>
                                {errors.hasLostWages && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="hasLostWages" />
                                  </Alert>
                                )}
                              </div>
                            </div>

                            {hasLostWages && (
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <label htmlFor="lostWagesAmount">Amount</label>
                                      <input
                                        type="number"
                                        ref={register(
                                          { required: 'A value is required.' },
                                          { pattern: /^[0-9]+(\.[0-9]{1,2})?$/ },
                                        )}
                                        name="lostWagesAmount"
                                        value={coverage.lostWagesAmount}
                                        onChange={handleChange}
                                        required="required"
                                        pattern="/^\d{1,}(\.\d{0,2})?$/"
                                        ref={register({
                                          required: 'A value is required.',
                                          pattern: {
                                            value: /^\d{1,}(\.\d{0,2})?$/,
                                            message:
                                              'Only numeric values with 2 decimals are allowed.',
                                          },
                                        })}
                                        className="form-control"
                                      />
                                      {errors.lostWagesAmount && (
                                        <Alert color="danger" className="mt-5">
                                          <ErrorMessage errors={errors} name="lostWagesAmount" />
                                        </Alert>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-3"></div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="hasInsuranceProvidedSettlementOffer">
                                  Has Insurance Co. provided a settlement offer?
                                </label>
                                <br />
                                <select
                                  name="hasInsuranceProvidedSettlementOffer"
                                  ref={register({ required: 'A value is required.' })}
                                  id="hasInsuranceProvidedSettlementOffer"
                                  value={coverage.hasInsuranceProvidedSettlementOffer}
                                  onChange={(e) => handleInsuranceProvidedSettlementOffer(e)}
                                  className="form-control"
                                >
                                  <option value="">Please select a value</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="Not Known at this time" selected>
                                    Not Known at this time
                                  </option>
                                  <option value="Not Answered">Not Answered</option>
                                </select>
                                {errors.hasInsuranceProvidedSettlementOffer && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage
                                      errors={errors}
                                      name="hasInsuranceProvidedSettlementOffer"
                                    />
                                  </Alert>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4">
                              {insuranceProvidedSettlementOffer && (
                                <div className="form-group">
                                  <label For="hasInsuranceProvidedSettlementOfferAmount">
                                    Amount
                                  </label>
                                  <input
                                    name="hasInsuranceProvidedSettlementOfferAmount"
                                    value={coverage.hasInsuranceProvidedSettlementOfferAmount}
                                    onChange={handleChange}
                                    ref={register({
                                      required: 'A value is required.',
                                      pattern: {
                                        value: /^\d{1,}(\.\d{0,2})?$/,
                                        message: 'Only numeric values with 2 decimals are allowed.',
                                      },
                                    })}
                                    type="number"
                                    pattern="/^\d{1,}(\.\d{0,2})?$/"
                                    className="form-control"
                                  />
                                  {errors.hasInsuranceProvidedSettlementOfferAmount && (
                                    <Alert color="danger" className="mt-5">
                                      <ErrorMessage
                                        errors={errors}
                                        name="hasInsuranceProvidedSettlementOfferAmount"
                                      />
                                    </Alert>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <Row>
                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="settlementtExpected">Settlement Expected</label>
                                <select
                                  name="settlementtExpected"
                                  className="form-control"
                                  value={coverage.settlementtExpected}
                                  onChange={handleChange}
                                  ref={register({ required: 'A value is required.' })}
                                  required="required"
                                >
                                  <option value="">Please select a value</option>
                                  <option value="0-6 months">0-6 months</option>
                                  <option value="6-12 months">6-12 months</option>
                                  <option value="12-18 months">12-18 months</option>
                                  <option value="18-30 months">18-30 months</option>
                                  <option value="More than 30 months">More than 30 months</option>
                                  <option value="More than 18 months">More than 18 months</option>
                                  <option value="Not Answered" selected>
                                    Not Answered
                                  </option>
                                </select>
                                {errors.settlementtExpected && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage errors={errors} name="settlementtExpected" />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <label htmlFor="approxLitigationCostsAmount">
                                  Approx. Litigation Costs To-Date
                                </label>
                                <input
                                  type="number"
                                  name="approxLitigationCostsAmount"
                                  value={coverage.approxLitigationCostsAmount || 0}
                                  onChange={handleChange}
                                  ref={register({
                                    required: 'A value is required.',
                                    pattern: {
                                      value: /^\d{1,}(\.\d{0,2})?$/,
                                      message: 'Only numeric values with 2 decimals are allowed.',
                                    },
                                  })}
                                  className="form-control"
                                  pattern="/^\d{1,}(\.\d{0,2})?$/"
                                />
                                {errors.approxLitigationCostsAmount && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage
                                      errors={errors}
                                      name="approxLitigationCostsAmount"
                                    />
                                  </Alert>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </fieldset>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="supportPaymentObligation">
                                Support Payments Obligation
                              </label>
                              <br />
                              <select
                                name="supportPaymentObligation"
                                ref={register({ required: 'A value is required.' })}
                                id="supportPaymentObligation"
                                value={coverage.supportPaymentObligation}
                                onChange={handleChange}
                                onChange={(e) => handleSupportPaymentsObligation(e)}
                                className="form-control"
                              >
                                <option value="">Please select a value</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Not Known at this time" selected>
                                  Not Known at this time
                                </option>
                                <option value="Not Answered">Not Answered</option>
                              </select>
                              {errors.supportPaymentObligation && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="supportPaymentObligation" />
                                </Alert>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            {supportPaymentsObligation && (
                              <div className="form-group">
                                <label htmlFor="supportPaymentObligationAmount">Amount</label>
                                <input
                                  name="supportPaymentObligationAmount"
                                  type="number"
                                  value={coverage.supportPaymentObligationAmount || 0}
                                  onChange={handleChange}
                                  ref={register({
                                    required: 'A value is required.',
                                    pattern: {
                                      value: /^\d{1,}(\.\d{0,2})?$/,
                                      message: 'Only numeric values with 2 decimals are allowed.',
                                    },
                                  })}
                                  min="0"
                                  className="form-control"
                                  pattern="/^\d{1,}(\.\d{0,2})?$/"
                                  ref={register({
                                    required: 'A value is required.',
                                    pattern: {
                                      value: /^\d{1,}(\.\d{0,2})?$/,
                                      message: 'Only numeric values with 2 decimals are allowed.',
                                    },
                                  })}
                                  required="required"
                                />
                                {errors.supportPaymentObligationAmount && (
                                  <Alert color="danger" className="mt-5">
                                    <ErrorMessage
                                      errors={errors}
                                      name="supportPaymentObligationAmount"
                                    />
                                  </Alert>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="estimatedSettlementValue">
                                <strong>Estimated Settlement Value</strong>
                              </label>
                              <input
                                type="number"
                                name="estimatedSettlementValue"
                                value={coverage.estimatedSettlementValue || 0}
                                onChange={handleChange}
                                ref={register({
                                  required: 'A value is required.',
                                  pattern: {
                                    value: /^\d{1,}(\.\d{0,2})?$/,
                                    message: 'Only numeric values with 2 decimals are allowed.',
                                  },
                                })}
                                className="form-control"
                                min="0"
                                pattern="/^\d{1,}(\.\d{0,2})?$/"
                              />
                              {errors.estimatedSettlementValue && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="estimatedSettlementValue" />
                                </Alert>
                              )}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="attorneyFeePerc">
                                <strong>Attorney Fee Percentage (%)</strong>
                              </label>
                              <input
                                name="attorneyFeePerc"
                                type="number"
                                value={coverage.attorneyFeePerc || 0}
                                onChange={handleChange}
                                ref={register({
                                  required: 'A value is required.',
                                  pattern: {
                                    value: /^\d{1,}(\.\d{0,2})?$/,
                                    message: 'Only numeric values with 2 decimals are allowed.',
                                  },
                                })}
                                className="form-control"
                                min="0"
                                pattern="/^\d{1,}(\.\d{0,2})?$/"
                              />
                              {errors.attorneyFeePerc && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="attorneyFeePerc" />
                                </Alert>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="expectedLiens">
                                <strong>Expected Liens</strong>
                              </label>
                              <input
                                type="number"
                                name="expectedLiens"
                                value={coverage.expectedLiens || 0}
                                onChange={handleChange}
                                ref={register({
                                  required: 'A value is required.',
                                  pattern: {
                                    value: /^\d{1,}(\.\d{0,2})?$/,
                                    message: 'Only numeric values with 2 decimals are allowed.',
                                  },
                                })}
                                className="form-control"
                                min="0"
                                pattern="/^\d{1,}(\.\d{0,2})?$/"
                              />
                              {errors.expectedLiens && (
                                <Alert color="danger" className="mt-5">
                                  <ErrorMessage errors={errors} name="expectedLiens" />
                                </Alert>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="approvedCaseValue">
                                <strong>Approved Case Value</strong>
                              </label>
                              <input
                                type="number"
                                name="approvedCaseValue"
                                value={coverage.approvedCaseValue}
                                className="form-control"
                                readOnly
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <button type="submit" className="btn btn-primary mr-5">
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={handleCancelButtonClicked}
                          className="btn btn-secondary"
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coverage;
