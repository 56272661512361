import React, { useContext, useEffect, useState } from 'react';
import FlagIcon from '@mui/icons-material/Flag';
import ApplicationsHeaderService from '@services/ApplicationsHeaderService';
import ApplicationsService from '@services/ApplicationsService';
import GridService from '@services/GridService';
import AppContext from '@contexts/AppContext';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { CloseOutlined } from '@mui/icons-material';
import { FormattedAmount } from '@components/Utils/FormattedAmount';
import {
  Button,
  Alert,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import ApplicationFlagService from '@services/ApplicationFlagService';
import ToastHeader from 'reactstrap/lib/ToastHeader';

const Header = (props) => {
  const appContext = useContext(AppContext);
  const [flagString, setFlagString] = useState(
    appContext.currentApplication && Object.keys(appContext.currentApplication).length > 0
      ? appContext.currentApplication.flagString
      : '',
  );
  const [client, setClient] = useState(
    appContext.currentApplication &&
      appContext.currentApplication &&
      Object.keys(appContext.currentApplication).length > 0
      ? appContext.currentApplication.client
      : '',
  );

  const [accountManagerName, setAccountManagerName] = useState('');
  const [attorneyName, setAttorneyName] = useState('');

  const [flagModal, setFlagModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [systemFlags, setSystemFlags] = useState(
    appContext.currentApplication && Object.keys(appContext.currentApplication).length > 0
      ? appContext.currentApplication.systemFlags
      : [],
  );
  const [customFlags, setCustomFlags] = useState(
    appContext.currentApplication && Object.keys(appContext.currentApplication).length > 0
      ? appContext.currentApplication.customFlags
      : [],
  );
  const [customFlag, setCustomFlag] = useState(null);
  const [applicationId, setApplicationId] = useState(
    appContext.currentApplication && Object.keys(appContext.currentApplication).length > 0
      ? appContext.currentApplication.publicId
      : null,
  );
  const [approvalModal, setApprovalModal] = useState(false);
  const [linkCasesModal, setLinkCasesModal] = useState(false);
  const [moveAdvanceModal, setMoveAdvanceModal] = useState(false);

  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const [historicalCaseValueModal, setHistoricalCaseValueModal] = useState(false);
  const [denialModal, setDenialModal] = useState(false);
  const [denialComments, setDenialComments] = useState('');
  const [linkCasesValues, setLinkCasesValues] = useState(null);

  const [advanceNo, setAdvanceNo] = useState(null);
  const [applicationNo, setApplicationNo] = useState(null);

  const [uecv, setUecv] = useState(0.0);
  const [statusList, setStatusList] = useState([]);
  const [statusNote, setStatusNote] = useState('');

  const [applicationStatus, setApplicationStatus] = useState(
    appContext.currentApplication && Object.keys(appContext.currentApplication).length > 0
      ? appContext.currentApplication.applicationStatus
      : null,
  );
  const [status, setStatus] = useState('Draft');
  const [fromStatus, setFromStatus] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [estimatedCaseValueError, setEstimatedCaseValueError] = useState(false);

  const [HCVValues, setHCVValues] = useState(null);
  const [historicalCaseValuePerc, setHistoricalCaseValuePerc] = useState(0.5);

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const toggleStatusModal = () => {
    setStatusModal(!statusModal);
    document.getElementById('action').selectedIndex = 0;
  };

  const toggleLinkCaseModal = () => {
    setLinkCasesValues(null);
    setLinkCasesModal(!linkCasesModal);
    document.getElementById('action').selectedIndex = 0;
  };

  const toggleMoveAdvanceModal = () => {
    // setLinkCasesValues(null);
    setMoveAdvanceModal(!moveAdvanceModal);
    document.getElementById('action').selectedIndex = 0;
  };

  const toggleConfirmationModal = () => {
    setIsOpenConfirmationModal(!isOpenConfirmationModal);
  };

  const toggleFlagModal = () => {
    setFlagModal(!flagModal);
    document.getElementById('action').selectedIndex = 0;
  };

  const toggleApproval = () => {
    setApprovalModal(!approvalModal);
    document.getElementById('action').selectedIndex = 0;
  };

  const toggleDenial = () => {
    setDenialModal(!denialModal);
    document.getElementById('action').selectedIndex = 0;
  };

  const toggleHistoricalCaseValueModal = () => {
    setHistoricalCaseValueModal(!historicalCaseValueModal);
    document.getElementById('action').selectedIndex = 0;
  };

  const calculateHistoricalCaseValue = () => {
    ApplicationsService.calculateHCV(
      appContext.currentApplication.publicId,
      historicalCaseValuePerc,
    ).then((response) => {
      setHCVValues(response?.data);
    });
  };

  useEffect(() => {
    if (approvalModal == true) {
      GridService.search(
        '/ApplicationCoverage/',
        { applicationNo: props.applicationId },
        null,
        null,
        1,
        null,
        10,
      )
        .then((response) => {
          if (!response.data) return;
          if (response.data.data.length > 0) {
            setUecv(response.data.data[0].estimatedSettlementValue);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [approvalModal]);

  useEffect(() => {
    if (props.applicationId !== undefined) {
      //Flag String
      ApplicationsHeaderService.read(props.applicationId)
        .then((resp) => {
          // var applData = resp.data.data[0];
          var applData = resp.data;
          if (!applData) return;

          appContext.currentApplication = applData;
          if (applData.flags != undefined) {
            var flags = applData.flags.split(',');
            let tempFlagString = '';
            flags.map((item) => {
              tempFlagString += ` ${item}, `;
            });
            tempFlagString = tempFlagString.replace(/,\s*$/, '');
            setFlagString(tempFlagString);
            appContext.currentApplication.flagString = tempFlagString;
          }
          if (applData.client != null) {
            setClient(applData.client);
          }
          if (applData.publicId) {
            setApplicationId(applData.publicId);
          }
          if (applData.applicationStatus) {
            setApplicationStatus(applData.applicationStatus);
          }
          if (applData.accountManagerName) {
            setAccountManagerName(applData.accountManagerName);
          }
          if (applData.attorneyName) {
            setAttorneyName(applData.attorneyName);
          }

          //ApplicationFlags
          let endpoint = '/ApplicationFlag/';
          GridService.search(
            endpoint,
            { applicationPublicId: applData.publicId },
            '',
            '',
            1,
            null,
            10,
          )
            .then((resp) => {
              if (resp.data != undefined) {
                let data = resp.data.data;
                let customFlags = [];
                let systemFlags = [];
                if (data.length > 0) {
                  data.map((item) => {
                    if (item.isCustomFlag === true) {
                      customFlags.push(item);
                      setCustomFlags(customFlags);
                    } else {
                      systemFlags.push(item);
                      setSystemFlags(systemFlags);
                    }
                  });
                }
                appContext.currentApplication.systemFlags = systemFlags;
                appContext.currentApplication.customFlags = customFlags;
              }
            })
            .catch((err) => {})
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }

    //Get status list
    GridService.read('/Application/applicationstatuses')
      .then((resp) => {
        if (!resp.data) return;
        if (resp.data.length > 0) {
          setStatusList(resp.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function onchangeStatus() {
    const action = document.getElementById('status');
    const selectedStatus = action.options[action.selectedIndex].value;
    setStatus(selectedStatus);
  }

  const changeAction = () => {
    const action = document.getElementById('action');
    const selectedAction = action.options[action.selectedIndex].value;

    switch (selectedAction) {
      case 'actions': {
        setFlagModal(false);
        setApprovalModal(false);
        setDenialModal(false);
        setLinkCasesModal(false);
        setMoveAdvanceModal(false);
        break;
      }

      case 'denial': {
        if (!(appContext?.userPermissions?.indexOf('Decline') > -1)) {
          appContext.toastMessage.message = 'You do not have permissions to perform this action.';
          toast.error(appContext.toastMessage.message, { autoClose: 3000 });
          appContext.toastMessage.message = null;
          return;
        }

        setFlagModal(false);
        setApprovalModal(false);
        setDenialModal(true);
        setStatusModal(false);
        setLinkCasesModal(false);
        setMoveAdvanceModal(false);
        break;
      }
      case 'changeStatus': {
        if (!(appContext?.userPermissions?.indexOf('Change Status') > -1)) {
          appContext.toastMessage.message = 'You do not have permissions to perform this action.';
          toast.error(appContext.toastMessage.message, { autoClose: 3000 });
          appContext.toastMessage.message = null;
          return;
        }

        setFlagModal(false);
        setStatusModal(true);
        setMoveAdvanceModal(false);
        break;
      }
      case 'review': {
        if (!(appContext?.userPermissions?.indexOf('Send to Review') > -1)) {
          appContext.toastMessage.message = 'You do not have permissions to perform this action.';
          toast.error(appContext.toastMessage.message, { autoClose: 3000 });
          appContext.toastMessage.message = null;
          return;
        }

        //API Call
        let params = {
          publicId: applicationId,
        };
        GridService.create('/Application/applicationsendtoreview', params)
          .then((resp) => {
            if (!resp.data) return;
            setApplicationStatus(resp.data.applicationStatus);
            document.getElementById('action').selectedIndex = 0;
            appContext.toastMessage.message = 'Send to review successfully.';
            toast.success(appContext.toastMessage.message, { autoClose: 3000 });
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });

        setFlagModal(false);
        setApprovalModal(false);
        setDenialModal(false);
        setMoveAdvanceModal(false);
        break;
      }
      case 'markAsFlagged': {
        setIsLoading(true);

        setFlagModal(false);
        setApprovalModal(false);
        setDenialModal(false);
        setMoveAdvanceModal(false);

        var requestObject = {
          applicationPublicId: applicationId,
          isCustomFlag: true,
          flagName: 'Flag for Review',
        };

        ApplicationFlagService.create(requestObject)
          .then((response) => {
            toast.success('Application marked as "Flagged" successfully.', { autoClose: 3000 });
            setFlagString(flagString + ', Flag for Review');
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      }
      case 'manageFlags': {
        if (!(appContext?.userPermissions?.indexOf('View Flags') > -1)) {
          appContext.toastMessage.message = 'You do not have permissions to perform this action.';
          toast.error(appContext.toastMessage.message, { autoClose: 3000 });
          appContext.toastMessage.message = null;
          return;
        }

        setApprovalModal(false);
        setDenialModal(false);
        setFlagModal(true);
        setStatusModal(false);
        setLinkCasesModal(false);
        setMoveAdvanceModal(false);
        break;
      }
      case 'linkCases': {
        if (!(appContext?.userPermissions?.indexOf('Add a new Application') > -1)) {
          appContext.toastMessage.message = 'You do not have permissions to perform this action.';
          toast.error(appContext.toastMessage.message, { autoClose: 3000 });
          appContext.toastMessage.message = null;
          return;
        }

        setApprovalModal(false);
        setDenialModal(false);
        setFlagModal(false);
        setStatusModal(false);
        setLinkCasesModal(true);
        setMoveAdvanceModal(false);
        break;
      }
      case 'moveAdvance': {
        if (!(appContext?.userPermissions?.indexOf('Add a new Application') > -1)) {
          appContext.toastMessage.message = 'You do not have permissions to perform this action.';
          toast.error(appContext.toastMessage.message, { autoClose: 3000 });
          appContext.toastMessage.message = null;
          return;
        }

        setApprovalModal(false);
        setDenialModal(false);
        setFlagModal(false);
        setStatusModal(false);
        setLinkCasesModal(false);
        setMoveAdvanceModal(true);
        break;
      }
      default: {
        return null;
      }
    }
  };

  //Delete custom flags
  const handleCustomFlags = (customItem, index) => {
    if (!(appContext?.userPermissions?.indexOf('Delete Flags') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    let tempCustomFlags = [...customFlags];
    let endpoint = `/ApplicationFlag/${customItem.publicId}`;

    GridService.delete(endpoint)
      .then((res) => {
        let updatedFlagString = flagString.replace(customItem.flagName, '');
        setFlagString(updatedFlagString.trim());
        appContext.currentApplication.flagString = updatedFlagString.trim();
        tempCustomFlags.splice(index, 1);
        setCustomFlags(tempCustomFlags);
        appContext.currentApplication.customFlags = tempCustomFlags;
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  //Add custom flags
  const save = () => {
    if (!(appContext?.userPermissions?.indexOf('Add Flags') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    if (customFlag != (null && '')) {
      let params = {
        flagName: customFlag,
        applicationPublicId: applicationId,
        isCustomFlag: true,
      };
      let endpoint = '/ApplicationFlag';
      GridService.create(endpoint, params)
        .then((res) => {
          if (!res.data) return;
          //Add custom flag
          let tempCustomFlags = [...customFlags];
          let tempFlagString = flagString;
          tempFlagString = `${tempFlagString} ${customFlag} `;
          setFlagString(tempFlagString.trim());
          appContext.currentApplication.flagString = tempFlagString.trim();
          tempCustomFlags.push(res.data);
          setCustomFlags(tempCustomFlags);
          appContext.currentApplication.customFlags = tempCustomFlags;
          setCustomFlag('');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const denyApplication = () => {
    if (!(appContext?.userPermissions?.indexOf('Decline') > -1)) {
      appContext.toastMessage.message = 'You do not have permissions to perform this action.';
      toast.error(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
      return;
    }

    let params = {
      applicationPublicId: applicationId,
      isApproved: false,
      note: denialComments,
      userPublicId: appContext.user.publicId,
    };

    // let endpoint = '/Application/applicationapproval';

    // GridService.create(endpoint, params)
    //   .then((resp) => {
    //     if (!resp.data) return;
    //     setApplicationStatus(resp.data.applicationStatus);
    //     toggleDenial();
    //     setDenialComments(null);
    //     appContext.toastMessage.message = 'Application denied successfully';
    //     toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    //   })
    //   .catch((err) => {})
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  const changeLinkCases = () => {
    if (!linkCasesValues || linkCasesValues === '') {
      setLinkCasesValues('');
      return;
    }

    let santitisedString = linkCasesValues.replace(/ /g, '');
    let params = {
      applicationPublicId: applicationId,
      linkCasesApplicationNos: santitisedString,
    };

    let endpoint = '/Application/applicationlinkedcases';

    setIsLoading(true);
    GridService.create(endpoint, params)
      .then((resp) => {
        if (!resp.data) return;
        toggleLinkCaseModal();
        setLinkCasesValues(null);
        appContext.toastMessage.message = 'Cases linked successfully';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const moveAdvanceFunc = () => {
    if (!applicationNo || !advanceNo) {
      return;
    }

    let params = {
      applicationNo: applicationNo,
      applicationAdvanceNo: advanceNo,
    };

    let endpoint = '/ApplicationAdvance/moveAdvance';

    setIsLoading(true);
    GridService.create(endpoint, params)
      .then((resp) => {
        if (!resp.data) return;
        toggleMoveAdvanceModal();
        setApplicationNo(null);
        setAdvanceNo(null);
        appContext.toastMessage.message = 'Advance moved successfully';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const confirmAction = () => {
    if (fromStatus === 'changeLinkCases') changeLinkCases();
    if (fromStatus === 'moveAdvance') moveAdvanceFunc();
    if (fromStatus === 'changeStatus') changeStatus();
    if (fromStatus === 'denyApplication') denyApplication();

    toggleConfirmationModal();
  };

  const openConfirmationModal = (status) => {
    setFromStatus(status);
    toggleConfirmationModal();
  };

  //change status save
  function changeStatus() {
    let params = {
      applicationPublicId: applicationId,
      userPublicId: appContext.user.publicId,
      applicationStatus: status,
      note: statusNote,
    };

    let endpoint = '/Application/applicationstatus';

    GridService.create(endpoint, params)
      .then((resp) => {
        if (!resp.data) return;
        setApplicationStatus(resp.data.applicationStatus);
        toggleStatusModal(!statusModal);
        appContext.toastMessage.message = 'Status changed successfully';
        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {}, [props.disableTabs != null]);

  return (
    <div className="mb-5">
      <div className="row">
        <div className="col-md-6">
          <h1 style={{ lineHeight: 'unset', marginBottom: '0.1rem' }}>
            {props.applicationId !== undefined
              ? `${client ? `${client} - ` : ''} ${props.applicationId}`
              : 'New Application'}
          </h1>

          <span style={{ color: 'black', marginBottom: '0.5rem' }}>
            {attorneyName}, {accountManagerName}
          </span>
          <br></br>
          {props.applicationId !== undefined && flagString != '' && flagString != null && (
            <span style={{ color: 'orange' }}>
              <FlagIcon />
              {flagString}
            </span>
          )}
        </div>
        <div className="col-md-6">
          {/* <div className="float-right" style={{ marginRight: "18%" }}> */}
          <div
            className="row"
            style={{ justifyContent: 'flex-end', alignItems: 'center', marginRight: '0px' }}
          >
            <span className="mr-5" style={{ color: 'var(--red)' }}>
              {applicationStatus}
            </span>
            {props.applicationId !== undefined && (
              <select
                className="form-control"
                style={{ width: '30%' }}
                onChange={changeAction}
                onSubmit={changeAction}
                id="action"
              >
                <option value="actions">Actions</option>
                {/* <option value="approve" {...(props.disableTabs === true && { disabled: 'true' })}>
                  Approve
                </option> */}
                <option value="denial" {...(props.disableTabs === true && { disabled: 'true' })}>
                  Unable to approve
                </option>
                <option
                  value="changeStatus"
                  {...(props.disableTabs === true && { disabled: 'true' })}
                >
                  Change Status
                </option>
                <option value="review" {...(props.disableTabs === true && { disabled: 'true' })}>
                  Send to Review
                </option>
                <option
                  {...(props.disableTabs === true && { disabled: 'true' })}
                  value="markAsFlagged"
                >
                  Mark as "Flagged"
                </option>
                <option value="manageFlags">Manage Flags</option>
                <option value="linkCases">Link Cases</option>
                <option value="moveAdvance">Move Advance</option>
              </select>
            )}
            <div>
              {
                <Modal isOpen={flagModal} toggle={toggleFlagModal}>
                  <ModalBody>
                    <div>
                      {systemFlags?.length > 0 && (
                        <FormControl>
                          <FormLabel style={{ fontWeight: 'bold', color: 'black' }}>
                            System Flags
                          </FormLabel>
                          <FormGroup>
                            {systemFlags.map((system, index) => {
                              return (
                                <div
                                  className="row"
                                  style={{
                                    paddingLeft: '12px',
                                    paddingRight: '12px',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <p>{system.flagName}</p>
                                </div>
                              );
                            })}
                          </FormGroup>
                        </FormControl>
                      )}
                    </div>
                    <div style={{ width: '100%' }}>
                      {customFlags?.length > 0 && (
                        <FormControl style={{ width: '50%' }}>
                          <FormLabel style={{ fontWeight: 'bold', color: 'black' }}>
                            Custom Flags
                          </FormLabel>
                          <FormGroup>
                            {customFlags.map((cust, index) => {
                              return (
                                <>
                                  <div
                                    className="row"
                                    style={{
                                      paddingLeft: '12px',
                                      paddingRight: '12px',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <p>{cust.flagName}</p>
                                    <CloseOutlined
                                      onClick={() => handleCustomFlags(cust, index)}
                                      style={{ borderRadius: '100px', border: '1px solid' }}
                                    />
                                  </div>
                                </>
                              );
                            })}
                          </FormGroup>
                        </FormControl>
                      )}
                    </div>
                    <div
                      style={
                        customFlags?.length > 0
                          ? {
                              paddingTop: '10px',
                              width: '100%',
                              borderTop: '1px solid',
                            }
                          : {}
                      }
                    >
                      <span style={{ marginTop: '5px' }}>Add New Flag</span>
                      <div
                        className="row"
                        style={{ justifyContent: 'space-between', padding: '12px' }}
                      >
                        <input
                          className="form-control"
                          style={{ width: '70%' }}
                          value={customFlag}
                          maxLength={20}
                          onChange={(e) => setCustomFlag(e.target.value)}
                        />
                        <button style={{ width: '20%' }} className="btn btn-primary" onClick={save}>
                          Add
                        </button>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                      <button
                        onClick={toggleFlagModal}
                        className="btn"
                        style={{ marginLeft: '5px', backgroundColor: '#ccc' }}
                      >
                        Close
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>
              }

              <Modal
                size="lg"
                isOpen={historicalCaseValueModal}
                toggle={toggleHistoricalCaseValueModal}
              >
                <ModalHeader>Historical Case Values</ModalHeader>
                <ModalBody id="historicalCaseValueModal">
                  <div className="row">
                    <div
                      className="col-md-1"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <label>Perc</label>
                    </div>
                    <div className="col-md-2">
                      <input
                        name="historicalCaseValuePerc"
                        defaultValue={0.5}
                        className="form-control"
                        onChange={(e) => setHistoricalCaseValuePerc(e.target.value)}
                      />
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => calculateHistoricalCaseValue()}
                    >
                      Calculate
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {HCVValues == '' ? (
                        <div className="col-md-6 mt-5 p-0">
                          <Alert color="danger">No data found.</Alert>
                        </div>
                      ) : undefined}
                      {HCVValues?.map((item, index) => (
                        <div className="mt-10" style={{ overflowY: 'scroll' }}>
                          <div>
                            Historical Case Value - {item.specificInjuryName}
                            <br />
                            <strong>
                              {FormattedAmount.format(
                                parseFloat(item.historicalCaseValue).toFixed(2),
                              )}
                            </strong>
                          </div>
                          <Table striped>
                            <thead>
                              <tr>
                                <th>Application #</th>
                                <th>Client age</th>
                                <th>County</th>
                                <th>Gender</th>
                                <th>Case Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.historicalCases.map((item2, index2) => (
                                <tr>
                                  <td>{item2?.applicationNo}</td>
                                  <td>{item2?.clientAge}</td>
                                  <td>{item2?.county}</td>
                                  <td>{item2?.gender}</td>
                                  <td>
                                    {FormattedAmount.format(
                                      parseFloat(item2?.caseValue).toFixed(2),
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      ))}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      toggleHistoricalCaseValueModal();
                      toggleApproval();
                    }}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={denialModal} toggle={toggleDenial}>
                <ModalHeader>Decline</ModalHeader>
                <ModalBody>
                  <p>Comments</p>
                  <textarea
                    className="form-control"
                    style={{ width: '100%', resize: 'none' }}
                    value={denialComments}
                    onChange={(e) => setDenialComments(e.target.value)}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    //onClick={denyApplication}
                    onClick={(_) => openConfirmationModal('denyApplication')}
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggleDenial}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={statusModal} toggle={toggleStatusModal}>
                <ModalBody style={{ width: '70%' }}>
                  <p>New Status</p>
                  <select id="status" className="form-control" onChange={onchangeStatus}>
                    {statusList.map((status, index) => {
                      return (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      );
                    })}
                  </select>
                  <p style={{ marginTop: '5px' }}>Note</p>
                  <textarea
                    className="form-control"
                    style={{ resize: 'none' }}
                    value={statusNote}
                    className="form-control"
                    onChange={(e) => setStatusNote(e.target.value)}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    //onClick={changeStatus}
                    onClick={(_) => openConfirmationModal('changeStatus')}
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggleStatusModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={linkCasesModal} toggle={toggleLinkCaseModal}>
                <ModalHeader>Link Cases</ModalHeader>

                {isLoading && (
                  <ModalBody>
                    <ReactLoading type="bars" color="#7E8299" />
                  </ModalBody>
                )}

                {!isLoading && (
                  <>
                    <ModalBody>
                      <p>
                        <textarea
                          className="form-control"
                          style={{ width: '100%', resize: 'none' }}
                          value={linkCasesValues}
                          onChange={(e) => setLinkCasesValues(e.target.value)}
                        />
                        {linkCasesValues === '' && (
                          <Alert color="danger" className="mt-5">
                            <p>Value cannot be empty!</p>
                          </Alert>
                        )}
                      </p>

                      <p>
                        Enter Application Numbers (NOT Advance Nunbers ) comma separated, such as:
                        1000,10001 with no spaces.
                        <br />
                        All advances for each Application No entered will be moved to the
                        application currently loaded.
                      </p>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        // onClick={changeLinkCases}
                        onClick={(_) => openConfirmationModal('changeLinkCases')}
                        disabled={!linkCasesValues || linkCasesValues === ''}
                      >
                        Save
                      </Button>
                      <Button color="secondary" onClick={toggleLinkCaseModal}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </Modal>

              <Modal isOpen={isOpenConfirmationModal} toggle={toggleConfirmationModal}>
                <ModalHeader>Confirmation</ModalHeader>
                <ModalBody>
                  <p>
                    Are you sure you want to proceed!
                    <br />
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={confirmAction}>
                    Yes
                  </Button>
                  <Button color="secondary" onClick={toggleConfirmationModal}>
                    No
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={moveAdvanceModal} toggle={toggleMoveAdvanceModal}>
                <ModalHeader>Move Advance</ModalHeader>

                {isLoading && (
                  <ModalBody>
                    <ReactLoading type="bars" color="#7E8299" />
                  </ModalBody>
                )}

                {!isLoading && (
                  <>
                    <ModalBody>
                      <Label htmlFor="advanceNo">Advance No</Label>
                      <input
                        name="advanceNo"
                        className="form-control"
                        value={advanceNo}
                        maxLength={20}
                        onChange={(e) => setAdvanceNo(e.target.value)}
                      />
                      <p>
                        Please enter the 'Advance No' of the advance you want to move to the
                        application below.
                      </p>

                      <Label htmlFor="applicationNo">Application No</Label>
                      <input
                        name="applicationNo"
                        className="form-control"
                        value={applicationNo}
                        maxLength={20}
                        onChange={(e) => setApplicationNo(e.target.value)}
                      />
                      <p>
                        Please enter the 'Application No' that the advance above will be moved to.
                      </p>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        color="primary"
                        // onClick={changeLinkCases}
                        onClick={(_) => openConfirmationModal('moveAdvance')}
                        disabled={!advanceNo || applicationNo === ''}
                      >
                        Move
                      </Button>
                      <Button color="secondary" onClick={toggleMoveAdvanceModal}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </Modal>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Header;
