import React, { useEffect } from 'react';
import Grid from './Grid';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const TypesOfAccidentsSummary = () => {
  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-reports').click();
    document.querySelector('#kt_header_menu > ul > li').classList.add('menu-item-active');
  };

  const exportFile = () => {
    //Fetch the exported file
  };

  useEffect(() => makeMenuItemActive(), []);

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border p-5">
          <Row>
            <Col md="12">
              <Grid />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default TypesOfAccidentsSummary;
