import React, { useEffect, useContext, useRef, useState } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import Grid from './Grid';
import { MultiSelect } from 'react-multi-select-component';
import AttorneyService from '@services/AttorneyService';
import AttorneyAutoSuggest from '@components/AttorneyAutoSuggest/AttorneyAutoSuggest';
import LawFirmAutoSuggest from '@components/LawFirmAutoSuggest/LawFirmAutoSuggest';
import ClientsAutoSuggest from '@components/ClientsAutoSuggest/ClientsAutoSuggest';
import PortfolioService from '@services/PortfolioService';
import GridService from '@services/GridService';

import './Styles.css';

const FilteringFields = (props) => {
  let dateFilter = useRef();
  let statusFilter = useRef();
  let caseStatusFilter = useRef();
  let lawFirmFilter = useRef();

  const clearFilters = () => {
    setDateValue('This month');
    dateFilter.current.value = 'This month';
    appContext.accountsAccountFindingFilters = {};
    setDaterange(false);
    setFromDate(null);
    setToDate(null);
    setStatusValue([]);
    setCaseStatus([]);
    setPortfolio([]);
    setSelectedLawFirm({});
    props.getAll();
  };
  const [caseStatus, setCaseStatus] = useState([]);
  const [statusValue, setStatusValue] = useState([]);
  const [caseStatusOptions, setCaseStatusOptions] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [dateRange, setDaterange] = useState(false);
  const [dateValue, setDateValue] = useState('This month');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [attorney, setAttorney] = useState({
    displayName: '',
    attorneyPublicId: '',
  });
  const [selectedLawFirm, setSelectedLawFirm] = useState({});
  const [appStatusOptions, setAppStatusOptions] = useState([]);

  function changeFunc() {
    const dateFilter = document.getElementById('dateFilter');
    const selectedDate = dateFilter.options[dateFilter.selectedIndex].value;
    if (selectedDate === 'Custom') {
      setDaterange(true);
      setDateValue(null);
    } else {
      setDaterange(false);
      setDateValue(selectedDate);
      setFromDate(null);
      setToDate(null);
    }
  }

  function changeFromDate() {
    const fromDate = document.getElementById('fromDate');
    const selectedFromDate = fromDate.value;
    setFromDate(selectedFromDate);
  }

  function changeToDate() {
    const toDate = document.getElementById('toDate');
    const selectedToDate = toDate.value;
    if (fromDate < selectedToDate) {
      setToDate(selectedToDate);
    } else {
      if (fromDate != null) {
      } else {
        alert('Select from date first');
      }
    }
  }

  function selectDateRange() {
    if (dateValue != null) {
      return dateValue;
    } else {
      if (fromDate != null && toDate != null) {
        let dateRange = {
          fromDate: new Date(fromDate).toISOString(),
          toDate: new Date(toDate).toISOString(),
        };
        return dateRange;
      } else {
        return '';
      }
    }
  }

  const appContext = useContext(AppContext);
  const accountsAccountFindingFilters = appContext.accountsAccountFindingFilters;

  useEffect(() => {
    const isFiltersEmpty = Object.keys(accountsAccountFindingFilters).length == 0;
    //Get application status options
    GridService.read('/Application/applicationstatuses').then((resp) => {
      if (!resp.data) return;
      if (resp.data.length > 0) {
        let tempStatusOptions = [];
        resp.data.map((item) => {
          tempStatusOptions.push({
            label: item,
            value: item,
          });
          setAppStatusOptions(tempStatusOptions);
        });
        if (
          props.defaultApplicationStatus &&
          props.defaultApplicationStatus !== '' &&
          props.defaultApplicationStatus.length > 0
        ) {
          setStatusValue([]);
          props.defaultApplicationStatus.map((stat) => {
            let newItem = {
              label: stat,
              value: stat,
            };
            setStatusValue((statusValue) => [...statusValue, newItem]);
          });
        }
      }
    });

    //Get case status options

    let tempCaseStatusOptions = [];

    appContext.applicationCaseStatuses.map((item) => {
      tempCaseStatusOptions.push({
        label: item,
        value: item,
      });
    });

    setCaseStatusOptions(tempCaseStatusOptions);

    //Get portfolio list
    PortfolioService.search({}, '', '', 1, null, 50).then((response) => {
      let portfolioList = [];
      if (!response.data) return;
      if (response.data.data.length > 0) {
        response.data.data.map((item) => {
          portfolioList.push({
            label: item.portfolioName,
            value: item.publicId,
          });
        });
        if (!isFiltersEmpty) {
          if (accountsAccountFindingFilters.portfolioName) {
            if (portfolioList.length > 0) {
              let tempportfolioName = [];
              accountsAccountFindingFilters.portfolioName.map((item) => {
                const portfolio = portfolioList.find((portfolio) => portfolio.value === item);
                tempportfolioName.push({
                  label: portfolio.label,
                  value: item,
                });
              });
              setPortfolio(tempportfolioName);
            }
          }
        }
      }
      setPortfolioOptions(portfolioList);
    });

    //Retaining data by checking with appcontext
    if (!isFiltersEmpty) {
      if (accountsAccountFindingFilters.dateRange) {
        if (typeof accountsAccountFindingFilters.dateRange == 'string') {
          dateFilter.current.value = accountsAccountFindingFilters.dateRange;
          setDateValue(accountsAccountFindingFilters.dateRange);
          setDaterange(false);
          setFromDate(null);
          setToDate(null);
        } else {
          dateFilter.current.value = 'Custom';
          setDaterange(true);
          setDateValue(null);
          setFromDate(accountsAccountFindingFilters.dateRange.fromDate.split('T')[0]);
          setToDate(accountsAccountFindingFilters.dateRange.toDate.split('T')[0]);
        }
      }
      if (accountsAccountFindingFilters.applicationStatus) {
        let tempAppStatus = [];
        accountsAccountFindingFilters.applicationStatus.map((item) => {
          tempAppStatus.push({
            label: item,
            value: item,
          });
        });
        setStatusValue(tempAppStatus);
      }
      if (accountsAccountFindingFilters.caseStatus) {
        let tempcaseStatus = [];
        accountsAccountFindingFilters.caseStatus.map((item) => {
          tempcaseStatus.push({
            label: item,
            value: item,
          });
        });
        setCaseStatus(tempcaseStatus);
      }
      if (accountsAccountFindingFilters.portfolioName) {
        let tempportfolioName = [];
        accountsAccountFindingFilters.portfolioName.map((item) => {
          tempportfolioName.push({
            label: item,
            value: item,
          });
        });
        setPortfolio(tempportfolioName);
      }

      if (accountsAccountFindingFilters.lawFirm) {
        setSelectedLawFirm(accountsAccountFindingFilters.lawFirm);
      }
    }
  }, []);

  const search = () => {
    const tempStatusValue = [];
    const tempCaseStatus = [];
    const tempPortfolio = [];
    statusValue.map((status) => {
      tempStatusValue.push(status.value);
    });
    caseStatus.map((status) => {
      tempCaseStatus.push(status.value);
    });
    portfolio.map((port) => {
      tempPortfolio.push(port.value);
    });
    let filters = {
      dateRange: selectDateRange(),
      applicationStatus: tempStatusValue.length > 0 ? tempStatusValue : null,
      caseStatus: tempCaseStatus.length > 0 ? tempCaseStatus : null,
      lawFirm: selectedLawFirm,
      portfolioName: tempPortfolio.length > 0 ? tempPortfolio : null,
    };

    if (filters.dateRange === '') delete filters.dateRange;

    props.searchWithFilters(filters);
  };

  return (
    <>
      <Col md="2" className="float-left" style={{ backgroundColor: '#e9e9e9' }}>
        <Row className="mt-5">
          <Col md="6">
            <h2>Filters</h2>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="dateRange">Date Range</label>
          </Col>
          <Col md="12">
            <select id="dateFilter" onChange={changeFunc} className="form-control" ref={dateFilter}>
              <option value="">Select date range</option>
              <option value="This month">This month</option>
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="This week">This week</option>
              <option value="Last week">Last week</option>
              <option value="Last month">Last month</option>
              <option value="YTD">YTD</option>
              <option value="Custom">Custom</option>
            </select>
          </Col>
        </Row>
        {dateRange == true && (
          <>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">From</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeFromDate}
                  type="date"
                  id="fromDate"
                  className="form-control"
                  required="required"
                  defaultValue={fromDate}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">To</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeToDate}
                  type="date"
                  id="toDate"
                  className="form-control"
                  required="required"
                  defaultValue={toDate}
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="lawFirm">Law Firm</label>
          </Col>
          <Col md="12">
            <LawFirmAutoSuggest
              ref={lawFirmFilter}
              selectedLawFirm={(value) => {
                setSelectedLawFirm(value);
              }}
              selectedLawFirmName={() => {}}
              defaultLawFirm={selectedLawFirm}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Application Status</label>
          </Col>
          <Col md="12">
            <div ref={statusFilter}>
              <MultiSelect
                value={statusValue}
                onChange={setStatusValue}
                labelledBy={'Application Status'}
                options={appStatusOptions}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Case Status</label>
          </Col>
          <Col md="12">
            <div ref={caseStatusFilter}>
              <MultiSelect
                value={caseStatus}
                onChange={setCaseStatus}
                labelledBy={'Case Status'}
                options={caseStatusOptions}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="status">Portfolio</label>
          </Col>
          <Col md="12">
            <div>
              <MultiSelect
                value={portfolio}
                onChange={setPortfolio}
                labelledBy={'Portfolio'}
                options={portfolioOptions}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col md="7">
            <span className="a-link">
              <a
                className="btn"
                style={{ color: '#000000', background: 'white' }}
                onClick={clearFilters}
              >
                Clear filters
              </a>
            </span>
          </Col>
          <Col md="4">
            <span className="row">
              <a className="btn btn-primary row" style={{ color: '#fff' }} onClick={search}>
                <i class="fas fa-search"></i>
                Search
              </a>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default FilteringFields;
