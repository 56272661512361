
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import AppContext from '../../contexts/AppContext';

import './Login.css';

import lasurisLogo from '@images/lasuris_logo.png';

const Login = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const email = useRef();
  const password = useRef();

  useEffect(() => {
    if (appContext.toastMessage.message === 'You have logged out sucessfully.') {
      toast.info(appContext.toastMessage.message);
      appContext.toastMessage.message = null;
    }
  });

  const loginFormSubmit = (e) => {
    e.preventDefault();

    const portfolio = 'Portfolio Manager';
    const underwriter = 'Underwriter';
    const accounting = 'Accounting';
    const accountManager = 'Account Manager';
    const executive = 'Executive Management';
    const lead = 'Lead Underwriter';

    AuthService.login(email.current.value, password.current.value)
      .then((res) => {
        localStorage.setItem('isUserAuthenticated', true);
        appContext.toastMessage.message = 'You have logged in sucessfully.';
        appContext.toastMessage.type = 'info';

        appContext.user = res.user;

        UserService.getUserPermissionsByUserPublicId(res.user.publicId).then((response) => {
          let taskNames = response.data.data.map((x) => x.taskName);

          localStorage.setItem('userPermissions', JSON.stringify(taskNames));
          appContext.userPermissions = taskNames;
        });
        console.log('res.user.roles', res.user.roles);
        if (res.user.roles) {
          let specialUsers = res.user.roles.some(
            (item) => item.roleName === executive || item.roleName === lead,
          );
          console.log('specialUsers', specialUsers);
          if (specialUsers === false) {
            switch (res.user.roles[0].roleName) {
              case portfolio: {
                if (
                  appContext.userPermissions.find(
                    (element) => element === 'View Cases Require Review',
                  )
                ) {
                  navigate(`/portfolios/cases-require-review`);
                } else {
                  navigate('/dashboard');
                }
                break;
              }
              case underwriter: {
                if (
                  appContext.userPermissions.find((element) => element === 'View Applications List')
                ) {
                  navigate(`/underwriting/applications`);
                } else {
                  navigate('/dashboard');
                }
                break;
              }
              case accounting: {
                if (
                  appContext.userPermissions.find(
                    (element) => element === 'View Cases to Be Advanced',
                  )
                ) {
                  navigate(`/accounting/cases-to-be-advanced`);
                } else {
                  navigate('/dashboard');
                }
                break;
              }
              case accountManager: {
                if (
                  appContext.userPermissions.find(
                    (element) => element === 'View Active Advance Receivables',
                  )
                ) {
                  navigate(`/accounts/active-advance-receivables`);
                } else {
                  navigate('/dashboard');
                }
                break;
              }
              default: {
                navigate('/dashboard');
              }
            }
          } else {
            navigate(`/dashboard`);
          }
        } else {
          navigate('/dashboard');
        }

        toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      })
      .catch((err) => {
        localStorage.setItem('isUserAuthenticated', false);
        setErrorMessage('Wrong username and/or password.');
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="d-flex flex-column flex-root flex-center mt-20">
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white box-shadow-border">
          <div
            className="login-content flex-row-fluid d-flex flex-column 
          justify-content-center position-relative overflow-hidden p-7 mx-auto"
            style={{ width: '500px' }}
          >
            <div className="d-flex flex-column-fluid flex-center" />
            <div className="login-form login-signin">
              <div className="d-flex flex-center mb-5">
                <Link to="/login">
                  <img src={lasurisLogo} alt="logo" />
                </Link>
              </div>

              <form
                className="form fv-plugins-bootstrap fv-plugins-framework"
                id="kt_login_signin_form"
                method="POST"
                onSubmit={loginFormSubmit}
              >
                {errorMessage && (
                  <div className="alert alert-custom alert-danger mb-10" role="alert">
                    <div className="alert-icon">
                      <i className="flaticon-warning" />
                    </div>
                    <div className="alert-text"> {errorMessage} </div>
                  </div>
                )}

                <div className="pt-lg-0 pt-5">
                  <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg d-flex flex-center">
                    LitFinPro Login
                  </h3>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <label className="font-size-h6 font-weight-bolder text-dark">Email</label>
                  <input
                    className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="email"
                    name="username"
                    ref={email}
                    autoComplete="off"
                    required="required"
                  />
                  <div className="fv-plugins-message-container" />
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <div className="d-flex justify-content-between mt-n5">
                    <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                      Password
                    </label>
                    <Link
                      to="forgotpassword"
                      className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                      id="kt_login_forgot"
                    >
                      Forgot Password ?
                    </Link>
                  </div>
                  <input
                    className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    ref={password}
                    name="password"
                    autoComplete="off"
                  />
                  <div className="fv-plugins-message-container" />
                </div>
                <div className="pb-lg-0 pb-5">
                  <input
                    type="submit"
                    id="kt_login_signin_submit"
                    className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                    value="Sign In"
                  />
                </div>

                <input type="hidden" />
                <div />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
