import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import ClientService from '../../services/ClientService';

class ClientsAutoSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultClient?.displayName || '',
      suggestions: [],
    };
  }

  componentDidMount() {
    const { defaultClient } = this.props;
    this.setState({
      suggestions: [],
      value: defaultClient?.displayName || '',
    });
  }

  componentDidUpdate(prevProps) {
    const { defaultClient } = this.props;
    if (prevProps.defaultClient !== defaultClient) {
      this.setState({
        value: defaultClient?.displayName || '',
      });
    }
  }

  getSuggestionValue = (suggestion) => {
    const { selectedClient, selectedClientName } = this.props;
    selectedClient(suggestion);
    selectedClientName(suggestion.displayName);
    return suggestion.displayName;
  };

  static renderSuggestion(suggestion) {
    return <span>{suggestion.displayName}</span>;
  }

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });

    if (!newValue) {
      const { selectedClient, selectedClientName } = this.props;
      selectedClientName('');
      selectedClient({});
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    ClientService.autoSuggest(value).then((response) => {
      this.setState({ suggestions: response.data.data });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Search Clients...',
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={ClientsAutoSuggest.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

ClientsAutoSuggest.propTypes = {
  defaultClient: PropTypes.shape({
    displayName: PropTypes.string,
  }),
  selectedClient: PropTypes.func.isRequired,
  selectedClientName: PropTypes.func.isRequired,
};

ClientsAutoSuggest.defaultProps = {
  defaultClient: {},
};

export default ClientsAutoSuggest;
