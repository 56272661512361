import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { Row, Col } from 'reactstrap';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import AccidentSummaryReportService from '@services/AccidentSummaryReportService';
import AccidentSummaryReportTypeService from '@services/AccidentSummaryReportTypeService';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@mui/material/TableSortLabel';
import ReactLoading from 'react-loading';
import FilteringFields from './FilteringFields';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { setSourceMapRange } from 'typescript';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: '14px', // Set font size for the body cells only
  },
}));

const useStyles2 = {
  minWidth: 500,
};

const user = JSON.parse(localStorage.getItem('lasuris-user'));
export default function CustomPaginationActionsTable(props) {
  const appContext = useContext(AppContext);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState(appContext.accidentSummaryReportFilters);
  const [orderBy, setOrderBy] = useState();
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const [accidentType, setAccidentType] = useState(false);
  const [sum, setSum] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (!filters.dateRange || filters.dateRange == '') {
      let tempFilters = {
        ...filters,
        dateRange: 'This month',
      };
      setFilters(tempFilters);
      appContext.accidentSummaryReportFilters = tempFilters;
      localStorage.setItem('accidentSummaryReportFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      loadData(filters);
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const loadData = (filters) => {
    let reqFilters = filters;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;

    delete reqFilters.portfolioName;
    delete reqFilters.portfolioName;

    setIsLoading(true);
    localStorage.setItem('accidentType', 'All Applications');

    let rowsPerPage = 1000;
    let orderBy = 'AccidentTypeName';

    AccidentSummaryReportService.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response?.data?.data);
        setPages(response?.data?.pages?.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    calcualteSum();
  }, [rows]);

  const calcualteSum = () => {
    let sum = 0;
    if (accidentType === true) {
      rows.map((row) => {
        sum += +row.totalAdvances;
      });
      setSum(sum);
    } else {
      rows.map((item) => {
        sum += +item.totalCases;
      });
      setSum(sum);
    }
  };

  const getAll = () => {
    let tempFilters = {
      dateRange: 'This month',
    };
    setFilters(tempFilters);
    appContext.accidentSummaryReportFilters = tempFilters;
    localStorage.setItem('accidentSummaryReportFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  };

  const update = (filters) => {
    setIsLoading(true);

    let rowsPerPage = 1000;
    let orderBy = 'AccidentTypeName';
    let orderDir = 'asc';

    AccidentSummaryReportService.search(filters, orderBy, orderDir, 1, null, rowsPerPage)
      .then((response) => {
        setRows(response?.data?.data);
        setPages(response?.data?.pages?.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportTo = (filters) => {
    var reqFilters = { ...filters };
    setIsLoading(true);

    let rowsPerPage = 1000;
    let orderBy = 'AccidentTypeName';
    let orderDir = 'asc';

    let accidentType = localStorage.getItem('accidentType');

    if (accidentType === 'Active Advance Recievable Only') {
      AccidentSummaryReportTypeService.export(reqFilters, orderBy, orderDir, 1, null, rowsPerPage)
        .then((response) => {
          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'Data-Export.xlsx';
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }
          fileDownload(response.data, fileName);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      AccidentSummaryReportService.export(reqFilters, orderBy, orderDir, 1, null, rowsPerPage)
        .then((response) => {
          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'Data-Export.xlsx';
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }
          fileDownload(response.data, fileName);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const searchWithFilters = (filters) => {
    setIsLoading(true);

    let accidentType = localStorage.getItem('accidentType');

    if (accidentType === 'Active Advance Recievable Only') {
      let orderBy = 'AccidentTypeName';
      let orderDir = 'asc';

      AccidentSummaryReportTypeService.search(filters, orderBy, orderDir, 1, null, rowsPerPage)
        .then((response) => {
          setAccidentType(true);
          setRows(response?.data?.data);
          setPages(response?.data?.pages?.slice(-1)[0]);
          setIsLoading(false);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setAccidentType(false);
      var newFilters = { ...filters };
      setFilters(newFilters);
      update(newFilters);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  return (
    <>
      <div className="d-flex mt-5 mb-10">
        <div className="mr-auto">
          <h1>Types Of Accident Summary Report</h1>
        </div>
        <Link
          onClick={() => exportTo(filters)}
          className="btn btn-primary export-btn "
          style={{
            backgroundColor: 'var(--green)',
            borderColor: 'var(--green)',
          }}
        >
          Export
        </Link>
      </div>
      <FilteringFields searchWithFilters={searchWithFilters} getAll={getAll}></FilteringFields>

      {accidentType === false ? (
        <Col md="9" className="float-right">
          <TableContainer component={Paper}>
            <Table className={useStyles2} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Id</StyledTableCell>

                  <StyledTableCell style={{ width: '3%' }}> TYPE OF ACCIDENT</StyledTableCell>
                  <StyledTableCell style={{ width: '3%' }}> TOTAL CASES </StyledTableCell>
                  <StyledTableCell style={{ width: '3%' }}> % OF TOTAL CASES </StyledTableCell>
                </TableRow>
              </TableHead>
              {isLoading === false && (
                <TableBody>
                  {rows &&
                    rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell style={{ width: '3%' }}>{row?.accidentTypeName}</TableCell>
                        <TableCell style={{ width: '3%' }}>{row?.totalCases}</TableCell>
                        <TableCell style={{ width: '3%' }}>{row?.percofTotalCases + '%'}</TableCell>
                      </TableRow>
                    ))}
                  {rows && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {}
                      </TableCell>
                      <TableCell className="total-border">
                        <span className="totals-text ">{'Totals'}</span>
                      </TableCell>
                      <TableCell className="total-border">
                        <span className="totals-text ">{sum}</span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
              {isLoading && (
                <TableBody>
                  <TableRow>
                    <td></td>
                    <td>
                      <ReactLoading type="bars" color="#7E8299" />
                    </td>
                    <td></td>
                  </TableRow>
                </TableBody>
              )}
              <TableFooter></TableFooter>
            </Table>
          </TableContainer>
        </Col>
      ) : (
        <Col md="9" className="float-right">
          <TableContainer component={Paper}>
            <Table className={useStyles2} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Id</StyledTableCell>

                  <StyledTableCell style={{ width: '3%' }}>TYPE OF ACCIDENT</StyledTableCell>
                  <StyledTableCell style={{ width: '3%' }}>TOTAL ADVANCES</StyledTableCell>
                  <StyledTableCell style={{ width: '3%' }}>% OF TOTAL ADVANCES</StyledTableCell>
                  <StyledTableCell style={{ width: '3%' }}>ADVANCED AMOUNT</StyledTableCell>
                  <StyledTableCell style={{ width: '3%' }}>% OF ADVANCED AMOUNT</StyledTableCell>
                  <StyledTableCell style={{ width: '3%' }}>CASH OUTLAY</StyledTableCell>
                  <StyledTableCell style={{ width: '3%' }}>% OF CASH OUTLAY</StyledTableCell>
                </TableRow>
              </TableHead>
              {isLoading === false && (
                <TableBody>
                  {rows &&
                    rows.map((row, index) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell style={{ width: '3%' }}>{row?.accidentTypeName}</TableCell>
                        <TableCell style={{ width: '3%' }}>{row?.totalAdvances}</TableCell>
                        <TableCell style={{ width: '3%' }}>
                          {row?.percofTotalAdvances + '%'}
                        </TableCell>
                        <TableCell style={{ width: '3%' }}>
                          {row?.totalAdvanceAmount &&
                            moneyFormatter.format(parseFloat(row.totalAdvanceAmount).toFixed(2))}
                        </TableCell>
                        <TableCell style={{ width: '3%' }}>
                          {row?.percofAdvanceAmount + '%'}
                        </TableCell>
                        <TableCell style={{ width: '3%' }}>
                          {row?.totalCashOutlay &&
                            moneyFormatter.format(parseFloat(row.totalCashOutlay).toFixed(2))}
                        </TableCell>
                        <TableCell style={{ width: '3%' }}>{row?.percofCashOutlay + '%'}</TableCell>
                      </TableRow>
                    ))}

                  {rows && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {}
                      </TableCell>
                      <TableCell className="total-border" style={{ width: '3%' }}>
                        <span className="totals-text">{'Totals'}</span>
                      </TableCell>
                      <TableCell className="total-border" style={{ width: '3%' }}>
                        <span className="totals-text">{sum}</span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
              {isLoading && (
                <TableBody>
                  <TableRow>
                    <td></td>
                    <td>
                      <ReactLoading type="bars" color="#7E8299" />
                    </td>
                    <td></td>
                  </TableRow>
                </TableBody>
              )}

              <TableFooter></TableFooter>
            </Table>
          </TableContainer>
        </Col>
      )}
    </>
  );
}
