import React, { useContext } from 'react';
import AppContext from '@contexts/AppContext';
import { toast } from 'react-toastify';

const UserPermittedRoute = ({ component: Component, task, ...rest }) => {
  const appContext = useContext(AppContext);

  if (appContext?.userPermissions) {
    if (task?.some((x) => appContext?.userPermissions?.includes(x))) {
      return <Component {...rest} />;
    }
  }

  toast.error('You do not have permissions to view this page.');
};

export default UserPermittedRoute;
