import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { useForm } from 'react-hook-form';
import AttorneyService from '@services/AttorneyService';

import AppContext from '@contexts/AppContext';
import ApplicationsNewMenu from './Menu';
import Header from './Header';

import StateSelect from '@components/StateSelect/StateSelect';
import AttorneyAutoSuggest from '@components/AttorneyAutoSuggest/AttorneyAutoSuggest';
import PortfolioService from '@services/PortfolioService';

const Application = () => {
  let appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const [HasFirstButtonSubbmited, setHasFirstButtonSubbmited] = useState(true);
  const [attorney, setAttorney] = useState({
    displayName: '',
    attorneyPublicId: '',
  });
  const [selectedAttorney, setSelectedAttorney] = useState('');
  const [selectedAttorneyName, setSelectedAttorneyName] = useState('');
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const [selectedAttorneyError, setSelectedAttorneyError] = useState(undefined);

  const handleFormSubmitted = (e) => {
    e.preventDefault();

    if (selectedAttorney === undefined) {
      setSelectedAttorneyError('Select an attorney.');
      return;
    }
    appContext.toastMessage.message = 'Your changes has been saved sucessfully.';
    if (applicationId) {
      PortfolioService.search({ applicationNo: applicationId }, null, null, 1, {}, 10).then(
        (response) => {
          var updatedApplication = { ...response.data.data[0] };

          updatedApplication.attorneyPublicId = selectedAttorney;

          PortfolioService.update(updatedApplication).then((response) => {
            appContext.currentApplication = response.data;
            appContext.newAppllicationStep = 1;

            navigate(
              '/underwriting/applications/' +
                appContext.currentApplication.applicationNo +
                '/plaintiff',
            );
          });
        },
      );
    } else {
      PortfolioService.create({ attorneyPublicId: selectedAttorney }).then((response) => {
        appContext.currentApplication = response.data;
        appContext.newAppllicationStep = 1;

        navigate(
          '/underwriting/applications/' +
            appContext.currentApplication.applicationNo +
            '/plaintiff',
        );
      });
    }
  };

  const handleCancelButtonClicked = () => {
    navigate('/portfolios/payoff-letter-requests');
  };

  const handleSaveAndExit = () => {
    setHasFirstButtonSubbmited(false);
    document.querySelector('button.btn.btn-primary.mr-5').click();
  };

  useEffect(() => {
    if (applicationId) {
      PortfolioService.search({ applicationNo: applicationId }, null, null, 1, {}, 1).then(
        (response) => {
          setSelectedAttorney(response.data.data[0].attorneyPublicId);
          setSelectedAttorneyError(undefined);
        },
      );
    }
  }, []);

  useEffect(() => {
    if (selectedAttorney != undefined && selectedAttorney != '') {
      AttorneyService.read(selectedAttorney).then((response) => {
        setAttorney(response.data);
        setSelectedAttorneyName(response.data.displayName);
        setSelectedAttorneyError(undefined);
      });
    }
  }, [selectedAttorney]);

  return (
    <Container className="mt-10 mb-10">
      <Row>
        <Col md="12" className="mx-auto bg-white box-shadow-border">
          <div className="p-10 mt-5 mb-10">
            <Header applicationId={applicationId} handleSaveAndExit={handleSaveAndExit} />

            <ApplicationsNewMenu applicationId={applicationId} menuItemIndex="0" />

            <Form onSubmit={handleFormSubmitted} method="POST">
              <Container className="p-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="attorneyName">Attorney&apos;s Name</Label>
                          {selectedAttorney != '' && selectedAttorneyName != '' ? (
                            <AttorneyAutoSuggest
                              selectedAttorney={setSelectedAttorney}
                              selectedAttorneyName={() => {}}
                              defaultAttorney={selectedAttorneyName}
                            />
                          ) : selectedAttorney === '' && selectedAttorneyName === '' ? (
                            <AttorneyAutoSuggest
                              selectedAttorney={setSelectedAttorney}
                              selectedAttorneyName={() => {}}
                              defaultAttorney={''}
                            />
                          ) : undefined}
                          {selectedAttorneyError && (
                            <Alert color="danger" className="mt-5">
                              <span>Select an attorney</span>
                            </Alert>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="lawFirm">Law Firm</Label>
                          <Input
                            type="text"
                            name="lawFirm"
                            className="form-control"
                            required="required"
                            value={attorney?.lawFirm?.lawFirmName}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <Label htmlFor="phoneNumber">Phone Number</Label>
                          <Input
                            type="tel"
                            name="phoneNumber"
                            className="form-control"
                            required="required"
                            value={attorney?.workingPhoneNo}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="1">
                        <FormGroup>
                          <Label htmlFor="phoneNumber">Ext</Label>
                          <Input
                            type="tel"
                            name="phoneExt"
                            className="form-control"
                            required="required"
                            value={attorney?.phoneExt}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="faxNumber">Fax Number</Label>
                          <Input type="tel" name="faxNumber" className="form-control" disabled />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <Label htmlFor="email">Email address</Label>
                          <Input
                            type="email"
                            name="email"
                            className="form-control"
                            required="required"
                            value={attorney?.emailAddress}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="address">Address</Label>
                          <Input
                            type="text"
                            id="address"
                            className="form-control"
                            required="required"
                            value={attorney?.lawFirm?.streetAddress}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="city">City</Label>
                          <Input
                            type="text"
                            id="city"
                            className="form-control"
                            required="required"
                            value={attorney?.lawFirm?.city}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <StateSelect
                          name="stateCode"
                          label="State"
                          useRef={register({ required: 'State is required.' })}
                          defaultValue={attorney?.lawFirm?.stateCode}
                          setObject={setAttorney}
                          disabled="disabled"
                          givenObject={attorney}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="zip">Zip Code</Label>
                          <Input
                            type="text"
                            id="zip"
                            className="form-control"
                            required="required"
                            pattern="[0-9]{5}"
                            maxLength="5"
                            value={attorney?.lawFirm?.zipCode}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <Button type="submit" color="primary" className="btn btn-primary mr-5">
                          Save
                        </Button>
                        <Button
                          color="secondary"
                          onClick={handleCancelButtonClicked}
                          className="btn btn-secondary"
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Application;
