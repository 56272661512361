import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import { Col } from 'reactstrap';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import VReportsAccountManagerSummary from '@services/VReportsAccountManagerSummary';
import AppContext from '@contexts/AppContext';
import TableSortLabel from '@mui/material/TableSortLabel';
import ReactLoading from 'react-loading';
import FilteringFields from './FilteringFields';
import '../Styles.css';
import { FormattedAmount } from '../../../../components/Utils/FormattedAmount';
import { toast } from 'react-toastify';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: '14px', // Set font size for the body cells only
  },
}));

const useStyles2 = {
  minWidth: 500,
};

export default function CustomPaginationActionsTable() {
  const appContext = useContext(AppContext);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState(appContext.accountManagerSummaryReportFilters);
  const [orderBy, setOrderBy] = useState('AccountManagerName');
  const [orderDir, setOrderDir] = useState('Asc');
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!filters.dateRange || filters.dateRange == '') {
      let tempFilters = {
        ...filters,
        dateRange: 'This month',
      };

      setFilters(tempFilters);
      appContext.accountManagerSummaryReportFilters = tempFilters;
      localStorage.setItem('accountManagerSummaryReportFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      loadData(filters);
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const loadData = (filters) => {
    let reqFilters = filters;
    if (reqFilters.dateRange == '') {
      delete reqFilters.dateRange;
    }

    setIsLoading(true);

    VReportsAccountManagerSummary.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAll = () => {
    let tempFilters = {
      dateRange: 'This month',
    };
    setFilters(tempFilters);
    appContext.accountManagerSummaryReportFilters = tempFilters;
    localStorage.setItem('accountManagerSummaryReportFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  };

  const update = (filters) => {
    let reqFilters = filters;
    if (reqFilters.dateRange == '') {
      delete reqFilters.dateRange;
    }

    setIsLoading(true);

    VReportsAccountManagerSummary.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchWithFilters = (filters) => {
    var newFilters = { ...filters };
    setFilters(newFilters);
    appContext.accountManagerSummaryReportFilters = newFilters;
    localStorage.setItem('accountManagerSummaryReportFilters', JSON.stringify(newFilters));
    if (page === 1) update(newFilters);
    else setPage(1);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  function gotoDetailPage(id) {
    appContext.accountManagerDetailsReportFilters.dateRange = filters.dateRange;
    appContext.accountManagerDetailsReportFilters.accountManager = id;
    localStorage.setItem(
      'accountManagerDetailsReportFilters',
      JSON.stringify(appContext.accountManagerDetailsReportFilters),
    );

    navigate('/reports/account-manager-details/' + id);
  }

  return (
    <>
      <FilteringFields searchWithFilters={searchWithFilters} getAll={getAll}></FilteringFields>
      <Col md="9" className="float-left ams-table-container">
        <TableContainer component={Paper}>
          <Table className={useStyles2} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell className="name-column">NAME</StyledTableCell>

                <StyledTableCell className="num-of-advances"># OF ADVANCES</StyledTableCell>

                <StyledTableCell className="ams-commadv">COMMISSIONABLE ADVANCES</StyledTableCell>

                <StyledTableCell className="ams-nfpfees">NFP FEES</StyledTableCell>
              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell className="name-column">
                        <a
                          className="name-link"
                          href="#"
                          onClick={() => gotoDetailPage(row.accountManagerPublicId)}
                        >
                          {row.accountManagerName}
                        </a>
                      </TableCell>
                      <TableCell className="num-of-advances">{row.numberofAdvances}</TableCell>
                      <TableCell className="ams-commadv">
                        {row?.commissionableAdvances
                          ? FormattedAmount.format(
                              parseFloat(row.commissionableAdvances).toFixed(2),
                            )
                          : '-'}
                      </TableCell>
                      <TableCell className="ams-nfpfees">
                        {row?.nfpfees
                          ? FormattedAmount.format(parseFloat(row.nfpfees).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
        <div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
      </Col>
    </>
  );
}
