import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { Row, Col } from 'reactstrap';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import VApplicationsListDataService from '@services/VApplicationsListDataService';

import AppContext from '@contexts/AppContext';
import TableSortLabel from '@mui/material/TableSortLabel';
import ReactLoading from 'react-loading';
import FilteringFields from './FilteringFields';
import Moment from 'moment';
import GlobalFilteringFields from '@components/Filtering/GlobalFilteringFields';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: '14px', // Set font size for the body cells only
  },
}));

const useStyles2 = {
  minWidth: 500,
};

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function ApplicationsGrid() {
  const appContext = useContext(AppContext);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState(appContext.underwritingApplicationFilters);
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [rows, setRows] = useState([]);

  const navigate = useNavigate();

  const DEFAULT_APP_STATUS = [];
  const DEFAULT_ADV_STATUS = [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [rowIndex, setRowIndex] = useState(undefined);
  const [givenRows, setGivenRows] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(undefined);

  const [activeFieldForSorting, setActiveFieldForSorting] = useState();

  useEffect(() => {
    if (!filters.dateRange || filters.dateRange == '') {
      let tempFilters = {
        ...filters,
        dateRange: 'This month',
      };
      setFilters(tempFilters);
      appContext.underwritingApplicationFilters = tempFilters;
      localStorage.setItem('underwritingApplicationFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      loadData(filters);
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  const loadData = (filters) => {
    let reqFilters = filters;
    if (reqFilters && reqFilters.client) {
      reqFilters = { ...reqFilters, ClientPublicId: reqFilters.client.publicId };
    }
    if (reqFilters && reqFilters.attorney) {
      reqFilters = { ...reqFilters, AttorneyPublicId: reqFilters.attorney.publicId };
    }
    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    if (reqFilters.applicationNo) reqFilters.ApplicationNo = reqFilters.applicationNo;
    if (reqFilters.advanceNo) reqFilters.AdvanceNo = reqFilters.advanceNo;
    if (reqFilters.caseStatus) reqFilters.ApplicationCaseStatus = reqFilters.caseStatus;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.accidentTypeName) reqFilters.AccidentTypeName = reqFilters.accidentTypeName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;
    if (reqFilters.advanceStatus) reqFilters.AdvanceStatus = reqFilters.advanceStatus;
    if (reqFilters.paymentConfinrmationNo)
      reqFilters.PaymentConfinrmationNo = reqFilters.paymentConfinrmationNo;

    delete reqFilters.applicationNo;
    delete reqFilters.advanceNo;
    delete reqFilters.client;
    delete reqFilters.caseStatus;
    delete reqFilters.lawFirm;
    delete reqFilters.attorney;
    delete reqFilters.portfolioName;
    delete reqFilters.accidentTypeName;
    delete reqFilters.applicationStatus;
    delete reqFilters.advanceStatus;
    delete reqFilters.paymentConfinrmationNo;
    setIsLoading(true);
    VApplicationsListDataService.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getAll = (filters) => {
    let tempFilters = {
      dateRange: filters,
    };
    setFilters(tempFilters);
    appContext.underwritingApplicationFilters = tempFilters;
    localStorage.setItem('underwritingApplicationFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  };

  const update = (filters) => {
    let reqFilters = filters;
    if (reqFilters && reqFilters.client) {
      reqFilters = { ...reqFilters, ClientPublicId: reqFilters.client.publicId };
    }
    if (reqFilters && reqFilters.attorney) {
      reqFilters = { ...reqFilters, AttorneyPublicId: reqFilters.attorney.publicId };
    }
    if (reqFilters && reqFilters.lawFirm) {
      reqFilters = { ...reqFilters, LawFirmPublicId: reqFilters.lawFirm.publicId };
    }
    if (reqFilters.applicationNo) reqFilters.ApplicationNo = reqFilters.applicationNo;
    if (reqFilters.advanceNo) reqFilters.AdvanceNo = reqFilters.advanceNo;
    if (reqFilters.caseStatus) reqFilters.ApplicationCaseStatus = reqFilters.caseStatus;

    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;

    if (reqFilters.accidentTypeName) reqFilters.AccidentTypeName = reqFilters.accidentTypeName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;
    if (reqFilters.advanceStatus) reqFilters.AdvanceStatus = reqFilters.advanceStatus;
    if (reqFilters.paymentConfinrmationNo)
      reqFilters.PaymentConfinrmationNo = reqFilters.paymentConfinrmationNo;

    delete reqFilters.applicationNo;
    delete reqFilters.advanceNo;
    delete reqFilters.client;
    delete reqFilters.caseStatus;
    delete reqFilters.lawFirm;
    delete reqFilters.attorney;
    delete reqFilters.portfolioName;
    delete reqFilters.accidentTypeName;
    delete reqFilters.applicationStatus;
    delete reqFilters.advanceStatus;
    delete reqFilters.paymentConfinrmationNo;

    setIsLoading(true);

    VApplicationsListDataService.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const searchWithFilters = (givenFilters) => {
    var newFilters = { ...givenFilters };
    setFilters(newFilters);
    appContext.underwritingApplicationFilters = newFilters;
    localStorage.setItem('underwritingApplicationFilters', JSON.stringify(newFilters));
    if (page === 1) update(newFilters);
    else setPage(1);
  };

  const arrange = (field) => {
    if (orderBy === field) {
      if (orderDir === 'ASC') {
        setOrderDir('DESC');
      } else {
        setOrderDir('ASC');
      }
    } else {
      setOrderBy(field);
      setOrderDir('ASC');
    }

    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      <GlobalFilteringFields
        appContextFilter={filters}
        searchWithFilters={searchWithFilters}
        getAll={getAll}
        defaultApplicationStatus={DEFAULT_APP_STATUS}
        defaultAdvanceStatus={DEFAULT_ADV_STATUS}
      ></GlobalFilteringFields>

      <Col md="10" className="float-right">
        <TableContainer component={Paper}>
          <Table className={useStyles2} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell style={{ width: '15%' }}>CLIENT</StyledTableCell>

                <StyledTableCell style={{ width: '8%' }}>APP STATUS</StyledTableCell>
                <StyledTableCell style={{ width: '8%' }}>ADV STATUS</StyledTableCell>

                <StyledTableCell
                  style={{ width: '8%' }}
                  onClick={() => {
                    arrange('applicationNo');
                    setActiveFieldForSorting('applicationNo');
                  }}
                >
                  APP #
                  <TableSortLabel
                    active={activeFieldForSorting === 'applicationNo' ? true : false}
                    direction={
                      activeFieldForSorting === 'applicationNo' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('applicationNo')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell
                  style={{ width: '8%' }}
                  onClick={() => {
                    arrange('advanceNo');
                    setActiveFieldForSorting('advanceNo');
                  }}
                >
                  ADV #
                  <TableSortLabel
                    active={activeFieldForSorting === 'advanceNo' ? true : false}
                    direction={
                      activeFieldForSorting === 'advanceNo' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('advanceNo')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell style={{ width: '10%' }}>TYPE OF ACCIDENT</StyledTableCell>

                <StyledTableCell
                  style={{ width: '10%' }}
                  onClick={() => {
                    arrange('applicationDate');
                    setActiveFieldForSorting('applicationDate');
                  }}
                >
                  ADV DATE
                  <TableSortLabel
                    active={activeFieldForSorting === 'applicationDate' ? true : false}
                    direction={
                      activeFieldForSorting === 'applicationDate' ? orderDir.toLowerCase() : 'asc'
                    }
                    onClick={() => arrange('applicationDate')}
                  ></TableSortLabel>
                </StyledTableCell>

                <StyledTableCell style={{ width: '12%' }}>ADVANCED AMOUNT</StyledTableCell>

                <StyledTableCell style={{ width: '10%' }}>ATTORNEY</StyledTableCell>

                {/* <StyledTableCell
                  style={{ width: '15%' }}
                >
                  LAST NOTE
                </StyledTableCell> */}

                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow key={row?.name}>
                      <TableCell component="th" scope="row">
                        {row?.id}
                      </TableCell>
                      <TableCell style={{ width: '15%' }}>{row?.client}</TableCell>
                      <TableCell style={{ width: '8%' }}>{row?.applicationStatus}</TableCell>
                      <TableCell style={{ width: '8%' }}>{row?.advanceStatus}</TableCell>
                      <TableCell style={{ width: '8%' }}>{row?.applicationNo}</TableCell>
                      <TableCell style={{ width: '8%' }}>{row?.advanceNo}</TableCell>
                      <TableCell style={{ width: '10%' }}>{row?.accidentTypeName}</TableCell>
                      <TableCell style={{ width: '10%' }}>
                        {row?.applicationDate &&
                          Moment(row?.applicationDate?.split('T')[0]).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell style={{ width: '12%' }}>
                        {row?.advancedAmount != null &&
                          moneyFormatter.format(parseFloat(row?.advancedAmount).toFixed(2))}
                      </TableCell>
                      <TableCell style={{ width: '10%' }}>{row?.attorney}</TableCell>
                      {/* <TableCell style={{ width: '15%' }}>{row?.lastNote}</TableCell> */}
                      <TableCell className="p-2">
                        <a
                          className="btn float-right"
                          onClick={() => {
                            navigate(`/underwriting/applications/${row?.applicationNo}/attorney`);
                          }}
                        >
                          <EditIcon />
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
        <div className="float-left mt-5">
          <select onChange={handlePageSizeChange} className="form-control" defaultValue={15}>
            {[5, 10, 15, 25, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="float-right mt-5">
          <Pagination
            count={pages}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            onChange={handleChangePage}
          />
        </div>
      </Col>
    </>
  );
}
