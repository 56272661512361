import React, { useEffect, useContext, useRef, useState } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import AppContext from '@contexts/AppContext';
import GridService from '@services/GridService';
import UserService from '@services/UserService';
import '../Styles.css';
import { useParams } from 'react-router-dom';
import CustomDateRange from '@components/CustomDateRange/CustomDateRange';

const CommissionFilteringFields = (props) => {
  // const { params } = props.match.accountManagerId;
  const [advanceDateRange, setAdvanceDateRange] = useState(false);
  const [payoffDateRange, setPayoffDateRange] = useState(false);
  const [accountManagersOptions, setAccountManagersOptions] = useState([]);
  const [selectedAccountManager, setSelectedAccountManager] = useState({});

  const [advanceDateValue, setAdvanceDateValue] = useState('This month');
  const [fromAdvanceDate, setFromAdvanceDate] = useState(null);
  const [toAdvanceDate, setToAdvanceDate] = useState(null);

  const [payoffDateValue, setPayoffDateValue] = useState('This month');
  const [fromPayoffDate, setFromPayoffDate] = useState(null);
  const [toPayoffDate, setToPayoffDate] = useState(null);

  const [appStatusOptions, setAppStatusOptions] = useState([]);
  const { accountManagerId } = useParams();
  const [userWithOnlyAccountManagerUserRole, setUserWithOnlyAccountManagerUserRole] =
    useState(false);
  const appContext = useContext(AppContext);
  const commissionReportFilters = appContext.commissionReportFilters ?? '';

  function clearFilters() {
    setAdvanceDateValue('This month');
    setPayoffDateValue('This month');
    appContext.commissionReportFilters = {};
    setAdvanceDateRange(false);
    setFromAdvanceDate(null);
    setToAdvanceDate(null);

    setPayoffDateRange(false);
    setFromPayoffDate(null);
    setToPayoffDate(null);

    setSelectedAccountManager({});
    props.clearGrid();
    //props.getAll();
  }

  function changeAccountManagerFunc(event) {
    setSelectedAccountManager(event.target.value);
    event.target.size = 1;
    event.target.blur();
  }

  function onFocusFunc(event) {
    event.target.size = 10;
  }

  function onBlurFunc(event) {
    event.target.size = 1;
  }

  function selectAdvanceDateRange() {
    if (advanceDateValue !== 'Custom') return advanceDateValue;

    let filter = {
      fromDate: new Date(fromAdvanceDate).toISOString(),
      toDate: new Date(toAdvanceDate).toISOString(),
    };

    return filter;
  }

  function selectPayoffDateRange() {
    if (payoffDateValue !== 'Custom') return payoffDateValue;

    let filter = {
      fromDate: new Date(fromPayoffDate).toISOString(),
      toDate: new Date(toPayoffDate).toISOString(),
    };

    return filter;
  }

  useEffect(() => {
    UserService.searchAccountManagers('').then((response) => {
      if (!response.data || !response.data.data) return;

      if (response.data.data.length > 0) {
        let tempStatusOptions = [];
        response.data.data.map((item) => {
          tempStatusOptions.push({
            label: item.displayName,
            value: item.publicId,
          });
          setAccountManagersOptions(tempStatusOptions);
        });
      }
    });

    const isFiltersEmpty = Object.keys(commissionReportFilters).length == 0;
    //Get application status options
    GridService.read('/Application/applicationstatuses').then((resp) => {
      if (!resp.data) return;
      if (resp.data.length > 0) {
        let tempStatusOptions = [];
        resp.data.map((item) => {
          tempStatusOptions.push({
            label: item,
            value: item,
          });
          setAppStatusOptions(tempStatusOptions);
        });
      }
    });

    //Retaining data by checking with appcontext
    if (!isFiltersEmpty) {
      if (commissionReportFilters.advanceDateRange) {
        if (typeof commissionReportFilters.advanceDateRange == 'string') {
          setAdvanceDateValue(commissionReportFilters.advanceDateRange);
          setAdvanceDateRange(false);
          setFromAdvanceDate(null);
          setToAdvanceDate(null);
          // appContext.commissionReportFilters.dateRange = dateFilter.current.value;
        } else {
          setAdvanceDateRange(true);
          appContext.commissionReportFilters.advanceDateRange =
            commissionReportFilters.advanceDateRange;
          setFromAdvanceDate(commissionReportFilters.advanceDateRange.fromDate.split('T')[0]);
          setToAdvanceDate(commissionReportFilters.advanceDateRange.toDate.split('T')[0]);
        }
      }

      if (commissionReportFilters.payoffDateRange) {
        if (typeof commissionReportFilters.payoffDateRange == 'string') {
          setPayoffDateValue(commissionReportFilters.payoffDateRange);
          setPayoffDateRange(false);
          setFromPayoffDate(null);
          setToPayoffDate(null);
          // appContext.commissionReportFilters.dateRange = dateFilter.current.value;
        } else {
          setPayoffDateRange(true);
          appContext.commissionReportFilters.payoffDateRange =
            commissionReportFilters.payoffDateRange;
          setFromPayoffDate(commissionReportFilters.payoffDateRange.fromDate.split('T')[0]);
          setToPayoffDate(commissionReportFilters.payoffDateRange.toDate.split('T')[0]);
        }
      }

      setAccountManager();
    }
  }, []);

  async function setAccountManager() {
    const userDetails = JSON.parse(localStorage.getItem('lasuris-user'));
    if (
      userDetails?.roles &&
      userDetails.roles.length == 1 &&
      userDetails.roles[0]?.roleName == 'Account Manager'
    ) {
      setUserWithOnlyAccountManagerUserRole(true);
      setSelectedAccountManager(userDetails);
      return;
    } else {
      setUserWithOnlyAccountManagerUserRole(false);
    }

    if (accountManagerId) {
      setSelectedAccountManager(accountManagerId);
    } else if (commissionReportFilters.accountManager) {
      setSelectedAccountManager(commissionReportFilters.accountManager);
    }
  }

  function search() {
    let filters = {
      advanceDateRange: selectAdvanceDateRange(),
      payoffDateRange: selectPayoffDateRange(),
      accountManager: selectedAccountManager,
    };

    if (filters.dateRange === '') delete filters.dateRange;

    props.searchWithFilters(filters);
  }

  return (
    <>
      <Col md="2" className="float-left acc-man-filter-container">
        <Row className="mt-5">
          <Col md="6">
            <h2>Filters</h2>
          </Col>
        </Row>

        <CustomDateRange
          labelText={'Advance date range'}
          setDateValue={setAdvanceDateValue}
          dateValue={advanceDateValue}
          fromDate={fromAdvanceDate}
          setFromDate={setFromAdvanceDate}
          toDate={toAdvanceDate}
          setToDate={setToAdvanceDate}
        />

        <CustomDateRange
          labelText={'Payoff date range'}
          setDateValue={setPayoffDateValue}
          dateValue={payoffDateValue}
          fromDate={fromPayoffDate}
          setFromDate={setFromPayoffDate}
          toDate={toPayoffDate}
          setToDate={setToPayoffDate}
        />

        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="accountManager">Account Manager</label>
          </Col>
          <Col md="12">
            <select
              className="form-control"
              id="accidentTypeFilter"
              name="accidentTypeFilter"
              onChange={changeAccountManagerFunc}
              onFocus={onFocusFunc}
              onBlur={onBlurFunc}
              value={selectedAccountManager}
            >
              <option value="">Select Account Manager</option>
              {accountManagersOptions.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col md="6" className="pr-0">
            <span className="a-link">
              <a className="btn clear-filter-btn" onClick={clearFilters}>
                Clear filters
              </a>
            </span>
          </Col>
          <Col md="6">
            <span className="a-link float-right">
              <a className="btn btn-primary search-btn" onClick={search}>
                <i class="fas fa-search"></i>
                Search
              </a>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default CommissionFilteringFields;
