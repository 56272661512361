import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { Row, Col } from 'reactstrap';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PresentValueReportService from '@services/PresentValueReportService';
import VReportsPresentValueDetail from '@services/VReportsPresentValueDetail';
import AppContext from '@contexts/AppContext';
import ReactLoading from 'react-loading';
import PresentValueFilteringFields from './PresentValueFilteringFields';
import { Link } from 'react-router-dom';
import { FormattedAmount } from '../../../components/Utils/FormattedAmount';
import fileDownload from 'js-file-download';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#efefef',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: '14px', // Set font size for the body cells only
  },
}));

const useStyles2 = {
  minWidth: 500,
};

export default function PresentValueGrid() {
  const appContext = useContext(AppContext);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage] = React.useState(9999);
  const [filters, setFilters] = useState(appContext.presentValueReportFilters);
  const [orderBy] = useState('StateName');
  const [orderDir] = useState('ASC');
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [, setPages] = useState(undefined);

  useEffect(() => {
    let tempFilters = {};
    if (!filters) {
      tempFilters = {
        dateRange: 'This month',
      };
      setFilters(tempFilters);
      appContext.presentValueReportFilters = tempFilters;
      localStorage.setItem('presentValueReportFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
      return;
    }

    if (!filters.dateRange || filters.dateRange == '') {
      tempFilters = {
        ...filters,
        dateRange: 'This month',
      };
      setFilters(tempFilters);
      appContext.presentValueReportFilters = tempFilters;
      localStorage.setItem('presentValueReportFilters', JSON.stringify(tempFilters));
      loadData(tempFilters);
    } else {
      loadData(filters);
    }
  }, [page, orderBy, orderDir, rowsPerPage]);

  function loadData(filters) {
    let reqFilters = filters;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;

    delete reqFilters.portfolioName;
    delete reqFilters.applicationStatus;
    setIsLoading(true);
    PresentValueReportService.search(reqFilters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getAll(filters) {
    let tempFilters = {
      dateRange: filters,
    };

    setFilters(tempFilters);
    appContext.presentValueReportFilters = tempFilters;
    localStorage.setItem('presentValueReportFilters', JSON.stringify(tempFilters));
    update(tempFilters);
  }

  function update(filters) {
    let reqFilters = filters;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;

    delete reqFilters.portfolioName;
    delete reqFilters.applicationStatus;

    setIsLoading(true);

    PresentValueReportService.search(filters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        setRows(response.data.data);
        setPages(response.data.pages.slice(-1)[0]);
        setIsLoading(false);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function exportTo(filters) {
    let reqFilters = filters;
    if (reqFilters.portfolioName) reqFilters.PortfolioPublicId = reqFilters.portfolioName;
    if (reqFilters.applicationStatus) reqFilters.ApplicationStatus = reqFilters.applicationStatus;

    delete reqFilters.portfolioName;
    delete reqFilters.applicationStatus;

    setIsLoading(true);

    VReportsPresentValueDetail.export(filters, orderBy, orderDir, page, null, rowsPerPage)
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Data-Export.xlsx';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }
        fileDownload(response.data, fileName);
        setIsLoading(false);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function searchWithFilters(filters) {
    var newFilters = { ...filters };
    setFilters(newFilters);
    appContext.presentValueReportFilters = newFilters;
    localStorage.setItem('presentValueReportFilters', JSON.stringify(newFilters));
    if (page === 1) update(newFilters);
    else setPage(1);
  }

  function exportToFile(stateName) {
    var reqFilters = { ...filters };
    reqFilters.stateName = stateName;
    exportTo(reqFilters);
  }

  return (
    <>
      <PresentValueFilteringFields
        searchWithFilters={searchWithFilters}
        getAll={getAll}
      ></PresentValueFilteringFields>
      <Col md="9" className="float-left fin-smry-table-container">
        <h3>Totals (by State)</h3>
        <TableContainer component={Paper}>
          <Table className={useStyles2} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>

                <StyledTableCell className="fsr-state-name">STATE</StyledTableCell>

                <StyledTableCell className="fsr-total-advances">TOTAL ADVANCES</StyledTableCell>

                <StyledTableCell className="fsr-total-present-value">
                  TOTAL PRESENT VALUE
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {isLoading === false && (
              <TableBody>
                {rows &&
                  rows[0] &&
                  rows[0].stateTotals &&
                  rows[0].stateTotals.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell className="fsr-state-name">
                        {row.stateName ? (
                          <Link onClick={() => exportToFile(row.stateName)}> {row.stateName}</Link>
                        ) : null}
                      </TableCell>
                      <TableCell className="fsr-total-advances">{row.totalAdvances}</TableCell>
                      <TableCell className="fsr-total-present-value">
                        {row?.totalPresentValue
                          ? FormattedAmount.format(parseFloat(row.totalPresentValue).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <td></td>
                  <td>
                    <ReactLoading type="bars" color="#7E8299" />
                  </td>
                  <td></td>
                </TableRow>
              </TableBody>
            )}

            <TableFooter></TableFooter>
          </Table>
        </TableContainer>

        <Row className="mt-25">
          <Col md="12" sm="12" className="table-totals-all-states">
            <h3>Totals (all States)</h3>
            <div>
              <TableContainer
                style={{ border: '0px solid', borderRadius: 0 }}
                className="tableFinancial"
                component={Paper}
              >
                <Table className={useStyles2} aria-label="custom pagination table">
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Total Advances</TableCell>
                      <TableCell>{rows[0]?.totalAdvances}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Advanced Amount</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalAdvanceAmount
                          ? FormattedAmount.format(
                              parseFloat(rows[0].totalAdvanceAmount).toFixed(2),
                            )
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">NFP Fees</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalNfpfees
                          ? FormattedAmount.format(parseFloat(rows[0].totalNfpfees).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Referral Fees</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalReferralFees
                          ? FormattedAmount.format(parseFloat(rows[0].totalReferralFees).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Financial Store Fees</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalStoreFees
                          ? FormattedAmount.format(parseFloat(rows[0].totalStoreFees).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Bank Wire Fees</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalBankWireFees
                          ? FormattedAmount.format(parseFloat(rows[0].totalBankWireFees).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Overnight Check Fees</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalOvernightCheckFees
                          ? FormattedAmount.format(
                              parseFloat(rows[0].totalOvernightCheckFees).toFixed(2),
                            )
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Other Disbursements</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalOtherDisbursements
                          ? FormattedAmount.format(
                              parseFloat(rows[0].totalOtherDisbursements).toFixed(2),
                            )
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Cash To Client</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalCashtoClient
                          ? FormattedAmount.format(parseFloat(rows[0].totalCashtoClient).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Total Cash Outlay</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalCashOutlay
                          ? FormattedAmount.format(parseFloat(rows[0].totalCashOutlay).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Average Days Outstanding</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.avgDaysOutstanding ? rows[0].avgDaysOutstanding : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Multiplier Average</TableCell>
                      <TableCell> {rows[0]?.avgMultiplier ? rows[0].avgMultiplier : '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Acrual Rate Average</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.avgAccrualRate ? rows[0].avgAccrualRate : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">Profits</TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalProfit
                          ? FormattedAmount.format(parseFloat(rows[0].totalProfit).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell className="highlight-text">
                        {appContext.presentValueReportFilters &&
                        appContext.presentValueReportFilters.presentValueDate
                          ? `Present Value as of ` +
                            appContext.presentValueReportFilters.presentValueDate.split('T')[0]
                          : 'Present Value'}
                      </TableCell>
                      <TableCell>
                        {' '}
                        {rows[0]?.totalPresentValue
                          ? FormattedAmount.format(parseFloat(rows[0].totalPresentValue).toFixed(2))
                          : '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>

                  <TableFooter></TableFooter>
                </Table>
              </TableContainer>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
}
