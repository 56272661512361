import React from 'react';

import { Link } from 'react-router-dom';

import './ForgotPasswordSuccess.css';

const ForgotPasswordSuccess = () => {
  return (
    <div className="d-flex flex-column flex-root">
      <div className="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-forgot-on box-shadow-border">
        <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
          <div className="d-flex flex-column-fluid flex-center" />
          <div className="login-form login-forgot bg-white">
            <h1>Password reset request is sent successfully</h1>
            <p>
              If the supplied user exists then an email with instructions on how to reset your
              password has been sent to your mailbox. Please check there.
            </p>

            <Link
              to="/login"
              className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
            >
              Go to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
