/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import SystemRolesService from '@services/SystemRolesService';
import AppContext from '@contexts/AppContext';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

const EditUserRole = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [roleName, setRoleName] = useState();
  const [checkAllCheckboxes, setCheckAllCheckboxes] = useState(undefined);
  const appContext = useContext(AppContext);
  const [systemRole, setSystemRole] = useState({
    waiting: true,
    publicId: '',
    roleName: '',
  });
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });
  const [systemRolePermissions, setPermission] = React.useState([]);
  const handleChange = (task) => (event) => {
    task.isActive = !task.isActive;
    setState({ ...systemRolePermissions, [task.taskGroup]: task });
  };

  useEffect(() => {
    if (!systemRole.waiting) {
      setIsLoading(true);

      SystemRolesService.getAllPermittedTask()
        .then((response) => {
          let permittedTasks = response.data;

          let groupTask = permittedTasks.reduce((acc, curr) => {
            const itemExists = acc.find((item) => curr.taskGroup === item.taskGroup);

            if (
              systemRole.systemRolePermissions.filter(
                (item) => curr.publicId == item.permittedTaskPublicId,
              ).length > 0
            ) {
              curr.isActive = true;
            } else {
              curr.isActive = false;
            }

            if (checkAllCheckboxes != undefined) {
              if (checkAllCheckboxes === true) {
                curr.isActive = true;
              } else if (checkAllCheckboxes === false) {
                curr.isActive = false;
              }
            }

            if (itemExists) {
              itemExists.taskName = [...itemExists.taskName, curr];
            } else {
              acc.push({ taskGroup: curr.taskGroup, taskName: [curr] });
            }

            return acc;
          }, []);
          setPermission(groupTask);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [systemRole, checkAllCheckboxes]);

  useEffect(() => {
    const readSystemRole = async () => {
      if (id) {
        setIsLoading(true);

        let response = await SystemRolesService.read(id)
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });

        setSystemRole({
          ...response.data,
        });

        setRoleName(response.data.roleName);
      } else {
        setSystemRole({
          publicId: '',
          roleName: '',
          systemRolePermissions: [],
        });
      }
    };

    readSystemRole();
  }, []);

  const handleFormSubmit = (data) => {
    setIsLoading(true);

    const activePermissions = [];

    systemRolePermissions.forEach((item) => {
      activePermissions.push(...item.taskName.filter((sitem) => sitem.isActive));
    });

    if (id) {
      if (!(appContext?.userPermissions?.indexOf('Edit  User Roles') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      const entity = {
        publicId: id,
        isActive: true,
        roleName: roleName,
        permittedTasks: [...activePermissions],
      };

      SystemRolesService.update(entity)
        .then((response) => {
          appContext.toastMessage.message = 'User Role has been successfully updated.';
          navigate('/settings/user-roles');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (!(appContext?.userPermissions?.indexOf('Add User Roles') > -1)) {
        appContext.toastMessage.message = 'You do not have permissions to perform this action.';
        toast.error(appContext.toastMessage.message, { autoClose: 3000 });
        appContext.toastMessage.message = null;
        return;
      }

      const entity = {
        isActive: true,
        roleName: roleName,
        permittedTasks: [...activePermissions],
      };

      SystemRolesService.create(entity)
        .then((response) => {
          appContext.toastMessage.message = 'User Role has been successfully saved.';
          navigate('/settings/user-roles');
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCancelButton = () => {
    navigate('/settings/user-roles');
  };

  const checkAll = () => {
    setCheckAllCheckboxes(!checkAllCheckboxes);
  };

  if (isLoading) {
    return (
      <div className="container mt-10 mb-10">
        <div className="row">
          <div className="col-md-12 mx-auto bg-white box-shadow-border">
            <div className="p-10">
              <div className="mt-5 mb-10">
                <ReactLoading type="bars" color="#7E8299" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 mx-auto bg-white box-shadow-border">
          <div className="p-10">
            <div className="mt-5 mb-10">
              {errorMessage && (
                <div className="alert alert-custom alert-danger mb-10" role="alert">
                  <div className="alert-icon">
                    <i className="flaticon-warning" />
                  </div>
                  <div className="alert-text"> {errorMessage} </div>
                </div>
              )}

              <div className="mb-10">
                <h1>{id ? 'User Role Details' : 'New User Role'}</h1>
                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={() => {
                    checkAll();
                  }}
                >
                  Check / Uncheck all
                </button>
              </div>

              <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="user-role-name">User Role Name</label>
                      <input
                        type="text"
                        name="userRoleName"
                        id="userRoleName"
                        className="form-control"
                        ref={register({ required: 'User Role Name is required.' })}
                        defaultValue={roleName}
                        onChange={(e) => {
                          setRoleName(e.target.value);
                        }}
                      />
                      {errors.userRoleName && (
                        <Alert color="danger" className="mt-5">
                          <ErrorMessage errors={errors} name="userRoleName" />
                        </Alert>
                      )}
                    </div>
                  </div>
                </div>
                {systemRolePermissions.map((row, index) => {
                  return (
                    <div className="row" key={row.taskGroup}>
                      <div className="col">
                        <div className="form-group">
                          <div className="container p-0">
                            <div className="row">
                              <div className="col-md-12">
                                <label htmlFor="user-permissions">
                                  <strong>{row.taskGroup}</strong>
                                </label>
                              </div>
                              {row.taskName &&
                                row.taskName.map((task, index) => {
                                  return (
                                    <div className="col-md-3">
                                      <FormGroup row>
                                        <FormControlLabel
                                          key={task.publicId}
                                          control={
                                            <Checkbox
                                              checked={task.isActive}
                                              onChange={handleChange(task)}
                                              name={task.taskName}
                                              color="primary"
                                            />
                                          }
                                          label={task.taskName}
                                        />
                                      </FormGroup>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <button type="submit" className="btn btn-primary mr-5">
                  Save
                </button>
                <a href="##" onClick={handleCancelButton} className="btn btn-secondary">
                  Cancel
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditUserRole;
