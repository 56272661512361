import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';

function FileRenamePopup({
  fileNameModal,
  togglefileName,
  handleRenameFileNameFormSubmit,
  setDocumentName,
  documentName,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  return (
    <Modal isOpen={fileNameModal} toggle={togglefileName}>
      <ModalHeader toggle={togglefileName}>Rename File</ModalHeader>
      <ModalBody>
        <form method="POST" onSubmit={handleSubmit(handleRenameFileNameFormSubmit)} noValidate>
          <div className="form-group">
            <label htmlFor="fileName">File Name</label>
            <input
              id="fileName"
              name="fileName"
              ref={register({ required: 'File Name is required.' })} // Explicitly passing ref instead of prop spreading
              className="form-control"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
            />
            {errors.fileName && (
              <Alert color="danger" className="mt-2">
                <ErrorMessage errors={errors} name="fileName" />
              </Alert>
            )}
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit(handleRenameFileNameFormSubmit)}
        >
          Save
        </button>
        <button type="button" className="ml-2 btn btn-secondary" onClick={togglefileName}>
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
}

FileRenamePopup.propTypes = {
  fileNameModal: PropTypes.bool.isRequired,
  togglefileName: PropTypes.func.isRequired,
  handleRenameFileNameFormSubmit: PropTypes.func.isRequired,
  setDocumentName: PropTypes.func.isRequired,
  documentName: PropTypes.string.isRequired,
};

export default FileRenamePopup;
