import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import UserRolesGrid from './UserRolesGrid';
import { toast } from 'react-toastify';
import { Row, Col } from 'reactstrap';
import AppContext from '@contexts/AppContext';

const UserRoles = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-settings').click();
    document
      .querySelector('#kt_header_tab_8 > div > div > ul > li:nth-child(2)')
      .classList.add('menu-item-active');
  };

  useEffect(() => {
    if (appContext.toastMessage.message) {
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
    }
    makeMenuItemActive();
  }, []);

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>User Roles</h1>
              </div>
              <Link
                to="/settings/user-roles/new-user-role"
                className="btn btn-primary"
                style={{
                  backgroundColor: 'var(--green)',
                  borderColor: 'var(--green)',
                }}
              >
                New User Role
              </Link>
            </div>

            <Row>
              <Col md="12">
                <UserRolesGrid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRoles;
