import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/Application/',
});

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => resError(error),
);

class ApplicationsService {
  constructor() {
    if (!ApplicationsService.instance) {
      ApplicationsService.instance = this;
    }
    return ApplicationsService.instance;
  }

  search = (filters, orderBy, orderDir, p, fields, perPage) => {
    return api.post('/search', {
      filters: Object.keys(filters).length > 0 ? filters : null,
      orderBy,
      orderDir,
      page: p,
      fields: undefined,
      perPage: parseInt(perPage),
    });
  };

  create = (entity) => {
    return api.post('/', entity);
  };

  read = (id) => {
    return api.get('/' + id);
  };

  update = (entity) => {
    return api.put('/', entity);
  };

  delete = (id) => {
    return api.delete('/' + id);
  };

  autoSuggest = (value) => {
    return api.post('/search', { filters: { displayName: value }, perPage: 20, page: 1 });
  };

  calculateHCV = (applicationPublicId, perc) => {
    let percentage = parseFloat(perc);
    return api.post('/calculateHCV', { applicationPublicId, percentage });
  };

  nextadvanceno = (id) => {
    return api.get('nextadvanceno/' + id);
  };
}

const instance = new ApplicationsService();
Object.freeze(instance);

export default instance;
