import axios from 'axios';
import { auth, resError } from '@services/Helpers/ServiceInterceptor';
import history from '@src/history';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => auth(config));
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('lasuris-user');
      localStorage.removeItem('lasuris-jwt');
      localStorage.setItem('isUserAuthenticated', false);
      navigate('/login');
      window.location.reload(false);
    } else if (error.response.status === 404) {
      navigate('/not-found');
      window.location.reload(false);
    } else if (error.response.status === 406) {
      try {
        const { data } = error.response;
        // Read blob file
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const res = e.target?.result;
          const { errors } = JSON.parse(res);
          toast.error(errors, { autoClose: 3000 });
        };

        let file = fileReader.readAsText(data);
      } catch {
        toast.error(error.response.data.errors ?? error.message, { autoClose: 3000 });
      }

      //toast.error(error.response.data.errors ?? error.message, { autoClose: 3000 });
    } else {
      toast.error('An error has occured. Please contact your system administrator.', {
        autoClose: 3000,
      });
    }
    throw new Error(error.response.data.errors ?? error.message);
  },
);

class GridService {
  constructor() {
    if (!GridService.instance) {
      GridService.instance = this;
    }
    return GridService.instance;
  }

  search = (endpoint, filters, orderBy, orderDir, p, fields, perPage) => {
    return api.post(`${endpoint}search`, {
      filters: Object.keys(filters).length > 0 ? filters : null,
      orderBy,
      orderDir,
      page: p,
      fields: undefined,
      perPage: parseInt(perPage),
    });
  };

  create = (endpoint, entity, config = null) => {
    return api.post(`${endpoint}`, entity, config);
  };

  read = (endpoint, config = null) => {
    return api.get(`${endpoint}`, config);
  };

  update = (endpoint, entity) => {
    return api.put(`${endpoint}`, entity);
  };

  delete = (endpoint) => {
    return api.delete(`${endpoint}`);
  };
}

const instance = new GridService();
Object.freeze(instance);

export default instance;
