import React, { useEffect, useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import GridComponent from '@components/Grid/GridComponent';
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import { useForm } from 'react-hook-form';
import SystemRolesService from '@services/SystemRolesService';
import { useNavigate, useLocation } from 'react-router';

import UserService from '@services/UserService';
// import UserRoles from '../../UserRoles/UserRoles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import User from '../User/User';

const UserManagePermissions = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  const [makeEverythingActive, setMakeEverythingActive] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState('');
  const [orderDir, setOrderDir] = useState('ASC');
  const [globalRoles, setGlobalRoles] = useState([]);

  const [user, setUser] = useState({
    publicId: '',
    firstName: '',
    lastName: '',
    password: '',
    userRole: '',
    workingPhoneNo: '',
    workingPhoneExt: '',
    mobilePhoneNo: '',
    emailAddress: '',
    approvalLimit: '',
    frontEndAccountManagerFeePerc: '',
    backEndAccountManagerFeePerc: '',
    notes: '',
  });

  const [userBackup, setUserBackup] = useState({
    publicId: '',
    firstName: '',
    lastName: '',
    password: '',
    userRole: '',
    workingPhoneNo: '',
    workingPhoneExt: '',
    mobilePhoneNo: '',
    emailAddress: '',
    approvalLimit: '',
    frontEndAccountManagerFeePerc: '',
    backEndAccountManagerFeePerc: '',
    notes: '',
  });

  const [systemRole, setSystemRole] = useState({
    waiting: true,
    publicId: '',
    roleName: '',
  });

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-settings').click();
    document
      .querySelector('#kt_header_tab_8 > div > div > ul > li:nth-child(1)')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  useEffect(() => {
    if (appContext.toastMessage.message) {
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
      appContext.toastMessage.message = null;
    }
  });

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (location.state?.user) {
      setUser(location.state.user);
      setUserBackup(location.state.user);
    } else {
      if (user.publicId) navigate('/settings/users/' + user.publicId);
      else navigate('/settings/users/new-user/');
    }
  }, []);

  useEffect(() => {
    if (user.roles && user.roles.length > 0) {
      searchByRoles(user.roles);
    }
  }, [user]);

  const searchByRoles = (_userRoles = []) => {
    let systemRolePublicId = _userRoles.map((item) => item.systemRolePublicId);

    if (systemRolePublicId.length === 0) return;

    SystemRolesService.search1(filters, orderBy, orderDir, 1, null, rowsPerPage, systemRolePublicId)
      .then((response) => {
        if (!response.data) return;

        setGlobalRoles(
          response.data.data.map((item) => {
            const _roleItem = item;
            _roleItem.systemRolePermissions = _roleItem.systemRolePermissions.map((permission) => {
              const _userRole = _userRoles.filter(
                (urole) => urole.systemRolePublicId == _roleItem.publicId,
              )[0];

              if (
                _userRole &&
                _userRole['rolePermisssions'] &&
                _userRole.rolePermisssions.length > 0 &&
                _userRole.rolePermisssions.filter(
                  (urpItem) => urpItem.permittedTaskPublicId == permission.permittedTaskPublicId,
                ).length > 0
              ) {
                permission['isActive'] = true;
              } else {
                permission['isActive'] = false;
              }

              if (makeEverythingActive != undefined) {
                if (makeEverythingActive === true) {
                  permission['isActive'] = true;
                } else if (makeEverythingActive === false) {
                  permission['isActive'] = false;
                }
              }

              return permission;
            });

            return _roleItem;
          }),
        );
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (index, subIndex) => {
    const _globalRoles = [...globalRoles];
    _globalRoles[index].systemRolePermissions[subIndex].isActive =
      !_globalRoles[index].systemRolePermissions[subIndex].isActive;
    setGlobalRoles([..._globalRoles]);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const _user = { ...user };
    const formattedRoles = globalRoles.map((item) => {
      item.rolePermisssions = item.systemRolePermissions.filter(
        (permission) => permission.isActive,
      );
      return item;
    });

    _user.roles = formattedRoles.map((val) => {
      let rolepermissions = val.rolePermisssions.map((srp) => {
        return {
          publicId: srp.publicId,
          permittedTaskPublicId: srp.permittedTaskPublicId,
          userRolePublicId: user.publicId ? user.publicId : null,
          taskName: srp.taskName,
          taskGroup: srp.taskGroup,
        };
      });

      return {
        publicId: val.publicId,
        userPublicId: user.publicId ? user.publicId : null,
        systemRolePublicId: val.publicId,
        roleName: val.roleName,
        rolePermisssions: rolepermissions,
      };
    });

    if (id) navigate('/settings/users/' + id, { user: { ..._user } });
    else navigate('/settings/users/new-user/', { user: { ..._user } });
  };

  const handleCancelButton = () => {
    if (id) navigate('/settings/users/' + id, { user: userBackup });
    else navigate('/settings/users/new-user/', { user: userBackup });
  };

  const checkAll = () => {
    if (makeEverythingActive === false || makeEverythingActive === undefined) {
      setMakeEverythingActive(true);
    } else {
      setMakeEverythingActive(false);
    }
  };

  useEffect(() => {
    searchByRoles(user.roles);
  }, [makeEverythingActive]);

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Permissions</h1>
              </div>
            </div>
            <form onSubmit={handleFormSubmit} noValidate>
              <Row>
                <Col md="12">
                  <Row>
                    <Col md="3">
                      <button
                        className="btn btn-secondary mr-5"
                        type="button"
                        onClick={() => {
                          checkAll();
                        }}
                      >
                        Check / Uncheck all
                      </button>
                    </Col>
                  </Row>
                  <div className="container mb-10 p-0">
                    <div className="row">
                      <div className="col-md-12 mx-auto bg-white">
                        <div>
                          <div className="mt-5 mb-10">
                            {globalRoles.map((row, index) => {
                              if (row.systemRolePermissions && row.systemRolePermissions.length > 0)
                                return (
                                  <div className="row" key={row.roleName}>
                                    <div className="col">
                                      <div className="form-group">
                                        <div className="container p-0">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <label htmlFor="user-permissions">
                                                <strong>{row.roleName}</strong>
                                              </label>
                                            </div>
                                            {row.systemRolePermissions.map(
                                              (sysPermission, index2) => {
                                                return (
                                                  <div className="col-md-3">
                                                    <FormGroup row>
                                                      <FormControlLabel
                                                        key={sysPermission.publicId}
                                                        control={
                                                          <Checkbox
                                                            checked={sysPermission.isActive}
                                                            onChange={() => {
                                                              handleChange(index, index2);
                                                            }}
                                                            name={sysPermission.taskName}
                                                            color="primary"
                                                          />
                                                        }
                                                        label={sysPermission.taskName}
                                                      />
                                                    </FormGroup>
                                                  </div>
                                                );
                                              },
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                            })}
                            <button type="submit" className="btn btn-primary mr-5">
                              Save
                            </button>
                            <a href="##" onClick={handleCancelButton} className="btn btn-secondary">
                              Cancel
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagePermissions;
