import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component }) => {
  const jwt = localStorage.getItem('lasuris-jwt');
  if (jwt) {
    // authorised so return component
    return <Component />;
  }
  return <Navigate to={'/login'} />;

};

export default PrivateRoute;
