import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from 'reactstrap';
import UserService from '@services/UserService';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';

const ChangePassword = () => {
  var isChanged = false;
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const lasurisUser = JSON.parse(localStorage.getItem('lasuris-user'));
  const [user, setUser] = useState({ displayName: '' });
  const [profile, setProfile] = useState({ password: '' });
  const navigate = useNavigate();
  const { register, handleSubmit, watch, errors, formState, getValues } = useForm({
    mode: 'onBlur',
  });

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-home').click();
  };

  useEffect(() => makeMenuItemActive(), []);

  useEffect(() => {
    setIsLoading(true);

    UserService.read(lasurisUser.publicId)
      .then((response) => {
        if (response.data) {
          setUser(response.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const editUser = (e) => {
    var usr = { ...user };
    isChanged = true;
    usr[e.target.name] = e.target.value;
    setUser(usr);
  };

  const form = useRef();

  const handleFormSubmit = (profile) => {
    setErrorMessage(null);

    const usr = { ...user };

    if (usr.password == '') usr.password = undefined;
    usr.displayName = undefined;
    usr.password = profile.password;
    usr.approvalLimit = undefined;
    usr.isActive = undefined;
    usr.isDeleted = undefined;
    usr.notes = undefined;
    usr.roles = undefined;
    usr.userId = undefined;
    usr.refreshToken = undefined;
    usr.workingPhoneExt = undefined;

    setUser(usr);

    setIsLoading(true);

    UserService.update(usr)
      .then(() => {
        toast.success('User has been successfully updated.');
        navigate('/dashboard');
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div className="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
        <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <button
              type="button"
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
            <div className="d-flex align-items-baseline flex-wrap mr-5">
              <h5 className="text-dark font-weight-bold my-1 mr-5">Your Profile</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="d-flex flex-row">
            <div
              className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
              id="kt_profile_aside"
            >
              <div className="card card-custom card-stretch">
                <div className="card-body pt-4">
                  <div className="d-flex justify-content-end mb-8" />
                  <div className="d-flex align-items-center">
                    <div>
                      <a
                        href="##"
                        className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                      >
                        {user.displayName}
                      </a>
                      <div className="text-muted"></div>
                    </div>
                  </div>
                  <div className="mb-10" />
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                    <div className="navi-item mb-2">
                      <Link to="/profile" className="navi-link py-4 ">
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path
                                  d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  opacity="0.3"
                                />
                                <path
                                  d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">Personal Information</span>
                      </Link>
                    </div>

                    <div className="navi-item mb-2">
                      <Link to="/profile/changepasssword" className="navi-link py-4 active">
                        <span className="navi-icon mr-2">
                          <span className="svg-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                                <path
                                  d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                                <path
                                  d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                        <span className="navi-text font-size-lg">Change Password</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-row-fluid ml-lg-8">
              <form ref={form} onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="card card-custom">
                  <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                      <h3 className="card-label font-weight-bolder text-dark">Change Password</h3>
                      <span className="text-muted font-weight-bold font-size-sm mt-1"></span>
                    </div>
                    <div className="card-toolbar">
                      <button type="submit" className="btn btn-success mr-2">
                        Save
                      </button>
                      <Link to="/dashboard" className="btn btn-secondary">
                        Cancel
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                        New Password
                      </label>

                      <Row>
                        <Col lg="9" xl="6">
                          {/* <label htmlFor="password">Password</label> */}
                          <input
                            type="password"
                            placeholder="New Password"
                            name="password"
                            className="form-control"
                            style={{ width: '200px' }}
                            defaultValue={profile.password}
                            ref={register({
                              required: 'Password is required',
                              pattern: {
                                value:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W\_])[A-Za-z\d\W\_]{8,}$/,
                                message:
                                  'Password must be at least 8 characters and include at least: 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character.',
                              },
                              maxLength: { value: 200, message: 'Max length is 200 characters' },
                            })}
                          />
                          {errors.password && (
                            <Alert color="danger" className="mt-3" style={{ width: '370px' }}>
                              <ErrorMessage errors={errors} name="password" />
                            </Alert>
                          )}
                        </Col>
                      </Row>

                      {/* <div className="col-lg-9 col-xl-6">
                        {successMessage && <Alert color="success">{successMessage}</Alert>}
                        {errorMessage && <Alert color="danger" className="mt-3">{errorMessage}</Alert>}
                      //   <input
                      //     type="password"
                      //     name="password"
                      //     className="form-control form-control-lg form-control-solid"
                      //     defaultValue=""
                      //     onKeyUp={editUser}
                      //     placeholder="New password"
                      //   />
                      // </div> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
