import React, { useEffect, useContext, useRef, useState } from 'react';
import { Row, Col, Input, Label, Alert, Button, Form, FormGroup, Container } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import Grid from './Grid';
import MultiSelect from 'react-multi-select-component';
import AttorneyService from '@services/AttorneyService';
import AttorneyAutoSuggest from '@components/AttorneyAutoSuggest/AttorneyAutoSuggest';
import LawFirmAutoSuggest from '@components/LawFirmAutoSuggest/LawFirmAutoSuggest';
import PortfolioService from '@services/PortfolioService';
import GridService from '@services/GridService';
import AccidentTypeService from '@services/AccidentTypeService';
import './Styles.css';

const FilteringFields = (props) => {
  let dateFilter = useRef();
  let attorneyFilter = useRef();
  let lawFirmFilter = useRef();

  const clearFilters = () => {
    setDateValue('This month');
    dateFilter.current.value = 'This month';
    appContext.attorneyDataReportFilters = {};
    setDaterange(false);
    setFromDate(null);
    setToDate(null);
    setSelectedAttorney({});
    setSelectedLawFirm({});
    props.getAll();
  };
  const [dateRange, setDaterange] = useState(false);
  const [dateValue, setDateValue] = useState('This month');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [attorney, setAttorney] = useState({
    displayName: '',
    attorneyPublicId: '',
  });
  const [selectedAttorney, setSelectedAttorney] = useState({});
  const [selectedAttorneyError, setSelectedAttorneyError] = useState(undefined);
  const [selectedLawFirm, setSelectedLawFirm] = useState({});
  function changeFunc() {
    const dateFilter = document.getElementById('dateFilter');
    const selectedDate = dateFilter.options[dateFilter.selectedIndex].value;
    if (selectedDate === 'Custom') {
      setDaterange(true);
      setDateValue(null);
    } else {
      setDaterange(false);
      setDateValue(selectedDate);
      setFromDate(null);
      setToDate(null);
    }
  }
  function changeFromDate() {
    const fromDate = document.getElementById('fromDate');
    const selectedFromDate = fromDate.value;
    setFromDate(selectedFromDate);
  }
  function changeToDate() {
    const toDate = document.getElementById('toDate');
    const selectedToDate = toDate.value;
    if (fromDate < selectedToDate) {
      setToDate(selectedToDate);
    } else {
      if (fromDate != null) {
      } else {
        alert('Select from date first');
      }
    }
  }
  function selectDateRange() {
    if (dateValue != null) {
      return dateValue;
    } else {
      if (fromDate != null && toDate != null) {
        let dateRange = {
          fromDate: new Date(fromDate).toISOString(),
          toDate: new Date(toDate).toISOString(),
        };
        return dateRange;
      } else {
        return '';
      }
    }
  }
  const appContext = useContext(AppContext);
  const attorneyDataReportFilters = appContext.attorneyDataReportFilters;
  useEffect(() => {
    const isFiltersEmpty = Object.keys(attorneyDataReportFilters).length == 0;

    //Retaining data by checking with appcontext
    if (!isFiltersEmpty) {
      if (attorneyDataReportFilters.dateRange) {
        if (typeof attorneyDataReportFilters.dateRange == 'string') {
          dateFilter.current.value = attorneyDataReportFilters.dateRange;
          setDateValue(attorneyDataReportFilters.dateRange);
          setDaterange(false);
          setFromDate(null);
          setToDate(null);
        } else {
          dateFilter.current.value = 'Custom';
          setDaterange(true);
          setDateValue('This month');
          setFromDate(attorneyDataReportFilters.dateRange.fromDate.split('T')[0]);
          setToDate(attorneyDataReportFilters.dateRange.toDate.split('T')[0]);
        }
      }
      if (attorneyDataReportFilters.attorney) {
        setSelectedAttorney(attorneyDataReportFilters.attorney);
      }
      if (attorneyDataReportFilters.lawFirm) {
        setSelectedLawFirm(attorneyDataReportFilters.lawFirm);
      }
    }
  }, []);

  const search = () => {
    let filters = {
      dateRange: selectDateRange(),
      attorney: selectedAttorney,
      lawFirm: selectedLawFirm,
    };

    if (filters.dateRange === '') delete filters.dateRange;

    props.searchWithFilters(filters);
  };

  return (
    <>
      <Col md="2" className="float-left" style={{ backgroundColor: '#e9e9e9' }}>
        <Row className="mt-5">
          <Col md="6">
            <h2>Filters</h2>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="dateRange">Application Date Range</label>
          </Col>
          <Col md="12">
            <select id="dateFilter" onChange={changeFunc} className="form-control" ref={dateFilter}>
              <option value="This month">This month</option>
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="This week">This week</option>
              <option value="Last week">Last week</option>
              <option value="Last month">Last month</option>
              <option value="YTD">YTD</option>
              <option value="Custom">Custom</option>
            </select>
          </Col>
        </Row>
        {dateRange == true && (
          <>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">From</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeFromDate}
                  type="date"
                  id="fromDate"
                  className="form-control"
                  required="required"
                  defaultValue={fromDate}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md="12">
                <label htmlFor="dateRange">To</label>
              </Col>
              <Col md="12">
                <input
                  onChange={changeToDate}
                  type="date"
                  id="toDate"
                  className="form-control"
                  required="required"
                  defaultValue={toDate}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="lawFirm">Law Firm</label>
          </Col>
          <Col md="12">
            <LawFirmAutoSuggest
              ref={lawFirmFilter}
              selectedLawFirm={(value) => {
                setSelectedLawFirm(value);
              }}
              selectedLawFirmName={() => {}}
              defaultLawFirm={selectedLawFirm}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md="12">
            <label htmlFor="attorney">Attorney</label>
          </Col>
          <Col md="12">
            <AttorneyAutoSuggest
              ref={attorneyFilter}
              selectedAttorney={(value) => {
                setSelectedAttorney(value);
              }}
              selectedAttorneyName={() => {}}
              defaultAttorney={selectedAttorney}
            />
            {selectedAttorneyError && (
              <Alert color="danger" className="mt-5">
                <span>Select an attorney</span>
              </Alert>
            )}
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col md="6" className="pr-0">
            <span className="a-link">
              <a className="btn clear-filter-button" onClick={clearFilters}>
                Clear filters
              </a>
            </span>
          </Col>
          <Col md="6">
            <span className="a-link float-right">
              <a className="btn btn-primary search-btn" onClick={search}>
                <i class="fas fa-search"></i>
                Search
              </a>
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default FilteringFields;
