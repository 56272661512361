import React, { useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppContext from '@contexts/AppContext';
import history from '@src/history';

import ClientsGrid from './components/Grid';

const Clients = () => {
  const appContext = useContext(AppContext);

  const makeMenuItemActive = () => {
    document.querySelector('#menuitem-underwriting').click();
    document
      .querySelector('#kt_header_tab_2 > div > ul > li:nth-child(3)')
      .classList.add('menu-item-active');
  };

  useEffect(() => makeMenuItemActive(), []);

  useEffect(() => {
    if (appContext.toastMessage.message)
      toast.success(appContext.toastMessage.message, { autoClose: 3000 });
    appContext.toastMessage.message = null;
  }, [appContext.toastMessage.message, history]);

  return (
    <div className="container mt-10 mb-10">
      <div className="row">
        <div className="col-md-12 bg-white box-shadow-border">
          <div className="p-10">
            <div className="d-flex mt-5 mb-10">
              <div className="mr-auto">
                <h1>Clients</h1>
              </div>
              <Link
                to="/underwriting/clients/new-client"
                className="btn btn-primary"
                style={{
                  backgroundColor: 'var(--green)',
                  borderColor: 'var(--green)',
                }}
              >
                New Client
              </Link>
            </div>
            <Row>
              <Col md="12">
                <ClientsGrid />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
