import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import UserService from '../../services/UserService';

class AccountManagersAutoSuggest extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultAccountManager?.displayName || '',
      suggestions: [],
    };
  }

  componentDidMount() {
    const { defaultAccountManager } = this.props;
    this.setState({
      value: defaultAccountManager?.displayName || '',
    });
  }

  componentDidUpdate(prevProps) {
    const { defaultAccountManager } = this.props;
    if (prevProps.defaultAccountManager !== defaultAccountManager) {
      this.setState({
        value: defaultAccountManager?.displayName || '',
      });
    }
  }

  getSuggestionValue = (suggestion) => {
    const { selectedAccountManager, selectedAccountManagerName } = this.props;
    selectedAccountManager(suggestion);
    selectedAccountManagerName(suggestion.displayName);
    return suggestion.displayName;
  };

  static renderSuggestion(suggestion) {
    return <span>{suggestion.displayName}</span>;
  }

  onChange = (event, { newValue }) => {
    const { selectedAccountManager, selectedAccountManagerName } = this.props;
    this.setState({ value: newValue });

    if (!newValue) {
      selectedAccountManagerName('');
      selectedAccountManager({});
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    UserService.searchAccountManagers(value).then((response) => {
      this.setState({ suggestions: response.data.data });
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  render() {
    const { value, suggestions } = this.state;
    const { makeDisabled } = this.props;

    const inputProps = {
      placeholder: 'Search Account Manager...',
      value,
      onChange: this.onChange,
      disabled: makeDisabled,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

AccountManagersAutoSuggest.propTypes = {
  selectedAccountManager: PropTypes.func.isRequired,
  selectedAccountManagerName: PropTypes.func.isRequired,
  defaultAccountManager: PropTypes.shape({
    displayName: PropTypes.string,
  }),
  makeDisabled: PropTypes.bool,
};

AccountManagersAutoSuggest.defaultProps = {
  defaultAccountManager: null,
  makeDisabled: false,
};

export default AccountManagersAutoSuggest;
